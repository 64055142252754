import React, { useState, forwardRef } from "react";

// components
import { Paper } from "../index";

const InputPassword = forwardRef(
  (
    {
      dataTestId,
      name,
      label,
      placeholder = "",
      error = "",
      value,
      onChange,
      className = "mb-4",
      InfoVisible = false,
      inputClassName = "",
      ...rest
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const [info, setInfo] = useState(false);

    return (
      <div className={className}>
        <label>
          {label && <span className="block text-prime-light">{label}</span>}

          <div className="flex relative">
            <div className="relative flex-1">
              <input
                data-testid={dataTestId}
                ref={ref}
                type={visible ? "text" : "password"}
                format=""
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={`w-full rounded-md border-gray-300 shadow-sm bg-gray-50 focus:border focus:border-prime focus:outline-none pr-9 ${inputClassName}`}
                onFocus={() => setInfo(true)}
                onBlur={() => setInfo(false)}
                {...rest}
              />

              <div
                className="absolute top-0 right-0 h-full flex items-center justify-center cursor-pointer px-2"
                onClick={() => setVisible((n) => !n)}
              >
                <i className="fa-regular fa-eye text-md text-prime-light" />
              </div>
            </div>

            {/* info */}
            {InfoVisible && (
              <div className="relative w-0 h-0">
                {/* <Button
                  dataTestId="test-id"
                  label={<i className="fa-solid fa-info text-xs text-terc"></i>}
                  variant="icon-flat"
                  themecolor="prime"
                  width="w-10"
                  height="h-10"
                  textSize="text-xl"
                  className="ml-2"
                /> */}
                <div className="absolute top-0 left-0 w-[30px] h-[40px] cursor-pointer flex justify-center items-center">
                  <i className="fa-solid fa-info text-xs text-terc" />
                </div>

                {info && (
                  <Paper
                    bgColor="bg-grey-light"
                    className="absolute z-20 bottom-0 right-0 px-4 py-2 text-white text-sm w-[160px] ml-[-160px] mt-[-160px]"
                  >
                    <div className="relative">
                      <p className="text-xs">The password must</p>
                      <p className="text-xs mb-1">contain at least:</p>
                      <p className="text-xs">- 6 characters</p>
                      <p className="text-xs">- One lower-case letter</p>
                      <p className="text-xs">- One upper-case letter</p>
                      <p className="text-xs">- One number</p>
                      <p className="text-xs">- One symbol:</p>
                      <p className="text-xs pl-2">!@#$%^&*-+()_;=?</p>
                      {/* carrot */}
                      <div
                        className="absolute bottom-0 right-0 mb-[-1.2rem] ml-[60px]"
                        style={{
                          width: 0,
                          height: 0,
                          left: 0,
                          borderLeft: "8px solid transparent",
                          borderRight: "8px solid transparent",
                          borderTop: "12px solid #1661D5",
                        }}
                      />
                    </div>
                  </Paper>
                )}
              </div>
            )}
          </div>

          {error && <div className="mb-2 text-sm text-error">{error}</div>}
        </label>
      </div>
    );
  }
);

export default InputPassword;
