import { Paper, Typography } from "../../components";

function PopTokenExplainer() {
  return (
    <>
      <Paper>
        <div className="flex flex-col sm:flex-row items-center">
          <i className="fa-solid fa-coins text-gold text-6xl mb-2 sm:mb-0 sm:mr-8" />
          <Typography type="title" className="text-3xl mb-2 sm:mb-0 sm:mr-6 sm:whitespace-nowrap">
            Pop-token Explainer
          </Typography>
          <p className="flex-shrink">
            Pop-tokens are a cyber currency that simplifies project payments and unlocks the ability
            to provide discounts and rewards. Users buy pop-tokens and use them to purchase
            projects.
          </p>
        </div>
      </Paper>
    </>
  );
}

export default PopTokenExplainer;
