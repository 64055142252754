import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

// components
import {
  Switch,
  Radio,
  Checkbox,
  Input,
  InputDate,
  Button,
  ModalConfirm,
  Select,
} from "../../../components";

// res
import { useToast } from "../../../context";
import Resources from "../../../resources/Resources";

// context

// services
import { errorMsgForUser, ProfileService } from "../../../services";

const WorkAvailabilityBlock = ({ profile, getProfile, profileEditsLocked }) => {
  const [loader, setLoader] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [workInformation, setWorkInformation] = useState({
    available: false,
    workload: "full-time",
    workloadOther: "",
    // billing: 'fixed',
    days: [],
    workingHoursStart: "",
    workingHoursEnd: "",
    meetingHoursStart: "",
    meetingHoursEnd: "",
  });
  const [timezones, setTimezones] = useState([]);
  const [timezone, setTimezone] = useState("");

  // context
  const { showToast } = useToast();
  // const { authContext } = useAuth();

  const handleDaysChange = (day) => {
    let arr = [...(workInformation.days || [])];

    if (arr.includes(day)) {
      arr = arr.filter((item) => item !== day);
    } else {
      arr.push(day);
    }

    setWorkInformation((n) => ({
      ...n,
      days: arr,
    }));
  };

  // // Send email to FSM that profile has been changed
  // const sendEmail = useCallback(() => {

  //     let emailReceiver = authContext?.user?.email;
  //     const emailForProd = "naime+fsm@cyberpopup.com";
  //     let message = `Cyber-expert ${profile?.responsible_person_first_name} ${profile?.responsible_person_last_name}, work availability has been updated!`

  //     if (process.env.NODE_ENV === "production") {
  //       emailReceiver = emailForProd;
  //     } else {
  //       message = `---------- this email on prod will be sent to ${emailForProd} <br />` + message
  //     }

  //   return UserService.sendEmail({
  //     subjects: "Cyber-expert profile updated",
  //     to_emails: emailReceiver,
  //     message,
  //   }).catch(() => showToast("error", "Error occurred while sending mail"));
  // }, [
  //   authContext.user.email,
  //   profile?.responsible_person_first_name,
  //   profile?.responsible_person_last_name,
  //   showToast,
  // ]);

  const handleUpdate = useCallback(() => {
    setLoader(true);
    return ProfileService.update({
      user_uid: profile.user_uid,
      work_availability: workInformation,
      time_zone: timezone,
    })
      .then(() => {
        showToast("success", "Work availability updated");
        getProfile();
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => {
        setLoader(false);
        setModalConfirm(false);
      });
  }, [profile.user_uid, workInformation, timezone, showToast, getProfile]);

  const toggleWorkAvailability = useCallback(
    (value) => {
      const updateObj = {
        ...workInformation,
        available: value,
      };
      setLoader(true);
      return ProfileService.update({
        user_uid: profile.user_uid,
        work_availability: updateObj,
      })
        .then(() => {
          showToast("success", "Work availability updated");
          // sendEmail();
          getProfile();
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => {
          setModalConfirm(false);
          setLoader(false);
        });
    },
    [getProfile, profile.user_uid, showToast, workInformation]
  );

  useEffect(() => {
    if (profile?.work_availability) {
      setWorkInformation(JSON.parse(profile.work_availability));
    }
  }, [profile.work_availability]);

  useEffect(() => {
    if (profile?.time_zone) {
      setTimezone(profile.time_zone);
    }
  }, [profile?.time_zone]);

  // useEffect(() => {
  //   console.log('workInformation:', workInformation);
  // }, [workInformation]);

  return (
    <div>
      {/* get resources */}
      <Resources setTimezones={setTimezones} />

      <div className="flex items-center pb-6">
        <div
          className={`p-2 border rounded-md ${
            workInformation?.available ? "border-transparent" : "border-warning"
          }`}
        >
          <Switch
            dataTestId="work-availability"
            name="work"
            className="mb-0"
            checked={workInformation?.available}
            onChange={(e) => toggleWorkAvailability(e.target.checked)}
            disabled={profileEditsLocked}
          />
        </div>
        <div className="ml-4">
          <p className="mb-0">Available for work?</p>
          <p className="text-prime-light">
            When work availability is off, new opportunities will not be sent to you.
          </p>
        </div>
      </div>

      {/* {workInformation?.available && ( */}
      {false && (
        <div>
          <Radio
            name="workload"
            mainLabel="Workload preference"
            radios={[
              {
                dataTestId: "full-time",
                label: "Full-time (8 hours daily)",
                value: "full-time",
              },
              {
                dataTestId: "part-time",
                label: "Part-time (4 hours daily)",
                value: "part-time",
              },
              { dataTestId: "other", label: "Other", value: "other" },
            ]}
            checked={workInformation.workload}
            onChange={(e) =>
              setWorkInformation((n) => ({
                ...n,
                workload: e.target.value,
              }))
            }
          />
          {/* If freelancer chooses other */}

          {workInformation.workload === "other" && (
            <Input
              dataTestId="workload-other"
              type="number"
              label="How many hours daily?"
              value={workInformation.workloadOther}
              onChange={(e) =>
                setWorkInformation((n) => ({
                  ...n,
                  workloadOther: e.target.value,
                }))
              }
            />
          )}

          {/* <Radio
            name="billing"
            mainLabel="Billing preference"
            radios={[
              {
                dataTestId: 'fixed',
                label: 'Fixed fee',
                value: 'fixed',
              },
              {
                dataTestId: 'hourly',
                label: 'Hourly',
                value: 'hourly',
              },
            ]}
            checked={workInformation.billing}
            onChange={e =>
              setWorkInformation(n => ({
                ...n,
                billing: e.target.value,
              }))
            }
          /> */}

          <div className="mb-6">
            <p className="block mb-2 text-lg text-prime-light">Availibale days per week</p>
            <Checkbox
              dataTestId="monday"
              className="mb-2"
              label="Monday"
              value="monday"
              checked={workInformation.days?.includes("monday")}
              onChange={(e) => handleDaysChange(e.target.value)}
            />
            <Checkbox
              dataTestId="tuesday"
              className="mb-2"
              label="Tuesday"
              value="tuesday"
              checked={workInformation.days?.includes("tuesday")}
              onChange={(e) => handleDaysChange(e.target.value)}
            />
            <Checkbox
              dataTestId="wednesday"
              className="mb-2"
              label="Wednesday"
              value="wednesday"
              checked={workInformation.days?.includes("wednesday")}
              onChange={(e) => handleDaysChange(e.target.value)}
            />
            <Checkbox
              dataTestId="thursday"
              className="mb-2"
              label="Thursday"
              value="thursday"
              checked={workInformation.days?.includes("thursday")}
              onChange={(e) => handleDaysChange(e.target.value)}
            />
            <Checkbox
              dataTestId="friday"
              className="mb-2"
              label="Friday"
              value="friday"
              checked={workInformation.days?.includes("friday")}
              onChange={(e) => handleDaysChange(e.target.value)}
            />
            <Checkbox
              dataTestId="saturday"
              className="mb-2"
              label="Saturday"
              value="saturday"
              checked={workInformation.days?.includes("saturday")}
              onChange={(e) => handleDaysChange(e.target.value)}
            />
            <Checkbox
              dataTestId="sunday"
              className="mb-4"
              label="Sunday"
              value="sunday"
              checked={workInformation.days?.includes("sunday")}
              onChange={(e) => handleDaysChange(e.target.value)}
            />
          </div>

          <Select
            dataTestId="time-zone"
            label="What timezone are you in? *"
            className="mb-6"
            keyForOptionValue="value"
            keyForOptionLabel="text"
            options={timezones}
            value={timezone}
            onChange={(e) => setTimezone(e.target.value)}
          />

          <p className="block mb-1 text-lg text-prime-light">Daily working hours</p>
          <div className="flex justify-between mb-4">
            <InputDate
              dataTestId="start-work"
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              timeFormat="h:mm aa"
              dateFormat="h:mm aa"
              timeIntervals={10}
              label="Start time"
              placeholder="H:mm am/pm"
              value={workInformation?.workingHoursStart || ""}
              onChange={(v) =>
                setWorkInformation((n) => ({
                  ...n,
                  workingHoursStart: moment(v).format("h:mm A"),
                }))
              }
            />
            <InputDate
              dataTestId="end-work"
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              timeFormat="h:mm aa"
              dateFormat="h:mm aa"
              timeIntervals={10}
              label="End time"
              placeholder="H:mm am/pm"
              value={workInformation?.workingHoursEnd || ""}
              onChange={(v) =>
                setWorkInformation((n) => ({
                  ...n,
                  workingHoursEnd: moment(v).format("h:mm A"),
                }))
              }
            />
          </div>

          {/*
          <p className="block mb-1 text-lg text-prime-light">Daily meeting hours</p>
          <div className="flex justify-between mb-4">
            <InputDate
              dataTestId="start-meet"
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              timeFormat="h:mm aa"
              dateFormat="h:mm aa"
              timeIntervals={10}
              label="Start time"
              placeholder="H:mm am/pm"
              value={workInformation?.meetingHoursStart || ''}
              onChange={v =>
                setWorkInformation(n => ({
                  ...n,
                  meetingHoursStart: moment(v).format('h:mm A'),
                }))
              }
            />
            <InputDate
              dataTestId="end-meet"
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              timeFormat="h:mm aa"
              dateFormat="h:mm aa"
              timeIntervals={10}
              label="End time"
              placeholder="H:mm am/pm"
              value={workInformation?.meetingHoursEnd || ''}
              onChange={v =>
                setWorkInformation(n => ({
                  ...n,
                  meetingHoursEnd: moment(v).format('h:mm A'),
                }))
              }
            />
          </div>
            */}

          <Button
            dataTestId="save-workload-availability-btn"
            label="Update"
            variant="full"
            themecolor="prime"
            className="block mx-auto"
            disabled={!timezone}
            onClick={() => {
              setModalConfirm(true);
            }}
          />
        </div>
      )}

      {/* modal confirm */}
      {modalConfirm && (
        <ModalConfirm
          title="Are you sure you want to change your work availability information?"
          closeModal={() => setModalConfirm(false)}
          onConfirm={() => handleUpdate()}
          loader={loader}
        />
      )}
    </div>
  );
};

export default WorkAvailabilityBlock;
