import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

// context
import { useAuth } from "../../context";

const APP_ENV = import.meta.env.VITE_APP_ENV;

const linksCustomer = [
  {
    dataTestid: "link-dashboard",
    to: "/app/home",
    text: "Dashboard",
    nextRoute: true,
    disabled: false,
  },
  {
    dataTestid: "link-projects",
    to: "/app/projects",
    text: "Projects",
    // temp disabled in 'production'
    // disabled: APP_ENV === 'production',
    nextRoute: true,
    disabled: false,
  },
  {
    dataTestid: "link-payments",
    to: "/payments",
    text: "Payments",
    // temp disabled in 'production'
    // disabled: APP_ENV === 'production',
    disabled: false,
  },
];

const linksFreelancer = [
  {
    dataTestid: "link-freelancer",
    to: "/dashboard",
    text: "Dashboard",
    disabled: false,
  },
  {
    dataTestid: "link-projects",
    to: "/projects",
    text: "Projects",
    // temp disabled in 'production'
    // disabled: APP_ENV === 'production',
    disabled: false,
  },
  // {
  //   dataTestid: "link-payments",
  //   to: "/payments",
  //   text: "Payments",
  //   // temp disabled in 'production'
  //   // disabled: APP_ENV === 'production',
  //   disabled: false,
  // },
];

const linksCsm = [
  {
    dataTestid: "link-freelancer",
    to: "/dashboard",
    text: "Dashboard",
    disabled: APP_ENV === "production",
    // disabled: false,
  },
  {
    dataTestid: "link-users",
    to: "/user-management",
    text: "Users",
    // temp disabled in 'production'
    // disabled: APP_ENV === 'production',
    disabled: false,
  },
  {
    dataTestid: "link-projects",
    to: "/project-templates",
    text: "Project templates",
    // temp disabled in 'production'
    // disabled: APP_ENV === 'production',
    disabled: false,
  },
];

const Navigation = () => {
  // context
  const { authContext } = useAuth();

  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (authContext?.role === "Customer") {
      setLinks(linksCustomer);
    } else if (authContext?.role === "Freelancer" || authContext?.role === "Enthusiast") {
      setLinks(linksFreelancer);
    } else if (authContext?.role === "Csm") {
      setLinks(linksCsm);
    }
  }, [authContext.role]);

  return (
    <ul className="flex justify-center align-baseline h-2/3 items-strech text-prime">
      {links.map((link) => {
        if (link.nextRoute)
          return (
            <li key={link.to} className="min-w-[7rem]">
              <a
                href={link.to}
                className={`min-h-full flex items-center justify-center text-terc p-3 hover:text-sec delay-150 transition-all ${
                  link.disabled ? "pointer-events-none" : ""
                }`}
              >
                {link.text}
              </a>
            </li>
          );
        else
          return (
            <li key={link.to} className="min-w-[7rem]">
              <NavLink
                data-testid={link.dataTestid}
                to={link.to}
                // TODO: in production 'Projects' and 'Payments' pages are disabled !!!
                className={`min-h-full flex items-center justify-center text-terc p-3 hover:text-sec delay-150 transition-all ${
                  link.disabled ? "pointer-events-none" : ""
                }`}
                style={({ isActive }) => ({
                  // backgroundColor: isActive ? "#676197" : "",
                  color: isActive && "#bc297f",
                  borderBottom: isActive && "2px solid #bc297f",
                  fontWeight: isActive && "700",
                })}
              >
                {link.text}
              </NavLink>
            </li>
          );
      })}
    </ul>
  );
};

export default Navigation;
