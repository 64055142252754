import { evalQuery } from "./index";

export const DocumentService = {
  get: (request) =>
    evalQuery({
      url: "/document/get-by-entity",
      request,
    }),

  create: (request, config) =>
    evalQuery({
      url: "/document/create",
      request,
      config,
    }),

  delete: (request) =>
    evalQuery({
      url: "/document/delete",
      request,
    }),


  getDocument: (request) =>
    evalQuery({
      url: "/document/get",
      request,
    }),
};
