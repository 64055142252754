/**
 * Object with all statuse
 * with labels and values
 */
export const STATUS = {
  EXPERT: {
    value: "Expert",
    label: "Expert",
  },
  PENDING_REVIEW: {
    value: "Pending_review",
    label: "Pending Review",
  },
  ENTHUSIAST: {
    value: "Enthusiast",
    label: "Enthusiast",
  },
  APPROVED: {
    value: "Approved",
    label: "Approved",
  },
  INCOMPLETE: {
    value: "Incomplete",
    label: "Incomplete",
  },
  SUBMITTED: {
    value: "Submitted",
    label: "Submitted",
  },
  RESUBMITTED: {
    value: "Resubmitted",
    label: "Resubmitted",
  },
  ABANDONED: {
    value: "Abandoned",
    label: "Abandoned",
  },
  REVIEWED: {
    value: "Reviewed",
    label: "Reviewed",
  },
  SENT: {
    value: "Sent",
    label: "Sent",
  },
  DENIED: {
    value: "Denied",
    label: "Denied",
  },
};

/**
 * Application statuses
 */
export const APPLICATION_STATUSES = [
  STATUS.ABANDONED,
  STATUS.INCOMPLETE,
  STATUS.PENDING_REVIEW,
  STATUS.RESUBMITTED,
  STATUS.EXPERT,
  STATUS.ENTHUSIAST,
  STATUS.DENIED,
];

/**
 * Cyber-expert statuses
 */
export const FREELANCER_STATUSES = [
  STATUS.EXPERT,
  STATUS.ENTHUSIAST,
  STATUS.DENIED,
  STATUS.PENDING_REVIEW,
  STATUS.INCOMPLETE,
];

/**
 * Background Check statuses
 */
export const BACKGROUND_CHECK_STATUSES = [STATUS.SENT, STATUS.APPROVED, STATUS.DENIED];

export const VIDEO_STATUSES = [
  STATUS.SUBMITTED,
  STATUS.INCOMPLETE,
  STATUS.ABANDONED,
  STATUS.REVIEWED,
];
