import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Loader from "./Loader";
import CardSecure from "./card/CardSecure";

export default function ModalTailwind({
  open,
  setOpen,
  selectedPlan,
  productName,
  redirectToCheckout,
  onClickLoader,
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <CardSecure
                    key={selectedPlan?.id}
                    id={selectedPlan?.id}
                    noRecommandedBlock={true}
                    width={330}
                    type="medium"
                    textSizeSecondary="text-sm"
                    currency={selectedPlan?.currency || "USD"}
                    amount={
                      selectedPlan?.recurring?.interval === "month"
                        ? selectedPlan.unit_amount / 100
                        : selectedPlan.unit_amount / 100 / 12
                    }
                    topTitle={selectedPlan?.metadata?.top_title || ""}
                    title={productName}
                    perMonthText={
                      selectedPlan?.recurring?.interval === "month"
                        ? "per month"
                        : "per month, billed annually"
                    }
                    subscription={false}
                    planInterval={
                      selectedPlan?.recurring?.interval === "month"
                        ? "Monthly"
                        : "Annual Commitment"
                    }
                    saveText={selectedPlan?.metadata?.save_text}
                    peaceOfMindText={selectedPlan?.metadata?.peace_of_mind_text}
                    peaceOfMindTextTrial={selectedPlan?.metadata?.peace_of_mind_text_trial}
                    extras={selectedPlan?.metadata?.extras}
                    featuresTitle={selectedPlan?.metadata?.features_title}
                    strechPerks={true}
                    btnVariant="full"
                    btnActive={true}
                    btnLabel={
                      onClickLoader ? (
                        <Loader className="relative w-8 h-8" />
                      ) : (
                        "Upgrade subscription"
                      )
                    }
                    onClick={redirectToCheckout}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
