/* NOTE:
  https://flowbite.com/docs/components/modal/
*/

import React, { useEffect } from "react";
import { isEmpty } from "lodash";

const Modal = ({
  dataTestId,
  header = true,
  title,
  centerTitle = false,
  showCloseIcon = true,
  children,
  buttons = [],
  closeModal,
  classPaper = "",
  maxWidth = "max-w-2xl",
  contentHeight,
}) => {
  // remove or addy scroll on body tag
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <>
      <div
        id={dataTestId}
        tabIndex="1"
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-auto md:max-h-[99vh] flex justify-center items-center z-50"
      >
        <div
          className={`relative w-full h-auto p-4 max-h-[100vh] md:h-auto md:max-h-[100vh] ${maxWidth} ${classPaper}`}
        >
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Modal header */}
            {header && (
              <div className="flex items-start justify-between p-4 text-center border-b rounded-t dark:border-gray-600">
                {/* Modla title */}
                {title && (
                  <h3
                    className={`text-xl font-medium text-gray-900 dark:text-white ${
                      centerTitle && "mx-auto text-center"
                    }`}
                  >
                    {title}
                  </h3>
                )}
                {/* Close modal button */}
                {showCloseIcon && (
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={() => closeModal()}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
              </div>
            )}

            {/* Modal body */}
            <div className={`p-6 space-y-6 ${contentHeight || ""} overflow-auto`}>{children}</div>

            {/* Modal footer */}
            {!isEmpty(buttons) && (
              <div className="flex items-center justify-end p-6 border-t border-gray-200 rounded-b space-x-2 dark:border-gray-600">
                {buttons.length && buttons.map((btn, i) => <div key={i}>{btn}</div>)}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* overlays */}
      <div className="fixed inset-0 z-40 bg-gray-900 bg-opacity-50 dark:bg-opacity-80" />
    </>
  );
};

export default Modal;
