import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography } from "../../components";
import PageNumberFooter from "./PageNumberFooter";
import ProjectList from "./ProjectsList";

interface ProjectBrowserProps {
  projectCredits: any[];
  totalPopTokens: number;
  availableProjects: any[];
  purchaseMoreTokens: any;
  handleBuyProjectClick: any;
  handleLearnMoreClick: any;
}

const ProjectBrowser: React.FC<ProjectBrowserProps> = ({
  projectCredits,
  totalPopTokens,
  availableProjects,
  purchaseMoreTokens,
  handleBuyProjectClick,
  handleLearnMoreClick,
}) => {
  const navigate = useNavigate();
  // Constant to dertermine how many projects should show per page
  const PROJECTS_PER_PAGE = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentProjects, setCurrentProjects] = useState<any[]>([]);

  const paginate = (page: number): void => {
    // Error handling so page cannot go past the min/max of page array
    if (page <= 0) page = 1;
    if (page >= Math.ceil(availableProjects.length / PROJECTS_PER_PAGE) + 1)
      page = Math.ceil(availableProjects.length / PROJECTS_PER_PAGE);

    const indexOfLastProject = page * PROJECTS_PER_PAGE;
    const indexOfFirstProject = indexOfLastProject - PROJECTS_PER_PAGE;
    setCurrentPage(page);
    setCurrentProjects(availableProjects.slice(indexOfFirstProject, indexOfLastProject));
  };

  useEffect(() => {
    const setCurrentProjectsHelper = () => {
      const current = availableProjects.slice(
        currentPage * PROJECTS_PER_PAGE - PROJECTS_PER_PAGE,
        currentPage * PROJECTS_PER_PAGE
      );
      setCurrentProjects(current);
    };
    setCurrentProjectsHelper();
  }, [availableProjects]);

  return (
    <Paper className="p-6 mb-8">
      <Typography
        type="title"
        className="mb-5 text-terc"
        textColor="text-prime"
        textSize="text-3xl"
      >
        <span className="flex justify-between">
          <span> Browse Available Projects </span>
          <span className="flex flex-col text-right">
            <span className="text-sm">{`Your current Pop-Token balance: ${totalPopTokens}`} </span>
            <span
              className="text-sm text-terc hover:underline hover:cursor-pointer"
              onClick={() => navigate("/profile/subscription")}
            >
              {" "}
              Purchase More{" "}
            </span>
          </span>
        </span>
      </Typography>
      <ProjectList
        projectCredits={projectCredits}
        projects={currentProjects}
        totalPopTokens={totalPopTokens}
        purchaseMoreTokens={purchaseMoreTokens}
        handleBuyProjectClick={handleBuyProjectClick}
        handleLearnMoreClick={handleLearnMoreClick}
      />
      <PageNumberFooter
        projectsPerPage={PROJECTS_PER_PAGE}
        totalProjects={availableProjects.length}
        currentPage={currentPage}
        paginate={paginate}
      />
    </Paper>
  );
};

export default ProjectBrowser;
