export default function ActiveFiltersBanner({ activeFilters }) {
  return (
    <>
      {activeFilters.map((filter) => (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
          {filter.type === "by_status"
            ? filter.label
            : filter.type === "by_freelancer"
            ? `CE: ${filter.label}`
            : `Project: ${filter.label}`}
        </span>
      ))}
    </>
  );
}
