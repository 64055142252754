/*
NOTE: this component can:
  1. show prop icon trough 'icon' attribute
  2. show initials if 'fullName' attribute has value (initials are created automatically),
  3. show image if 'preview' has value (BASE 64)
  4. add new image and returned input value trough 'setValue'
  // style
  6. change size of component with 'width' and 'height'
  7. box-shadow can be added trouh classes 'boxShadow="shadow"'
  8. change border-radius if 'borderRadius 'is passed
  9. change size of initials font with 'initialsSize'
  10. change background color trough ' = 'bg-prime''
  11. change size of initial icon with 'iconSize'
  12. input is disabled with 'disabled' attribute.
  13. with 'viewOnly' you disable input


import { InputImage } from '......./components';
...
const [inputImgValue, setInputImgValue] = useState('');
const [inputImgPreview, setInputImgPreview] = useState('');
...
<InputImage
  dataTestId="input-image"
  className=""
  width="w-24"
  height="h-24"
  // boxShadow="shadow"
  bgColor="bg-prime"
  // borderRadius="rounded-full"
  // holderClass=""
  label="Add Image"
  labelPosition="bottom"
  // labelClass="my-2"
  fullName="Wolter Woles"
  initialClass="text-4xl text-white"
  icon={<i className="text-4xl text-white fa-solid fa-user-large"></i>}
  // disabled={true}
  preview={inputImgPreview}
  setValue={v => setInputImgValue(v)}
  setPreview={v => setInputImgPreview(v)}
/>
*/

import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

const InputImage = ({
  dataTestId,
  className = "mb-4",
  holderClass,
  width = "w-20",
  height = "h-20",
  boxShadow = "shadow",
  bgColor = "bg-prime",
  borderRadius = "rounded-full",
  imgWrapperClass,
  label,
  labelPosition = "bottom", // top
  labelClass = "my-2",
  fullName,
  initialClass,
  icon,
  preview,
  viewOnly,
  disabled,
  setPreview,
  setValue,
}) => {
  const [nameInitial, setNameInitial] = useState("");

  const browseImage = (e) => {
    let file = e.target.files[0];

    if (file) {
      // console.log('file', file);
      // NOTE: here you can restricte file size?
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        if (/\.(jpe?g|png)$/i.test(file.name)) {
          setPreview(reader.result);
          setValue(reader.result);
        }
      };
    }
  };

  useEffect(() => {
    if (fullName) {
      if (fullName.split(" ").length < 2) {
        setNameInitial(`${fullName.split(" ")[0].charAt(0)}`);
      } else {
        setNameInitial(`${fullName.split(" ")[0].charAt(0)}${fullName.split(" ")[1].charAt(0)}`);
      }
    }
  }, [fullName]);

  return (
    <div className={className}>
      <div
        className={`relative my-0 mx-auto ${disabled ? "cursor-not-allowed" : "cursor-pointer"} ${
          viewOnly ? "cursor-default" : "cursor-pointer"
        } ${holderClass}`}
      >
        {/* label */}
        {label && labelPosition === "top" && (
          <label htmlFor={label} className={`block text-center text-prime-light ${labelClass}`}>
            {label}
          </label>
        )}

        <div
          className={`relative my-0 mx-auto overflow-hidden object-cover ${width} ${height} ${boxShadow} ${borderRadius} ${imgWrapperClass}`}
        >
          {/* preview exist */}
          {preview && (
            <img
              src={preview}
              alt="img"
              style={{
                width: "inherit",
                height: "inherit",
                objectFit: "cover",
              }}
            />
          )}

          {/* no preview, fullname exist -> show initials */}
          {!preview && fullName && (
            <div
              className={`flex items-center justify-center ${initialClass} ${bgColor}`}
              style={{ width: "inherit", height: "inherit" }}
            >
              {nameInitial}
            </div>
          )}

          {/* no preview, no fullname -> show icon */}
          {!preview && !fullName && (
            <div className={`flex items-center justify-center w-full h-full ${bgColor}`}>
              {!isEmpty(icon) ? icon : ""}
            </div>
          )}

          {/* input file */}
          {!viewOnly && !disabled && (
            <input
              data-testid={dataTestId}
              id={label ? label : Math.random().toString()}
              type="file"
              // accept="image/*"
              accept="image/png, image/jpeg, image/jpg"
              className={`absolute top-0 left-0 z-10 opacity-0 ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } ${viewOnly ? "cursor-default" : "cursor-pointer"}`}
              style={{ width: "inherit", height: "inherit" }}
              disabled={disabled || viewOnly}
              onChange={browseImage}
            />
          )}
        </div>

        {label && labelPosition === "bottom" && (
          <label htmlFor={label} className={`block text-center text-prime-light ${labelClass}`}>
            {label}
          </label>
        )}
      </div>
    </div>
  );
};

export default InputImage;
