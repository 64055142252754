import PrivateRoute from "../../components/privateRoute/PrivateRoute";
import ProjectView from "../../components/project/ProjectView";
import DashboardCustomer from "../dashboard/DashboardCustomer";

import CompanyOnboardingVideo from "../onboarding/CompanyOnboardingVideo";
import CustomerDashboardTourVideo from "../onboarding/CustomerDashboardTourVideo";
import CustomerPayment from "../onboarding/CustomerPayment";
// import CustomerRiskRating from "../onboarding/CustomerRiskRating";
import Payments from "../payments/Payments";
import Profile from "../profile/Profile";
import ProjectsCustomer from "../projects/customer/ProjectsCustomer";
import CustomerQuestionnaire from "../questionnaire/CustomerQuestionnaire";

export default [
  { path: "/company-onboarding-video", element: <CompanyOnboardingVideo /> },
  {
    path: "/questionnaire/:blok",
    element: (
      // INFO
      // allowedRoles currently is not implemented
      // Which roles can se what is decided in App.jsx
      // It was meant for more precise control, but there were bugs and
      // later all needed was successfully implemented in current way.
      <PrivateRoute allowedRoles={["Customer"]}>
        <CustomerQuestionnaire />
      </PrivateRoute>
    ),
  },
  // {
  //   path: "/risk-rating",
  //   element: (
  //     <PrivateRoute allowedRoles={["Customer"]}>
  //       <CustomerRiskRating />
  //     </PrivateRoute>
  //   ),
  // },
  {
    path: "/payment/:planId",
    element: (
      <PrivateRoute allowedRoles={["Customer"]}>
        <CustomerPayment />
      </PrivateRoute>
    ),
  },

  {
    path: "/dashboard-tour-video",
    element: (
      <PrivateRoute allowedRoles={["Customer"]}>
        <CustomerDashboardTourVideo />
      </PrivateRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <PrivateRoute allowedRoles={["Customer"]}>
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    path: "/profile/:selectedTab",
    element: (
      <PrivateRoute allowedRoles={["Customer"]}>
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute allowedRoles={["Customer"]}>
        <DashboardCustomer />
      </PrivateRoute>
    ),
  },
  {
    path: "/projects",
    element: (
      <PrivateRoute allowedRoles={["Customer"]}>
        <ProjectsCustomer />
      </PrivateRoute>
    ),
  },
  {
    path: "/project/:projectUid",
    element: (
      <PrivateRoute allowedRoles={["Customer"]}>
        <ProjectView />
      </PrivateRoute>
    ),
  },
  {
    path: "/project/:projectUid/:taskUid",
    element: (
      <PrivateRoute allowedRoles={["Customer"]}>
        <ProjectView />
      </PrivateRoute>
    ),
  },
  {
    path: "/payments",
    element: (
      <PrivateRoute allowedRoles={["Customer"]}>
        <Payments />
      </PrivateRoute>
    ),
  },
];
