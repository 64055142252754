import React from "react";

// components
import { Paper } from "../../components";
import FreelancerCodeOfConduct from "./FreelancerCodeOfConduct";

const FreelancerCodeOfConductPage = () => (
  <div className="flex-1 flex justify-center p-page">
    <Paper className="max-w-[1000px]">
      <FreelancerCodeOfConduct />
    </Paper>
  </div>
);

export default FreelancerCodeOfConductPage;
