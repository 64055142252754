import { evalQuery } from "./index";

export const ProjectTemplateService = {
  create: (request) =>
    evalQuery({
      url: "/project-template/create",
      request,
    }),

  getAll: (request) =>
    evalQuery({
      url: "/project-template/get-all",
      request,
    }),

  getPublished: (request) =>
    evalQuery({
      url: "/project-template/get-all-published",
      request,
    }),

  checkPomrPurchase: (request) =>
    evalQuery({
      url: "/project-template/has-purchased-pomr",
      request,
    }),

  getByTemplateNumber: (request) =>
    evalQuery({
      url: "/project-template/get-by-template-number",
      request,
    }),

  getAvailableTemplates: (request) =>
    evalQuery({
      url: "/project-template/get-available-templates",
      request,
    }),

  get: (request) =>
    evalQuery({
      url: "/project-template/get",
      request,
    }),

  updateRoadmap: (request) =>
    evalQuery({
      url: "/project-template/update-roadmap",
      request,
    }),

  inheritFromPublished: (request) =>
    evalQuery({
      url: "/project-template/inherit-from-published",
      request,
    }),

  requirementsForProject: (request) =>
    evalQuery({
      url: "/project-template/get-filter-data",
      request,
    }),

  latestPublished: (request) =>
    evalQuery({
      url: "/project-template/change-latest",
      request,
    }),

  update: (request) =>
    evalQuery({
      url: "/project-template/update",
      request,
    }),

  delete: (request) =>
    evalQuery({
      url: "/project-template/delete",
      request,
    }),
};
