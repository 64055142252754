import React, { useState, useEffect } from "react";

// context
import { useAuth } from "../../context";

const customerSteps = [
  {
    id: 0,
    url: "questionnaire",
    value: "Questionnaire",
  },
  {
    id: 1,
    url: "risk_rating",
    value: "Risk Rating",
  },
  {
    id: 2,
    url: "payment",
    value: "Payment",
  },
  {
    id: 3,
    url: "dashboard_tour",
    value: "Dashboard Tour",
  },
];

const freelancerSteps = [
  {
    id: 0,
    url: "questionnaire",
    value: "Questionnaire",
  },
  {
    id: 1,
    url: "video_interview",
    value: "Video Interview",
  },
  {
    id: 2,
    url: "what_to_expect",
    value: "What to Expect",
  },
  {
    id: 4,
    url: "application_submission",
    value: "Submit Application",
  }
];

const initApplicationStatusSteps = [
  {
    id: 0,
    url: "not_reviewed",
    value: "About you",
  },
  {
    id: 1,
    url: "in_review",
    value: "In review",
  },
  {
    id: 2,
    url: "background_check",
    value: "Background Check",
  },
  {
    id: 3,
    url: "freelancer_status",
    value: "Cyber-expert Status",
  },
];

const Stepper = ({
  step = "questionnaire",
  className = "",
  itemClassName = "",
  status = false,
  freelancerApplicationStatus = null,
}) => {
  // context
  const { authContext } = useAuth();

  // local
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);

  // set steps base on 'role' and 'freelancerApplicationStatus'
  const setLocalSteps = () => {
    let localSteps = [];

    // onboarding part
    if (authContext?.role === "Customer") {
      localSteps = customerSteps;
    } else if (authContext?.role === "Enthusiast" && status === false) {
      localSteps = freelancerSteps;
      // freelancer dashboard - application status
    } else if (
      freelancerApplicationStatus === "In_review" ||
      freelancerApplicationStatus === "Not_reviewed"
    ) {
      localSteps = initApplicationStatusSteps;
    } else if (freelancerApplicationStatus === "Enthusiast") {
      // remove 'background_check'
      localSteps = initApplicationStatusSteps
        .filter((n) => n.url !== "background_check")
        .map((m) => {
          if (m.url === "freelancer_status") {
            return { ...m, value: "Status Enthusiast" };
          } else {
            return m;
          }
        });
    } else if (freelancerApplicationStatus === "Expert") {
      localSteps = initApplicationStatusSteps.map((m) => {
        if (m.url === "freelancer_status") {
          return { ...m, value: "Status Expert" };
        } else {
          return m;
        }
      });
    }

    // set active step
    const activeStepObj = localSteps.find((n) => n.url === step);

    if (localSteps.length) {
      if (authContext?.role === "Freelancer" && freelancerApplicationStatus === "Expert") {
        setActiveStep(activeStepObj?.id + 1);
      } else {
        setActiveStep(activeStepObj?.id);
      }
    }

    setSteps(localSteps);
  };

  useEffect(() => {
    setLocalSteps();
  }, [step, freelancerApplicationStatus, authContext?.role]);

  return (
    <div className={`${className} flex flex-col max-w-[1000px] mt-6`}>
      {/* <div className="flex justify-between">
        {steps.map((item, i) => (
          <div
            key={i}
            className={`flex mr-2 text-xl ${
              activeStep >= i ? 'text-sec' : 'text-prime-light'
            } ${itemClassName}`}
          >
            <p>{item.value}</p>
          </div>
        ))}
      </div> */}

      <div className="flex mt-2">
        {steps.map((item, i) => (
          // whole wrapper
          <div key={item.id} className="flex items-center w-full">
            {/* text and circle wrapper */}
            <div className="flex flex-col relative">
              {/* text */}
              <div
                className={`flex text-xl mr-28 absolute top-[-40px] right-[0px] left-[-45px] w-[200px] whitespace-nowrap ${
                  activeStep >= i ? "text-prime" : "text-prime-light"
                } ${itemClassName}`}
              >
                <p>{item.value}</p>
              </div>

              {/* circle */}
              <div
                className={`grid justify-items-center text-center items-center w-[26px] h-[26px] mx-auto mb-4 rounded-full  border-2 relative
                ${activeStep > i ? "bg-[#332E76] border-[#332E76]" : "bg-white border-gray-400"}`}
              >
                {/* checkmark */}
                {activeStep > i && (
                  <div className="absolute bottom--1.5">
                    <i className="fa-solid fa-check text-white text-lg" />
                  </div>
                )}
                {/* little blue circle */}
                {activeStep === i && <div className="w-[10px] h-[10px] bg-prime rounded-full" />}
              </div>
            </div>

            {/* line */}
            {i !== steps.length - 1 && (
              <div
                className={`w-[255px] h-1 bg-grey mb-3 ${i === 3 ? "hidden" : ""} ${
                  activeStep > i ? "bg-[#332E76]" : ""
                }
              `}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
