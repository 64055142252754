import React, { useState } from "react";

// components
import { Button } from "../../components";

const ChatInput = ({ addNewMsg, fileInput = false }) => {
  const [textMsg, setTextMsg] = useState("");

  // context

  const addFile = (e) => {
    let file = e.target.files[0];

    if (file) {
      // console.log('file', file);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        addNewMsg("file", reader.result);
        setTextMsg("");
      };
    }
  };

  const addMsgClicked = () => {
    addNewMsg("string", textMsg);
    setTextMsg("");
  };

  return (
    <div className="flex items-center pt-4 border-t">
      <div className="relative flex-1">
        <textarea
          data-testid="new-message-input"
          rows="1"
          type="text"
          className="w-full py-2 pl-4 pr-8 mr-2 rounded-full border-grey-light"
          style={{ resize: "none", overflow: "hidden" }}
          value={textMsg}
          onChange={(e) => setTextMsg(e.target.value)}
        />

        {fileInput && (
          <div className="absolute top-0 right-0 flex items-center justify-center w-10 h-10 overflow-hidden rounded-full">
            <input
              data-testid="new-file-input"
              type="file"
              className="absolute top-0 left-0 w-10 h-10 opacity-0"
              onChange={addFile}
            />
            <i className="cursor-pointer fa-solid fa-paperclip text-prime-light" />
          </div>
        )}
      </div>

      <Button
        dataTestId="new-message-btn"
        label={
          textMsg ? (
            <i className="fa-solid fa-paper-plane" />
          ) : (
            <i className="fa-regular fa-paper-plane" />
          )
        }
        variant="icon-flat"
        themecolor="prime"
        width="w-10"
        height="h-10"
        textSize="text-xl"
        className="mt-[-0.6rem]"
        hover=""
        disabled={!textMsg}
        onClick={addMsgClicked}
      />
    </div>
  );
};

export default ChatInput;
