import React, { useEffect, useState } from "react";

// components
import { Typography, EnhancedTable, Tabs } from "../../components";

// services
import { useToast, useAuth } from "../../context";
import { TransactionService, errorMsgForUser } from "../../services";

// context

const Payments = () => {
  // context
  const { showToast } = useToast();
  const { authContext } = useAuth();

  // local
  const [transactions, setTransactions] = useState([]);
  const [loader, setLoader] = useState(false);
  // const [page, setPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currency, setCurrency] = useState({
    value: "usd",
    label: "USD",
  }); // 'usd', 'poptoken' or 'eur'

  const getTransactions = () => {
    // get profile Api
    setLoader(true);
    return TransactionService.getAllTransactions({
      user_uid: authContext.user.uid,
      currency: currency?.value || "usd",
    })
      .then((res) => {
        setTransactions(res.transactions);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  const TABLE_COLUMNS = [
    {
      id: "from_user_name",
      type: "string",
      label: "From user",
    },
    // {
    //   id: 'from_user_uid',
    //   type: 'string',
    //   label: 'From User UID',
    //   style: 'min-w-[180px] text-xs',
    // },
    // {
    //   id: 'user_uid',
    //   type: 'string',
    //   label: 'User UID',
    //   style: 'min-w-[180px] text-xs',
    // },
    // {
    //   id: 'to_user_uid',
    //   type: 'string',
    //   label: 'To User UID',
    //   style: 'min-w-[180px] text-xs',
    // },
    {
      id: "kind",
      type: "string",
      label: "Kind",
    },
    {
      id: "date",
      type: "date",
      label: "Date",
      style: "min-w-[120px]",
    },
    {
      id: "amount",
      type: "number",
      label: "Amount",
    },
    // {
    //   id: 'currency',
    //   type: 'string',
    //   label: 'Currency',
    //   style: 'min-w-[200px]',
    // },
    // {
    //   id: 'stripe_charge_id',
    //   type: 'string',
    //   label: 'Stripe charge ID',
    //   style: 'min-w-[180px] text-xs',
    // },
  ];

  const TABLE_ROWS = transactions.map((transaction) => ({
    // TODO: Conditional rendering for pop-tokens and USD
    "data-testid": transaction.uid,
    from_user_name: transaction.from_user_name,
    // from_user_uid: transaction.from_user_uid,
    // user_uid: transaction.user_uid,
    // to_user_uid: transaction.to_user_uid,
    kind: transaction.kind,
    date: transaction.created_at,
    amount: transaction.amount,
    // stripe_charge_uid: transaction.stripe_charge_uid,
  }));

  useEffect(() => {
    getTransactions();
  }, [currency]);

  return (
    <div className="flex-1 p-page mx-10">
      {/* <Paper className="max-w-[1000px]"> */}
      <Typography type="title" className="text-center mb-8">
        Payments
      </Typography>

      {/* currency */}
      <div>
        <Tabs
          dataTestId="currency_tabs"
          className="mb-4"
          tabs={[
            { value: "usd", label: "USD" },
            { value: "poptoken", label: "Pop-token" },
          ]}
          value={currency.value}
          onChange={(tab) => setCurrency(tab)}
        />

        {/* <Button
          dataTestId="currency-usd-btn"
          label="USD"
          variant={currency === 'usd' ? 'full' : 'outlined'}
          themecolor="sec"
          className="flex-1 border border-sec rounded-r-none"
          onClick={() => setCurrency('usd')}
        /> */}
        {/* <Button
          dataTestId="currency-eur-btn"
          label="EUR"
          variant={currency === 'poptoken' ? 'full' : 'outlined'}
          themecolor="eur"
          className="flex-1 border border-sec rounded-l-none"
          onClick={() => setCurrency('eur')}
        /> */}
        {/* <Button
          dataTestId="currency-poptoken-btn"
          label="Pop-token"
          variant={currency === 'poptoken' ? 'full' : 'outlined'}
          themecolor="sec"
          className="flex-1 border border-sec rounded-l-none"
          onClick={() => setCurrency('poptoken')}
        /> */}
      </div>

      <div className="hover:cursor-default">
        <EnhancedTable
          showTableTop={false}
          tableColumns={TABLE_COLUMNS}
          tableRows={TABLE_ROWS}
          // loaderloading={loaderloading}
          loader={loader}
          noInfiniteScroll={true}
          rowClickable={false}
          // hasMore={freelancers.length === counters[currentTab.value]}
          // fetchMore={() => {
          //   setPage(p => p + 1);
          //   fetchMore(page + 1, itemsPerPage);
          // }}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Payments;
