import React, { useRef } from "react";
import PropTypes from "prop-types";
import TooltipContent from "./TooltipContent";
import TooltipWrapper from "./TooltipWrapper";

const Tooltip = ({ children, placement, text }) => {
  const ref = useRef();
  return (
    <div>
      {React.cloneElement(children, { ref: ref })}
      <TooltipWrapper ref={ref} placement={placement}>
        <TooltipContent>{text}</TooltipContent>
      </TooltipWrapper>
    </div>
  );
};

Tooltip.defaultProps = {
  placement: "top",
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Tooltip;
