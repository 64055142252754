import Button from "../Button";
import Modal from "../Modal";

const CardProjectDetailsModal = ({ displayedProject, closeModal }) => (
  <Modal title={displayedProject?.short_text} centerTitle={true} closeModal={closeModal}>
    {
      <>
        <div className="flex flex-col h-full p-6">
          {/* project goal */}
          <div className="mb-6">
            <a
              href={displayedProject?.href}
              target="_blank"
              rel="noreferrer"
              className="text-lg font-medium text-sec"
            >
              Project goal
            </a>
            {displayedProject?.project_goal && (
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: displayedProject?.project_goal,
                }}
              />
            )}
          </div>
          {/* project why */}
          <div className="mb-6">
            <a
              href={displayedProject?.href}
              target="_blank"
              rel="noreferrer"
              className="text-lg font-medium text-sec"
            >
              Why this Matters
            </a>

            {displayedProject?.project_why && (
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: displayedProject?.project_why,
                }}
              />
            )}
          </div>
          <div className="flex flex-col flex-1">
            {/* what to expect */}
            <div className="flex-1 mb-6">
              <a
                href={displayedProject?.href}
                target="_blank"
                rel="noreferrer"
                className="text-lg font-medium text-sec"
              >
                What to expect
              </a>

              {displayedProject?.project_expectations && (
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: displayedProject?.project_expectations,
                  }}
                />
              )}
            </div>

            <div className="mb-3 text-lg text-center text-gold">
              <i className="mr-2 fa-solid fa-coins" />
              {displayedProject?.cost / 50} Pop tokens
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Button
              dataTestId={`purchase_project_${displayedProject?.short_tex}`}
              label="Back to dashboard"
              className="hover:animate-pulse"
              // variant="full"
              themecolor="sec"
              onClick={() => closeModal()}
            />
          </div>
        </div>
      </>
    }
  </Modal>
);

export default CardProjectDetailsModal;
