import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { taskTemplateAssignedTo } from "../../functions/assignedTo";
import { readableDuration } from "../../functions/duration";

const MovableItem = ({
  name,
  item,
  targetedRoadmapIndex,
  currentColumnName,
  moveCardHandler,
  handleDelete,
  handleTaskEdit,
  editable = true,
  //if used in table which show instance of started project
  // set it to true
  shownInstance = false,
}) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: "CARD",
    hover(item, monitor) {
      // console.log('item from drop', item.name, item);
      if (!ref.current) {
        return;
      }
      const dragIndex = item.roadmap_index;
      const targetIndex = targetedRoadmapIndex;
      // Don't replace items with themselves
      if (dragIndex === targetIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical third
      const hoverThirdY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 3;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed third of the items height
      // When dragging downwards, only move when the cursor is below 33%
      // When dragging upwards, only move when the cursor is above 33%
      // Dragging downwards
      if (dragIndex < targetIndex && hoverClientY < hoverThirdY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > targetIndex && hoverClientY > hoverThirdY) {
        return;
      }
    },
    drop: () => ({ targetPhase: item.phase, targetItem: item }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: "CARD",
    item: { ...item, currentColumnName, type: "CARD" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      console.log("drag item", item);
      console.log("drop result", dropResult);

      if (dropResult) {
        const { targetPhase, targetItem } = dropResult;

        moveCardHandler(item.roadmap_index, targetItem?.roadmap_index, targetPhase, true);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  drag(drop(ref));

  return (
    <div
      ref={editable ? ref : null}
      data-testid={item.uid}
      className="relative flex items-center justify-start p-2 mb-2 duration-300 border-b rounded-md cursor-pointer border-bottom border-grey-light hover:bg-grey-pale"
      style={{ opacity }}
    >
      <div className="flex w-1/2">
        {editable ? (
          <div className="cursor-move">
            <i className="fa-solid fa-bars text-prime-light" />
          </div>
        ) : (
          shownInstance &&
          (item.status === "Finished" ? (
            <div className="task-status">
              <i className="fa-solid fa-check-circle text-success" />
            </div>
          ) : (
            <div className="task-status">
              <i className="fa fa-check-circle text-prime-light-light" />
            </div>
          ))
        )}
        <p
          onClick={handleTaskEdit}
          className={`ml-2 text-blue-500 flex-1
            ${item.estimated_duration_delta ? " pl-2 " : " pl-7 italic text-sm"}`}
        >
          {name}
        </p>
      </div>
      <div className="w-1/4">{taskTemplateAssignedTo(item)}</div>
      <div className="w-1/4">{readableDuration(item.estimated_duration_delta)}</div>
      {editable && (
        <i onClick={handleDelete} className="absolute fa-solid fa-trash text-prime-light right-2" />
      )}
    </div>
  );
};

export default MovableItem;
