import React, { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";

// components
import { Typography, Button, Divider, Textarea, Select, Modal, Loader } from "../../components";

// services
import { useToast, useAuth } from "../../context";
import { getCountryFullName } from "../../functions/getCountryFullName";
import {
  ProjectService,
  ProfileService,
  UserService,
  QuestionnaireService,
  CompanyService,
  errorMsgForUser,
} from "../../services";

// context

const CustomerView = ({
  closeModal,
  item,
  csmOptions,
  getCustomers,
  allCountries,
  handleDeleteCompanyFirstClick,
}) => {
  // context
  const { showToast } = useToast();
  const { authContext } = useAuth();

  // local
  const [loader, setLoader] = useState(0);
  const [questions, setQuestions] = useState({});
  const [noteAboutCustomer, setNoteAboutCustomer] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [assignedCsm, setAssignedCsm] = useState(item.csm_assigned_uid);
  const [companyProfile, setCompanyProfile] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const getEmail = useCallback(() => {
    setLoader((l) => l + 1);
    return UserService.get({
      user_uid: item?.id,
    })
      .then((res) => {
        setCustomerEmail(res?.user?.email);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  }, [item?.id, showToast]);

  const getQuestionnaire = useCallback(() => {
    setLoader((l) => l + 1);
    return (
      QuestionnaireService.getByUserId({
        user_uid: item?.id,
      })
        .then((res) => {
          const resQuestions = res?.questionnaire?.questions
            ? JSON.parse(res?.questionnaire?.questions)
            : null;

          setQuestions(resQuestions);
        })
        // .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader((l) => l - 1))
    );
  }, [item?.id, showToast]);

  const getCustomerNotes = useCallback(() => {
    setLoader((l) => l + 1);
    return ProfileService.getNotes({ customer_uid: item?.id })
      .then((res) => {
        // console.log(res);
        setNoteAboutCustomer(res.csm_notes);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  }, [item?.id, showToast]);

  const sendNoteAboutCustomerToDb = () => {
    setLoader((l) => l + 1);
    return ProfileService.updateNotes({
      customer_uid: item.id,
      csm_notes: noteAboutCustomer,
    })
      .then(() => {
        showToast("success", "The note updated");
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  // const processQuestionnaireSkills = (data, name) => {
  //   return data
  //     ? Object.keys(data)
  //         .map(key => {
  //           if (data[key] !== 'None' && key !== 'Other') {
  //             if (data['Other']) {
  //               return data['Other'];
  //             }
  //             return { name, key, value: data[key] };
  //           } else {
  //             return null;
  //           }
  //         })
  //         .filter(item => item)
  //     : [];
  // };

  // const managementSkills = processQuestionnaireSkills(
  //   questions.management_skills,
  //   'Management Skills'
  // );

  // const experienceInIndustry = processQuestionnaireSkills(
  //   questions.experience_in_industry,
  //   'Experience In Industry'
  // );

  // const experienceInArea = processQuestionnaireSkills(
  //   questions.experience_in_area,
  //   'Experience In Area'
  // );

  // const rows = [
  //   ...managementSkills,
  //   ...experienceInArea,
  //   ...experienceInIndustry,
  // ];

  // const renderSkills = data => {
  //   return (
  //     <>
  //       {data.length
  //         ? data.map((item, i) => {
  //             if (typeof item === 'string') {
  //               return <div key={i}>Other: ${item}</div>;
  //             }

  //             return (
  //               <div key={i}>
  //                 {item.key}: <span className="text-prime-light">{item.value}</span>
  //               </div>
  //             );
  //           })
  //         : 'N/A'}
  //     </>
  //   );
  // };

  const getCompanyProfile = (company_uid) => {
    CompanyService.getCompany({ company_uid }).then((r) => {
      setCompanyProfile({ ...r.company });
    });
  };

  useEffect(() => {
    getCompanyProfile(item.id);
  }, [item]);

  const assignCsm = useCallback(
    (uid, freelancer_uid, csm_uid) => {
      setLoader((l) => l + 1);
      return ProjectService.assignFreelancerToProject({
        project_uid: uid,
        // freelancer_uid,
        csm_uid,
      })
        .then((res) => {
          getCustomers();
          setAssignedCsm(res?.assigned_data?.csm_uid);
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader((l) => l - 1));
    },
    [getCustomers, showToast]
  );

  useEffect(() => {
    if (item) {
      getQuestionnaire();
      getCustomerNotes();
      getEmail();
    }
  }, [getQuestionnaire, getCustomerNotes, getEmail, item]);

  return (
    <>
      <Modal
        title={
          <div>
            <p className="px-2 pt-8 pb-2">
              Customer:{" "}
              <span className="font-semibold text-sec">{item.customer || item.customer_name}</span>
            </p>

            <p className="px-2 text-sm text-prime-light">
              Email: <span className="text-sec">{customerEmail}</span>
            </p>
            <p className="px-2 text-sm text-prime-light">
              Responsible person:{" "}
              <span className="text-sec">{item?.custemer_responsible_person}</span>
            </p>
          </div>
        }
        closeModal={closeModal}
        maxWidth="max-w-5xl"
        buttons={
          authContext?.role === "Customer" && [
            <Button
              dataTestId="cancel"
              label="Cancel"
              variant="outlined"
              themecolor="prime-light"
              onClick={closeModal}
            />,
            <Button
              dataTestId="save_changes"
              label="Save Changes"
              variant="full"
              themecolor="prime"
              className="mr-4"
              onClick={() => console.log("In Progress")}
            />,
          ]
        }
      >
        <div className="z-30 flex flex-col flex-1 bg-white md:flex-row">
          <div className="flex-1 overflow-y-auto">
            {/* loader */}
            {!!loader && <Loader themeColor="fill-prime" />}

            <>
              <p className="font-semibold">Company info:</p>

              {/* <div className="h-[500px] overflow-auto"> */}
              <div>
                <div className="flex py-2 border-b">
                  <div className="w-1/4 text-prime-light">Country: </div>
                  <p className="w-3/4">
                    {getCountryFullName(companyProfile.country, allCountries)}
                  </p>
                </div>

                <div className="flex py-2 border-b">
                  <div className="w-1/4 text-prime-light">State: </div>
                  <p className="w-3/4">{companyProfile?.state || "N/A"}</p>
                </div>

                <div className="flex py-2 border-b">
                  <div className="w-1/4 text-prime-light">City: </div>
                  <p className="w-3/4">{companyProfile?.city || "N/A"}</p>
                </div>

                <div className="flex py-2 border-b">
                  <div className="w-1/4 text-prime-light">Postal code: </div>
                  <p className="w-3/4">{companyProfile?.postal_code || "N/A"}</p>
                </div>

                <div className="flex py-2 border-b">
                  <div className="w-1/4 text-prime-light">Address 1: </div>
                  <p className="w-3/4">{companyProfile?.address_1 || "N/A"}</p>
                </div>

                <div className="flex py-2 border-b">
                  <div className="w-1/4 text-prime-light">Address 2: </div>
                  <p className="w-3/4">{companyProfile?.address_2 || "N/A"}</p>
                </div>

                <div className="flex py-2 border-b">
                  <p className="w-1/4 text-prime-light">Number of employees:</p>
                  <p className="w-3/4">{companyProfile?.number_of_employees || "N/A"}</p>
                </div>

                <div className="flex py-2 border-b">
                  <p className="w-1/4 text-prime-light">Industry:</p>
                  <p className="w-3/4">{companyProfile?.industry || "N/A"}</p>
                </div>

                <div className="flex py-2 border-b">
                  <p className="w-1/4 text-prime-light">POMR started:</p>
                  <p className="w-3/4">
                    {companyProfile?.peace_of_mind_roadmap_started === true ? "Yes" : "No" || "N/A"}
                  </p>
                </div>
              </div>
            </>

            {!isEmpty(questions) && (
              <>
                <Typography className="mb-4" type="subtitle">
                  Customer Onboarding Questionnaire Responses
                </Typography>

                {/* <div className="h-[500px] overflow-auto"> */}
                <div>
                  {/* table header */}
                  <div className="flex items-center justify-start py-2 border-b">
                    <div className="w-1/2 text-prime-light">Question</div>
                    <div className="w-1/2 text-prime-light">Customer Response</div>
                  </div>

                  {/* <div className="flex py-2 border-b">
                  <div className="w-1/2">Certifications:</div>
                  {questions?.certifications?.map((cert, i) => (
                    <div key={i} className="flex flex-col w-1/2">
                      <div className="flex">
                        <div className="mr-2">
                          Cert body:{' '}
                          <span className="text-prime-light">{cert.certBody}</span>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="mr-2">
                          Cert name:{' '}
                          <span className="text-prime-light">{cert.certName}</span>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="mr-2">
                          Cert number:{' '}
                          <span className="text-prime-light">{cert.certNumber}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div> */}

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Annual Revenue:</p>
                    <p className="w-1/2">{questions?.annual_revenue || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Cloud Collaboration Tool:</p>
                    <p className="w-1/2">{questions?.cloud_collaboration_tool || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Cloud Email Tool:</p>
                    <p className="w-1/2">{questions?.cloud_email_tool || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Cloud File Storage Tool:</p>
                    <p className="w-1/2">{questions?.cloud_file_storage_tool || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Current Technology:</p>
                    <div className="w-1/2">
                      {questions?.current_technology?.map((tech, i) => (
                        <p key={i}>{tech ? tech : "N/A"}</p>
                      )) || "N/A"}
                    </div>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Customers Generally Location:</p>
                    <p className="w-1/2">{questions?.customers_generally_location || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Cyber Maturity:</p>
                    <p className="w-1/2">{questions?.cyber_maturity || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Cybersecurity Controls:</p>
                    <div className="w-1/2">
                      {questions?.cyber_security_controls?.map((security, i) => (
                        <p key={i}>{security ? security : "N/A"}</p>
                      )) || "N/A"}
                    </div>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Cybersecurity Rate:</p>
                    <p className="w-1/2">{questions?.cyber_security_rate || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Cybersecurity Insurance Policy</p>
                    <p className="w-1/2">{questions?.cybersecurity_insurance_policy || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Cybersecurity Strategy</p>
                    <p className="w-1/2">{questions?.cybersecurity_strategy || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Formal cybersecurity strategy</p>
                    <p className="w-1/2">{questions?.formal_cyber_security_strategy || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Full time cybersecurity workers</p>
                    <p className="w-1/2">{questions?.full_time_cyber_security_workers || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Employees number:</p>
                    <p className="w-1/2">{questions?.employees_num || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Where did you hear about us:</p>
                    <p className="w-1/2">
                      {questions?.how_did_you_hear_about_us !== "Other"
                        ? questions?.how_did_you_hear_about_us
                        : questions?.how_did_you_hear_about_us_other}
                    </p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Industry:</p>
                    <p className="w-1/2">{questions?.industry || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Main Customers:</p>
                    <div className="w-1/2">
                      {questions?.main_customers?.map((customers, i) => (
                        <p key={i}>{customers ? customers : "N/A"}</p>
                      )) || "N/A"}
                    </div>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Sensitive Information Collection:</p>
                    <p className="w-1/2">{questions?.sensitive_info_collection || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Vendor Collaboration Tools:</p>
                    <div className="w-1/2">
                      {questions?.vendor_collaboration_tools?.map((tools, i) => (
                        <p key={i}>{tools ? tools : "N/A"}</p>
                      )) || "N/A"}
                    </div>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Vendor Hosted Website Tool:</p>
                    <p className="w-1/2">{questions?.vendor_hosted_website_tool || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Vendors Number:</p>
                    <p className="w-1/2">{questions?.vendors_num || "N/A"}</p>
                  </div>

                  <div className="flex py-2 border-b">
                    <p className="w-1/2">Work Type:</p>
                    <p className="w-1/2">{questions?.work_type || "N/A"}</p>
                  </div>
                </div>
              </>
            )}
            {/* <div className="flex py-2 border-b">
                  <p className="w-1/2"> Management skills: </p>
                  <div className="w-1/2">{renderSkills(managementSkills)}</div>
                </div>

                <div className="flex py-2 border-b">
                  <p className="w-1/2"> Experience in industry: </p>
                  <div className="w-1/2">
                    {renderSkills(experienceInIndustry)}
                  </div>
                </div>

                <div className="flex py-2 border-b">
                  <p className="w-1/2"> Experience in area: </p>
                  <div className="w-1/2">{renderSkills(experienceInArea)}</div>
                </div>
              </div> */}

            {/* CSM Assigned  */}
            {authContext?.role === "Csm" && (
              <div className="flex items-center justify-between w-full max-w-xs py-7">
                <p> CSM Assigned </p>
                <Select
                  dataTestId="csm-assigned"
                  options={csmOptions}
                  value={assignedCsm}
                  keyForOptionValue="csm_id"
                  keyForOptionLabel="csm_first_name"
                  className="mb-0"
                  onChange={(e) =>
                    assignCsm(item.project_uid, item.freelance_assigned_uid, e.target.value)
                  }
                />
              </div>
            )}

            <div className="pb-5">
              <Divider lineColor="bg-grey-light" />
            </div>

            {/* note */}
            {authContext?.role === "Csm" && (
              <div className="flex flex-col items-center w-full pb-2 sm:items-start">
                <div className="flex flex-col items-center w-full max-w-lg md:items-start">
                  <Textarea
                    label="Notes"
                    rows={3}
                    className="w-full mb-2"
                    value={noteAboutCustomer}
                    onChange={(e) => setNoteAboutCustomer(e.target.value)}
                  />

                  <div className="flex">
                    <Button
                      dataTestId="update_notes_btn"
                      className="mr-4"
                      label="Delete company"
                      // variant="outlined"
                      themecolor="warning"
                      onClick={() => handleDeleteCompanyFirstClick(true)}
                    />

                    <Button
                      dataTestId="update_notes_btn"
                      label="Update Notes"
                      variant="outlined"
                      themecolor="prime-light"
                      onClick={sendNoteAboutCustomerToDb}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomerView;
