/* NOTE: react-player docs
  https://github.com/cookpete/react-player
*/

import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ className = "", ...rest }) => (
  <div className={`h-full ${className}`}>
    <ReactPlayer {...rest} style={{ backgroundColor: "transparent" }} />
  </div>
);

export default VideoPlayer;

// .player-wrapper {
//   width: auto; /* Reset width */
//   height: auto; /* Reset height */
// }
// .react-player {
//   padding-top: 56.25%; /* Percentage ratio for 16:9 */
//   position: relative; /* Set to relative */
// }

// .react-player > div {
//   position: absolute; /* Scaling will occur since parent is relative now */
// }
