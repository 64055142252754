import { evalQuery } from "./index";

export const PlansService = {
    getAll: (request) =>
        evalQuery({
            url: "/plan/get-all",
            request,
        }),
    create: (request) =>
        evalQuery({
            url: "/plan/create",
            request,
        }),
    getByPlanCode: (request) =>
        evalQuery({
            url: "/plan/get-by-plan-code",
            request,
        }),
    getSinglePlanAndPriceByPlanCode: (request) =>
        evalQuery({
            url: "/plan/get-plan-and-prices-by-plan-code",
            request,
        }),
    getPlansAndPrices: (request) =>
        evalQuery({
            url: "/plan/get-all-plans-and-prices",
            request,
        }),

    getOnboardingLink: (request) =>
        evalQuery({
            url: "/plan/get-onboarding-link",
            request,
        }),

}