import React, { useState } from "react";

const Accordion = ({
  className = "",
  title,
  menuTitle,
  menuText,
  classNameMenuTitle = "",
  classNameBtn = "flex justify-between items-center text-left w-full mb-2",
  classArrow = "text-prime-light-light",
  children,
}) => {
  const [accordionOpen, setAccordionOpet] = useState(false);

  const ToggleClass = () => {
    setAccordionOpet(!accordionOpen);
  };

  return (
    <div className={`${className} flex flex-col`}>
      {title && (
        <div className="mb-2 mt-4">
          <p className="text-2xl font-semibold">{title}</p>
        </div>
      )}
      {menuText && (
        <div className="mb-4 p-2 h-2/3">
          <p className="text-lg">{menuText}</p>
        </div>
      )}
      {/* Dropdown button */}
      <button type="button" className={classNameBtn} onClick={ToggleClass}>
        {/* menu title */}
        <span className={`text-xl mr-4 ${classNameMenuTitle}`}>{menuTitle}</span>

        {/* arrow */}
        <div
          className={`flex justify-center align-center ${classArrow} ${
            accordionOpen ? "shrink-0 rotate-180 duration-500" : "shrink-0 duration-500"
          }`}
        >
          <i className="fa-solid fa-angle-down shrink-0" />
        </div>
      </button>

      {/* Content */}
      <div className={accordionOpen ? "" : "hidden"}>{children}</div>
    </div>
  );
};

export default Accordion;
