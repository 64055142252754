import React, { useState, useEffect, useCallback, useRef } from "react";
import { PopupButton } from "@typeform/embed-react";
import moment from "moment";

// components
import { Typography, Loader, Button, Divider } from "..";

// context
import { useToast, useAuth } from "../../context";
import { customerViewAssignedTo, isAssignedToYou } from "../../functions/assignedTo";

// services
import { TaskService, errorMsgForUser } from "../../services";
import TaskDiscussion from "./TaskDiscussion";
import TaskDocuments from "./TaskDocuments";

const TaskSlider = ({
  selectedTask,
  setSelectedTask,
  getProject,
  calendlyLink,
  currentProject,
  csmUid,
  customerUid,
  freelancerUid,
  taskList,
  fromName,
  changeMessageReadStateUi,
  paidSub,
  company,
}) => {
  const { authContext } = useAuth();
  const sliderTopPosition = useRef();

  // context
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState((l) => l + 1);
  const [task, setTask] = useState({
    name: "",
    description: "",
    assigned_to: "",
    status: "",
    assignability: {
      to_csm: false,
      to_customer: false,
      to_freelancer: false,
    },
  });
  const [typeFormId, setTypeformId] = useState("");

  const startNextTask = useCallback(
    (task_uid) => {
      setLoader(true);

      TaskService.startNextTask({ task_uid })
        .then(() => {
          getProject(task?.project_uid);
          showToast("success", "Next task started successfully");
          setSelectedTask(null);
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader(false));
    },
    [getProject, task?.project_uid, showToast, setSelectedTask]
  );

  const reopenTask = useCallback(
    (task_uid) => {
      setLoader(true);

      TaskService.startNextTask({ task_uid, reopening_task: true })
        .then((r) => {
          getProject(task?.project_uid);
          showToast("success", r.task);
          setSelectedTask(null);
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader(false));
    },
    [getProject, task?.project_uid, showToast, setSelectedTask]
  );

  const copyTypeformLink = () => {
    navigator.clipboard.writeText(task?.typeform_link);
    showToast("success", "Link copied to clipboard");
  };

  useEffect(() => {
    setTask(selectedTask);
  }, [selectedTask]);

  useEffect(() => {
    sliderTopPosition?.current?.scrollIntoView();
  }, [task]);

  useEffect(() => {
    // If the task has a typeform link find the unique ID
    let changedTypeformId = "";
    if (task?.typeform_link) {
      // TEMPORARY FIX UNTIL PROJECTS IN PROD GET THE NEW URL
      if (task?.typeform_link.includes("core-intake")) {
        changedTypeformId = "vsRYZUHp";
      } else {
        const regex = new RegExp(/(?<=\/)[^\/#]+(?=#)/);
        const match = regex.exec(task?.typeform_link);
        changedTypeformId = match[0];
      }
    }
    setTypeformId(changedTypeformId);
  });

  return (
    <div
      className={`bg-grey-pale leading-page-height overflow-x-none overflow-y-auto w-[600px] max-w-[600px] ${
        task?.uid ? "max-w-[600px]" : "max-w-0"
      } transition-all`}
    >
      {/* loader */}
      {!!loader && <Loader themeColor="fill-prime" />}

      {/* header */}
      <div ref={sliderTopPosition} className="flex items-center px-4 py-2 bg-sec">
        {/* Task - <span className="text-sec">{taskObj?.name || ''}</span> */}
        <div className="flex items-center flex-1 pr-2 text-white">
          {/* {task?.name} */}
          {task?.name !== "" && (
            <>
              <Button
                dataTestId="test-id"
                label={
                  task?.status === "In Progress"
                    ? task?.estimated_duration_delta
                      ? isAssignedToYou(authContext?.role, task)
                        ? "✓  Mark Complete"
                        : ""
                      : `Wait, this task will be closed automatically`
                    : task?.status
                }
                variant="icon-flat"
                className="text-white"
                disabled={
                  task?.status !== "In Progress" ||
                  !isAssignedToYou(authContext?.role, task) ||
                  (task?.status === "In Progress" && !task?.estimated_duration_delta)
                }
                onClick={() => startNextTask(task?.uid)}
              />
              {task?.status === "Completed" &&
                task?.estimated_duration_delta > 0 &&
                authContext?.role === "Csm" && (
                  <Button
                    dataTestId="reopen-task"
                    label="Reopen task"
                    variant="icon-flat"
                    className="text-white"
                    onClick={() => reopenTask(task?.uid)}
                  />
                )}
            </>
          )}
          {/* <p className="px-3 text-sm"> Only CSM may mark this as complete. </p> */}
        </div>

        <Button
          dataTestId="test-id"
          label={<i className="fa-solid fa-xmark" />}
          variant="icon-flat"
          themecolor="prime"
          width="w-10"
          height="h-10"
          textSize="text-xl"
          onClick={() => setSelectedTask(null)}
        />
      </div>

      {/* body */}
      <div className="p-4">
        {/* basic task info */}
        <div>
          <div className="mb-4 text-xs font-light text-prime-light">
            <i className="fa-solid fa-lock" /> This task was created by the CSM and may not be
            changed.
          </div>

          <Typography type="subtitle" className="mb-4 font-medium text-md">
            {task?.name}
          </Typography>

          <div className="flex justify-between">
            <span className="mr-4 text-sm font-extralight text-prime-light">
              Due:{" "}
              {task?.estimated_duration
                ? moment(task?.estimated_duration).format("MMM DD, YYYY")
                : "--"}
            </span>
            <span className="mr-4 text-sm font-extralight text-prime-light">
              {task?.completed_on &&
                `Completed:  
                ${moment(task?.completed_on).format("MMM DD, YYYY")}`}
            </span>
            <span className="pr-6 text-sm font-extralight text-prime-light">
              {customerViewAssignedTo({ ...task })}
            </span>
          </div>
        </div>
        <Divider className="mt-2 mb-6" lineColor="bg-grey-light" />

        {/* task description */}
        <div className="mb-8">
          <Typography type="subtitle" className="mb-2 text-sm text-prime-light font-extralight">
            Task Description
          </Typography>
          <div
            className="text-prime"
            dangerouslySetInnerHTML={{
              __html: task?.description.replace(/\n/g, "<br/>"),
            }}
          />
        </div>

        {task?.typeform_link && (
          <>
            <div className="flex justify-center items-center">
              <PopupButton
                id={typeFormId}
                style={{ fontSize: 20 }}
                className="my-button"
                hidden={{
                  project_id: task.project_uid,
                  trial: paidSub ? false : true,
                  project_shortname: currentProject.name,
                  accountemail: authContext.user.email,
                  company: company?.name,
                }}
                transitiveSearchParams={[
                  "project id",
                  "trial",
                  "project shortname",
                  "accountemail",
                  "company",
                ]}
              >
                <Button
                  dataTestId="typeform-link-button"
                  label="Launch Questionnaire"
                  variant="full"
                  themecolor="prime"
                />
              </PopupButton>
            </div>
            <p
              className="flex justify-center items-center mt-4 hover:font-semibold hover:underline hover:cursor-pointer hover:text-prime-light"
              onClick={() => copyTypeformLink()}
            >
              Or click here copy the questionnaire link to share with others
            </p>
          </>
        )}
        <Divider className="my-4" lineColor="bg-grey-light" />

        {/* documents*/}
        <div>
          <TaskDocuments task={task} loader={loader} />
        </div>
        {/* <Divider className="my-6" lineColor="bg-grey-light" /> */}

        {/* discussion */}
        {task?.comments_widget && (
          <>
            <div>
              <Typography type="subtitle" className="mb-2 text-sm text-prime-light font-extralight">
                Discussion
              </Typography>

              <TaskDiscussion
                csmUid={csmUid}
                customerUid={customerUid}
                freelancerUid={freelancerUid}
                taskUid={task.uid}
                taskName={task.name}
                taskList={taskList}
                taskStatus={task?.status}
                currentProject={currentProject}
                fromName={fromName}
                changeMessageReadStateUi={changeMessageReadStateUi}
              />
            </div>
            <Divider className="my-4" lineColor="bg-grey-light" />
          </>
        )}

        {/* meetings */}
        {task?.calendar_widget && (
          <div>
            <Typography type="subtitle" className="mb-2 text-sm text-prime-light font-extralight">
              Meetings
            </Typography>

            {/* calendar */}
            {currentProject?.freelancer_uid && calendlyLink && (
              <p className="mb-4 text-prime-light">
                Schedule a meeting by clicking on the Calendar button below. It will redirect you to
                your assigned freelancer's Calendly profile. A third-party platform used for
                scheduling meetings.
              </p>
            )}

            {calendlyLink && (
              <a
                data-testid="schedule-meeting"
                href={calendlyLink}
                target="_blank"
                className="p-2 text-white rounded-lg bg-prime"
                rel="noreferrer"
              >
                <i className="mr-2 fa-solid fa-calendar-days" /> Calendar
              </a>
            )}

            {!currentProject?.freelancer_uid && <p>No freelancer assigned for now</p>}

            {!calendlyLink && <p>Freelancer did not add Calendly link yet</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskSlider;
