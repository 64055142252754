import React from "react";

// components
import { Paper, Typography } from "../../components";

const CybersecurityResources = ({ resources, title, className }) => (
  <Paper className={`p-6 mb-8 ${className}`}>
    <Typography type="title" className="mb-5">
      {title}
    </Typography>

    <div className="flex">
      {resources?.map((item, i) => (
        <div key={i} className="mx-2">
          <a
            href={item.href}
            target="_blank"
            className="mb-6 text-2xl font-medium text-sec"
            rel="noreferrer"
          >
            {item.hrefTitle}
          </a>
          <p className="px-1 my-4">{item.text}</p>
          <a
            href={item.href}
            target="_blank"
            className="mb-6 text-lg text-sec hover:underline"
            rel="noreferrer"
          >
            {item.hrefTitle2}
          </a>
        </div>
      ))}
    </div>
  </Paper>
);

export default CybersecurityResources;
