import React, { useEffect } from "react";

// context
import { useToast } from "../context";

// services
import { ResourcesService, errorMsgForUser } from "../services";

const ResourceTimeZone = ({ timeZone = "", setTimeZoneFormat }) => {
  // context
  const { showToast } = useToast();

  const getTimezones = () =>
    ResourcesService.getTimezones()
      .then((res) => {
        const timezones = res?.timezones || [];
        const timezoneObj = timezones?.find((n) => n.value === timeZone);

        if (timezoneObj) {
          setTimeZoneFormat(timezoneObj?.text);
        }
      })
      .catch((err) => showToast("error", errorMsgForUser(err)));

  useEffect(() => {
    getTimezones();
  }, [timeZone]);

  return <div />;
};

export default ResourceTimeZone;
