import React from "react";
import moment from "moment";
import DefaultAvatar from "../contact-manager/default-avatar.png";

const DiscussionMessages = ({ messages, goToBottom }) => (
  // const goToBottom = useRef(null);

  // useEffect(() => {
  //   goToBottom?.current?.scrollIntoView();
  // }, [messages]);

  <div className="max-h-[300px] overflow-y-auto">
    {messages &&
      messages.map((message) => (
        <div key={message.uid} className="my-6">
          <div ref={goToBottom} className="flex items-center my-3">
            <img
              src={
                message?.profile?.profile_image ? message?.profile?.profile_image : DefaultAvatar
              }
              alt="contact"
              className="w-6 h-6 mr-2 rounded-full"
            />
            <p className="mr-5 text-sm text-priome-light">
              {message?.profile?.responsible_person_first_name &&
              message?.profile?.responsible_person_last_name === "test"
                ? "CSM: Rolando Lopez"
                : `${message?.profile?.responsible_person_first_name} ${message?.profile?.responsible_person_last_name}`}
            </p>
            <p className="text-xs text-right text-prime-light">
              {moment(message?.created_at).format("MMM DD, YYYY @ h:mma")}
            </p>
          </div>

          <p className="px-3 break-words whitespace-pre-wrap">{message?.message}</p>
        </div>
      ))}
  </div>
);
export default DiscussionMessages;
