import { forwardRef } from "react";

const InputFile = forwardRef(
  (
    {
      dataTestId,
      label,
      placeholder = "",
      error = "",
      accept = "",
      // value = '',
      onChange,
      className = "mb-4",
      inputClassName = "",
      ...rest
    },
    ref
  ) => {
    const fileAdded = (e) => {
      let file = e.target.files[0];

      if (file) {
        // console.log('file', file);
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          // if (/\.(jpe?g|png)$/i.test(file.name)) {
          onChange(reader.result);
          // }
        };
      }
    };

    return (
      <div className={className}>
        <label>
          {label && <span className="block text-prime-light">{label}</span>}

          <input
            data-testid={dataTestId}
            ref={ref}
            type="file"
            placeholder={placeholder}
            // value={value}
            className={`w-full rounded-md border-gray-300 shadow-sm bg-gray-50 focus:border focus:border-prime focus:outline-none ${inputClassName}`}
            accept={accept}
            onChange={fileAdded}
            {...rest}
          />

          {error && <div className="mb-2 text-sm text-error">{error}</div>}
        </label>
      </div>
    );
  }
);

export default InputFile;
