import FoundersShield from "../../assets//img/partnerLogos/founders_shield.svg";
import { Paper, Typography } from "../../components";
import SVGImage from "../../components/SvgImage";

function CyberInsurance() {
  return (
    <>
      <Paper>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-8">
            <div className="flex flex-col">
              <Typography type="title" className="text-3xl mb-2">
                Need Cyber Insurance?
              </Typography>
              <p className="mb-4">
                Cyber Pop-up protects your business from cyber threats while our vetted partner,
                Founder Shield, is your insurance safety net if the unexpected occurs. Work with an
                expert to find the best coverage for your size, budget, and risk level today.
              </p>
              <p>
                You’ll get more attractive quotes if you have basic cybersecurity measures in place.
                Completing our Cyber Pop-up Peace of Mind Roadmap is a good start.
              </p>
            </div>
          </div>
          <div className="col-span-4">
            <div className="flex flex-col items-center">
              <div className="w-48">
                <SVGImage src={FoundersShield} />
              </div>
              <div className="mt-4 sm:mt-8">
                <a
                  href="https://app.foundershield.com/signup?partner=CyberPU&sf_type=Partnership&sf_referral=CyberPU" // You may wish to specify a target URL here!
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-md bg-prime px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
}

export default CyberInsurance;
