export const customer_questions = {
  question_bloks: {
    1: ["company_name", "industry"],
    2: ["employees_num", "annual_revenue"],
    3: ["main_customers"],
    4: ["customers_generally_location", "vendors_num"],
    5: ["sensitive_info_collection"],
    6: ["formal_cyber_security_strategy"],
    7: ["cyber_maturity"],
    8: ["current_technology"],
    9: ["vendor_collaboration_tools"],
    10: [
      "work_type",
      "full_time_cyber_security_workers",
      "cybersecurity_insurance_policy",
      "cyber_security_rate",
    ],
    11: [
      // 'responsible_person_email',
      "responsible_person_first_name",
      "responsible_person_last_name",
    ],
    12: [
      "web_site",
      // 'linkedin',
      "country",
      "state",
      "city",
      "zip_code",
      "time_zone",
      "prefered_language",
    ],
    13: ["how_did_you_hear_about_us"],
  },
  titles: {
    about_company: "First, tell us more about your amazing company!",
    cyber_security:
      "Let's talk about what you've done to date for Cybersecurity! It's okay if you're just getting started.",
    about_you: ["Almost done, just a few questions left to help us, help you!"],
  },
  company_name: {
    uid: 1,
    title: "about_company",
    input_element: "input",
    input_type: "text",
    label: "What is the name of your company?",
    value: "company_name",
    required: true,
  },
  industry: {
    uid: 2,
    title: "about_company",
    input_element: "select",
    input_type: null,
    label: "What industry best describes your company?",
    sublabel:
      "Certain industries have special cybersecurity regulations and compliance requirements.",
    options: [
      "Accounting",
      "Advertising",
      "Aerospace / Aviation",
      "Agriculture",
      "Biotech",
      "Construction",
      "E-commerce",
      "Education",
      "Energy",
      "Engineering",
      "Entertainment",
      "Finance / Banking / Insurance",
      "Food and Hospitality",
      "Government / Military",
      "Healthcare / Medical",
      "Information Technology",
      "Legal",
      "Manufacturing",
      "Marketing / Public Relations",
      "Media / Print / Publishing",
      "Mining",
      "Non-profit",
      "Professional Services",
      "Research and Development",
      "Real Estate",
      "Retail",
      "Telecommunications",
      "Transportation",
      "Utilities",
      "Other",
    ],
    value: "industry",
    required: true,
  },
  employees_num: {
    uid: 3,
    title: "about_company",
    input_element: "select",
    input_type: null,
    label: "How many employees work at your company?",
    sublabel:
      "Every single employee can become a target of a cyber attack. 90% of breaches start with human error.",
    options: ["0 - 10", "10 - 50", "50 - 500", "5,000 - 50,000", "50,000 +"],
    value: "employees_num",
    required: true,
  },
  annual_revenue: {
    uid: 4,
    title: "about_company",
    input_element: "select",
    input_type: null,
    label: "What's your companys  annual revenue?",
    sublabel:
      "Cyber attackers target companies of all sizes looking for security holes that can lead to huge illegal paydays.",
    options: [
      "Under $50,000",
      "$50,000 - $100,000",
      "$100,000 - $500,000",
      "$500,000 - $1 Million",
      "$1 Million - $10 Million",
      "$10 Million - $100 Million",
      "$100 Million - $1 Billion",
      "$1 Billion +",
    ],
    value: "annual_revenue",
    required: true,
  },
  main_customers: {
    uid: 5,
    title: "about_company",
    input_element: "checkbox",
    input_type: "multiple",
    label: "Who are your main customers?",
    sublabel:
      "These are the people who trust in your brand and your ability to delivery. Let's keep it that way.",
    options: [
      {
        value: "Individual consumers",
        dataTestId: "main_customers-individual-consumers",
      },
      {
        value: "For-profit businesses",
        dataTestId: "main_customers-for-profit-businesses",
      },
      {
        value: "Non-profit organizations",
        dataTestId: "main_customers-non-profit-organizations",
      },
      {
        value: "Government",
        dataTestId: "main_customers-government",
      },
      {
        value: "Healthcare providers",
        dataTestId: "main_customers-healthcare-providers",
      },
      {
        value: "Other",
        dataTestId: "main_customers-other",
      },
    ],
    value: "main_customers",
    required: true,
  },
  customers_generally_location: {
    uid: 6,
    title: "about_company",
    input_element: "select",
    input_type: null,
    label: "Where are your customers generally located?",
    options: [
      "Locally within my state",
      "Nationwide across the US",
      "Internationally within North America",
      "Worldwide",
    ],
    value: "customers_generally_location",
    required: true,
  },
  vendors_num: {
    uid: 7,
    title: "about_company",
    input_element: "select",
    input_type: null,
    label: "How many other vendors do you leverage to run your business?",
    sublabel:
      "Any vendor with access to your systems or data can cause a breach. For example, target experienced a multi-million dollar breach after their air conditioning vendor was breached.",
    options: [
      "We do it all on our own",
      "1 - 10 vendors",
      "10 - 50 vendors",
      "50+ vendors",
      "Not sure",
    ],
    value: "vendors_num",
    required: true,
  },
  sensitive_info_collection: {
    uid: 8,
    title: "cyber_security",
    input_element: "radio",
    input_type: null,
    label: "Does your company collect, process, or maintain any sensitive information?",
    sublabel:
      "This can include personal information on employees and customers, business strategies, product roadmaps, and more!",
    options: [
      {
        value:
          "Yes, but we only collect this information for ourselves and employees. Not for our customers.",
        dataTestId: "sensitive_info_collection-yes-only-to-ourselves",
      },
      {
        value: "Yes, we collect information from employees and customers.",
        dataTestId: "sensitive_info_collection-yes",
      },
      {
        value: "No, we don’t collect sensitive information.",
        dataTestId: "sensitive_info_collection-no",
      },
    ],
    value: "sensitive_info_collection",
    required: true,
  },
  formal_cyber_security_strategy: {
    uid: 9,
    title: "cyber_security",
    input_element: "select",
    input_type: null,
    label: "Do you have a formal Cybersecurity strategy and policy?",
    sublabel:
      "A good cybersecurity strategy outlines how you will prevent, detect, and respond to cybersecurity risks through secure processes, technology, and people.",
    options: [
      "Not yet",
      "We have one, but it needs work",
      "We have a world-class Cybersecurity program",
    ],
    value: "formal_cyber_security_strategy",
    required: true,
  },
  cybersecurity_strategy: {
    uid: "9-b-c",
    title: "cyber_security",
    input_element: "radio",
    input_type: null,
    label: "Which of the following best describes your Cybersecurity strategy?",
    sublabel:
      "Please select the statement below that best applies to your cybersecurity strategy: ",
    options: [
      {
        value:
          "I log in to each of my tool settings page and turn on all the security features I can the first time I login",
        dataTestId: "cybersecurity_strategy-1",
      },
      {
        value:
          "I have a documented Cybersecurity strategy aligned with an industry framework (i.e. CMMC, NIST, ISO)",
        dataTestId: "cybersecurity_strategy-2",
      },
      {
        value:
          "I have a documented Cybersecurity strategy aligned with an industry framework and a step by step plan on how to continue to improve",
        dataTestId: "cybersecurity_strategy-3",
      },
    ],
    value: "cybersecurity_strategy",
    required: true,
  },
  cyber_maturity: {
    uid: 10,
    title: "cyber_security",
    input_element: "radio",
    input_type: null,
    label: "How would you rate the maturity of your Cybersecurity program?",
    sublabel: "This is your strategy in action!",
    options: [
      {
        value: "We are eager to get started, but haven't yet",
        dataTestId: "cyber_maturity-0",
      },
      {
        value:
          "We are doing some of the basics like anti-virus, firewalls, and employee security training but could do better",
        dataTestId: "cyber_maturity-1",
      },
      {
        value:
          "We are doing a lot of the basics like antivirus, firewalls, centralized device management, automated patching, and vulnerability scanning but could use some help taking our Cybersecurity to the next level",
        dataTestId: "cyber_maturity-2",
      },
      {
        value: "We have a 5 star program and the cyber attackers can bring it on!",
        dataTestId: "cyber_maturity-3",
      },
    ],
    value: "cyber_maturity",
    required: true,
  },
  cyber_security_controls: {
    uid: "10-d",
    title: "cyber_security",
    input_element: "checkbox",
    input_type: "multiple",
    label: "What Cybersecurity controls are in place today?",
    options: [
      {
        value: "Anti-virus",
        dataTestId: "cyber_security_controls-0",
      },
      {
        value: "Firewalls",
        dataTestId: "cyber_security_controls-1",
      },
      {
        value: "Virtual Private Network (VPN)",
        dataTestId: "cyber_security_controls-2",
      },
      {
        value: "Employee Security training",
        dataTestId: "cyber_security_controls-3",
      },
      {
        value: "Cybersecurity Policy",
        dataTestId: "cyber_security_controls-4",
      },
      {
        value: "Individual logins & passwords assigned to each employee in all systems needed",
        dataTestId: "cyber_security_controls-5",
      },
      {
        value: "Centralized device management (i.e. MDM - Mobile Device management)",
        dataTestId: "cyber_security_controls-6",
      },
      {
        value: "Centralized incident response processes",
        dataTestId: "cyber_security_controls-7",
      },
      // {
      //   value: 'Badge reader access for company locations',
      //   dataTestId: 'cyber_security_controls-8',
      // },
    ],
    value: "cyber_security_controls",
    required: true,
  },
  current_technology: {
    uid: 11,
    title: "cyber_security",
    input_element: "checkbox",
    input_type: "multiple",
    label: "What technology does your company currently leverage today?",
    options: [
      {
        value: "Company owned:",
        dataTestId: "current_technology-0",
        heading: true,
      },
      {
        value: "Laptops",
        dataTestId: "current_technology-1",
      },
      {
        value: "Tablets",
        dataTestId: "current_technology-2",
      },
      {
        value: "Phones",
        dataTestId: "current_technology-3",
      },
      {
        value: "On-premise servers (not in Cloud)",
        dataTestId: "current_technology-4",
      },
      {
        value: "Cloud servers",
        dataTestId: "current_technology-5",
      },
      {
        value: "Custom applications",
        dataTestId: "current_technology-6",
      },
      {
        value: "Employee owned:",
        dataTestId: "current_technology-7",
        heading: true,
      },
      {
        value: "Laptops used for work",
        dataTestId: "current_technology-8",
      },
      {
        value: "Phones accessing some company apps (i.e. email, chat, etc.)",
        dataTestId: "current_technology-9",
      },
    ],
    value: "current_technology",
    required: true,
  },
  vendor_collaboration_tools: {
    uid: 12,
    title: "cyber_security",
    input_element: "checkbox",
    input_type: "multiple",
    label: "Are you using any of these common collaboration tools hosted by your vendor?",
    options: [
      {
        value: "Cloud email (i.e. Google Workspace, Microsoft O365, ZohoMail, etc.)",
        dataTestId: "vendor_collaboration_tools-0",
      },
      {
        value: "Cloud collaboration tools (i.e. Slack, Teams, Google chat)",
        dataTestId: "vendor_collaboration_tools-1",
      },
      {
        value: "Cloud file storage solutions (i.e. Google Drive, Box, OneDrive)",
        dataTestId: "vendor_collaboration_tools-2",
      },
      {
        value: "Vendor hosted website (i.e. Wix, Wordpress, Squarespace, Shopify)",
        dataTestId: "vendor_collaboration_tools-3",
      },
    ],
    value: "vendor_collaboration_tools",
    required: true,
  },
  vendor_collaboration_tools_host: {
    uid: "12-a",
    title: "cyber_security",
    input_element: "input",
    input_type: "text",
    label: "Which one ?",
    value: "vendor_collaboration_tools_host",
    required: true,
  },
  work_type: {
    uid: 13,
    title: "cyber_security",
    input_element: "radio",
    input_type: null,
    label: "How much of your workforce is remote?",
    options: [
      {
        value:
          "We are 100% remote and don't have a physical office (outside of one for mailing purposes).",
        dataTestId: "work_type-0",
      },
      {
        value: "We have a hybrid workforce with time split between in-office and remote work.",
        dataTestId: "work_type-1",
      },
      {
        value: "We are 100% in office and don’t allow remote work options today.",
        dataTestId: "work_type-2",
      },
    ],
    value: "work_type",
    required: true,
  },
  full_time_cyber_security_workers: {
    uid: 14,
    title: "cyber_security",
    input_element: "radio",
    input_type: null,
    label: "How many people at your company are dedicated full-time to managing Cybersecurity?",
    sublabel: "This can include employees and contractors.",
    options: [
      {
        value: "We don't have anyone yet",
        dataTestId: "full_time_cyber_security_workers-0",
      },
      {
        value: "We have an IT generalist that handles Cybersecurity related work as needed.",
        dataTestId: "full_time_cyber_security_workers-1",
      },
      {
        value: "1 - 25 people",
        dataTestId: "full_time_cyber_security_workers-2",
      },
      {
        value: "25+ people",
        dataTestId: "full_time_cyber_security_workers-3",
      },
    ],
    value: "full_time_cyber_security_workers",
    required: true,
  },
  cybersecurity_insurance_policy: {
    uid: 15,
    title: "cyber_security",
    input_element: "radio",
    input_type: null,
    label: "Does your company have a Cybersecurity insurance policy?",
    sublabel:
      "This is an insurance policy that helps you in case you ever experience a cyber attack or breach.",
    options: [
      {
        value: "Yes",
        dataTestId: "cybersecurity_insurance_policy-0",
      },
      {
        value: "No",
        dataTestId: "cybersecurity_insurance_policy-1",
      },
      {
        value: "I am not sure",
        dataTestId: "cybersecurity_insurance_policy-2",
      },
    ],
    value: "cybersecurity_insurance_policy",
    required: true,
  },
  cyber_security_rate: {
    uid: 16,
    title: "cyber_security",
    input_element: "select",
    input_type: null,
    label:
      "On a scale of 1 being low, 5 being high, how would you rate your peace of mind regarding your company's cybersecurity to protect against hackers?",
    options: ["1", "2", "3", "4", "5"],
    value: "cyber_security_rate",
    required: true,
  },
  // responsible_person_email: {
  //   uid: 17,
  //   title: 'about_you',
  //   input_element: 'input',
  //   input_type: 'email',
  //   label: 'Email address',
  //   value: 'responsible_person_email',
  //   required: true,
  // },
  responsible_person_first_name: {
    uid: 18,
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "What is your first name?",
    value: "responsible_person_first_name",
    required: true,
  },
  responsible_person_last_name: {
    uid: 19,
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "What is your last name?",
    value: "responsible_person_last_name",
    required: true,
  },
  web_site: {
    uid: 20,
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "Company Website",
    value: "web_site",
    required: true,
  },
  // linkedin: {
  //   uid: 21,
  //   title: 'about_you',
  //   input_element: 'input',
  //   input_type: 'text',
  //   label: 'Company Linkedin',
  //   value: 'linkedin',
  //   required: true,
  // },
  country: {
    uid: 21,
    title: "about_you",
    input_element: "select",
    input_type: null,
    label: "What Country is your company registered in?",
    options: "countries",
    keyForOptionValue: "alpha3Code",
    keyForOptionLabel: "country",
    value: "country",
    required: true,
  },
  state: {
    uid: 22,
    title: "about_you",
    input_element: "select",
    input_type: null,
    label: "What State is your company based out of?",
    options: "states",
    value: "state",
    required: false,
  },
  city: {
    uid: 23,
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "What City is your company based out of?",
    value: "city",
    required: true,
  },
  zip_code: {
    uid: 24,
    title: "about_you",
    input_element: "input",
    input_type: "text",
    min: "0",
    label: "What Postal Code is your company registered in?",
    value: "zip_code",
    required: true,
  },
  time_zone: {
    uid: 25,
    title: "about_you",
    input_element: "select",
    input_type: null,
    label: "What timezone are you in?",
    options: "timezones",
    keyForOptionValue: "value",
    keyForOptionLabel: "text",
    value: "time_zone",
    required: true,
  },
  prefered_language: {
    uid: 26,
    title: "about_you",
    input_element: "select",
    input_type: null,
    label: "What language do you prefer for interactions and meetings?",
    options: "languages",
    value: "prefered_language",
    required: true,
  },
  how_did_you_hear_about_us: {
    uid: 27,
    title: "about_you",
    input_element: "select",
    input_type: null,
    label: "How did you hear about us?",
    options: ["Google", "LinkedIn", "Email", "Other"],
    value: "how_did_you_hear_about_us",
    required: true,
  },
  how_did_you_hear_about_us_other: {
    uid: "27-a",
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "Please specify",
    required: true,
    value: "how_did_you_hear_about_us_other",
  },
};
