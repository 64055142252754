import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// components
import { Loader, Typography } from "../../components";

// context
import { useToast } from "../../context";

// services
import { UserService, errorMsgForUser } from "../../services";

const VerifyEmail = () => {
  // route
  let navigate = useNavigate();
  let { token, userUid } = useParams();

  // context
  const { showToast } = useToast();

  // local
  const [noUserUid, sestNoUserUid] = useState(false);

  const verifyUserEmail = (userUid, token) =>
    UserService.verifyEmail({ user_uid: userUid, token: token })
      .then(() => {
        showToast("success", "Email verified");
        // reroute
        navigate("/signin");
      })
      .catch((err) => {
        showToast("error", errorMsgForUser(err));
        sestNoUserUid(true);
      });

  useEffect(() => {
    if (userUid) {
      sestNoUserUid(false);
      verifyUserEmail(userUid, token);
    } else {
      sestNoUserUid(true);
    }
  }, [userUid]);

  return (
    <div className="flex items-center justify-center h-full">
      {noUserUid ? (
        <div>
          <Typography type="subtitle" className="mb-4 text-center">
            Something went wrong.
          </Typography>
          <Typography type="subtitle" className="text-center">
            Please go to the email and try again.
          </Typography>
        </div>
      ) : (
        <div>
          {/* loader */}
          <Loader themeColor="fill-prime" />

          <Typography type="subtitle" className="text-center">
            Email validation is in progress ...
          </Typography>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
