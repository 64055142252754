import React from "react";

// components
import { Paper, Typography } from "../../components";

const TermsAndConditions = () => (
  <div className="flex-1 flex justify-center p-page">
    <Paper className="max-w-[1000px]">
      <Typography type="title" className="text-center mb-16">
        Terms and Conditions
      </Typography>

      <p className="font-bold">Cyber Pop-up Terms of Use</p>
      <p className="mb-4">
        The following are the terms and conditions (the "Terms") for the use of the Cyber Pop-up web
        site, Cyber Pop-up services, and any related material (collectively the "Site"). The Terms
        describe when and how you (the “Site Visitor”) are allowed to access and use the Site. These
        terms are considered a subset of the Cyber Pop-up Terms of Service.
      </p>

      <p className="font-bold">GENERAL</p>
      <p className="mb-4">With each visit to the Site you signify your agreement to these Terms.</p>
      <p className="mb-4">These Terms were last modified on January 11, 2020.</p>
      <p className="mb-4">
        Cyber Pop-up owns the Cyber Pop-up trade name, brand and trademark and websites. Cyber
        Pop-up Material is defined as any intellectual property, legal materials, documents, web
        pages, software, products, web links, email, information, or any other materials provided by
        or previously provided by Cyber Pop-up or the Site, including any portion thereof. Cyber
        Pop-up Services is defined as any work provided or received through the Site.
      </p>
      <p className="mb-4">
        If you do not wish to be bound by these Terms, you are not granted authority to use or
        access the Site or Cyber Pop-up Material.
      </p>

      <p className="font-bold">1. USE OF THE CYBER POP-UP SITE</p>
      <p className="mb-4">
        You agree not to use any Cyber Pop-up Material and Cyber Pop-up Services for any purpose
        which is against any law in your jurisdiction or for any purpose which would not give full
        effect to the Terms even if that purpose is otherwise allowed under the Terms.
      </p>
      <p className="mb-4">
        Cyber Pop-up provides a limited license to use the Site which can be terminated effective
        immediately upon notice.
      </p>
      <p className="font-bold">2. INTELLECTUAL PROPERTY</p>
      <p className="mb-4">
        All copyright, trade marks (including its distinguishing guise and/or trade dress), and
        other intellectual property rights (registered and unregistered) of Cyber Pop-up Material
        belong to Cyber Pop-up and its suppliers. Cyber Pop-up reserves all of its rights in the
        Cyber Pop-up Material. Nothing in the
      </p>
      <p className="mb-4">
        Terms grants you a right or licence to use any intellectual property rights owned or
        controlled by Cyber Pop-up or any other third party except as expressly provided in these
        Terms.
      </p>
      <p className="mb-4">
        In addition, Cyber Pop-up Material, contains information and intellectual property that is
        selected and organized by Cyber Pop-up and represents significant work made by Cyber Pop-up.
        Nothing in the Terms should be construed as granting any license or right to use any Cyber
        Pop-up Material or intellectual property displayed or used in any Cyber Pop-up Material
        except as expressly provided in the Terms.
      </p>
      <p className="font-bold">You agree to the following:</p>
      <ol className="mb-4 list-decimal pl-12">
        <li>the Cyber Pop-up Material is the property of Cyber Pop-up and its suppliers;</li>
        <li>
          you will not use the Cyber Pop-up Material for any purpose other than is expressly
          permitted in these Terms;
        </li>
        <li>
          you will not distribute in any medium any Cyber Pop-up Material without Cyber Pop-up prior
          written authorization or as expressly provided these Terms.
        </li>
        <li>
          any distribution or past distribution of any Cyber Pop-up Material that violates these
          Terms is subject to liquidated damages in an amount equal to that which would be payable
          by a copyright infringer for knowingly copying a work, and based on each page of any
          website which uses Cyber Pop-up Material being a separate work under the Copyright Act and
          each access of a page of a website by a customer being a separate publication of a work.
        </li>
        <li>Cyber Pop-up shall have the right to represent its suppliers in any dispute.</li>
      </ol>
      <p className="font-bold">3. PERMITTED SITE USE</p>
      <p className="mb-4">
        The Site is available for users to enter into short term service relationships, make and
        receive payments, and receive and provide Cyber-expert Cybersecurity services. We do not make
        any representations or warranties with respect to any information that is posted on the Site
        by us or anyone else. You should independently verify the accuracy of any content.
      </p>
      <p className="font-bold">4. PROHIBITED SITE USES AND RESTRICTIONS</p>
      <p className="mb-4">
        You may not use, or encourage, promote, facilitate, instruct or induce others to use, the
        Site or Cyber Pop-up Services for any activities that violate any law, statute, ordinance or
        regulation; for any other illegal or fraudulent purpose or any purpose that is harmful to
        others; or to transmit, store, display, distribute or otherwise make available content that
        is illegal, fraudulent or harmful to others.
      </p>
      <p className="mb-4">
        Federal law prevents Cyber Pop-up from facilitating completion of projects or working with
        freelancers in certain countries. These countries include North Korea, Iran, Cuba, Syria,
        Crimea, and any other country added to the U.S. sanctions program{" "}
        <a
          className="text-blue-500"
          href="https://home.treasury.gov/policy-issues/financial-sanctions/sanctions-programs-and-country-information"
        >
          list
        </a>
        .
      </p>
      <p className="mb-4">
        No matter where you reside, you are responsible for complying with any work authorization
        requirements that may apply to you. We are not able to advise you on whether you can use
        Cyber Pop-up, and you are fully responsible for an non-compliance stemming from your use of
        the Site.
      </p>
      <p className="font-bold">5. EXAMPLES OF PROHIBITED USES OF THE SITE</p>
      <p className="mb-4">
        The following are examples of uses that are prohibited on the Site or when using the Cyber
        Pop-up Services:
      </p>
      <ul className="mb-4 list-disc	pl-8">
        <li>
          Seeking, offering, promoting, or endorsing and services, content, or activities that:
        </li>
        <ul className="list-decimal pl-8 mb-2">
          <li>
            are defamatory, illegal, profane, vulgar, threatening, unlawfully discriminatory,
            illegal, pornographic, obscene, or sexually explicit in nature;
          </li>
          <li>
            would violate the intellectual property rights, such as and including copyrights, of
            another person, entity, service, product, or website;
          </li>
          <li>
            would violate (a) Cyber Pop-ups Terms of Service, (b) the terms of service of another
            website or any similar contractual obligations, or (c) the academic policies of any
            educational institution;
          </li>
          <li>
            are harassing toward another person based on the person's inclusion in a protected class
            as defined by applicable law;
          </li>
        </ul>
        <li>Fraudulent or misleading uses or content, including:</li>
        <ul className="list-decimal pl-8">
          <li>fraudulently billing or attempting to fraudulently bill any Client or Freelancer</li>
          <li>
            misrepresentin63-profile-change-password-and-imageg your experience, skills, or
            information, including by representing another person’s profile, or parts of another
            person’s profile, as your own;
            {/* Edit here */}
          </li>
          <li>
            using a profile photo that misrepresents your identity or represents you as someone
            else;
          </li>
          <li>impersonating any person or entity</li>
          <li>
            falsely stating or implying a relationship with Cyber Pop-up or with another company
            with whom you do not have a relationship;
          </li>
          <li>
            allowing another person to use your account, which is misleading to other users; or
          </li>
          <li>
            falsely stating that one freelancer will perform the work on a job when another will in
            fact perform the work, including submitting a proposal on behalf of a freelancer that is
            unable, unwilling, or unavailable to do the work;
          </li>
        </ul>
        <li>Duplicating or sharing accounts;</li>
        <li>Attempted to gain access to unauthorized accounts or groups on the site</li>
        <li>Selling, trading, or giving an account to another person</li>
        <li>
          Sharing or soliciting contact information, such as email, phone number, or Skype ID, in a
          profile or job post;
        </li>
        <li>
          Directly or indirectly, advertising or promoting another website, product, or service or
          soliciting other users for other websites, products, or services, including advertising on
          Cyber Pop-up to recruit Freelancers and/or Clients to join an Agency or another website or
          company;
        </li>
        <ul className="list-decimal pl-8">
          <li>
            interfering or attempting to interfere with the proper operation of the Site or Cyber
            Pop-up Services or any activities conducted on the Site;
          </li>
          <li>
            bypassing any measures we may use to prevent or restrict access to the Site or any
            subparts of the Site, including, without limitation, features that prevent or restrict
            use or copying of any content or enforce limitations on use of the Site or the content
            therein;
          </li>
          <li>
            attempting to interfere with or compromise the system integrity or security or decipher
            any transmissions to or from the servers running the Site;
          </li>
          <li>
            using any robot, spider, scraper, or other automated means to access the Site for any
            purpose without our express written permission;
          </li>
          <li>
            attempting to or interfering with or compromising the system integrity or security or
            deciphering any transmissions to or from the servers running the Site;
          </li>
          <li>
            collecting or harvesting any personally identifiable information, including Account
            names, from the Site;
          </li>
          <li>
            attempting to or imposing an unreasonable or disproportionately large load (as
            determined in Cyber Pop-up’s sole discretion) on the Site’s infrastructure; Introducing
            any invalid data, virus, worm, or other harmful or malicious software code, agent,
            hidden procedure, routine, or mechanism through or to the Site that is designed to or
            known to cause to cease functioning, disrupt, disable, harm, or otherwise impair in any
            manner, including aesthetic disruptions or distortions, the operation of (or to allow
            you or any other person to access or damage or corrupt data, storage media, programs,
            equipment, or communications or otherwise interfere with operations of or on) the Site
            or any software, firmware, hardware, computer system, or network of Cyber Pop-up or any
            third party;
          </li>
          <li>
            accessing or attempting to access the Site or Cyber Pop-up Services by any means or
            technology other than the interface provided; or
          </li>
          <li>
            framing or linking to the Site or Cyber Pop-up Services except as permitted in writing
            by Cyber Pop-up
          </li>
        </ul>
        <li>Conduct or actions that could jeopardize the integrity of or circumvent the Site:</li>
        <ul className="list-decimal pl-8">
          <li>
            attempting to or actually reverse engineering, modifying, adapting, translating,
            preparing derivative works from, decompiling, interfering with the operation of, or
            otherwise attempting to derive source code from any part of the Site
          </li>
          <li>
            Accessing or using the Site to build a similar service or application, identify or
            solicit Cyber Pop-up users, or publish any performance or any benchmark test or analysis
            relating to the Site.
          </li>
        </ul>
      </ul>
      <p className="font-bold">6. ENFORCEMENT</p>
      <p className="mb-4">
        We reserve the right, but do not assume the obligation, to investigate any potential
        violation of the Terms of Service and to remove, disable access to, or modify any content on
        the Site. Our failure to act with respect to a breach by you or others does not waive our
        right to act with respect to subsequent or similar breaches. Cyber Pop-up retains the right
        to prohibit organizations, groups, or individuals, or yourself from using its websites or
        Cyber Pop-up Material or Cyber Pop-up Services at its discretion.
      </p>

      <p className="mb-4">
        You agree to only use the Site as a customer. Licenses granted are subject to these Terms,
        and are non-exclusive, non-transferable, and revocable. The rights granted under these Terms
        are granted to you only.
      </p>
      <p className="font-bold">7. REPORTING AND CORRECTING VIOLATIONS</p>
      <p className="mb-4">
        If you become aware of any violation of these Site Terms or Use, you must immediately report
        it to us at info@cyberpopup.com. You agree to assist us with any investigation we undertake
        and to take any remedial steps we require in order to correct a violation of these Site
        Terms of Service.
      </p>
      <p className="font-bold">8. NON COMPETE</p>
      <p className="mb-4">
        If a person providing contract work (the “Freelancer”) and the entity purchasing the service
        (the “Client”) are connected through the Cyber Pop-up Services or the Site, both parties are
        prohibited from conducting direct transactions with each other for Cyber-expert security
        services outside of the Site for up to two year after the initial connection. However,
        Freelancers are allowed to be hired full-time with Clients, in which case direct work is
        allowed.
      </p>
      <p className="font-bold">9. LIMITED WARRANTIES</p>
      <p className="mb-4">
        Except as expressly provided in these Terms, to the maximum extent permitted under
        applicable law, Cyber Pop-up Material and Cyber Pop-up Services are provided "as is" without
        any kind of warranty.
      </p>
      <p className="mb-4">
        You accept full responsibility for determining whether Cyber Pop-up Material and Cyber
        Pop-up Services are suitable for any particular purpose and for protecting yourself against
        any possible consequential damages.
      </p>
      <p className="mb-4">
        Except as expressly provided in these Terms or where prohibited by law, we are not
        responsible for any loss, injury, claim, liability, damage, or consequential damage related
        to your use of Cyber Pop-up Material and Cyber Pop-up Services, or for inaccessibility of
        Cyber Pop-up Material and Cyber Pop-up Services whether from errors or omissions in the
        content of Cyber Pop-up Material and Cyber Pop-up Services or any other linked sites or for
        any other reason. Use of Cyber Pop-up Material and Cyber Pop-up Services is at your own
        risk.
      </p>
      <p className="mb-4">
        Cyber Pop-up does not represent or warrant that Cyber Pop-up Material, the Site or any
        linked sites are free of any harmful materials.
      </p>
      <p className="font-bold">10. MAXIMUM LIABILITY</p>
      <p className="mb-4">
        Except as expressly provided in these Terms or where prohibited by law, the maximum
        liability of Cyber Pop-up is the amount paid to Cyber Pop-up by the customer; however, where
        Cyber Pop-up has provided a Cyber-expert to complete a service, the maximum liability of Cyber
        Pop-up for any Cyber Pop-up Services is the portion of the amount retained by Cyber Pop-up
        specifically for the Cyber Pop-up Services as calculated by Cyber Pop-up.
      </p>
      <p className="font-bold">11. INDEMNIFICATION</p>
      <p className="mb-4">
        Except where prohibited by law, you agree to indemnify and hold Cyber Pop-up, its directors,
        officers and employees, harmless from any actions, claims, losses, damages, liabilities and
        expenses including legal fees, asserted by any third party due to or arising out of your use
        of the Site or Cyber Pop-up Material or Cyber Pop-up Services.
      </p>
      <p className="font-bold">12. GOVERNING LAW</p>
      <p className="mb-4">
        Except where prohibited by law, the Terms are governed by the laws of Illinois. Except where
        prohibited by law, you irrevocably attorn to the exclusive jurisdiction of the courts of
        Illinois.
      </p>
      <p className="font-bold">13. BINDING ARBITRATION</p>
      <p className="mb-4">
        Subject to exceptions specified herein, if you and Cyber Pop-up are unable to resolve any
        dispute by informal negotiations, then any resolution of this dispute will be conducted
        exclusively by binding arbitration.
      </p>
      <p className="mb-4">
        A request for appointment of an arbitrator must be made in writing. Upon receipt of the
        written request, Cyber Pop-up will have 120 days to choose and appoint an independent and
        impartial arbitrator. The arbitration will be held in Chicago, IL.
      </p>
      <p className="mb-4">
        The cost of the binding arbitration proceedings and any proceeding in court to confirm or to
        vacate any arbitration award, including, without limitation, reasonable attorneys' fees and
        costs, will be borne by the unsuccessful party and will be determined and awarded by the
        arbitrator.
      </p>
      <p className="mb-4">
        Exceptions to the use of binding arbitration are as follows: Cyber Pop-up may bring forth a
        lawsuit, without using binding arbitration, should the lawsuit involve intellectual property
        infringement or injunctive relief.
      </p>
      <p className="font-bold">14. CLASS ACTION WAIVER</p>
      <p className="mb-4">
        You will not seek to have the dispute heard as a class action, private attorney general
        action or in any other proceeding in which either party acts or proposes to act in a
        representative capacity. Arbitration or any other proceeding to resolve any dispute, in any
        forum, will be conducted solely on an individual basis and not combined with another without
        the prior written consent of all parties to all affected arbitrations or proceedings.
      </p>
      <p className="font-bold">15. MODIFICATION OF TERMS</p>
      <p className="mb-4">
        Except that you must be informed in a reasonable manner regarding any pricing increase,
        Cyber Pop-up may, in its sole discretion, change these Terms at any time. Other terms and
        conditions are only valid when signed in writing by an authorized Cyber Pop-up officer.
      </p>
      <p className="font-bold">16. SEVERABILITY</p>
      <p className="mb-4">
        If any term, covenant, condition or provision of these Terms is held by a court of competent
        jurisdiction to be invalid, void or unenforceable, such provision is to be excluded to the
        extent of such invalidity or unenforceability and all other provisions will remain in full
        force and effect; and, to the extent permitted and possible, the invalid or unenforceable
        provision will be deemed replaced by a provision that is valid and enforceable and that
        comes closest to expressing the intention of such invalid or unenforceable provision.
      </p>
      <p className="font-bold">17. WAIVERS</p>
      <p className="mb-4">
        No failure or delay, on the part of Cyber Pop-up, in exercising any right or power under
        these Terms will operate as a waiver of such right or power.
      </p>
      <p className="font-bold">18. WHOLE AGREEMENT</p>
      <p className="mb-4">
        Except as explicitly set forth in this agreement, these Terms and any content included on
        the Site, constitute the whole agreement between you and Cyber Pop-up. notwithstanding any:
      </p>
      <ol>
        <li>Cyber Pop-up Material other than this Agreement,</li>
        <li>
          communication between you and Cyber Pop-up, including telephone, email and online chat
          assistance, or
        </li>
        <li>announcements, newsletters or promotional materials from Cyber Pop-up.</li>
      </ol>
    </Paper>
  </div>
);

export default TermsAndConditions;
