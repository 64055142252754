import { useEffect, useState } from "react";
import { Card, Loader } from "../../../components";
import { APPLICATION_STATUSES, FREELANCER_STATUSES } from "../../../constants/statuses";
import CyberExpertStatusDisplay from "./CyberExpertStatusDisplay";
import ProfileCompletionTaskList from "./ProfileCompletionTaskList";
import StripeSetupTask from "./StripeSetupTask";

export default function CyberExpertStatus({
  progressValue,
  profile,
  handleTabChange,
  handleUploadPictureClick,
  handleProfileSubmit,
  stripeConfirmed,
  setSubmitApplicationModalVisible,
}) {
  // * Value states
  const [applicationStatus, setApplicationStatus] = useState();
  const [backgroundCheckStatus, setBackgroundCheckStatus] = useState();
  const [cyberExpertStatus, setCyberExpertStatus] = useState();

  // * Label states
  const [applicationStatusLabel, setApplicationStatusLabel] = useState();
  const [backgroundCheckStatusLabel, setBackgroundCheckStatusLabel] = useState();
  const [cyberExpertStatusLabel, setCyberExpertStatusLabel] = useState();

  useEffect(() => {
    setBackgroundCheckStatus(profile?.background_check_status);
    setApplicationStatus(profile?.application_status);
    setCyberExpertStatus(profile?.freelancer_status);
  }, [profile]);

  useEffect(() => {
    let label = "";

    let status;
    // Changes "Expert" and "Enthusiast" to "Approved" label
    if (["Expert", "Enthusiast"].includes(applicationStatus)) {
      label = "Approved";
    } else {
      status = APPLICATION_STATUSES.find((status) => status.value === applicationStatus);
      label = status?.label;
    }
    setApplicationStatusLabel(label);

    // Changes null and "Sent" to "Pending Review"
    if (!backgroundCheckStatus || backgroundCheckStatus === "Sent") {
      label = "Pending Review";
      // Currently denied applications write the string "null" to the DB
      // TODO: Fix backend to not write string "null"
    } else if (backgroundCheckStatus === "null") {
      label = "Denied";
    } else {
      label = backgroundCheckStatus;
    }
    setBackgroundCheckStatusLabel(label);

    // Cyber expert label
    status = FREELANCER_STATUSES.find((status) => status.value === cyberExpertStatus);
    setCyberExpertStatusLabel(status?.label);
  }, [applicationStatus]);

  if (!applicationStatus)
    return (
      <div className="relative">
        <Loader className="absolute" />
      </div>
    );

  if (applicationStatus === "Abandoned") {
    return (
      <Card title="Application inactive" subtile="get back">
        <p className="mb-4">
          You application has gone more than 60 days without a submission and was marked inactive.
        </p>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-prime px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
          onClick={() => handleProfileSubmit("Incomplete")}
        >
          Re-instate application
        </button>
      </Card>
    );
  }

  if (applicationStatus === "Incomplete") {
    return (
      <ProfileCompletionTaskList
        profile={profile}
        progressValue={progressValue}
        handleTabChange={handleTabChange}
        handleUploadPictureClick={handleUploadPictureClick}
        setSubmitApplicationModalVisible={setSubmitApplicationModalVisible}
      />
    );
  }

  if (cyberExpertStatus === "Expert" && !stripeConfirmed && profile?.country === "USA") {
    return <StripeSetupTask handleTabChange={handleTabChange} />;
  }

  if (applicationStatus !== "Incomplete") {
    return (
      <CyberExpertStatusDisplay
        applicationStatus={applicationStatus}
        applicationStatusLabel={applicationStatusLabel}
        backgroundCheckStatusLabel={backgroundCheckStatusLabel}
        cyberExpertStatusLabel={cyberExpertStatusLabel}
      />
    );
  }
}
