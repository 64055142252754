import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

// components
import { Paper, Typography, Button, ProgressBar, Loader } from "../../components";
import { useAuth, useToast } from "../../context";
import Resources from "../../resources/Resources";
import { QuestionnaireService, ProfileService, errorMsgForUser } from "../../services";
import FreelancerCodeOfConduct from "../terms-privacies/FreelancerCodeOfConduct";
import FreelancerProjectTerms from "../terms-privacies/FreelancerProjectTerms";
import FreelancerQuestionnaireAdditionalQuestions from "./FreelancerQuestionnaireAdditionalQuestions";
import SetInputElement from "./SetInputElement";

// context

// services
import Stepper from "./Stepper";

// constants
import { freelancer_questions } from "./freelancer_questions";

const initialProfile = {
  responsible_person_first_name: "",
  responsible_person_last_name: "",
  country: "USA",
  state: "",
  city: "",
  zip_code: "",
  address_one: "",
  address_two: "",
  time_zone: "",
  prefered_language: "English",
  social_media: [],
  resume_upload: "",
  hear_about_us: "",
  hear_about_us_where: "",
  what_led_to_applay: "", // has characters restriction
  years_of_experience: "",
  management_skills: {
    "Cyber Security Strategy and Program Development": "None",
    "General Security Advising": "None",
    "Risk Assessment": "None",
    "Compliance (e.g. GDPR, PCI, HIPAA)": "None",
    "Training and Awareness": "None",
    "Cybersecurity Policy": "None",
    "Cybersecurity Maturity Assessment": "None",
    Other: "",
  },

  experience_in_industry: {
    Agriculture: "None",
    Energy: "None",
    "Computer and Electronics": "None",
    "Transportation and Warehousing": "None",
    Software: "None",
    "Real Estate, Rental, Leasing": "None",
    Education: "None",
    "Hospitality and Travel": "None",
    Healthcare: "None",
    Legal: "None",
    Construction: "None",
    Manufacturing: "None",
    Retail: "None",
    Telecommunications: "None",
    Finance: "None",
    "Arts, Entertainment": "None",
    "Government and Public Administration": "None",
    Scientific: "None",
    Military: "None",
    Other: "",
  },
  experience_in_area: {
    "Network Security (VPN, Firewall, Anti-virus)": "None",
    "Security Testing and Ethical Hacking": "None",
    "Data Security": "None",
    "Security Operations Center": "None",
    "Cloud Security": "None",
    "Data Loss Prevention": "None",
    "Email Security": "None",
    "Identity and Access Management": "None",
    "Incident Response": "None",
    "Security Monitoring and SIEM": "None",
    "Digital Forensics and Investigations": "None",
    "Cybersecurity Architecture": "None",
    "Cybersecurity Automation": "None",
    "Application Security": "None",
    Other: "",
  },
  certifications: [],

  hours_per_week: "",
  comfort_level: "",
  accomplishment: "",
  gender: "",
  race: [],
  race_other: "",
  veteran_status: "",

  project_terms: "",
  code_of_conduct: "",
};

const FreelancerQuestionnaire = () => {
  // router
  let navigate = useNavigate();
  let { blok } = useParams();

  // context
  const { authContext, setAuthContext } = useAuth();
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(0);
  const [profile, setProfile] = useState(initialProfile);
  const [currentQuestionsBlok, setCurrentQuestionsBlok] = useState([]);
  const [certificateBlockLocked, setCertificateBlockLocked] = useState(true);
  // resources
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [timezones, setTimezones] = useState([]);

  const setInputElement = (question) => (
    <SetInputElement
      question={question}
      questions={freelancer_questions}
      profile={profile}
      setProfile={setProfile}
      countries={countries}
      states={states}
      languages={languages}
      timezones={timezones}
      setCertificateBlockLocked={setCertificateBlockLocked}
    />
  );

  const isNextDisabled = () => {
    let disableButton = false;

    // dependency - state
    if (blok === "1") {
      if (!isEmpty(states) && !profile.state) {
        disableButton = true;
      }
    }

    const requiredQuestionsInBlock = currentQuestionsBlok.filter(
      (n) => freelancer_questions[n]?.required === true
    );
    requiredQuestionsInBlock &&
      requiredQuestionsInBlock.forEach((q) => {
        if (!profile[q] || isEmpty(profile[q])) {
          disableButton = true;
        }
      });

    // dependency - hear_about_us
    if (blok === "3") {
      if (profile.hear_about_us === "Other" && !profile.hear_about_us_where) {
        disableButton = true;
      }

      if (profile?.what_led_to_applay?.length > 600) {
        disableButton = true;
      }
    }

    // dependency - experience_in_industry
    if (blok === "5") {
      if (profile?.experience_in_industry["Other"]?.length > 50) {
        disableButton = true;
      }
    }

    // dependency - experience_in_area
    if (blok === "6") {
      if (profile?.experience_in_area["Other"]?.length > 50) {
        disableButton = true;
      }
    }

    // certificate added or skipped
    if (blok === "7") {
      if (certificateBlockLocked) {
        disableButton = true;
      }
    }

    // dependency - accomplishment
    if (blok === "9") {
      if (profile?.accomplishment?.length > 600) {
        disableButton = true;
      }
    }

    return disableButton;
  };

  const nextClicked = () => {
    // is last group of questions
    if (blok === "12") {
      return createQuestionnaire();
    }

    const nextBlok = Number(blok) + 1;
    navigate(`/questionnaire/${nextBlok}`);
  };

  const createQuestionnaire = () => {
    // questionnaire create Api
    const questionnaireObj = {
      social_media: profile.social_media,
      hear_about_us: profile.hear_about_us,
      hear_about_us_where: profile.hear_about_us_where,
      what_led_to_applay: profile.what_led_to_applay,
      years_of_experience: profile.years_of_experience,
      management_skills: profile.management_skills,
      experience_in_industry: profile.experience_in_industry,
      experience_in_area: profile.experience_in_area,
      certifications: profile.certifications,
      hours_per_week: profile.hours_per_week,
      comfort_level: profile.comfort_level,
      accomplishment: profile.accomplishment,
    };

    // create questionnaire Api
    setLoader((l) => l + 1);
    return QuestionnaireService.create({
      questions: questionnaireObj,
      user_uid: authContext?.user?.uid,
    })
      .then((res) => {
        // update 'authContext.user'
        const newUserObj = { ...authContext.user, questionnaire_uid: res?.uid };
        const newAuthObj = { ...authContext, user: newUserObj };
        setAuthContext(newAuthObj);

        // create profile
        createProfile(newUserObj);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const createProfile = (newUserObj) => {
    // questionnaire create Api
    const profileObj = {
      user_uid: authContext?.user?.uid,
      role: authContext?.role,
      responsible_person_first_name: profile.responsible_person_first_name,
      responsible_person_last_name: profile.responsible_person_last_name,
      country: profile.country,
      state: profile.state,
      city: profile.city,
      zip_code: profile.zip_code,
      address_one: profile.address_one,
      address_two: profile.address_two,
      time_zone: profile.time_zone,
      prefered_language: profile.prefered_language,
      gender: profile.gender,
      race: JSON.stringify(profile.race),
      race_other: profile.race_other || "",
      veteran_status: profile.veteran_status,
      // preper other paramaters for profile
      document_file: profile.resume_upload,
      document_name: "resume_upload",
      application_status: "Incomplete",
    };

    setLoader((l) => l + 1);
    return ProfileService.create(profileObj)
      .then((res) => {
        // update 'authContext.user'
        const newUserObject = { ...newUserObj, profile_uid: res?.profile?.uid };
        const newAuthObj = { ...authContext, user: newUserObject };
        setAuthContext(newAuthObj);

        // update 'user' in 'localStorage'
        localStorage.setItem("user", JSON.stringify(newUserObject));

        // NOTE: redirection is not in useEffect
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  // set new question block
  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);

    // set current block
    const activeQuestionBlok = freelancer_questions?.question_bloks[blok];
    setCurrentQuestionsBlok(activeQuestionBlok);
  }, [blok]);

  // set 'profile.state'
  useEffect(() => {
    setProfile((n) => ({ ...n, state: "" }));
  }, [profile.country]);

  useEffect(() => {
    if (authContext?.user?.profile_uid) {
      navigate("/video-interview/onboarding");
    }
  }, [authContext, navigate]);

  useEffect(() => {
    if (blok !== "1") {
      setProfile(initialProfile);
      navigate("/questionnaire/1");
    }
  }, []);

  // useEffect(() => {
  //   console.log('currentQuestionsBlok:', currentQuestionsBlok);
  // }, [currentQuestionsBlok]);

  // useEffect(() => {
  //   console.log('profile:', profile);
  // }, [profile]);

  return (
    <div className="flex items-center flex-col p-page">
      {/* get resources */}
      <Resources
        countries={countries}
        setCountries={setCountries}
        alpha3Code={profile?.country}
        setStates={setStates}
        setLanguages={setLanguages}
        setTimezones={setTimezones}
      />

      <Stepper step="questionnaire" status={false} className="mb-8" itemClassName="px-2" />

      <Paper className="w-[700px]">
        {/* loader */}
        {!!loader && <Loader themeColor="fill-prime" />}

        <ProgressBar
          text=""
          className="mb-8"
          bgcolor="bg-sec"
          height={17}
          maxStep={12}
          step={Number(blok)}
        />

        {/* title and subtitle */}
        <Typography type="subtitle" className="text-left mb-8">
          {Number(blok) < 4
            ? freelancer_questions?.titles?.about_you
            : Number(blok) < 8
            ? freelancer_questions?.titles?.cyber_security
            : Number(blok) < 10
            ? freelancer_questions?.titles?.more_about_you
            : Number(blok) < 11
            ? freelancer_questions?.titles?.diversity_id
            : ""}
        </Typography>

        {/* diversity id text */}
        {Number(blok) === 10 && (
          <div className="text-prime-light mb-8">
            Our mission at Cyber Pop-up is to build and maintain an inclusive community of diverse
            cyber security freelancers to complete projects for businesses around the globe. In
            order to assess our progress and make actionable plans towards our diversity and
            inclusion goals, we would greatly appreciate if you would provide this information.
            Privacy is our first priority and we will not share your data with anyone. In order to
            assess our progress and make actionable plans towards our diversity and inclusion goals,
            we would greatly appreciate if you would provide this information. Privacy is our first
            priority and we will not share your data with anyone.
          </div>
        )}

        {/* set input element */}
        {currentQuestionsBlok?.map((question) => (
          <div key={question}>
            {/* project terms and conduct */}
            {Number(blok) === 11 && <FreelancerProjectTerms />}
            {Number(blok) === 12 && <FreelancerCodeOfConduct />}

            {/* set input element */}
            {setInputElement(question)}

            {/* set additional input element */}
            {question === "hear_about_us" && (
              <FreelancerQuestionnaireAdditionalQuestions
                blok={blok}
                profile={profile}
                setProfile={setProfile}
                setInputElement={setInputElement}
              />
            )}
          </div>
        ))}

        {/* active buttons */}
        <div className="flex justify-between mt-8">
          <Button
            dataTestId="back-btn"
            variant="flat"
            themecolor="prime"
            label="Back"
            onClick={() => navigate(-1)}
          />
          <Button
            dataTestId="next-btn"
            variant="full"
            themecolor="prime"
            label="Next"
            disabled={isNextDisabled()}
            onClick={() => nextClicked()}
          />
        </div>
      </Paper>
    </div>
  );
};

export default FreelancerQuestionnaire;
