import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

// resources

// components
import {
  Paper,
  Typography,
  Input,
  Select,
  Textarea,
  Button,
  ModalConfirm,
  Loader,
} from "../../../components";

// context
import { useToast } from "../../../context";
import Resources from "../../../resources/Resources";

// services
import { CompanyService, errorMsgForUser } from "../../../services";

// constants
import { customer_questions } from "../../questionnaire/customer_questions";

const CompanyProfile = ({ profile }) => {
  // context
  const { showToast } = useToast();

  // constants
  const industryOptions = customer_questions?.industry?.options;
  const employeesNumOptions = customer_questions?.employees_num?.options;

  // resources
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  // local
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loader, setLoader] = useState(0);
  // from profile
  const [profileData, setProfileData] = useState({
    company_uid: "",
    company_name: "",
    web_site: "",
    linkedin: "",
    address_one: "",
    address_two: "",
    city: "",
    zip_code: "",
    state: "",
    country: "USA",
    notes: "",
  });
  // from questionnaire
  const [industry, setIndustry] = useState("");
  const [employeesNum, setEmployeesNum] = useState("");

  // const editQuestionnaireVariables = () => {
  //   // edit questionnaire variables
  //   const questionnaireObj = {
  //     uid: questionnaire.uid,
  //     questions: {
  //       ...questionnaire.questions,
  //       industry: industry,
  //       employees_num: employeesNum,
  //     },
  //   };
  //   // questionnaire update Api
  //   setLoader((l) => l + 1);
  //   return QuestionnaireService.update(questionnaireObj)
  //     .then(() => {
  //       // console.log('QuestionnaireService.update res:', res);
  //
  //       // reset values
  //       getProfile();
  //       getQuestionnaire();
  //
  //       showToast("success", "Update successfully");
  //     })
  //     .catch((err) => showToast("error", errorMsgForUser(err)))
  //     .finally(() => {
  //       setLoader((l) => l - 1);
  //       setModalConfirm(false);
  //     });
  // };

  const editCompanyInfo = () => {
    // edit profile variables
    const profileObj = {
      uid: profileData?.company_uid || "",
      company_name: profileData?.company_name || "",
      web_site: profileData?.web_site || "",
      linkedin: profileData?.linkedin || "",
      address_one: profileData?.address_one || "",
      address_two: profileData?.address_two || "",
      city: profileData?.city || "",
      zip_code: profileData?.zip_code || "",
      state: profileData?.state || "",
      country: profileData?.country || "",
      notes: profileData?.notes || "",
      number_of_employees: employeesNumOptions || "",
      industry: industry || "",
    };
    // profile update Api
    setLoader((l) => l + 1);
    return CompanyService.updateCompany(profileObj)
      .then(() => {
        // edit profile variables
        // editQuestionnaireVariables();
        showToast("success", "Update successfully");
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => {
        setModalConfirm(false);
        setLoader((l) => l - 1);
      })

  };

  // set profile variables
  useEffect(() => {
    if (profile && !isEmpty(profile)) {
      setProfileData({
        company_uid: profile.company_uid || "",
        company_name: profile.company_name || "",
        web_site: profile.web_site || "",
        linkedin: profile.linkedin || "",
        address_one: profile.address_one || "",
        address_two: profile.address_two || "",
        city: profile.city || "",
        zip_code: profile.zip_code || "",
        state: profile.state || "",
        country: profile.country || "",
        notes: profile.notes || "",
      });
    }
  }, [profile]);

  // set questionnaire variables
  useEffect(() => {
    if (profile && !isEmpty(profile)) {
      setIndustry(profile?.industry || "");
      setEmployeesNum(profile?.number_of_employees|| "");
    }
  }, [profile]);

  return (
    <div>
      <Paper className="min-w-[400px] h-fit">
        {/* get resources */}
        <Resources
          countries={countries}
          setCountries={setCountries}
          alpha3Code={profileData?.country}
          setStates={setStates}
        />

        {/* loader */}
        {!!loader && <Loader themeColor="fill-prime" />}

        <Typography type="title" className="mb-4 text-left">
          Company Profile
        </Typography>

        {/* <p className="text-lg text-left text-prime-light">
          This is visible to clients. Tell them why they should hire you.
        </p> */}
        <div className="max-w-{400}">
          <Input
            dataTestId="company_name"
            type="text"
            label="Company name *"
            className="mb-6 text-left"
            value={profileData?.company_name}
            onChange={(e) => setProfileData((n) => ({ ...n, company_name: e.target.value }))}
          />
          <Input
            dataTestId="company_web_site"
            type="text"
            label="Company Website *"
            className="mb-6 text-left"
            value={profileData?.web_site}
            onChange={(e) => setProfileData((n) => ({ ...n, web_site: e.target.value }))}
          />
          <Input
            dataTestId="company_linkedin"
            type="text"
            label="Company Linkedin profile (optional)"
            className="mb-6 text-left"
            value={profileData?.linkedin}
            onChange={(e) => setProfileData((n) => ({ ...n, linkedin: e.target.value }))}
          />

          <div className="flex mb-4">
            <Input
              dataTestId="address"
              type="text"
              label="Address 1 *"
              className="flex-1 mr-4 text-left"
              value={profileData?.address_one}
              onChange={(e) =>
                setProfileData((n) => ({
                  ...n,
                  address_one: e.target.value,
                }))
              }
            />
            <Input
              dataTestId="address_two"
              type="text"
              label="Address 2 (Optional)"
              className="flex-1 text-left"
              value={profileData?.address_two}
              onChange={(e) =>
                setProfileData((n) => ({
                  ...n,
                  address_two: e.target.value,
                }))
              }
            />
          </div>

          <div className="flex mb-4">
            <Input
              dataTestId="city"
              type="text"
              label="City *"
              className="flex-1 mr-4 text-left"
              value={profileData?.city}
              onChange={(e) =>
                setProfileData((n) => ({
                  ...n,
                  city: e.target.value,
                }))
              }
            />
            <Input
              dataTestId="zip-code"
              type="text"
              label="Postal Code *"
              className="flex-1 text-left"
              value={profileData?.zip_code}
              onChange={(e) => setProfileData((n) => ({ ...n, zip_code: e.target.value }))}
            />
          </div>

          <div className="flex mb-4">
            <Select
              dataTestId="state"
              label="State *"
              className="flex-1 mr-4"
              options={states}
              value={profileData?.state}
              onChange={(e) =>
                setProfileData((n) => ({
                  ...n,
                  state: e.target.value,
                }))
              }
            />

            <Select
              dataTestId="country"
              label="Country *"
              className="flex-1"
              value={profileData?.country}
              options={countries}
              keyForOptionValue="alpha3Code"
              keyForOptionLabel="country"
              onChange={(e) =>
                setProfileData((n) => ({
                  ...n,
                  country: e.target.value,
                }))
              }
            />
          </div>

          <Select
            dataTestId="industry"
            label="Industry *"
            className="mb-6 text-left"
            options={industryOptions}
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          />

          <Select
            dataTestId="employees_number"
            label="Number of employees *"
            className="mb-6 text-left"
            options={employeesNumOptions}
            value={employeesNum}
            onChange={(e) => setEmployeesNum(e.target.value)}
          />

          <Textarea
            dataTestId="company_description"
            rows="2"
            label="Company description (optional)"
            placeholder="Describe your company, and let people get to know who you are and
            the type of work your company performs."
            value={profileData?.notes}
            onChange={(e) => setProfileData((n) => ({ ...n, notes: e.target.value }))}
          />
        </div>

        <Button
          dataTestId="update-company-info"
          label="Update"
          variant="full"
          themecolor="prime"
          className="block mx-auto mt-10"
          disabled={
            !profileData?.company_name ||
            !profileData?.address_one ||
            !profileData?.city ||
            !profileData?.zip_code ||
            !profileData?.country ||
            !(isEmpty(states) || profileData.state) ||
            !industry ||
            !employeesNum
          }
          onClick={() => setModalConfirm(true)}
        />
      </Paper>

      {/* modal confirm */}
      {modalConfirm && (
        <ModalConfirm
          title="Are you sure you want to change your company info?"
          closeModal={() => setModalConfirm(false)}
          onConfirm={editCompanyInfo}
        />
      )}
    </div>
  );
};

export default CompanyProfile;
