import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// components

// context
import { useToast } from "../../context";

// services
import { UserService, errorMsgForUser } from "../../services";
import { Button, Typography, Accordion, Textarea, Input, Modal, Loader } from "../index";

const ContactSupport = ({ setModalOpened }) => {
  // context
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(false);
  const [tab, setTab] = useState("Customer");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  let navigate = useNavigate();

  const sendClicked = () => {
    const sendEmailObj = {
      to_emails: "support@cyberpopup.com",
      from_email: email,
      subjects: email,
      message,
    };

    // send email Api
    setLoader(true);
    return UserService.sendEmail(sendEmailObj)
      .then(() => {
        // reset form
        setEmail("");
        setMessage("");
        showToast("success", "An message has been sent");
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  return (
    <Modal
      title="Contact and Support"
      maxWidth="max-w-3xl"
      closeModal={() => setModalOpened(false)}
    >
      {/* loader */}
      {loader && <Loader themeColor="fill-prime" />}

      {/* FAQ */}
      <Typography type="subtitle" className="mb-8 text-center">
        In case you need any help feel free to browse through our FAQ
      </Typography>

      {/* tab buttons */}
      <div className="flex mb-6">
        <Button
          dataTestId="customer-btn"
          label="Customer"
          variant={tab === "Customer" ? "full" : "outlined"}
          themecolor="sec"
          className="flex-1 border rounded-r-none border-sec"
          onClick={() => setTab("Customer")}
        />
        <Button
          dataTestId="cyber-expert-btn"
          label="Cyber-expert"
          variant={tab === "Cyber-expert" ? "full" : "outlined"}
          themecolor="sec"
          className="flex-1 border rounded-l-none border-sec"
          onClick={() => setTab("Cyber-expert")}
        />
      </div>

      {/* accoridon - customer */}
      {tab === "Customer" && (
        <div>
          <Accordion menuTitle="How do I get started with Cyber Pop-up?" className="mb-8 text-xl">
            <div className="text-prime-light">
              We are happy you would like to join us! First, press Sign-up to get a secure login to
              our platform. From there, you will be asked a short questionnaire to determine your
              cybersecurity risk level and we will recommend a subscription level to our service.
              Once you are a subscribing member, you will first complete a Peace of Mind Roadmap
              project which is a more in-depth look at your current cybersecurity practices. Once
              completed, you will be provided a customized roadmap and access to projects and
              Cyber-experts that will guide you through to success.
            </div>
          </Accordion>

          <Accordion
            menuTitle="My company is at high risk what should i do?"
            className="mb-8 text-xl"
          >
            <div className="text-prime-light">
              We got you! Once a member, you will be prompted to launch a Peace of Mind Roadmap
              project. During this project you will have a 60 minute information gathering call
              where you and a cyber-expert will review your business model, operating environment,
              and documentation. This project will result in the creation of a customized
              recommended prioritization roadmap. The goal is to provide you with step-by-step
              guidance to get you where you need to be.
            </div>
          </Accordion>

          <Accordion menuTitle="Will I work with a real human?" className="mb-8 text-xl">
            <div className="text-prime-light">
              YES! Once a member, you will be assigned a human Customer Success Manager for any
              questions or support needed. Also, for each project you embark on, you will be
              assigned a human Cyber-expert to assist you.
            </div>
          </Accordion>
          <Accordion
            menuTitle="Approximately how long will a project take?"
            className="mb-8 text-xl"
          >
            <div className="text-prime-light">
              Most projects can be completed in less than a week. Easy-peasy! We have strategically
              broken down cybersecurity measures into bite-sized chunks. You can pop on through your
              roadmap quickly with easy to understand and complete projects.
            </div>
          </Accordion>
          <Accordion menuTitle="I need help, who do I contact?" className="mb-8 text-xl">
            <div className="text-prime-light">
              Feel free to use the form below for any questions. If you are a member, feel free to
              contact your assigned Customer Success Manager.
            </div>
          </Accordion>
        </div>
      )}

      {/* accoridon -  cyber-expert */}
      {tab === "Cyber-expert" && (
        <div>
          <Accordion
            menuTitle="I have completed the application process, now what?"
            className="mb-8 text-xl"
          >
            <div className="text-prime-light">
              Upon completion of your application and submission of your video, we will have our
              Cyber-expert Success Manager review your application to identify your best fit in our
              platform. We should have your application completely reviewed in 7-10 business days
              and will reach out with your status in next steps!
            </div>
          </Accordion>
          <Accordion menuTitle="How do I get started with Cyber Pop-up?" className="mb-8 text-xl">
            <div className="text-prime-light">
              We are happy you would like to join our platform as a Cyber-expert! First, we require
              all prospective Cyber-experts to complete our application to learn more about who you
              are and the great skills you offer. Select the Cyber-expert option and get started here!
              <button
                type="button"
                className="ml-2 text-blue-500"
                onClick={() => {
                  navigate("/signup");
                  setModalOpened(false);
                }}
              >
                Signup
              </button>
              .
            </div>
          </Accordion>
          <Accordion
            menuTitle="How will I be notified when a project is available for me?"
            className="mb-8 text-xl"
          >
            <div className="text-prime-light">
              When a project becomes available that we think that you would be a great fit for, we
              will notify you with the project details in our platform, which will trigger an email
              with the same message to be sent to you as well. With this information, you can choose
              if you would like to take on this project or not.
            </div>
          </Accordion>
          <Accordion
            menuTitle="What will I be able to do while waiting on projects? How long can I expect to wait?"
            className="mb-8 text-xl"
          >
            <div className="text-prime-light">
              We have a Knowledgebase that offers resources on general cybersecurity topics, cyber
              certification information, and freelancing with Cyber Pop-up for you to read up on in
              the meantime.
            </div>
          </Accordion>
          <Accordion
            menuTitle="What if I am running into an issue with a customer and need to contact Cyber Pop-up to remediate this?"
            className="mb-8 text-xl"
          >
            <div className="text-prime-light">
              Every Cyber-expert is assigned a Cyber-expert Success Manager that they will be able to
              get in contact with in the event that an issue arises while completing a project.
            </div>
          </Accordion>
        </div>
      )}

      {/* contact: images and form */}
      <div className="pt-8">
        <Typography type="subtitle" className="mb-8 text-center">
          Our support center
        </Typography>

        <Input
          data-testid="email"
          label="Your Email"
          type="text"
          className="mb-4"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Textarea
          dataTestId="contact-support"
          label="Message"
          className="mb-6"
          rows="4"
          placeholder="We'll try to respond as soon as possible."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <Button
          dataTestId="send-support-ticet"
          label="Send"
          variant="full"
          themecolor="prime"
          className="block mx-auto mb-2"
          disabled={!email || !message}
          onClick={sendClicked}
        />
      </div>
    </Modal>
  );
};

export default ContactSupport;
