import React, { useState, useEffect, useCallback } from "react";
import { isEmpty } from "lodash";

// resources

// components
import {
  Input,
  InputPhone,
  Select,
  Button,
  ModalConfirm,
  Loader,
  Modal,
} from "../../../components";

// context
import { useToast, useAuth } from "../../../context";
import Resources from "../../../resources/Resources";

// services
import { ProfileService, UserService, errorMsgForUser } from "../../../services";
import ModalNewPwd from "./ModalNewPwd";

const AccountInfo = ({ profile = {}, profileEditsLocked }) => {
  // context
  const { showToast } = useToast();
  const { authContext } = useAuth();

  // resources
  const [timezones, setTimezones] = useState([]);
  const [languages, setLanguages] = useState([]);

  // local
  const [loader, setLoader] = useState(0);
  const [modalPwdOpened, setModalPwdOpened] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [initialPhone, setInitialPhone] = useState(""); // for edit check
  const [phone, setPhone] = useState(""); // for input
  const [code, setCode] = useState("");
  const [modalVerifyUpdatePhone, setModalVerifyUpdatePhone] = useState(false);
  const [profileData, setProfileData] = useState({
    responsible_person_first_name: "",
    responsible_person_last_name: "",
    time_zone: "",
    prefered_language: "English",
  });

  // get user data - get phone
  const getUserData = useCallback(
    () =>
      // get user Api
      UserService.get({ user_uid: authContext?.user?.uid })
        .then((res) => {
          // console.log('UserService.get res:', res);
          setPhone(res?.user?.phone || "");
          setInitialPhone(res?.user?.phone || "");
        })
        .catch((err) => showToast("error", errorMsgForUser(err))),
    [authContext?.user?.uid, showToast]
  );

  const editAccountInfo = () => {
    const profileObj = {
      user_uid: profile?.user_uid,
      responsible_person_first_name: profileData.responsible_person_first_name,
      responsible_person_last_name: profileData.responsible_person_last_name,
      time_zone: profileData.time_zone,
      prefered_language: profileData.prefered_language,
      phone, // phone update requires code confirmation
    };

    // profile update Api
    setLoader((l) => l + 1);
    return ProfileService.update(profileObj)
      .then(() => {
        if (initialPhone === phone) {
          // console.log('ProfileService.update res:', res);
          showToast("success", "Update successfully");
        } else {
          // phone update requires code confirmation
          updatePhone();
        }
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => {
        setLoader((l) => l - 1);
        setModalConfirm(false);
      });
  };

  // phone update requires code confirmation
  const updatePhone = () => {
    // user update Api
    setLoader((l) => l + 1);
    return UserService.updatePhone({
      uid: authContext?.user?.uid,
      phone: `+${phone}`,
    })
      .then(() => {
        setModalVerifyUpdatePhone(true);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const verifyUpdatePhone = () => {
    // user verify updated phone  Api
    setLoader((l) => l + 1);
    return UserService.verifyUpdatePhone({
      uid: authContext?.user?.uid,
      phone_validation_code: code,
    })
      .then(() => {
        // console.log('UserService.verifyUpdatePhone res:', res);
        setModalVerifyUpdatePhone(false);
        showToast("success", "Update successfully");
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  useEffect(() => {
    if (authContext?.user?.uid) {
      getUserData();
    }
  }, [authContext?.user?.uid, getUserData]);

  // set first and last name
  useEffect(() => {
    if (profile && !isEmpty(profile)) {
      setProfileData({
        responsible_person_first_name: profile?.responsible_person_first_name,
        responsible_person_last_name: profile?.responsible_person_last_name,
        time_zone: profile?.time_zone,
        prefered_language: profile?.prefered_language || "English",
      });
    }
  }, [profile]);

  return (
    <div className="relative">
      {/* get resources */}
      <Resources setTimezones={setTimezones} languages={languages} setLanguages={setLanguages} />

      {/* loader */}
      {!!loader && <Loader themeColor="fill-prime" />}

      <div>
        <div className="p-1 mb-4">
          <div className="flex justify-between mb-6">
            <Input
              className="w-3/6 mr-4 text-left"
              dataTestId="first-name"
              type="text"
              label="First Name *"
              required={true}
              value={profileData?.responsible_person_first_name}
              onChange={(e) =>
                setProfileData((n) => ({
                  ...n,
                  responsible_person_first_name: e.target.value,
                }))
              }
            />

            <Input
              className="w-3/6 text-left"
              dataTestId="last-name"
              type="text"
              label="Last Name *"
              value={profileData?.responsible_person_last_name}
              onChange={(e) =>
                setProfileData((n) => ({
                  ...n,
                  responsible_person_last_name: e.target.value,
                }))
              }
            />
          </div>

          <InputPhone
            dataTestId="phone"
            className="w-full mb-6 mr-2 text-left"
            label="Phone *"
            value={phone || ""}
            onChange={(v) => setPhone(v)}
          />

          <Select
            dataTestId="time-zone"
            label="What timezone are you in? *"
            className="mb-6"
            keyForOptionValue="value"
            keyForOptionLabel="text"
            options={timezones}
            value={profileData?.time_zone}
            onChange={(e) =>
              setProfileData((n) => ({
                ...n,
                time_zone: e.target.value,
              }))
            }
          />

          <Select
            dataTestId="language"
            label="Preferred Language? *"
            className="mb-8"
            options={languages}
            value={profileData?.prefered_language}
            onChange={(e) =>
              setProfileData((n) => ({
                ...n,
                prefered_language: e.target.value,
              }))
            }
          />
        </div>

        <Button
          dataTestId="save-info-button"
          label="Update"
          variant="full"
          themecolor="prime"
          className="block mx-auto mb-10"
          disabled={
            !profileData?.responsible_person_first_name ||
            !profileData?.responsible_person_last_name ||
            !phone ||
            !profileData?.time_zone ||
            !profileData?.prefered_language ||
            profileEditsLocked
          }
          onClick={() => setModalConfirm(true)}
        />

        <Button
          dataTestId="change-pwd-button"
          label="Change password"
          variant="flat"
          themecolor="prime"
          className="block mx-auto mb-6"
          onClick={() => setModalPwdOpened(true)}
        />

        {/* modal change password */}
        {modalPwdOpened && (
          <ModalNewPwd
            dataTestId="modal-change-password"
            title="Change password"
            closeModal={() => setModalPwdOpened(false)}
          />
        )}

        {/* modal confirm */}
        {modalConfirm && (
          <ModalConfirm
            dataTestId="modal-confirm-account-info"
            title="Are you sure you want to change your account info?"
            confirmBtnText="Yes"
            closeModal={() => setModalConfirm(false)}
            onConfirm={editAccountInfo}
          />
        )}

        {/* modal verify change phone */}
        {modalVerifyUpdatePhone && (
          <Modal
            title="Enter authorization code from SMS"
            closeModal={() => setModalVerifyUpdatePhone(false)}
            buttons={[
              <Button
                dataTestId="close-modal-verify-update-phone"
                label="Close"
                variant="flat"
                themecolor="prime"
                onClick={() => setModalVerifyUpdatePhone(false)}
              />,
              <Button
                dataTestId="submite-code-btn"
                label="Submit"
                variant="full"
                themecolor="prime"
                onClick={verifyUpdatePhone}
              />,
            ]}
          >
            <div>
              <Input
                dataTestId="enter-code-from-phone"
                type="number"
                label="Enter the code *"
                className="mb-8"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && verifyUpdatePhone()}
              />
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default AccountInfo;
