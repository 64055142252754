import { useState } from "react";
import { Loader, Paper, Typography } from "../../../components";
import { useAuth, useToast } from "../../../context";
import { StripeService } from "../../../services";
import OutOfUsa from "./OutOfUsa";
import SetupStripe from "./SetupStripe";

const PaymentDetails = ({ profile, setTab, stripeVerified }) => {
  const { authContext } = useAuth();
  const { showToast } = useToast();

  // * UI States
  const [loader, setLoader] = useState(false);

  // Returns a Stripe login link for the user
  const createStripeLoginLink = async () => {
    setLoader(true);
    try {
      const { stripe_login_link } = await StripeService.createLoginLink({
        user_uid: authContext?.user.uid,
      });
      window.open(stripe_login_link, "_blank");
    } catch (error) {
      showToast("error", "Uh oh! Apologies, but that didn't work. Please try again");
    } finally {
      setLoader(false);
    }
  };

  // * This component uses guard clauses to determine the return value
  // User cannot setup Stripe if they are not in the USA
  if (profile?.country !== "USA") {
    return <OutOfUsa />;
  }

  // User cannot add payment details until they are approved
  if (
    profile?.application_status === "Incomplete" ||
    profile?.application_status === "Pending_review"
  ) {
    return (
      <Paper className="flex flex-col h-1/2">
        <Typography type="title" className="mb-4">
          Payments
        </Typography>
        <p className="text-lg mb-4">
          Once your application is approved you will be able to add your payment details via Stripe.
        </p>
        <div className="relative flex flex-grow justify-center items-center">
          <button
            type="button"
            className="inline-flex w-5/6 justify-center rounded-md bg-prime px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-prime-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
            onClick={() => setTab("Cyber-expert status")}
          >
            Check profile status
          </button>
        </div>
      </Paper>
    );
  }

  // If the are approved and haven't setup Stripe - show setup stripe component
  if (stripeVerified === false) {
    return <SetupStripe profile={profile} />;

    // If they are verified and setup Stripe show them them the stripe login button
  } else {
    return (
      <Paper>
        <Typography type="title" className="mb-4">
          Payments
        </Typography>
        <p className="text-lg mb-4">Your Cyber-expert account is properly connected to Stripe.</p>
        <button
          type="button"
          className="inline-flex w-5/6 justify-center rounded-md bg-prime px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-prime-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
          onClick={createStripeLoginLink}
          disabled={loader}
        >
          {loader ? <Loader className="relative w-7 h-7" /> : "View payments in Stripe"}
        </button>
      </Paper>
    );
  }
};

export default PaymentDetails;
