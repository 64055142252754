import React from "react";

const FreelancerQuestionnaireAdditionalQuestions = ({
  blok,
  profile,
  // setProfile,
  setInputElement,
}) => (
  <div className="mb-6">
    {/* is hear_about_us  */}
    {blok === "3" && profile.hear_about_us === "Other" && (
      <div>{setInputElement("hear_about_us_where")}</div>
    )}

    {/* is experience_in_industry  */}
    {blok === "5" &&
      profile.experience_in_industry &&
      (profile.experience_in_industry === "Other" ? (
        <div>{setInputElement("experience_in_industry_other")}</div>
      ) : (
        <div>{setInputElement("experience_in_industry_level")}</div>
      ))}

    {/* is experience_in_area  */}
    {blok === "6" &&
      profile.experience_in_area &&
      (profile.experience_in_area === "Other" ? (
        <div>{setInputElement("experience_in_area_other")}</div>
      ) : (
        <div>{setInputElement("experience_in_area_level")}</div>
      ))}
  </div>
);

export default FreelancerQuestionnaireAdditionalQuestions;
