import React from "react";
import { Card } from "../../../components";
import { useAuth } from "../../../context";

// components
import AccountInfo from "./AccountInfo";
// import Location from './Location';

const AccountCustomer = ({ profile }) => {
  const { authContext } = useAuth();
  return (
    <div>
      <Card
        title="Account Info"
        subtitle={authContext?.user.email}
        subtitleClass="text-md text-terc font-medium"
        customClass="mb-8"
      >
        <AccountInfo profile={profile} />
      </Card>

      {/* <Card title="Location" customClass="mb-8">
        <Location profile={profile} getProfile={getProfile} />
      </Card> */}
    </div>
  );
};

export default AccountCustomer;
