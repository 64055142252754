import React from "react";

// components
import GoogleLogo from "../../assets/img/logo/logo-google.png";
import { Paper, Typography, Button, Tooltip, Dropdown } from "../../components";

const Buttons = () => (
  <div className="flex justify-center p-page">
    <Paper className="min-w-[400px] h-fit">
      <Typography type="title" className="mb-8 text-center">
        Buttons
      </Typography>

      <div className="flex flex-col">
        <Button
          dataTestId="test-id"
          label="Full Button with Img"
          icon={GoogleLogo}
          variant="full"
          themecolor="prime"
          className="block mx-auto mb-4"
          disabled={false}
          onClick={() => console.log("Clicked")}
        />

        <Button
          dataTestId="test-id"
          label="Full Button with Icon"
          icon={<i className="mr-4 fa-solid fa-user-large" />}
          variant="full"
          themecolor="prime"
          className="block mx-auto mb-4"
          disabled={false}
          onClick={() => console.log("Clicked")}
        />

        <Button
          dataTestId="test-id"
          label="Full Button"
          variant="full"
          themecolor="prime"
          className="block mx-auto mb-4"
          disabled={false}
          onClick={() => console.log("Clicked")}
        />

        <Button
          dataTestId="test-id"
          label="Outlined Button"
          variant="outlined"
          themecolor="prime-light"
          className="block mx-auto mb-4"
          disabled={false}
          onClick={() => console.log("Clicked")}
        />

        <Button
          dataTestId="test-id"
          label="Text Button"
          variant="flat"
          themecolor="prime"
          className="block mx-auto mb-4"
          disabled={false}
          onClick={() => console.log("Clicked")}
        />

        <Button
          dataTestId="test-id"
          label={<i className="fas fa-user-alt" />}
          variant="icon-full"
          themecolor="prime"
          width="w-10"
          height="h-10"
          textSize="text-xl"
          className="block mx-auto mb-4"
          disabled={false}
          onClick={() => console.log("Clicked")}
        />

        <Button
          dataTestId="test-id"
          label={<i className="fas fa-user-alt" />}
          variant="icon-outlined"
          themecolor="prime-light"
          width="w-10"
          height="h-10"
          textSize="text-xl"
          className="block mx-auto mb-4"
          disabled={false}
          onClick={() => console.log("Clicked")}
        />

        <Button
          dataTestId="test-id"
          label={<i className="fas fa-user-alt" />}
          variant="icon-flat"
          themecolor="prime"
          width="w-10"
          height="h-10"
          textSize="text-xl"
          className="block mx-auto mt-2 mb-4"
          disabled={false}
          onClick={() => console.log("Clicked")}
        />

        <Tooltip text="Welcome" placement="bottom">
          <Button
            dataTestId="test-id"
            label="Button Tooltip"
            variant="full"
            themecolor="prime"
            className="block mx-auto mb-4"
            disabled={false}
            onClick={() => console.log("Clicked")}
          />
        </Tooltip>

        <Dropdown
          className="mx-auto mb-4"
          contentClassName=""
          placement="bottom-start"
          options={[
            {
              label: "Male",
              value: "male",
              action: () => console.log("Male"),
            },
            {
              label: "Female",
              value: "female",
              action: () => console.log("Female"),
            },
            {
              label: "Other",
              value: "other",
              action: () => console.log("Other"),
            },
          ]}
          button={
            <Button
              dataTestId="test-id"
              label="Dropdown Button"
              variant="full"
              themecolor="prime"
              className="block mx-auto"
              disabled={false}
            />
          }
        />
      </div>
    </Paper>
  </div>
);

export default Buttons;
