// components
import { Textarea, Tags } from "../../../components";

const AboutYou = ({ aboutMe, socialMedia, handleChangeValue }) => (
  <div>
    <Textarea
      dataTestId="about_me"
      label="This is visible to clients. Tell them why they should hire you. *"
      rows="2"
      className="mb-2"
      borderClass={!aboutMe ? "border-warning" : ""}
      value={aboutMe}
      onChange={(e) => handleChangeValue({ changeKey: "about_me", changeValue: e.target.value })}
    />

    <Tags
      id="social-media-links"
      dataTestId="social_media_links"
      tagsAsList={true}
      className="flex-1"
      label="Link to your LinkedIn profile, Github or personal website: (optional)"
      tags={socialMedia}
      setTags={(v) => handleChangeValue({ changeKey: "about_me", changeValue: v })}
    />
  </div>
);
export default AboutYou;
