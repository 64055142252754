import { evalQuery } from "./index";

export const ProgramService = {
    create: (request) =>
        evalQuery({
            url: "/program/create",
            request,
        }),
    getProgramByUserEmail: (request) =>
        evalQuery({
            url: "/program/get-by-user-email",
            request,
        }),
    getAll: (request) =>
        evalQuery({
            url: "/program/get-all",
            request,
        }),
}