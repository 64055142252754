import { evalQuery } from "./index";

export const DiscountService = {
    getAll: (request) =>
        evalQuery({
            url: "/discount/get-all",
            request,
        }),
    getCompanyDiscounts: (request) =>
        evalQuery({
            url: "/discount/get-company-discounts",
            request,
        }),

    getUserDiscounts: (request) =>
        evalQuery({
            url: "/discount/get-discount-by-user-uid",
            request,
        }),
}

