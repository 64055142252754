import React, { useState, useEffect, useCallback, useRef } from "react";
import { Loader, Discussion } from "../../components";
import { useAuth, useToast } from "../../context";
import { ChatService, errorMsgForUser } from "../../services";

// components

const TaskDiscussion = ({
  taskUid,
  csmUid,
  customerUid,
  freelancerUid,
  taskList,
  currentProject,
  taskName,
  fromName,
  taskStatus,
  changeMessageReadStateUi,
}) => {
  const [messages, setMessages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [commentsRemaining, setCommentsRemaining] = useState(0);
  const [commentsAllowed, setCommentsAllowed] = useState(0);

  const goToBottom = useRef(null);

  // context
  const { authContext } = useAuth();
  const { showToast } = useToast();

  const getMessages = useCallback(() => {
    ChatService.getAllMessages({
      auth_user_uid: authContext.user.uid,
      task_uid: taskUid,
    })
      .then((res) => {
        setMessages(res.messages);
        setCommentsRemaining(res.comments_left);
        setCommentsAllowed(res.comments_allowed);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)));
  }, [authContext?.user?.uid, taskUid, showToast]);

  const addNewMsg = (value) => {
    setLoader(true);
    ChatService.postComment({
      user_uid: authContext.user.uid,
      company_uid: customerUid,
      task_uid: taskUid,
      project_uid: currentProject.uid,
      message: value,
    })
      .then(() => {
        getMessages();
        setTimeout(() => {
          goToBottom?.current?.scrollIntoView();
        }, 400);
        showToast("success", "Comment added! A team member will respond shortly.");
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  useEffect(() => {
    const updateReadStatus = async () => {
      // check if the user uid is in the arry
      // if not add the uid and update
      messages.forEach(async (message) => {
        let readBy = message.read_by ? JSON.parse(message.read_by) : [];
        if (!readBy.includes(authContext.user.uid)) {
          readBy.push(authContext.user.uid);
          // console.log("ORGINAL message", message);
          const updateDict = {
            message_values: {
              uid: message.uid,
              read_by: JSON.stringify(readBy),
            },
          };
          await ChatService.updateMessage(updateDict);
        }
      });
      changeMessageReadStateUi(taskUid);
    };

    updateReadStatus();
  }, []);

  return (
    <div>
      {/* loader */}
      {!!loader && <Loader themeColor="fill-prime" />}
      <Discussion
        addNewMsg={addNewMsg}
        messages={messages}
        goToBottom={goToBottom}
        commentsRemaining={commentsRemaining}
        commentsAllowed={commentsAllowed}
        taskStatus={taskStatus}
      />
      {/* <ChatConversations
        className=""
        maxHeightClass="max-h-96"
        messagesData={messagesDemo || []}
        addNewMsg={addNewMsg}
      /> */}
    </div>
  );
};

export default TaskDiscussion;
