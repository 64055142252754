import { evalQuery } from "./index";

export const QuestionnaireService = {
  create: (request) =>
    evalQuery({
      url: "/questionnaire/create",
      request,
    }),

  get: (request) =>
    evalQuery({
      url: "/questionnaire/get",
      request,
    }),

  getByUserId: (request) =>
    evalQuery({
      url: "/questionnaire/get-questionnaire-by-user-id",
      request,
    }),

  update: (request) =>
    evalQuery({
      url: "/questionnaire/update",
      request,
    }),
  delete: (request) =>
    evalQuery({
      url: "/questionnaire/delete",
      request,
    }),
};
