import React, { forwardRef, useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Typography, Divider, Checkbox, Input, Button, Loader } from "../components";
import { useAuth, useToast } from "../context";
import { DocumentService, errorMsgForUser } from "../services";

const Documents = forwardRef(
  (
    {
      dataTestId,

      placeholder = "",
      error = "",
      accept = "",
      // value = '',
      onChange,
      className = "mb-4",
      inputClassName = "",
      inputHolder,
      getDocumentsIds,
      entityName,
      entityUid,
      titleColor,
      titleSize,
      documentWidget = true,
      showDivider = false,
      copyToNextTaskCheckbox = false,
      ...rest
    },
    ref
  ) => {
    // context
    const { showToast } = useToast();

    const [loader, setLoader] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [localDocuments, setLocalDocuments] = useState([]);
    const [documentsUid, setDocumentsUid] = useState([]);
    const [resDocuments, setResDocuments] = useState([]);
    const [allDocuments, setAllDocuments] = useState([]);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [copyToNextTask, setCopyToNextTask] = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);
    const [linkAttachment, setLinkAttachment] = useState("");
    const [linkAttachmentInput, setLinkAttachmentInput] = useState(false);

    // context
    const { authContext } = useAuth();

    let userId = authContext?.user?.uid;

    const b64toBlob = (b64Data, contentType = "application/pdf", sliceSize = 512) => {
      let splicedBlob = b64Data.slice(28);
      const byteCharacters = atob(splicedBlob);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });

      let objectURL = URL.createObjectURL(blob);

      return objectURL;
    };

    const handleUploadFiles = (files) => {
      const uploaded = [...uploadedFiles];
      files.map((file) => {
        if (file.size > 10485760) {
          return showToast(
            "error",
            "File size exceeds the maximum limit. Maximum upload size is 10MB."
          );
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          onChange(reader.result);

          uploadDocument(entityUid, userId, file.name, reader.result);
        };

        uploaded.push(file);
        return setUploadedFiles(uploaded);
      });
    };

    const handleFileEvent = (e) => {
      const chosenFiles = Array.prototype.slice.call(e.target.files);
      handleUploadFiles(chosenFiles);
    };

    // config for upload documents progress bar
    const config = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setProgressPercent(percent);
      },
    };

    const uploadDocument = (entity_uid, uploaded_by_uid, name, file) => {
      setShowProgressBar(true);
      setLoader(true);

      DocumentService.create({ entity_uid, uploaded_by_uid, name, file }, config)
        .then((res) => {
          setShowProgressBar(false);
          const documentsUploaded = [...localDocuments];
          const resDocumentsUid = [...documentsUid];
          documentsUploaded.push(res.document);
          resDocumentsUid.push(res.document.uid);
          setLocalDocuments(documentsUploaded);
          setDocumentsUid(resDocumentsUid);
          showToast("success", "Document successfully uploaded.");
          // getDocumentsIds(documentsUid);
          if (entityUid) {
            getDocument(entityName, entityUid);
          }
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader(false));
    };

    const getDocument = useCallback(
      (entity_name, entity_uid) => {
        setLoader(true);
        DocumentService.get({ entity_uid, entity_name })
          .then((res) => {
            setResDocuments(res.documents);
          })
          .catch((err) => showToast("error", errorMsgForUser(err)))
          .finally(() => setLoader(false));
      },
      [showToast]
    );

    const deleteDocument = (document_uid, entity_uid) => {
      setLoader(true);

      if (entity_uid) {
        DocumentService.delete({ document_uid })
          .then(() => {
            getDocument(entityName, entityUid);
            showToast("success", "Document successfully deleted.");
          })
          .catch((err) => showToast("error", errorMsgForUser(err)))
          .finally(() => setLoader(false));
      } else {
        let localDocumentsRemoved = localDocuments.filter((obj) => obj.uid !== document_uid);
        setLocalDocuments(localDocumentsRemoved);

        let localUidRemoved = documentsUid.filter((uid) => uid !== document_uid);
        setDocumentsUid(localUidRemoved);
      }
    };

    const attachLinkHandle = () => {
      setLinkAttachmentInput(true);

      if (linkAttachment !== "") {
        DocumentService.create(
          {
            entity_uid: entityUid,
            uploaded_by_uid: userId,
            name: linkAttachment,
            file: linkAttachment,
          },
          config
        )
          .then((res) => {
            const documentsUploaded = [...localDocuments];
            const resDocumentsUid = [...documentsUid];
            documentsUploaded.push(res.document);
            resDocumentsUid.push(res.document.uid);
            setLocalDocuments(documentsUploaded);
            setDocumentsUid(resDocumentsUid);
            showToast("success", "Link successfully added.");
            // console.log('RES', res);
            setLinkAttachment("");
            if (entityUid) {
              getDocument(entityName, entityUid);
            }
          })
          .catch((err) => showToast("error", errorMsgForUser(err)))
          .finally(() => setLoader(false));
      }
    };

    useEffect(() => {
      entityUid
        ? setAllDocuments([...resDocuments])
        : setAllDocuments([...localDocuments, ...resDocuments]);
    }, [entityUid, localDocuments, resDocuments]);

    useEffect(() => {
      if (entityUid) {
        getDocument(entityName, entityUid);
      }
    }, [getDocument, entityUid, entityName]);

    useEffect(() => {
      if (getDocumentsIds) {
        getDocumentsIds(documentsUid);
      }
    }, [documentsUid, getDocumentsIds]);

    return (
      <>
        <div className={className}>
          {(allDocuments.length > 0 || documentWidget) && (
            <Typography
              className={` ${titleSize} ${titleColor ? titleColor : "text-prime-light-dark"} mb-2`}
              type="subtitle"
            >
              Documents
            </Typography>
          )}

          {loader && <Loader themeColor="fill-prime" />}

          {allDocuments.length > 0 && (
            <div className={` ${copyToNextTaskCheckbox ? "pr-5" : "pr-0"} mb-5`}>
              <div className="flex mb-2">
                <p className="w-1/2" />
                {documentWidget && (
                  <>
                    <p className="w-1/2 pl-2 text-prime-light"> Date </p>
                    <p className="w-1/2 text-prime-light"> Uploaded By </p>
                    {copyToNextTaskCheckbox && (
                      <>
                        <p className="w-1/4 text-prime-light"> Copy to next Task? </p>
                      </>
                    )}
                  </>
                )}
              </div>
              {allDocuments.map((document, index) => (
                <div className="flex mb-2" key={index}>
                  {document.file.includes("data:") ? (
                    <a
                      style={{ wordBreak: "break-word" }}
                      className={` ${
                        !documentWidget ? "w-4/5" : "w-1/2"
                      } text-prime underline underline-offset-1 cursor-pointer`}
                      href={b64toBlob(document.file)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {document.name}
                    </a>
                  ) : (
                    <div className="w-1/2 break-words" style={{ wordBreak: "break-word" }}>
                      <a
                        className="underline cursor-pointer text-prime underline-offset-1"
                        href={document.file}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {document.file}
                      </a>
                    </div>
                  )}
                  {documentWidget && (
                    <>
                      <p className="w-1/2 pl-2 text-prime-light">
                        {moment(document.created_at).format("MMM DD, YYYY")}
                      </p>
                      <p
                        className={` ${
                          copyToNextTaskCheckbox ? "w-1/2" : "w-1/4"
                        } text-prime-light`}
                      >
                        {" "}
                        {document.uploaded_by}{" "}
                      </p>
                      {copyToNextTaskCheckbox && (
                        <div className="w-1/4">
                          <Checkbox
                            dataTestId="copy-to-next-task"
                            labelClassName="text-sec"
                            value="cookiePolicy"
                            checked={copyToNextTask}
                            onChange={(e) => setCopyToNextTask(e.target.checked)}
                          />
                        </div>
                      )}
                      {(authContext?.role === "Csm" ||
                        document.uploaded_by_uid === authContext?.user?.uid) && (
                        <div className={`${copyToNextTaskCheckbox ? "w-0" : "w-1/4"}`}>
                          <i
                            className="cursor-pointer fa-solid fa-trash text-prime-light"
                            onClick={() => deleteDocument(document.uid, document.entity_uid)}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

          {linkAttachmentInput && (
            <div className="flex items-center mb-5">
              <Input
                className="w-3/6 mr-4 text-left"
                dataTestId="document-link"
                type="text"
                placeholder="Attach Link"
                // label="First Name *"
                required={true}
                value={linkAttachment}
                onChange={(e) => setLinkAttachment(e.target.value)}
              />
              <i
                className="text-xl cursor-pointer fa-sharp fa-solid fa-circle-xmark text-prime"
                onClick={() => setLinkAttachmentInput(false)}
              />
            </div>
          )}
          {documentWidget && (
            <div className={`flex items-center mb-5 ${inputHolder}`}>
              <input
                data-testid={dataTestId}
                ref={ref}
                id="attachment"
                type="file"
                placeholder={placeholder}
                // value={value}
                className={`hidden w-full border-gray-300 rounded-md shadow-sm bg-gray-50 focus:border focus:border-prime focus:outline-none ${inputClassName}`}
                accept={accept}
                multiple={false}
                onChange={handleFileEvent}
                disabled={showProgressBar}
                {...rest}
              />
              <label
                className={`text-center mr-4 hover:ring-1 focus:ring-2 font-medium rounded-lg px-5 py-2.5 w-fit h-fit bg-transparent text-prime text-sm border-prime border ${
                  showProgressBar
                    ? "cursor-not-allowed border-grey text-prime-light"
                    : "cursor-pointer"
                }`}
                htmlFor="attachment"
              >
                Attach Documents
              </label>

              <Button
                dataTestId="attach-link"
                label="Attach Links"
                variant="outlined"
                themecolor="text-prime"
                className="block"
                disabled={false}
                onClick={attachLinkHandle}
              />
            </div>
          )}

          {error && <div className="mb-2 text-sm text-error">{error}</div>}
        </div>

        {showProgressBar && (
          <div className="relative">
            <p className="absolute w-full text-center text-prime-light-light">
              {" "}
              Uploading... {progressPercent}%{" "}
            </p>
            <div
              style={{ width: `${progressPercent}%` }}
              className="flex items-center justify-center h-6 p-3 rounded-lg bg-prime-hover"
            />
          </div>
        )}

        {showDivider && (allDocuments.length > 0 || documentWidget) && (
          <Divider className="my-6" lineColor="bg-grey-light" />
        )}
      </>
    );
  }
);

export default Documents;
