import * as React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string;
  dataTestId?: string;
  variant?: string;
  themecolor?: string;
  width?: string;
  height?: string;
  label?: string;
  hover?: string;
  bgColor?: string;
  textColor?: string;
  fontWeight?: string;
  borderColor?: string;
  icon?: string | JSX.Element;
  textSize?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      dataTestId,
      variant = "full", // outlined, flat
      themecolor = "prime", // sec, terc, gold, success, error
      width = "w-fit",
      height = "h-fit",
      label,
      hover = "hover:ring-1",
      bgColor = "",
      textColor = "",
      fontWeight = "font-medium",
      borderColor,
      icon,
      textSize = "text-sm",
      children,
      disabled = false,
      onClick,
      className = "",
      ...rest
    },
    ref
  ) => {
    let additional = "";
    let rounded = "rounded-lg";

    const setBgColor = () => {
      switch (themecolor) {
        case "prime":
          return "bg-prime";
        case "prime-light":
          return "bg-prime-light";
        case "sec":
          return "bg-sec";
        case "sec-light":
          return "bg-sec-light";
        case "terc":
          return "bg-terc";
        case "success":
          return "bg-success";
        case "gold":
          return "bg-gold";
        case "error":
          return "bg-error";
        case "warning":
          return "bg-warning";
        case "white":
          return "bg-white";
        case "grey":
          return "bg-grey";
        case "cyberPink":
          return "d43890";
        default:
          return "bg-prime";
      }
    };

    const setTextColor = () => {
      switch (themecolor) {
        case "prime":
          return "text-prime";
        case "prime-light":
          return "text-prime-light";
        case "sec":
          return "text-sec";
        case "terc":
          return "text-terc";
        case "success":
          return "text-success";
        case "gold":
          return "text-gold";
        case "error":
          return "text-error";
        case "warning":
          return "text-warning";
        case "grey":
          return "text-prime-light";
        case "grey-dark":
          return "text-prime-light-dark";
        case "grey-light":
          return "text-prime-light-light";
        case "white":
          return "text-white";
        default:
          return "text-prime";
      }
    };

    const setBorderColor = () => {
      switch (themecolor) {
        case "prime":
          return "border-prime";
        case "prime-light":
          return "border-prime-light";
        case "sec":
          return "border-sec";
        case "terc":
          return "border-terc";
        case "success":
          return "border-success";
        case "gold":
          return "border-gold";
        case "error":
          return "border-error";
        case "warning":
          return "border-warning";
        case "grey":
          return "border-grey";
        case "grey-dark":
          return "border-grey-dark";
        case "grey-light":
          return "border-grey-light";
        case "white":
          return "border-white";
        default:
          return "border-prime";
      }
    };

    switch (variant) {
      case "full":
        bgColor = setBgColor();
        textColor = "text-white";
        if (disabled) {
          bgColor = `${setBgColor()} opacity-40 cursor-not-allowed hover:ring-transparent focus:ring-transparent`;
        }
        break;
      case "outlined":
        bgColor = "bg-transparent";
        textColor = setTextColor();
        borderColor = setBorderColor();
        fontWeight = "font-medium";
        additional = "font-semibold border";
        if (disabled) {
          textColor = "text-prime-light";
          additional =
            "border border-grey cursor-not-allowed hover:ring-transparent focus:ring-transparent";
        }
        break;
      case "flat":
        bgColor = "bg-transparent";
        textColor = setTextColor();
        fontWeight = "font-bold";
        additional = "focus:ring-transparent";
        if (disabled) {
          textColor = "text-prime-light";
          additional = "border-0 cursor-not-allowed hover:ring-transparent focus:ring-transparent";
        }
        break;
      case "icon-full":
        bgColor = setBgColor();
        textColor = "text-white";
        rounded = "rounded-full flex justify-center items-center";
        if (disabled) {
          bgColor = `${setBgColor()} opacity-40 cursor-not-allowed hover:ring-transparent focus:ring-transparent`;
        }
        break;
      case "icon-outlined":
        bgColor = "bg-transparent";
        textColor = setTextColor();
        borderColor = setBorderColor();
        borderColor = borderColor ? borderColor : `border-${themecolor}`;
        rounded = "rounded-full flex justify-center items-center";
        additional = "font-semibold border";
        if (disabled) {
          textColor = "text-prime-light";
          additional =
            "border border-grey cursor-not-allowed hover:ring-transparent focus:ring-transparent";
        }
        break;
      case "icon-flat":
        bgColor = "bg-transparent";
        textColor = setTextColor();
        rounded = "rounded-full flex justify-center items-center";
        additional = "font-bold focus:ring-transparent";
        if (disabled) {
          textColor = "text-prime-light";
          additional =
            "font-bold border-0 cursor-not-allowed hover:ring-transparent focus:ring-transparent";
        }
        break;
      default:
        break;
    }

    return (
      <button
        type="button"
        id={id}
        data-testid={dataTestId}
        ref={ref}
        // themecolor="button"
        className={`text-center flex items-center justify-center ${hover} focus:ring-2 ${fontWeight} ${rounded} px-5 py-2.5 ${width} ${height} ${bgColor} ${textColor} ${textSize} ${borderColor} ${additional} ${className}`}
        disabled={disabled}
        onClick={onClick}
        {...rest}
      >
        {icon && typeof icon === "object" && icon}
        {icon && typeof icon === "string" && (
          <img src={icon} alt="logo" style={{ height: "20px", marginRight: "1.2rem" }} />
        )}
        {children || label}
      </button>
    );
  }
);

export default Button;
