import { useState } from "react";
import { Typography } from "../../../components";
import TaskList from "../../../components/task/TaskList";

export default function StripeSetupTask({ handleTabChange }) {
  const [tasks] = useState([
    {
      key: "setup_stripe",
      title: "Setup Stripe account",
      description: "Setup Stripe to get paid for projects!",
      complete: false,
      onClick: () => handleTabChange("Payment Details"),
    },
  ]);
  return (
    <>
      <Typography type="title" className="mb-6">
        Complete tasks to finish account
      </Typography>
      <TaskList tasks={tasks} />
    </>
  );
}
