import { useNavigate } from "react-router-dom";
import { Button, Paper, Typography } from "../../components";
import Stepper from "../questionnaire/Stepper";

const faqs = [
  {
    question: "How do I get assigned projects",
    answer: "You must complete your application to 100% then be approved by our team",
  },
  // More questions...
];

const SubmitApplication = () => {
  let navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="flex justify-center mt-16">
          <Stepper step="application_submission" itemClassName="px-2" />
        </div>
        <Paper className="w-[700px]">
          <div className="flex flex-col justify-center items-center mt-6">
            <div className="px-4 py-5 sm:p-6">
              <div className="lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-indigo-600">Get approved</p>
                <h1 className="mt-2 text-3xl tracking-tight sm:text-4xl mb-4">
                  <Typography type="title">Submiting your Cyber-Expert application</Typography>
                </h1>
                <div className="text-prime-light text-lg mb-8">
                  To start the application review process your profile must be completed to 100%.
                  Once you complete and submit your profile a member of our team will review and
                  approve it.
                </div>
                {/* <p className="mt-6 text-lg leading-8 text-prime-light mb-4">
                  To start the application review process your profile must be completed to 100%.
                  Once you complete and submit your profile a member of our team will review and
                  approve it.
                </p> */}
                <p className="text-lg">Please note:</p>
                <ul className="list-disc pl-5 text-lg">
                  <li>
                    Once your profile is submitted, you will not be able to make changes while it is
                    under review.
                  </li>
                  <li>
                    Once your profile is approved, you may make changes and re-submit it for another
                    review.
                  </li>
                </ul>
              </div>
            </div>
            <div className="px-4 py-4 sm:px-6">
              <Button
                dataTestId="skip-btn"
                label="Continue to complete application"
                variant="full"
                themecolor="sec"
                className="block m-auto"
                onClick={() => navigate("/profile")}
              />
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default SubmitApplication;
