import { evalQuery } from "./index";

export const ResourcesService = {
  getCountries: () =>
    evalQuery({
      url: "/resource/all-countries",
    }),

  getTimezones: () =>
    evalQuery({
      url: "/resource/all-timezones",
    }),

  getLanguages: () =>
    evalQuery({
      url: "/resource/all-languages",
    }),
};
