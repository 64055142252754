import React, { useState, useEffect } from "react";
import { isEmpty, includes } from "lodash";

// components
import { Paper, Typography, Radio, Checkbox, Input, Button, Loader } from "../../../components";

// constans

// context
import { useToast, useAuth } from "../../../context";

// services
import { errorMsgForUser, ProfileService } from "../../../services";
import { freelancer_questions } from "../../questionnaire/freelancer_questions";

const DiversityIDFreelancer = ({ profile = {}, profileEditsLocked }) => {
  // context
  const { authContext } = useAuth();
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(0);
  const [diversity, setDiversity] = useState({
    gender: "",
    race: [],
    race_other: "",
    veteran_status: "",
  });

  const updateProfile = () => {
    const profileObj = {
      user_uid: authContext?.user?.uid,
      gender: diversity.gender,
      race: JSON.stringify(diversity.race),
      race_other: diversity.race_other,
      veteran_status: diversity.veteran_status,
    };
    // profile update Api
    setLoader((l) => l + 1);
    return ProfileService.update(profileObj)
      .then(() => {
        // console.log('ProfileService.update res:', res);
        showToast("success", "Update successfully");
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => {
        setLoader((l) => l - 1);
      });
  };

  useEffect(() => {
    if (!isEmpty(profile)) {
      setDiversity({
        gender: profile.gender,
        race: profile.race ? JSON.parse(profile.race) : "",
        race_other: profile.race_other,
        veteran_status: profile.veteran_status,
      });
    }
  }, [profile]);

  // useEffect(() => {
  //   console.log('diversity:', diversity);
  // }, [diversity]);

  return (
    <div>
      <Paper className="relative min-w-[400px] h-fit mb-6 rounded-md">
        {/* loader */}
        {!!loader && <Loader themeColor="fill-prime" />}

        <Typography type="title" className="mb-4">
          Diversity ID
        </Typography>

        <Typography type="subtitle" className="mb-8 text-lg">
          Our mission at Cyber Pop-up is to build and maintain an inclusive community of diverse
          cyber security freelancers to complete projects for businesses around the globe. In order
          to assess our progress and make actionable plans towards our diversity and inclusion
          goals, we would greatly appreciate if you would provide this information. Privacy is our
          first priority and we will not share your data with anyone. In order to assess our
          progress and make actionable plans towards our diversity and inclusion goals, we would
          greatly appreciate if you would provide this information. Privacy is our first priority
          and we will not share your data with anyone.
        </Typography>

        {/* <Typography type="subtitle" className="mb-6 text-lg">
          In order to assess our progress and make actionable plans towards our
          diversity and inclusion goals, we would greatly appreciate if you
          would provide this information. Privacy is our first priority and we
          will not share your data with anyone.
        </Typography> */}

        <div className="mb-8">
          <Typography type="subtitle" className="mb-1 text-lg">
            1) Please let us know what you most closely identify with?
          </Typography>

          <Radio
            name="gender"
            radios={freelancer_questions?.gender?.options}
            checked={diversity.gender}
            onChange={(e) =>
              setDiversity((n) => ({
                ...n,
                gender: e.target.value,
              }))
            }
          />
        </div>

        <div className="mb-8">
          <Typography type="subtitle" className="mb-1 text-lg">
            2) Please self-classify your race
          </Typography>

          {freelancer_questions?.race?.options &&
            freelancer_questions?.race?.options.map((race) => (
              <Checkbox
                key={race.dataTestId}
                dataTestId={race.dataTestId}
                value={race.value}
                className="mb-2"
                label={race.value}
                radios={freelancer_questions?.race?.options}
                checked={includes(diversity.race, race.value)}
                onChange={(e) => {
                  let newAnswerObj = [];
                  if (e.target.checked) {
                    newAnswerObj = [...diversity.race, e.target.value];
                  } else {
                    newAnswerObj = diversity?.race?.filter((n) => n !== e.target.value);
                  }
                  setDiversity((n) => ({ ...n, race: newAnswerObj }));
                }}
              />
            ))}

          <Input
            dataTestId="race_other"
            type="text"
            label="Other race"
            value={diversity.race_other}
            onChange={(e) =>
              setDiversity((n) => ({
                ...n,
                race_other: e.target.value,
              }))
            }
          />
        </div>

        <div className="mb-8">
          <Typography type="subtitle" className="mb-1 text-lg">
            3) What is your veteran status?
          </Typography>

          <Radio
            name="veteran"
            radios={freelancer_questions?.veteran_status?.options}
            checked={diversity.veteran_status}
            onChange={(e) =>
              setDiversity((n) => ({
                ...n,
                veteran_status: e.target.value,
              }))
            }
          />
        </div>

        <Button
          dataTestId="update_devirsity_btn"
          label="Update"
          className="block mx-auto mt-5"
          variant="full"
          themecolor="prime"
          onClick={updateProfile}
          disabled={profileEditsLocked}
        />
      </Paper>
    </div>
  );
};

export default DiversityIDFreelancer;
