import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import { useAuth } from "../../context";
import ModalNewPwd from "../../pages/profile/account/ModalNewPwd";
import { Button, Dropdown } from "../index";

// context

const UserButton = () => {
  // router
  let navigate = useNavigate();

  // context
  const { authContext } = useAuth();

  // local
  const allOptions = [
    {
      label: "Profile",
      icon: <i className="fa-solid fa-user-large" />,
      value: "profile",
      action: () => navigate("/profile"),
    },
    {
      label: "Change Password",
      icon: <i className="fa-solid fa-lock" />,
      value: "change_profile",
      action: () => setModalPwdOpened(true),
    },
    {
      label: "Sign Out",
      icon: <i className="fa-solid fa-arrow-right-from-bracket" />,
      value: "signout",
      action: () => navigate("/signout"),
    },
  ];
  const [modalPwdOpened, setModalPwdOpened] = useState(false);
  const [options, setOptions] = useState([]);

  const setOptionsForRole = () => {
    let roleOptions = [];

    if (authContext?.role === "Fsm") {
      roleOptions = allOptions.filter((n) => n.value !== "profile");
    } else {
      roleOptions = allOptions.filter((n) => n.value !== "change_profile");
    }

    setOptions(roleOptions);
  };

  // set 'options' for role
  useEffect(() => {
    setOptionsForRole();
  }, [authContext?.role]);

  return (
    <>
      <Dropdown
        className="ml-2 rounded-md"
        contentClassName=""
        placement="bottom-start"
        options={options}
        button={
          <Button
            dataTestId="header-user-button"
            label={<i className="fa-solid fa-user-large" />}
            variant="icon-flat"
            themecolor="prime"
            width="w-10"
            height="h-10"
            textSize="text-xl"
            // className="text-prime-contrast"
          />
        }
      />

      {/* modal change password */}
      {modalPwdOpened && (
        <ModalNewPwd
          dataTestId="modal-change-password"
          title="Change password"
          closeModal={() => setModalPwdOpened(false)}
        />
      )}
    </>
  );
};

export default UserButton;
