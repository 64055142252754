import { useEffect, useState } from "react";
import _ from "lodash";
import { Paper } from "../../../components";
import { useToast } from "../../../context";
import { errorMsgForUser } from "../../../services";
import { PlansService } from "../../../services/PlansService";
import { PriceService } from "../../../services/PriceService";
import { ProgramService } from "../../../services/ProgramService";
import AddPlanModal from "../AddPlanModal";
import PlansTable from "../PlansTable";
import AddProgramModal from "./AddProgramModal";
import ProgramSection from "./ProgramSection";

const defaultPlan = {
  default: true,
  name: "",
  short_description: "",
  long_description: "",
  prices: [],
  status: "Draft",
  initial_poptokens: 0,
  monthly_poptokens: 0,
};

const defaultPrice = {
  default: true,
  price: 0,
  interval: "Once",
  stripe_price_id_dev: "",
  stripe_price_id_prod: "",
};

export default function CsmAddEditPlans() {
  const { showToast } = useToast();
  // * Component States
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(defaultPlan);
  const [currentPrice, setCurrentPrice] = useState(defaultPrice);
  const [programs, setPrograms] = useState([]);

  // * Modal States & Form States
  const [planAddEditModal, setPlanAddEditModal] = useState(false);
  const [programAddModal, setProgramAddModal] = useState(false);

  const handleFormChange = async (event, attribute) => {
    event.preventDefault();
    setCurrentPlan((prevValues) => {
      const copy = { ...prevValues };

      copy[attribute] = event.target.value;
      return copy;
    });
  };

  const createNewPlan = async () => {
    const planCreateObj = {
      name: currentPlan.name,
      short_description: currentPlan.short_description,
      status: currentPlan.status.toLowerCase(),
      initial_poptokens: 0 || Number(currentPlan.initial_poptokens),
      monthly_poptokens: 0 || Number(currentPlan.monthly_poptokens),
      long_description: currentPlan.long_description,
    };

    const { plan } = await PlansService.create(planCreateObj);

    for (let i = 0; i < currentPlan.prices.length; i++) {
      const priceCreateObj = {
        plan_uid: plan.uid,
        interval: currentPlan.prices[i].interval.toLowerCase(),
        price: Number(currentPlan.prices[i].price),
        stripe_price_id_dev: currentPlan.prices[i].stripe_price_id_dev,
        stripe_price_id_prod: currentPlan.prices[i].stripe_price_id_prod,
      };
      await PriceService.create(priceCreateObj);
    }
    return plan;
  };

  const handleLinkActions = async (action, code) => {
    const { plan_link } = await PlansService.getOnboardingLink({
      plan_code: code,
    });

    try {
      switch (action) {
        case "navigate":
          window.location.replace(plan_link);
          break;
        case "copy":
          navigator.clipboard.writeText(plan_link);
          showToast("success", "Link copied to clipboard");
          break;
      }
    } catch (error) {
      console.log(error.message);
      showToast("error", errorMsgForUser(error) || "Something went wrong");
    }
  };

  const handlePlanFormSubmit = async (event) => {
    event.preventDefault();
    try {
      if (_.isEmpty(currentPlan.prices)) {
        throw new Error("Please add at least one price to the plan");
      }

      currentPlan.prices.forEach((e) => {
        if (e.price > 0 && (e.stripe_price_id_dev === "" || e.stripe_price_id_prod === "")) {
          throw new Error("Please add Stripe Price ID for the price");
        }
      });

      let stateCopy = [...plans];
      if ("uid" in currentPlan) {
        const idx = plans.findIndex((plan) => plan.uid === currentPlan.uid);
        stateCopy[idx] = currentPlan;
      } else {
        const plan = await createNewPlan();
        stateCopy = [...plans, plan];
      }
      setPlans(stateCopy);
      setPlanAddEditModal(false);
    } catch (error) {
      console.log(error.message);
      showToast("error", error.message);
    } finally {
      setCurrentPlan(defaultPlan);
    }
  };

  useEffect(() => {
    const getPlansPrices = async () => {
      const { plans } = await PlansService.getPlansAndPrices();
      setPlans(plans);
      console.log(plans);
    };
    getPlansPrices();
  }, []);

  useEffect(() => {
    const getPrograms = async () => {
      try {
        const { programs } = await ProgramService.getAll();
        setPrograms(programs);
      } catch (error) {
        showToast("error", errorMsgForUser(error));
      }
    };
    getPrograms();
  }, []);

  return (
    <>
      <Paper className="sm:mt-8">
        <div className="container flex-1 mx-auto p-page max-w-10xl">
          <PlansTable
            plans={plans}
            handleLinkActions={handleLinkActions}
            setPlanAddEditModal={setPlanAddEditModal}
            setCurrentPlan={setCurrentPlan}
          />
        </div>
      </Paper>

      <Paper className="my-4">
        <div className="container flex-1 mx-auto p-page max-w-10xl">
          <ProgramSection
            programs={programs}
            setPrograms={setPrograms}
            setProgramAddModal={setProgramAddModal}
            handleLinkActions={handleLinkActions}
          />
        </div>
      </Paper>

      {programAddModal && (
        <AddProgramModal
          open={programAddModal}
          setOpen={setProgramAddModal}
          setPrograms={setPrograms}
        />
      )}

      {planAddEditModal && (
        <AddPlanModal
          defaultPrice={defaultPrice}
          open={planAddEditModal}
          setOpen={setPlanAddEditModal}
          currentPlan={currentPlan}
          currentPrice={currentPrice}
          defaultPlan={defaultPlan}
          setCurrentPlan={setCurrentPlan}
          setCurrentPrice={setCurrentPrice}
          handleFormChange={handleFormChange}
          handlePlanFormSubmit={handlePlanFormSubmit}
        />
      )}
    </>
  );
}
