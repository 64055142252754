import { useState } from "react";
import _ from "lodash";
import FlatPillBadge from "../../components/badges/FlatPillBadge";

export default function AddPlanForm({
  setOpen,
  currentPlan,
  handleFormChange,
  handlePlanFormSubmit,
  setCurrentPlan,
  currentPrice,
  setCurrentPrice,
  defaultPlan,
  defaultPrice,
}) {
  const [addingPrice, setAddingPrice] = useState(false);

  return (
    <form onSubmit={handlePlanFormSubmit}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Add a new Cyber Pop-up Plan
          </h2>

          <div className="grid grid-cols-2 gap-x-6 gap-y-8">
            <div className="mt-4 col-span-1">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Plan name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="plan_name"
                  id="plan_name"
                  value={currentPlan?.name}
                  onChange={(e) => handleFormChange(e, "name")}
                  placeholder="Plan name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mt-4 col-span-1">
              <label
                htmlFor="street-address"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Short Description
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="short_description"
                  id="short_description"
                  value={currentPlan?.short_description}
                  onChange={(e) => handleFormChange(e, "short_description")}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="This will be shown to users"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 sm:grid sm:grid-cols-3 gap-x-6 gap-y-8">
            <div className="sm:col-span-1">
              <label
                htmlFor="country"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Status
              </label>
              <div className="mt-2">
                <select
                  id="status"
                  name="status"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  value={currentPlan?.status}
                  onChange={(e) => handleFormChange(e, "status")}
                >
                  <option>Active</option>
                  <option>Draft</option>
                </select>
              </div>
            </div>
            <div className="sm:col-span-1">
              <label
                htmlFor="initial_poptokens"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Initial Poptokens
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="initial_poptokens"
                  id="initial_poptokens"
                  className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={currentPlan?.initial_poptokens}
                  onChange={(e) => handleFormChange(e, "initial_poptokens")}
                  placeholder="0"
                />
              </div>
            </div>
            <div className="sm:col-span-1">
              <label
                htmlFor="initial_poptokens"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Monthly Poptokens
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="monthly_poptokens"
                  id="monthly_poptokens"
                  value={currentPlan?.monthly_poptokens}
                  onChange={(e) => handleFormChange(e, "monthly_poptokens")}
                  className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="0"
                />
              </div>
            </div>

            <div className="sm:col-span-full sm:-mt-4">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                Long description
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={currentPlan?.long_description}
                  onChange={(e) => handleFormChange(e, "long_description")}
                  placeholder="This will be shown to users. Write a marketing description of the plan."
                />
              </div>
            </div>
          </div>

          {_.isEmpty(currentPlan?.prices) && !addingPrice ? (
            <>
              {/* Empty State Start */}
              {/* ++++++++++++++++++ */}
              <div className="mt-4">
                <button
                  type="button"
                  className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    console.log(currentPlan);
                    setAddingPrice(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="mx-auto h-12 w-12 text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  <span className="mt-2 block text-sm font-semibold text-gray-900">
                    Add a new price
                  </span>
                </button>
              </div>
              {/* ++++++++++++++++++ */}
              {/* Empty State End */}
            </>
          ) : addingPrice ? (
            <>
              {/* PRICE ADD FORM START */}
              {/* ++++++++++++++++++ */}
              <div className="border rounded border-gray-300 p-4 bg-gray-100 mt-4 ">
                <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8">
                  <div>
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Price
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="text"
                        name="price"
                        id="price"
                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={currentPrice?.price}
                        onChange={(e) =>
                          setCurrentPrice({ ...currentPrice, price: e.target.value })
                        }
                        placeholder="0.00"
                        aria-describedby="price-currency"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <span className="text-gray-500 sm:text-sm" id="price-currency">
                          USD
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="interval"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Interval
                    </label>
                    <div className="mt-2">
                      <select
                        id="interval"
                        name="interval"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        value={currentPrice?.interval}
                        onChange={(e) =>
                          setCurrentPrice({ ...currentPrice, interval: e.target.value })
                        }
                      >
                        <option>Once</option>
                        <option>Monthly</option>
                        <option>Annual</option>
                      </select>
                    </div>
                  </div>
                  {currentPrice?.price > 0 && (
                    <>
                      <div className="col-span-1">
                        <label
                          htmlFor="stripe_price_id_dev"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Stripe Price ID (Dev)
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="stripe_price_id_dev"
                            id="stripe_price_id_dev"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={currentPrice?.stripe_price_id_dev}
                            onChange={(e) =>
                              setCurrentPrice({
                                ...currentPrice,
                                stripe_price_id_dev: e.target.value,
                              })
                            }
                            placeholder="price_1NhvzwEVoawK75O7qRTewgrv"
                          />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <label
                          htmlFor="stripe_price_id_prod"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Stripe Price ID (Prod)
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="stripe_price_id_prod"
                            id="stripe_price_id_prod"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={currentPrice?.stripe_price_id_prod}
                            onChange={(e) =>
                              setCurrentPrice({
                                ...currentPrice,
                                stripe_price_id_prod: e.target.value,
                              })
                            }
                            placeholder="price_1NhvzwEVoawK75O7qRTewgrv"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {/* Don't delete this single self closing div. Its a grid placeholder */}
                  <div id="div-placeholder" />
                  <div className="flex justify-end space-x-4">
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-gray-600 hover:text-indigo-500"
                      onClick={() => setAddingPrice(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                      onClick={() => {
                        console.log("currentPrice in onclick", currentPrice);
                        setCurrentPlan((prev) => ({
                          ...prev,
                          prices: [...prev.prices, currentPrice],
                        }));
                        setAddingPrice(false);
                        setCurrentPrice(defaultPrice);
                      }}
                    >
                      Save price
                    </button>
                  </div>
                </div>
                {/* ++++++++++++++++++ */}
                {/* PRICE ADD FORM END */}
              </div>
            </>
          ) : (
            <>
              {/* PRICES LIST START */}
              {/* ++++++++++++++++++ */}
              <div className="mt-4">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Recurring
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Stripe ID (Dev)
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Stripe ID (Prod)
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <a
                          onClick={() => {
                            setAddingPrice(true);
                            setCurrentPrice(defaultPrice);
                          }}
                          className="text-sm cursor-pointer text-indigo-600 hover:text-indigo-900"
                        >
                          + Add Price
                          <span className="sr-only" />
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {currentPlan.prices.map((price, idx) => (
                      <tr key={price.uid || idx} className="hover:bg-gray-100 cursor-pointer">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          ${price.price}.00
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {price.interval}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <FlatPillBadge
                            color={price.stripe_price_id_dev ? "green" : "red"}
                            text={
                              price.stripe_price_id_dev
                                ? "Added"
                                : price.price > 0
                                ? "Missing"
                                : "Not required if free"
                            }
                          />
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <FlatPillBadge
                            color={price.stripe_price_id_prod ? "green" : "red"}
                            text={
                              price.stripe_price_id_prod
                                ? "Added"
                                : price.price > 0
                                ? "Missing"
                                : "Not required if free"
                            }
                          />
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          {/* <a href="#" className="text-indigo-600 hover:text-indigo-900">
                            Edit<span className="sr-only">, {price.name}</span>
                          </a> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* ++++++++++++++++++ */}
              {/* PRICES LIST END */}
            </>
          )}
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className={`${
            addingPrice ? "cursor-not-allowed" : "cursor-pointer hover:text-indigo-500"
          }  text-sm font-semibold leading-6 text-gray-900`}
          disabled={addingPrice}
          onClick={() => {
            setOpen(false);
            setCurrentPlan(defaultPlan);
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`${
            addingPrice || !currentPlan?.default
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-indigo-600 hover:bg-indigo-500"
          } rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
          disabled={addingPrice || !currentPlan?.default}
        >
          Save
        </button>
      </div>
    </form>
  );
}
