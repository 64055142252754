import React, { useState, useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import moment from "moment";

// components
import { ChatInput } from "../../components";

// context
import { useAuth } from "../../context";

const receiversClasses = "bg-[#f2f2f2] rounded-tr-none ml-auto";
const sendersClasses = "bg-terc rounded-tl-none text-white mr-6";

const ChatConversations = ({
  className = "",
  maxListHeightClass = "",
  messagesData = [],
  addNewMsg,
}) => {
  // ref
  const conversationsList = useRef();

  // context
  const { authContext } = useAuth();

  // local
  const [messages, setMessages] = useState([]);

  // scroll to bottom of list
  useEffect(() => {
    if (!isEmpty(messages)) {
      conversationsList?.current?.scrollIntoView();
    }
  }, [messages]);

  useEffect(() => {
    if (!isEmpty(messagesData)) {
      const sortMegs = messagesData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      setMessages(sortMegs || []);
    }
  }, [messagesData]);

  return (
    <div className={`flex flex-col ${className}`}>
      {/* conversations list */}
      <div className={`flex-1 overflow-x-hidden overflow-y-auto max-h-96 ${maxListHeightClass}`}>
        {messages?.map((m) => (
          <div
            key={m.uid}
            data-testid={m.uid}
            className={`w-fit mb-4 px-4 py-2 rounded-2xl ${
              authContext?.user?.uid === m?.from_user_uid ? receiversClasses : sendersClasses
            }`}
          >
            <div
              className={`flex justify-between text-xs ${
                authContext?.user?.uid === m?.from_user_uid ? "text-prime-light" : "text-prime-light-light"
              }`}
            >
              {/* first name */}
              <div className="mr-4">{m?.from_first_name}</div>

              {/* date */}
              <div>{m?.created_at ? moment.utc(m.created_at).format("MMM DD, HH:mm") : ""}</div>
            </div>

            {/* message */}
            <div className="">{m?.message}</div>
          </div>
        ))}

        {/* bottom element for scrolling */}
        <span ref={conversationsList} />
      </div>

      {/* chat input */}
      <ChatInput addNewMsg={addNewMsg} />
    </div>
  );
};

export default ChatConversations;
