// components
import { Typography } from "../../components";

const ChatUsers = () => (
  <div className="p-4">
    <Typography type="subtitle" className="text-center">
      ChatUsers
    </Typography>
  </div>
);

export default ChatUsers;
