import React from "react";
import { useNavigate, useParams } from "react-router-dom";

// components
import { VideoPlayer, Button, Typography } from "../../components";

// context
import {
  freelancerEntuziastOnboardingVideoUrl,
  freelancerExpertOnboardingVideoUrl,
} from "../../constants/videos";
import { useAuth } from "../../context/AuthContext";

// constants

const FreelancerEntuziastOnboardingVideo = () => {
  // router
  let navigate = useNavigate();
  const { role } = useParams();

  // context
  const { authContext } = useAuth();

  const reroute = () => {
    if (authContext.token) {
      navigate("/dashboard");
    } else {
      navigate("/signin");
    }
  };

  return (
    <div className="p-page flex flex-col h-full">
      <Typography type="title" className="text-center mb-8">
        Onboarding video
      </Typography>

      <div className="flex-1 flex justify-center w-full mb-8">
        <VideoPlayer
          url={
            role === "Enthusiast"
              ? freelancerEntuziastOnboardingVideoUrl
              : freelancerExpertOnboardingVideoUrl
          }
          className="max-w-page w-full"
          width="100%"
          height="100%"
          controls={true}
          playing={false}
          onEnded={reroute}
        />
      </div>

      <Button
        dataTestId="skip-btn"
        label="Skip"
        variant="full"
        themecolor="sec"
        className="block m-auto"
        onClick={reroute}
      />
    </div>
  );
};

export default FreelancerEntuziastOnboardingVideo;
