import React from "react";

// components
import Button from "./Button";
import Loader from "./Loader";

const ModalConfirm = ({
  dataTestId,
  loader = 0,
  title,
  confirmBtnText = "Yes",
  closeModal,
  description = "",
  onConfirm = null,
}) => (
  <>
    <div
      id={dataTestId}
      tabIndex="1"
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
      {!!loader && <Loader themeColor="fill-prime" />}
      <div className="relative w-full h-full max-w-2xl p-4 md:h-auto">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {/* Modal header */}
          <div className="p-6">
            {/* Modla title */}
            <h3 className="text-xl font-normal text-center text-prime dark:text-white">{title}</h3>
          </div>

          {/* Modal description */}
          {description && <div className="px-6 my-6 text-lg text-gray-600">{description}</div>}

          {/* Modal footer */}
          <div className="flex items-center justify-end p-6">
            <Button
              dataTestId="close"
              label="Close"
              variant="flat"
              themecolor="prime"
              onClick={closeModal}
            />
            {onConfirm && (
              <Button
                dataTestId="accept"
                label={confirmBtnText}
                className="ml-4"
                variant="full"
                themecolor="prime"
                onClick={onConfirm}
              />
            )}
          </div>
        </div>
      </div>
    </div>

    {/* overlayer */}
    <div className="fixed inset-0 z-40 bg-gray-900 bg-opacity-50 dark:bg-opacity-80" />
  </>
);

export default ModalConfirm;
