import React from "react";
import moment from "moment";

// components
import { Button, Loader } from "../index";

// images
import CheckIcon from "./img/check_icon.png";
// import UncheckIcon from './assets/img/uncheck_icon.png';

const CardSecure = ({
  id,
  className = "",
  bgColor = "bg-white",
  cardClass,
  textSizePrimary,
  isLoading = false,
  textSizeSecondary,
  width,
  topTitle,
  title,
  amount,
  currency,
  planInterval,
  perMonthText,
  saveText,
  cancelationDate,
  peaceOfMindText,
  peaceOfMindTextTwo,
  extras,
  btnVariant,
  btnLabel,
  btnColor,
  btnActive, // 'True', 'False'
  perks,
  strechPerks = false, // bottom parts will be same height
  recommend,
  noRecommandedBlock,
  onClick,
  featuresTitle,
  botBtnLabel,
  botBtnColor,
  trial,
  peaceOfMindTextTrial,
}) => {
  // switch (type) {
  //   case 'full':
  //     textColor = 'text-prime';
  //     textSizePrimary = 'text-4xl';
  //     textSizeSecondary = `text-xl`;
  //     break;
  //   case 'medium':
  //     textColor = 'text-prime';
  //     textSizePrimary = 'text-3xl';
  //     textSizeSecondary = `text-xl`;

  //     break;
  //   case 'small':
  //     textColor = 'text-prime';
  //     textSizePrimary = 'text-lg';
  //     textSizeSecondary = `text-base`;

  //     break;
  //   default:
  //     break;
  // }

  const parentWidth = {
    width: width,
  };

  // useEffect(() => {
  //   console.log('perks2:', perks, typeof perks);
  // }, [perks]);

  return (
    <div className={`flex flex-col ${className}`} id={id}>
      <div
        style={parentWidth}
        className={`drop-shadow-lg text-center mb-4 flex flex-col rounded-b-3xl rounded-t-3xl border-2 ${bgColor}  ${textSizePrimary} ${
          noRecommandedBlock ? "" : recommend ? "" : "mt-12"
        } ${strechPerks ? "flex-1" : ""} ${cardClass}`}
      >
        {/* if loading */}
        {isLoading && <Loader themeColor="fill-prime" />}

        {/* recommend */}
        {recommend && (
          <div className="flex items-center justify-center w-full h-12 text-xl bg-yellow-300 border-none text-prime rounded-t-3xl">
            <div>
              <p className="text-base">Recommended for Your Risk Level</p>
            </div>
          </div>
        )}

        <div className="p-4">
          {/* before title */}
          {topTitle && (
            <div className="flex justify-center">
              <div className="flex justify-center items-center h-[60px] w-full px-3 text-sm rounded-xl mt-4 bg-prime-pale text-prime">
                {topTitle}
              </div>
            </div>
          )}

          {/* title */}
          {title && (
            <>
              <div className="mt-5 text-2xl font-roboto text-prime-light-dark">
                {trial ? `${title}` : title}
              </div>
              {/* Plan interval */}
              <div className="mb-1 text-base text-prime-light">{planInterval}</div>
            </>
          )}

          {/* amount */}
          {!isLoading && (amount !== null || amount !== undefined || !isLoading) && (
            <div className="mb-2 text-5xl break-words text-prime-light-dark">
              {currency === "usd" ? "$" : currency}
              {amount}
              {/* <span className="text-sm">/mo</span> */}
            </div>
          )}
          {/* pa */}
          <div className="mb-1 text-base text-prime-light">{}</div>

          <div className="mb-2">
            {/* plan month text */}
            {perMonthText && <div className="mb-1 text-base text-prime-light">{perMonthText}</div>}
            {trial && (
              <div className="text-base font-bold text-prime">No credit card required today!</div>
            )}

            {/* cancelation date */}
            {cancelationDate && (
              <div className="text-base text">
                Subscription will renew
                <br />
                {moment.unix(cancelationDate).format("MMM DD, YYYY")}
              </div>
            )}
          </div>

          {peaceOfMindText && (
            <div className="h-16">
              {/* peace of mind text */}
              {peaceOfMindText && (
                <div className={`h-6 px-6 text-sec ${textSizeSecondary}`}>{peaceOfMindText}</div>
              )}
            </div>
          )}
          {peaceOfMindTextTwo && (
            <div className="h-16">
              {/* peace of mind text */}
              {peaceOfMindTextTwo && (
                <div className={`h-6 px-6 text-sec ${textSizeSecondary}`}>{peaceOfMindTextTwo}</div>
              )}
            </div>
          )}

          {/* extras */}
          {extras && (
            <div
              className={`flex items-center justify-center p-2 h-[120px] text-prime-light h- ${textSizeSecondary}`}
            >
              {extras}
            </div>
          )}

          {/* button  */}
          {btnLabel && (
            <Button
              className="block mx-auto my-2"
              dataTestId={`plan-button-${id}`}
              label={!btnActive ? "Coming soon" : btnLabel}
              variant={btnVariant}
              themecolor={btnColor}
              width="w-full max-w-[260px]"
              disabled={!btnActive}
              onClick={onClick}
            />
          )}

          {/* Divider */}
          {!isLoading && <div className="mt-6 mb-6 bg-grey-light" style={{ height: "1px" }} />}

          {/* Features Title */}

          {featuresTitle && (
            <div>
              <p className="mb-3 text-xl">{featuresTitle}</p>
            </div>
          )}

          {/* Features */}
          {perks?.map((item, i) => (
            <div key={i} className="flex items-baseline mb-2">
              <img
                src={CheckIcon}
                alt="check"
                style={{
                  height: 15,
                  width: 15,
                  marginRight: 8,
                }}
              />

              <span className="text-base text-left">{item}</span>
            </div>
          ))}

          {/* button  */}
          {botBtnLabel && (
            <Button
              dataTestId="card-button"
              label={botBtnLabel}
              variant="flat"
              themecolor={botBtnColor}
              width="w-full max-w-[260px]"
              onClick={onClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CardSecure;
