import React, { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const ToastContext = createContext();
/**
 * Show toast hook
 * @returns show toast function
 */
export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const ToastIcon = (type) => {
    switch (type) {
      case "success":
        return (
          <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-500 bg-green-100 rounded-md dark:bg-green-800 dark:text-green-200">
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        );
      case "error":
        return (
          <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 bg-red-100 rounded-md text-warning dark:bg-red-800 dark:text-red-200">
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        );
      case "warning":
        return (
          <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 text-yellow-500 bg-orange-100 rounded-md dark:bg-orange-700 dark:text-orange-200">
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        );

      case "info":
        return (
          <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 text-blue-500 bg-orange-100 rounded-md dark:bg-orange-700 dark:text-orange-200">
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        );

      default:
        break;
    }
  };

  const handleToastColor = {
    error: "bg-warning",
    info: "bg-blue-500",
    warning: "bg-yellow-500",
    success: "bg-green-500",
  };

  /**
   * Function for dispatching the snackbar alert
   * @param {string} type error | success | warning | info
   * @param {string} description snackbar message content
   */
  const showToast = (type, description) => {
    const id = `${type}-${description}`;

    toast[type](description, {
      delay: 0,
      icon: ToastIcon(type),
      toastId: id,
      className: `${handleToastColor[type]} text-white rounded-md`,
    });
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      <ToastContainer
        toastClassName="text-white"
        bodyClassName="mb-0 mt-0"
        hideProgressBar={true}
        pauseOnHover={true}
        limit={5}
        autoClose={5000}
        position={toast.POSITION.BOTTOM_LEFT}
      />
      {children}
    </ToastContext.Provider>
  );
};
