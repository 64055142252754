import React, { useCallback, useEffect, useState, useRef } from "react";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

// components
import { Paper, Typography, Accordion, ContactManager, Loader } from "../../components";
import SVGImage from "../../components/SvgImage";
import { useAuth, useToast } from "../../context";
import { getProfileCompletion } from "../../functions/getProfileCompletion";
import { ProfileService, QuestionnaireService, errorMsgForUser } from "../../services";
import Roadmap from "../onboarding/Roadmap";
import Stepper from "../questionnaire/Stepper";
import CyberInsurance from "./CyberInsurance";
import CybersecurityResources from "./CybersecurityResources";
import Subscribe from "./Subscribe";

// images
import arrow from "./img/CyberPopup_Roadmap-Arrow (1).svg";
import img1 from "./img/CyberPopup_Roadmap_01_application-review.svg";
import img2 from "./img/CyberPopup_Roadmap_02_onboarding .svg";
import img3 from "./img/CyberPopup_Roadmap_03_pop-portunities.svg";
import img4 from "./img/CyberPopup_Roadmap_04_complete-projects.svg";
import img5 from "./img/CyberPopup_Roadmap_05_earn-money.svg";

// services

// context

// functions

// Road obj is used for roadmap component
const road = [
  {
    title: "Application Review",
    text: "Our team will review your application and determine if you have what it takes to begin doing projects right away as an expert. If you don’t meet the experience requirements just yet, all is not lost. You'll be categorized as an enthusiast and can get access to resources that can help you learn and grow as a cybersecurity professional.",
    img: img1,
    arrowImg: arrow,
  },
  {
    title: "Onboarding",
    text: "Meet your Cyber-expert Success Manager who will support you through the completion of your first project and beyond.",
    img: img2,
    arrowImg: arrow,
  },
  {
    title: "Access Pop-portunities",
    text: "You can choose to accept or decline projects sent to you based on your skillset. Projects typically take 2 - 40 hours to complete so you own your schedule and how much time you want to commit to projects.",
    img: img3,
    arrowImg: arrow,
  },
  {
    title: "Complete Projects",
    text: "When you accept a project, you'll receive access to guidance and templates that will help you deliver on standard cybersecurity projects.",
    img: img4,
    arrowImg: arrow,
  },
  {
    title: "Earn Money",
    text: "Once a project is complete and deliverables are approved, you'll get paid!",
    img: img5,
    arrowImg: arrow,
  },
];

// Projcet obj is object for the top part of Accordion component
const projects = [
  {
    title: "Peace of Mind Roadmap",
    menuText:
      "Cybersecurity threats come in a variety of shapes and sizes. There are also dozens of cyber protection methods,and figuring out where to start can be a challenge.",
    menuTitle: "What To Expect",
    details: [
      "Complete a project questionnaire",
      "60 minute information gathering call",
      "Review of business model, operating environment, and documentation Assessment",
      "Creation of a customized recommended prioritization and roadmap",
      "Detailed report of findings with feedback iterations",
      "30 minute debrief",
    ],
    color: "bg-sec",
  },
  {
    title: "Information Security Policy",
    menuText:
      "Every company should have an information security policy. It sets the foundation for cybersecurity guidance, rules, and culture within an organization.",
    menuTitle: "What To Expect",
    details: [
      "Complete a project questionnaire",
      "30 minute deep dive call (if needed)",
      "A review of your business",
      "Review and high-level gap analysis",
      "A custom outline of your new policy",
      "Delivery of the full policy manual",
      "30 minute debrief and Q&A meeting",
    ],
    color: "bg-blue-400",
  },
  {
    title: "Email Security Project",
    menuText:
      "While email communication is an important way for businesses to communicate with key users stakeholders, it`s also a common way to  to target and penetrate vulnerable businesses.",
    menuTitle: "What To Expect",
    details: [
      "Complete a project questionnaire",
      "30 minute deep dive call (if needed)",
      "A review of your current processes and technology",
      "Review and high-level gap analysis",
      "A recommendations of security configurations",
      "30 minute debrief",
    ],
    color: "bg-prime",
  },
];

const colorProjects = [
  {
    title: "Peace of Mind Roadmap",
    color: "bg-sec",
  },
  {
    title: "Information Security Policy",
    color: "bg-blue-400",
  },
  {
    title: "Email Security",
    color: "bg-prime",
  },
  {
    title: "Device Hardening",
    color: "bg-blue-400",
  },
  {
    title: "Training & Awareness",
    color: "bg-prime",
  },
];

// Used for Freelancer resources
const resources = [
  {
    hrefTitle: "Whitepapers",
    hrefTitle2: "Learn more",
    href: "https://www.canva.com/design/DAEUXpLzlV8/X8B1ZovibWEaK2jPOsaW8w/view?utm_campaign=designshare&utm_medium=email&_hsmi=112083244&_hsenc=p2ANqtz-_tmjrupVDeJ7xdxZTgEiYazen82X3JIcwIZz8ZfTaC8NoV_lBahceW4YNGu_NC5QC9CP9xdDy_JtAHq4GSC5EUYEHhYw&utm_content=112083244&utm_source=hs_automation",
    text: "Our thought leaders share the latest insights on reaserch and trends in the Cybersecurity industry along with practical tips for adapting",
  },
  {
    hrefTitle: "Courses",
    hrefTitle2: "Learn more",
    href: "https://www.youtube.com/watch?v=Qfk8T-6brZc",
    text: "Our courses explain the importance of Cybersecurity and the steps small and medium sized should take to stay secure. The part? No tech-speak. Always simple and easy to digest",
  },
  {
    hrefTitle: "Free Database",
    hrefTitle2: "Learn more",
    href: "https://drive.google.com/file/u/2/d/1CQ-WSIF_LTMJnM5WCFowEz8qb2f3LoLc/view?utm_medium=email&_hsmi=112079099&_hsenc=p2ANqtz-8IfZIka1YjwSQPaIS0GM27ZKSak7MHILvI8-y7eDlE8noJZVOGLAjstkWTB-SK5Qvmnj6D4YpqiyG6VwgM4I02dImIVQ&utm_content=112079099&utm_source=hs_automation",
    text: "We've pulled together a database of free Resources to help small and medium size businesses learn about and implement security strategies on a budget",
  },
];

const DashboardFreelancer = () => {
  // router
  let navigate = useNavigate();

  // context
  const { showToast } = useToast();
  const { authContext } = useAuth();

  // local
  const [loader, setLoader] = useState(0);
  const [profile, setProfile] = useState({});
  const [questionnaire, setQuestionnaire] = useState({});
  const [progresValue, setProgressValue] = useState({});
  const [step, setActiveStep] = useState("not_reviewed");
  const mounted = useRef(false);

  const statusCheck = useCallback((profile) => {
    if (!profile) return;

    if (profile.application_status === "Not_reviewed") {
      setActiveStep("not_reviewed");
    } else if (profile.application_status === "In_review") {
      setActiveStep("in_review");
    } else if (profile.application_status === "Enthusiast") {
      setActiveStep("freelancer_status");
    } else if (profile.background_check_status === "Sent") {
      setActiveStep("background_check");
    } else if (
      profile.application_status === "Expert" &&
      profile.background_check_status === "Approved"
    ) {
      setActiveStep("freelancer_status");
    }
  }, []);

  /**
   * Get Freelancer profile
   */
  const getProfile = useCallback(() => {
    // get profile Api
    setLoader((l) => l + 1);
    return ProfileService.get({ user_uid: authContext.user.uid })
      .then((res) => {
        const resProfile = {
          ...res.profile,
        };
        setProfile(resProfile);
        statusCheck(resProfile);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  }, [authContext.user.uid, showToast, statusCheck]);

  const getQuestionnaire = useCallback(
    () =>
      // get questionnaire Api
      QuestionnaireService.getByUserId({
        user_uid: authContext?.user?.uid,
      }).then((res) => {
        // console.log('QuestionnaireService.get res:', res);
        setQuestionnaire({
          ...res?.questionnaire,
          questions: JSON.parse(res?.questionnaire?.questions),
        });
      }),
    // .catch((err) => showToast("error", errorMsgForUser(err)))
    [authContext?.user?.uid, showToast]
  );

  useEffect(() => {
    if (mounted.current) {
      return;
    }
    mounted.current = true;
    getProfile();
    getQuestionnaire();
  }, [getProfile, getQuestionnaire]);

  useEffect(() => {
    const progressValue = getProfileCompletion(profile, questionnaire?.questions);

    if (progressValue) {
      setProgressValue(progressValue);
    }
  }, [profile, questionnaire]);

  useEffect(() => {
    if (mounted.current) {
      return;
    }
    mounted.current = true;
    getProfile();
    getQuestionnaire();
    // console.log(statusCheck());
  }, [getProfile, getQuestionnaire, profile, questionnaire]);

  return (
    <div className="mx-auto p-page max-w-page">
      {/* loader */}
      {!!loader && <Loader themeColor="fill-prime" />}

      {/* block upload profile if not done */}
      {!isEmpty(profile) && progresValue.total && progresValue.total !== 100 && (
        <div
          className="w-full h-20 py-6 mb-8 text-center rounded-lg cursor-pointer bg-prime-light hover:bg-prime"
          onClick={() => navigate("/profile")}
        >
          <p className="text-lg text-white">
            In order to receive project opportunities your profile must be 100% complete, please
            complete your profile here.
          </p>
        </div>
      )}

      {/* block upload video if not done */}
      {!isEmpty(profile) && !profile.media_url && (
        <div
          className="w-full h-20 py-6 mb-8 text-center rounded-lg cursor-pointer bg-prime-light hover:bg-prime"
          onClick={() => navigate("/video-interview/dashboard")}
        >
          <p className="text-lg text-white">
            Please remember to add your video to your application.<span> </span>
            <button
              type="button"
              className="underline hover:text-blue-400"
              onClick={() => navigate("/video-interview/dashboard")}
            >
              Record Your Video
            </button>
          </p>
        </div>
      )}

      {/* subscribe and contact */}
      <div className="flex items-center justify-between w-full mb-8">
        <Subscribe />

        <ContactManager role="Fsm" />
      </div>

      {/* Stepper with application status */}
      <Paper className="flex flex-col justify-center align-middle">
        <p className="mb-10 text-xl">
          <span className="font-semibold">Application Status: </span>
          <span>Your Resume, Video, and Website Links are being reviewed.</span>
        </p>
        {/* steper */}
        <Stepper
          step={step}
          freelancerApplicationStatus={profile?.application_status}
          itemClassName="px-2"
          status={true}
          className="mx-auto mt-12"
        />
      </Paper>

      {/* circle images that show steps */}
      <Roadmap road={road} title="What you can expect as a cybersecurity freelancer." />
      <Paper className="p-6">
        <Typography type="title" className="mt-6 mb-8 text-2xl text-left">
          The value you bring{" "}
        </Typography>
        <Typography type="subtitle" className="mb-8 text-xl text-left">
          For every 100 small and medium sized businesses, 30 will get hacked and nearly 20 will
          shut down as a result due to expensive recovery costs. This is heartbreaking. These
          businesses need access to cybersecurity professionals, but may not be able to hire a
          full-time expert.
        </Typography>
        <Typography type="subtitle" className="mb-8 text-xl text-left">
          Thanks to your commitment, they still have a fighting chance to not only survive, but
          thrive! Whether you're a professional with a full-time job looking to consult on the side,
          a full-time freelancer, or budding talent looking to gain more experience by freelancing
          with Cyber Pop-up are are making a difference. All while making extra cash in the process!
        </Typography>
      </Paper>

      <div className="ml-6">
        <p className="mt-10 text-3xl">We Match your Skills to Specific Projects</p>
        <p className="mb-6 text-xl text-gray">
          This is an example of what your customers will see:
        </p>
      </div>

      {/* Projects with colors */}
      <div className="flex flex-wrap mt-6 mb-8">
        {colorProjects.map((item, i) => (
          <div key={i} className="flex mx-4">
            <div className="flex flex-col items-center text-center">
              <div className={`w-[45px] h-[45px] rounded-full ${item.color} `} />

              <p className="mt-3 text-xl font-semibold text-prime">{item.title}</p>
            </div>
          </div>
        ))}
      </div>

      <Paper className="flex justify-between">
        {/* <p className='text-2xl font-semibold'>Types of Projects You Might Work On</p> */}
        {projects.map((item, i) => (
          <div
            key={item.title}
            className="py-4 w-2/6 mx-4 bg-[#F8F8F8] rounded-lg p-6 shadow-lg hover:shadow-xl duration-200 h-fit"
          >
            <div className={`w-[20px] h-[20px] rounded-full ${item.color}`} />
            <Accordion
              key={i}
              className=""
              title={item.title}
              menuTitle={item.menuTitle}
              menuText={item.menuText}
              classNameBtn="flex justify-between items-center text-left w-full font-medium text-prime-light mb-2"
              classNameMenuTitle="text-black"
            >
              <ul className="ml-6 text-lg list-disc">
                {item.details.map((dit, i) => (
                  <li className="mb-2" key={i}>
                    {dit}
                  </li>
                ))}
              </ul>
            </Accordion>
          </div>
        ))}
      </Paper>

      <Paper className="mt-6">
        <div className="flex items-center justify-around ">
          <div className="flex flex-col w-3/4">
            <Typography type="title" className="text-3xl mb-2">
              Cyber-Expert Hub
            </Typography>
            <p className="mb-4">
              Our Cyber-expert hub is a place to build a community for our Cyber Pop-up security
              professionals. Sign up to get free access. Once signed up you'll be able to network
              and communicate with out cybersecurity professionals just like yourself.
            </p>
          </div>
          <div className="">
            <a
              href="https://hub.cyberpopup.com/share/VJA5gGALScOLpEfj?utm_source=manual" // You may wish to specify a target URL here.
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-md bg-prime px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign-up now
            </a>
          </div>
        </div>
        {/* <div className="grid grid-cols-12 gap-4">
          <div className="col-span-8">
          </div>
          <div className="col-span-4">
            <div className="flex flex-col items-center">
            </div>
          </div>
        </div> */}
      </Paper>

      <CybersecurityResources
        resources={resources}
        title="Cyber-expert Knowledge Base"
        className="mt-12"
      />
    </div>
  );
};

export default DashboardFreelancer;
