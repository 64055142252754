import React, { useState } from "react";
import { Textarea, Button } from "../../components";
import { useToast, useAuth } from "../../context";
import DiscussionMessages from "./DiscussionMessages";

const Discussion = ({
  addNewMsg,
  messages,
  commentsRemaining,
  commentsAllowed,
  goToBottom,
  taskStatus,
}) => {
  const [message, setMessage] = useState("");

  // context
  const { authContext } = useAuth();
  const { showToast } = useToast();

  const addMsgClicked = () => {
    addNewMsg(message);
    setMessage("");
  };

  return (
    <div>
      <Textarea
        dataTestId="message"
        rows="4"
        placeholder="Add a comment..."
        // label="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      {authContext?.role !== "Csm" &&
        commentsRemaining >= 0 &&
        commentsAllowed !== commentsRemaining && (
          <p className="text-prime-light"> Remaining Comments: {commentsRemaining} </p>
        )}
      <Button
        dataTestId="video-upload-btn"
        label="Comment"
        variant="full"
        className="block mt-2 mb-4"
        themecolor="prime"
        disabled={!message}
        onClick={addMsgClicked}
      />
      <DiscussionMessages messages={messages} goToBottom={goToBottom} />
    </div>
  );
};

export default Discussion;
