import React from "react";

// components
import { ModalConfirm } from "../../components";

const ModalCmsWillBeInContact = ({ closeModal }) => (
  <ModalConfirm
    dataTestId="modal_cms_will_be_in_contact"
    title="Your project has been initiated, your Customer Success Manager will be in contact with you shortly."
    closeModal={closeModal}
  />
);

export default ModalCmsWillBeInContact;
