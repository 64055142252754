import React, { useState, useEffect } from "react";

// components
import { InputImage, Loader } from "../../components";

// context
import { useToast } from "../../context";

// services
import { errorMsgForUser, ProfileService } from "../../services";

const UserImage = ({ profile = {}, getProfile, pictureUploadIsPinging }) => {
  // context
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(false);
  const [inputImgPreview, setInputImgPreview] = useState("");
  const [inputImgValue, setInputImgValue] = useState("");

  const editAccountInfo = () => {
    // console.log('editAccountInfo');
    const profileObj = {
      user_uid: profile?.user_uid,
      file_name: "user-image",
      image: inputImgPreview,
    };

    // profile update Api
    setLoader(true);
    return ProfileService.update(profileObj)
      .then(() => {
        showToast("success", "Update successfully");

        getProfile();
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    if (inputImgValue) {
      editAccountInfo();
    }
  }, [inputImgValue]);

  useEffect(() => {
    if (profile?.profile_image) {
      setInputImgPreview(profile?.profile_image);
    }
  }, [profile.profile_image]);

  return (
    <div className="relative">
      <div
        className={`absolute h-full w-full ${
          pictureUploadIsPinging
            ? "border-4 rounded-lg border-red-500 scale-125 shadow-2xl animate-ping"
            : ""
        }`}
      />
      {/* loader */}
      {loader && (
        <div className="absolute bottom-0 left-0 w-10 h-10 ml-[3.5rem] mb-[3rem] z-[10]">
          <Loader themeColor="fill-prime" />
        </div>
      )}

      <InputImage
        dataTestId="input-image"
        className=""
        width="w-36"
        height="h-36"
        labelPosition="top"
        label={
          <span className={inputImgPreview ? "" : " text-warning"}>
            {profile?.application_status === "Incomplete" ? "Upload photo" : ""}
            {!inputImgPreview && (
              <i className="ml-1 fa-solid fa-circle-exclamation text-warning text-md" />
            )}
          </span>
        }
        bgColor="bg-prime"
        // imgWrapperClass={inputImgPreview ? '' : 'border-2 border-warning'}
        initialClass="text-4xl text-white"
        icon={<i className="text-6xl text-white fa-solid fa-user-large" />}
        preview={inputImgPreview}
        setValue={(v) => setInputImgValue(v)}
        setPreview={(v) => setInputImgPreview(v)}
      />

      {/* <div className="absolute bottom-0 right-0">
        <i class="fa-solid fa-pen"></i>
      </div> */}
    </div>
  );
};

export default UserImage;
