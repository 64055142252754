import React from "react";
import { useNavigate, useParams } from "react-router-dom";

// components
import { VideoPlayer, Button, Typography } from "../../components";

// constants
import { freelancerWhatToExpectVideoUrl } from "../../constants/videos";
import Stepper from "../questionnaire/Stepper";

const FreelancerWhatToExpectVideo = () => {
  // router
  let navigate = useNavigate();
  let { page } = useParams();

  return (
    <div className="p-page" style={{ height: "calc(100vh - 190px)" }}>
      <div className="flex flex-col items-center justify-center">
        <Stepper step="what_to_expect" className="mb-8" itemClassName="px-2" />

        <div className="flex justify-center w-9/12 h-3/4 mb-8">
          <VideoPlayer
            url={freelancerWhatToExpectVideoUrl}
            className="w-full h-auto aspect-video"
            width="100%"
            height="100%"
            controls={true}
            playing={false}
            onEnded={() => navigate("/dashboard")}
          />
        </div>

        <Button
          dataTestId="skip-btn"
          label="Continue"
          variant="full"
          themecolor="sec"
          className="block m-auto"
          onClick={() => navigate("/submit-application")}
        />
      </div>
    </div>
  );
};

export default FreelancerWhatToExpectVideo;
