import React, { useState } from "react";

// components
import { Button, Modal, Textarea, Loader } from "../../components";

// context
import { useToast, useAuth } from "../../context";

// services
import { errorMsgForUser, UserService } from "../../services";

const ContactModal = ({ setModalOpened, managerEmail, fullName }) => {
  // context
  const { showToast } = useToast();
  const { authContext } = useAuth();

  // local
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");

  console.log("Send mail: ", fullName);

  const sendClicked = () => {
    // NOTE: 'to_emails' on 'local' and 'development' ENV is user email
    let to_emails = authContext?.user?.email;

    if (import.meta.env.VITE_APP_ENV === "production") {
      to_emails = managerEmail;
    }

    const messageUpgrade = `<div><p>Message from user:</p><p>${fullName} (role ${authContext?.role})</p><p>${authContext?.user?.email}</p><div style="width: 300px; height:1px; background-color:#ccc";></div><div style="height:12px;"></div><p>${message}</p></div>`;

    const sendEmailObj = {
      from_email: authContext?.user?.email,
      to_emails,
      subjects: authContext?.user?.email,
      message: messageUpgrade,
    };

    // send email Api
    setLoader(true);
    return UserService.sendEmail(sendEmailObj)
      .then(() => {
        setMessage("");
        setModalOpened(false);

        // message on different ENV
        if (import.meta.env.VITE_APP_ENV === "production") {
          showToast("success", "An message has been sent");
        } else {
          showToast(
            "success",
            "In the LOCAL and DEV environment, requests will be sent to the registration email"
          );
        }
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  // useEffect(() => {
  //   getProfile();
  // }, [getProfile]);

  return (
    <Modal
      dataTestId="modal-contact-manger"
      title={`Contact Your ${
        authContext.role === "Customer" ? "Customer" : "Freelancer"
      } Success Manager`}
      maxWidth="max-w-2xl"
      closeModal={() => setModalOpened(false)}
      buttons={[
        <Button
          dataTestId="close"
          label="Close"
          variant="flat"
          themecolor="prime"
          onClick={() => setModalOpened(false)}
        />,
        <Button
          dataTestId="send-msg-bnt"
          label="Send"
          variant="full"
          themecolor="prime"
          disabled={!message}
          onClick={sendClicked}
        />,
      ]}
    >
      {/* loader */}
      {loader && <Loader themeColor="fill-prime" />}
      <div className="h-full w-full">
        <Textarea
          dataTestId="message"
          rows="6"
          label="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default ContactModal;
