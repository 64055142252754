import React, { useState, useEffect } from "react";

// components
import { Button, Paper, Typography, ModalConfirm, Loader } from "../../components";

// context
import { useToast, useAuth } from "../../context";

// services
import { UserService, errorMsgForUser } from "../../services";

const Subscribe = () => {
  // context
  const { showToast } = useToast();
  const { authContext } = useAuth();

  // local
  const [loader, setLoader] = useState(0);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [user, setUser] = useState({});

  const getUser = () => {
    // get profile Api
    setLoader((l) => l + 1);
    return UserService.get({ user_uid: authContext?.user?.uid })
      .then((res) => {
        // console.log('UserService.get res:', res);

        // set user
        setUser(res?.user);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const changeSubscription = () => {
    // user update Api
    setLoader((l) => l + 1);
    return UserService.update({
      uid: user?.uid,
      subscription_newslatter: !user.subscription_newslatter,
    })
      .then(() => {
        getUser();
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => {
        setLoader((l) => l - 1);
        setModalConfirm(false);
      });
  };

  // get user
  useEffect(() => {
    getUser();
  }, []);

  return (
    <Paper className="flex-1 p-6 text-left bg-white border-2">
      {/* loader */}
      {!!loader && <Loader themeColor="fill-prime" />}

      <Typography type="title" className="my-5">
        Welcome to Cyber Pop-up!
      </Typography>

      <p className="my-4 text-lg">
        {authContext?.role === "Customer"
          ? "Your Customer Success Manager will be reaching out to you shortly to get introduced and kick off the process for building your Peace of Mind Roadmap. In the meantime, please subscribe to stay up to date on the latest news regarding our official product launch coming soon."
          : "Your Cyber-expert Success Manager will review your information. While we continue working on improvements to the platform, you will receive email updates through our newsletter."}
      </p>

      <Button
        dataTestId="subscribe-btn"
        label={`${user?.subscription_newslatter ? "Subscribed" : "Subscribe"} to our newsletter`}
        icon={user?.subscription_newslatter && <i className="fa-solid fa-check mr-2 mt-0.5" />}
        variant={user?.subscription_newslatter ? "full" : "outlined"}
        themecolor="sec"
        className="block mx-auto"
        onClick={() => setModalConfirm(true)}
      />

      {/* modal confirm */}
      {modalConfirm && (
        <ModalConfirm
          dataTestId="modal-confirm-subscription"
          title={`Are you sure you want to  ${
            user?.subscription_newslatter ? "unsubscribe" : "subscribe"
          }?`}
          confirmBtnText="Yes"
          closeModal={() => setModalConfirm(false)}
          onConfirm={changeSubscription}
        />
      )}
    </Paper>
  );
};

export default Subscribe;
