import React from "react";

const Typography = ({ children, type, textColor, textSize, className = "" }) => {
  switch (type) {
    case "title":
      textColor = textColor || "text-prime";
      textSize = textSize || "text-3xl";
      break;
    case "subtitle":
      textColor = "text-prime";
      textSize = textSize || "text-2xl";
      break;
    default:
      break;
  }

  return <div className={`${textColor} ${textSize} ${className}`}>{children}</div>;
};

export default Typography;
