function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function TaskList({ tasks }) {
  return (
    <ul role="list" className="space-y-3 mb-8">
      {tasks.map((item) => (
        <div className="shadow">
          <li
            key={item.key}
            className={classNames(
              item.complete ? "ring-green-300" : "ring-red-300",
              item.disabled ? "cursor-not-allowed" : "cursor-pointer",
              "text-prime hover:bg-gray-100 hover:animate-pulse flex items-center justify-between overflow-hidden rounded-md bg-white px-6 py-4 ring-2 ring-inset"
            )}
            onClick={item.disabled ? null : item.onClick}
          >
            <div className="min-w-0">
              <div className="flex items-center gap-x-3">
                <p className="text-lg">{item.title}</p>

                {item.due_date && (
                  <div className="text-xs flex items-center">
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="ml-1">Complete by {item.due_date}</p>
                  </div>
                )}
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5">
                <p className="whitespace-nowrap text-base">{item.description}</p>
              </div>
            </div>
            <div className="">
              {/* Pill Badges */}
              <p
                className={classNames(
                  item.complete
                    ? "text-white bg-green-500 ring-green-600/20"
                    : "text-black bg-red-300 ring-red-500/10",
                  "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                )}
              >
                {item.complete ? "Completed" : "Not Completed"}
              </p>
            </div>
          </li>
        </div>
      ))}
    </ul>
  );
}
