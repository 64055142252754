// components
import { Loader, Documents } from "../../components";

// services
// import { TaskTemplateService } from '../../../services';


const TaskDocuments = ({ task, loader }) =>

  <div>
    {/* loader */}
    {!!loader && <Loader themeColor="fill-prime" />}

      <Documents
        accept="application/pdf"
        entityName="Task"
        entityUid={task?.uid}
        titleColor="text-prime-light"
        titleSize="text-sm"
        showDivider={true}
        copyToNextTaskCheckbox={true}
        documentWidget={task?.docs_upload_widget}
        onChange={() => {}}
      />
  </div>

export default TaskDocuments;
