import React from "react";
import Button from "../Button";

const StatusIcon = ({ status, tier }) => {
  switch (status) {
    case "Not Started":
      return <i className="fa-regular fa-circle text-prime-light mr-4" />;
    case "In Progress":
      return <i className="fa-regular fa-circle-right text-success mr-4" />;
    case "Completed":
      return <i className="fa-regular fa-check-circle text-success mr-4" />;
    default:
      return <i className="fa-solid fa-exclamation text-success mr-4" />;
  }
};

export default StatusIcon;
