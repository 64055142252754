const FlatPillBadge = ({ color, text }) => {
  const defineColor = () => {
    switch (color) {
      case "gray":
        return "bg-gray-100 text-gray-600";
      case "red":
        return "bg-red-100 text-red-700";
      case "yellow":
        return "bg-yellow-100 text-yellow-800";
      case "green":
        return "bg-green-100 text-green-700";
      case "blue":
        return "bg-blue-100 text-blue-700";
      case "indigo":
        return "bg-indigo-100 text-indigo-700";
      case "purple":
        return "bg-purple-100 text-purple-700";
      case "pink":
        return "bg-pink-100 text-pink-700";
    }
  };

  color = defineColor();

  return (
    <span
      className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${color}`}
    >
      {text}
    </span>
  );
};

export default FlatPillBadge;
