import React, { useEffect, useState } from "react";
import { Button } from "../../components";

interface ProjectPurchaseButtonProps {
  project: any;
  totalPopTokens: number;
  projectCredits: any[];
  purchaseMoreTokens: any;
  handleBuyProjectClick: any;
}

const ProjectPurchaseButton: React.FC<ProjectPurchaseButtonProps> = ({
  project,
  totalPopTokens,
  projectCredits,
  purchaseMoreTokens,
  handleBuyProjectClick,
}) => {
  const [hasCredit, setHasCredit] = useState(false);

  useEffect(() => {
    const hasCreditHelper = () => {
      const hasCredit = projectCredits.find(
        (credit) => credit.project_template_number === project.number
      );
      setHasCredit(hasCredit);
    };
    hasCreditHelper();
  }, [projectCredits]);

  if (hasCredit)
    return (
      <div className="">
        <Button
          dataTestId={`purchase_project_${project?.short_tex}`}
          label="Redeem Project"
          className="justify-center mx-auto"
          variant="full"
          themecolor="prime"
          onClick={() => handleBuyProjectClick(project)}
        />
      </div>
    );

  if (totalPopTokens < project.cost / 50)
    return (
      <Button
        dataTestId={`purchase_project_${project?.short_tex}`}
        label={`Purchase ${project.cost / 50 - totalPopTokens} more Pop Tokens`}
        className="justify-center mx-auto"
        variant="full"
        themecolor="prime"
        onClick={() => purchaseMoreTokens(project.cost / 50 - totalPopTokens)}
      />
    );

  return (
    <Button
      dataTestId={`purchase_project_${project?.short_text}`}
      label="Purchase this Project"
      className="justify-center mx-auto"
      variant="full"
      themecolor="prime"
      onClick={() => handleBuyProjectClick(project)}
    />
  );
};

export default ProjectPurchaseButton;

// if they have credit
// one button "redeem project"
// if they dont have enough PT
// "purchase more poptokens"
// on button "Purchase project"
