import React, { useEffect, useState } from "react";

// components
import { Button, Loader, Modal, Input } from "../../components";

// context
import { useToast } from "../../context";

// services
import { StripeService } from "../../services";

const ModalPayFreelancer = ({ closeModal = () => {}, data }) => {
  // context
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(0);
  const [freelancerCard, setFreelancerCard] = useState(null);
  const [amountToPay, setAmountToPay] = useState("");

  const getFreelancerCard = () => {
    setLoader((l) => l + 1);

    return StripeService.getPaymentActiveCard({
      stripe_account_id: data?.user?.stripe_account_id,
      stripe_card_number_id: data?.user?.stripe_card_number_id,
    })
      .then((res) => {
        // console.log('StripeService.getPaymentCard res:', res);
        setFreelancerCard(res.cards);
      })
      .catch((err) => {
        console.error("stripe err:", err);
        showToast("error", err);
      })
      .finally(() => setLoader((l) => l - 1));
  };

  const payToFreelancerClicked = () => {
    setLoader((l) => l + 1);
    return StripeService.payoutToFreelancer({
      user_uid: data?.user?.uid,
      currency: "usd",
      amount: 100,
    })
      .then(() => {
        // console.log('StripeService.payoutToFreelancer res:', res);
        showToast("success", "Payment Successful");
        closeModal();
      })
      .catch((err) => {
        console.error("stripe err:", err);
        showToast("error", err);
      })
      .finally(() => setLoader((l) => l - 1));
  };

  useEffect(() => {
    if (data) {
      // console.log('data:', data);
      getFreelancerCard();
    }
  }, [data]);

  return (
    <Modal
      title="Cyber-expert payment"
      maxWidth="max-w-3xl"
      closeModal={closeModal}
      data={data}
      buttons={[
        <Button
          dataTestId="cancel-pay-freelancer"
          label="Cancel"
          variant="flat"
          className="mr-2"
          onClick={closeModal}
        />,
        <Button
          dataTestId="send-freelancer-payment"
          label="Send payment"
          variant="full"
          disabled={!amountToPay}
          onClick={payToFreelancerClicked}
          // onClick={() => showToast('info', 'In Progress')}
        />,
      ]}
    >
      <div className="relative">
        {/* loader */}
        {!!loader && <Loader themeColor="fill-prime" />}

        <p className="mb-4 text-md">
          <span className="mr-2 text-prime-light">You're about to payout freelancer:</span>
          <span>
            {data.profile.responsible_person_first_name}{" "}
            {data?.profile?.responsible_person_last_name}
          </span>
        </p>

        <div className="mb-8">
          <p className="mb-2 text-lg text-prime">Card Info</p>
          <p>
            <span className="w-10 mr-2 text-prime-light">Brand:</span>
            {freelancerCard?.brand}
          </p>
          <p>
            <span className="w-10 mr-2 text-prime-light">Num: &nbsp;</span>
            **** **** **** {freelancerCard?.last4}
          </p>
          <p>
            <span className="w-10 mr-2 text-prime-light">Exp: &nbsp; &nbsp;</span>
            {freelancerCard?.exp_month}/{freelancerCard?.exp_year}
          </p>
        </div>

        <Input
          dataTestId="amount-to-pay"
          type="number"
          label="Amount ($)*"
          className="mb-4"
          value={amountToPay}
          onChange={(e) => setAmountToPay(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default ModalPayFreelancer;
