import { evalQuery } from "./index";

export const ChatService = {
  sendMessage: (request) =>
    evalQuery({
      url: "/user/send-message",
      request,
    }),

  getAllMessages: (request) =>
    evalQuery({
      url: "/user/get-all-messages",
      request,
    }),
  updateMessage: (request) =>
    evalQuery({
      url: "/user/update-message",
      request,
    }),
  postComment: (request) =>
    evalQuery({
      url: "/user/post-comment",
      request,
    }),
};
