import React, { useState, useEffect, useRef, useCallback } from "react";

import { Link, useNavigate, useLocation, useSearchParams, useParams } from "react-router-dom";

// comomon components
import { Tooltip } from "../../components";
import SingleLineWithAccentBorderAlert from "../../components/alertBanners/SingleLineWithAccentBorderAlert";
import { useToast, useAuth } from "../../context";
import { getProfileCompletion } from "../../functions/getProfileCompletion";

import {
  errorMsgForUser,
  ProfileService,
  QuestionnaireService,
  UserService,
  StripeService,
  CompanyService,
  ZapierService,
  ProjectService,
} from "../../services";

import { ProgramService } from "../../services/ProgramService";
import BannerUpdate from "./BannerUpdate";
import NavCustomer from "./NavCustomer";
import NavFreelancer from "./NavFreelancer";
import UserImage from "./UserImage";

// customer components
import AccountCustomer from "./account/AccountCustomer";
import AccountFreelancer from "./account/AccountFreelancer";
import CustomerSubscription from "./customer-subscription/CustomerSubscription";
import CyberExpertStatus from "./cyber-expert-status/CyberExpertStatus";
import SubmitApplicationModal from "./cyber-expert-status/SubmitApplicationModal";
import DiversityIDFreelancer from "./diversity-id/DiversityIDFreelancer";
import CompanyProfile from "./my-profile/CompanyProfile";
// import Settings from './Settings';

// freelancer components
import MyProfile from "./my-profile/MyProfile";
import PaymentDetails from "./payment-details/PaymentDetails";
import WorkAvailability from "./work-availability/WorkAvailability";
// import Calendar from './Calendar';

// functions

// context

// services

const initialQuestionnaire = {
  social_media: [],
  resume_upload: "",
  hear_about_us: "",
  hear_about_us_where: "",
  what_led_to_applay: "", // has characters restriction
  years_of_experience: "",
  management_skills: {
    "Cyber Security Strategy and Program Development": "None",
    "General Security Advising": "None",
    "Risk Assessment": "None",
    "Compliance (e.g. GDPR, PCI, HIPAA)": "None",
    "Training and Awareness": "None",
    "Cybersecurity Policy": "None",
    "Cybersecurity Maturity Assessment": "None",
    Other: "",
  },
  experience_in_industry: {
    Agriculture: "None",
    Energy: "None",
    "Computer and Electronics": "None",
    "Transportation and Warehousing": "None",
    Software: "None",
    "Real Estate, Rental, Leasing": "None",
    Education: "None",
    "Hospitality and Travel": "None",
    Healthcare: "None",
    Legal: "None",
    Construction: "None",
    Manufacturing: "None",
    Retail: "None",
    Telecommunications: "None",
    Finance: "None",
    "Arts, Entertainment": "None",
    "Government and Public Administration": "None",
    Scientific: "None",
    Military: "None",
    Other: "",
  },
  experience_in_area: {
    "Network Security (VPN, Firewall, Anti-virus)": "None",
    "Security Testing and Ethical Hacking": "None",
    "Data Security": "None",
    "Security Operations Center": "None",
    "Cloud Security": "None",
    "Data Loss Prevention": "None",
    "Email Security": "None",
    "Identity and Access Management": "None",
    "Incident Response": "None",
    "Security Monitoring and SIEM": "None",
    "Digital Forensics and Investigations": "None",
    "Cybersecurity Architecture": "None",
    "Cybersecurity Automation": "None",
    "Application Security": "None",
    Other: "",
  },
  certifications: [],
  hours_per_week: "",
  comfort_level: "",
  accomplishment: "",
};

const Profile = () => {
  // router
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedTab } = useParams();

  // refs
  const mounted = useRef(false);

  // context
  const { authContext } = useAuth();
  const { showToast } = useToast();

  // local state
  const [tab, setTab] = useState(
    location.state?.active_profile_tab || authContext?.role === "Customer"
      ? "My Account"
      : "Cyber-expert status"
  );
  const [loader, setLoader] = useState(0);
  const [profile, setProfile] = useState({});
  const [progressValue, setProgressValue] = useState({});
  const [questionnaire, setQuestionnaire] = useState(initialQuestionnaire);
  const [stripeVerified, setStripeVerified] = useState(null);
  const [profileEditsLocked, setProfileEditsLocked] = useState(false);
  const [userProgram, setUserProgram] = useState(null);

  // * UI States
  const [pictureUploadIsPinging, setPictureUploadIsPinging] = useState(false);

  // * Modal States
  const [submitApplicationModalVisible, setSubmitApplicationModalVisible] = useState(false);

  const prevProgressTotalRef = useRef();

  useEffect(() => {
    if (selectedTab) {
      setTab(selectedTab);
    }
  }, []);

  /**
   * Get Freelancer profile
   */
  const getProfile = useCallback(() => {
    // get profile Api
    setLoader((l) => l + 1);
    return ProfileService.get({ user_uid: authContext?.user?.uid })
      .then((res) => {
        const resProfile = {
          ...res.profile,
          // image: res.image
          image: !res.image || res.image === "NoneNone" ? null : res.image,
        };
        setProfile(resProfile);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  }, [authContext?.user?.uid, showToast]);

  // * Checks if users profile can be edited.
  // If pending review of resubmitted they can not edite profile
  useEffect(() => {
    let editsLocked = false;

    if (
      profile?.application_status === "Pending_review" ||
      profile?.application_status === "Resubmitted"
    ) {
      editsLocked = true;
    }
    setProfileEditsLocked(editsLocked);
  }, [profile]);

  const getQuestionnaire = useCallback(() => {
    setLoader((l) => l + 1);

    // get questionnaire Api
    return (
      QuestionnaireService.getByUserId({
        user_uid: authContext?.user?.uid,
      })
        .then((res) => {
          // console.log('QuestionnaireService.get res:', res);
          // console.log(
          //   'QuestionnaireService.get res:',
          //   JSON.parse(res?.questionnaire?.questions)
          // );

          setQuestionnaire({
            ...res?.questionnaire,
            questions: JSON.parse(res?.questionnaire?.questions),
          });
        })
        // .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => {
          setLoader((l) => l - 1);
        })
    );
  }, [authContext?.user?.uid, showToast]);

  const handleTabChange = (tab) => {
    setTab(tab);
    navigate(location.pathname, { state: { active_profile_tab: tab } });
  };

  const handleUploadPictureClick = () => {
    setPictureUploadIsPinging(true);

    setTimeout(() => {
      setPictureUploadIsPinging(false);
    }, 3500);
  };
  const redirectToNextSubscriptionPage = () => {
    window.location.href = "/app/subscription";
  };
  const handleProfileSubmit = async (application_status) => {
    setSubmitApplicationModalVisible(false);
    const profileObj = {
      user_uid: authContext?.user?.uid,
      application_status,
    };
    await ProfileService.update(profileObj);
    await getProfile();

    if (application_status === "Pending_review" || application_status === "Resubmitted") {
      const emailSubject =
        application_status === "Pending_review"
          ? "New Cyber-expert Application"
          : "New Cyber-expert Resubmission";
      sendEmail({
        subject: emailSubject,
        textBody: `Cyber-Expert Name: ${profile?.responsible_person_first_name} ${profile?.responsible_person_last_name}; Cyber-Expert Email: ${authContext?.user.email} `,
      });
    }
  };

  useEffect(() => {
    if (mounted.current) {
      return;
    }
    mounted.current = true;
    getProfile();
    getQuestionnaire();
  }, [getProfile, getQuestionnaire]);

  useEffect(() => {
    const progressValue = getProfileCompletion(profile, questionnaire?.questions);

    if (progressValue) {
      setProgressValue(progressValue);
    }
  }, [profile, questionnaire]);

  useEffect(() => {
    const handleSubscriptionLoadAndUpgrade = async () => {
      // setLoader((l) => l + 1);
      if (searchParams.get("checkout_session_id")) {
        try {
          const { subscription } = await StripeService.getSubscription({
            user_uid: authContext?.user?.uid,
          });
          const { checkout_session } = await StripeService.getCheckoutSession({
            checkout_session_id: searchParams.get("checkout_session_id"),
          });

          if (checkout_session.subscription !== subscription.id) {
            await StripeService.cancelSubscription({
              subscription_id: subscription.id,
              user_uid: authContext?.user?.uid,
            });
            const { company } = await CompanyService.getCompanyByUserUid({
              user_uid: authContext?.user?.uid,
            });

            await CompanyService.updateCompany({
              uid: company?.uid,
              stripe_subscription_id: checkout_session?.subscription,
            });
            // * Notify Zapier about subscription upgrade
            const { projects } = await ProjectService.getAllProjectsByCustomer({
              customer_uid: authContext?.user?.uid,
            });

            const { uid } = projects.find((project) => project.name === "Peace of Mind Roadmap");

            await ZapierService.notifyOfSubscriptionUpgrade({
              project_uid: uid,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    handleSubscriptionLoadAndUpgrade();
  }, []);

  useEffect(() => {
    prevProgressTotalRef.current = progressValue?.total;
  }, [progressValue]);

  useEffect(() => {
    const verifyStripe = async () => {
      const wasRedirectedFromStripeAccountSetup = searchParams.get("stripe_setup");

      if (wasRedirectedFromStripeAccountSetup === "true") {
        const stripe_account_id = searchParams.get("account_id");

        // verify the account.updated details_submitted
        const { stripe_account_verified } = await StripeService.verifyAccountSetup({
          user_uid: authContext?.user.uid,
          stripe_account_id,
        });

        let hasStripeValue = false;
        if (stripe_account_verified) hasStripeValue = true;
        setStripeVerified(hasStripeValue);
      }
    };
    const checkStripeAccountVerification = async () => {
      let hasStripeValue = false;
      if (profile?.freelancer_status === "Expert") {
        const { user } = await UserService.get({
          user_uid: authContext?.user.uid,
        });

        if (user?.stripe_account_verified === true) {
          hasStripeValue = true;
        }
      }
      setStripeVerified(hasStripeValue);
    };

    const wasRedirectedFromStripe = searchParams.get("stripe_setup");

    if (wasRedirectedFromStripe === "true") {
      verifyStripe();
    } else {
      checkStripeAccountVerification();
    }
  }, [profile]);

  const sendEmail = useCallback(
    ({ subject, textBody }) => {
      let emailReceiver = authContext?.user?.email;
      const emailForProd = ["support@www.cyberpopup.com.hubspot-inbox.com"];
      const emailForDev = ["dev-emails@cyberpopup.com", "hubspot-ticket-dev@cyberpopup.com"];
      let message = textBody;

      if (import.meta.env.VITE_APP_ENV === "production") {
        emailReceiver = emailForProd;
      } else if (import.meta.env.VITE_APP_ENV === "development") {
        emailReceiver = emailForDev;
      } else {
        message = `---------- this email on prod will be sent to ${emailForProd} <br />` + message;
      }

      return UserService.sendEmail({
        subjects: subject,
        to_emails: emailReceiver,
        message,
      }).catch(() => showToast("error", "Error occurred while sending mail"));
    },
    [
      authContext.user.email,
      profile?.responsible_person_first_name,
      profile?.responsible_person_last_name,
      showToast,
    ]
  );

  useEffect(() => {
    const getProgram = async () => {
      const { program } = await ProgramService.getProgramByUserEmail({
        user_email: authContext?.user?.email,
      });
      setUserProgram(program);
    };
    getProgram();
  }, []);

  if (!profile) {
    return (
      <div className="w-full m-auto text-2xl text-center text-prime-light">
        Error occurred while loading profile, please contact support.
      </div>
    );
  } else {
    return (
      <>
        <BannerUpdate />
        <div className="profile">
          <div className="container flex-col justify-between py-20 mx-auto max-w-page md:flex md:flex-row">
            {/* loader */}
            {/* {!!loader && <Loader themeColor="fill-prime" />} */}

            {/* user image and nav */}
            <div className="flex flex-col items-center justify-start">
              {/* user image */}
              <UserImage
                profile={profile}
                getProfile={getProfile}
                pictureUploadIsPinging={pictureUploadIsPinging}
              />

              {/* Freelancer progress */}
              {(authContext?.role === "Enthusiast" || authContext?.role === "Freelancer") && (
                <>
                  {profile && Boolean(profile) && progressValue.total !== 100 ? (
                    <div className="flex flex-col items-center mt-4">
                      {/* style is in 'index.css' */}
                      <progress
                        className="w-[150px]"
                        id="profile_completion"
                        value={progressValue?.total || 0}
                        max="100"
                      />
                      <div className="flex items-center mt-2">
                        <label className="text-gray-600 text-md" htmlFor="profile_completion">
                          {progressValue?.total}% Completed
                        </label>
                        {progressValue?.total < 100 && (
                          <Tooltip
                            placement="top"
                            text="Reach 100% by completing the following sections."
                          >
                            <i className="ml-2 text-xl fas fa-info-circle text-warning" />
                          </Tooltip>
                        )}
                      </div>
                      <div className="text-md w-[200px] text-center mt-5 text-gray-500">
                        {progressValue.total !== 100 &&
                          "Your profile must be 100% complete in order to be matched to projects."}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <NavFreelancer
                    completion={progressValue}
                    profile={profile}
                    tab={tab}
                    setTab={(tab) => handleTabChange(tab)}
                  />
                </>
              )}
              {authContext?.role === "Customer" && (
                <NavCustomer
                  tab={tab}
                  userProgram={userProgram}
                  setTab={(tab) => handleTabChange(tab)}
                />
              )}
            </div>

            <div className="w-full md:px-2 md:w-2/3">
              {(authContext?.role === "Enthusiast" || authContext?.role === "Freelancer") && (
                <>
                  {/* If the profile is under review - then show this banner stating they cannot edit */}

                  {tab !== "Cyber-expert status" &&
                    tab !== "Payment Details" &&
                    profileEditsLocked && (
                      <SingleLineWithAccentBorderAlert
                        infoText="You cannot edit your profile while its under review."
                        linkText="Check your profile status"
                        onClick={() => setTab("Cyber-expert status")}
                      />
                    )}
                  {/* freelancer blocks */}
                  {tab === "Cyber-expert status" && (
                    <CyberExpertStatus
                      authContext={authContext}
                      profile={profile}
                      progressValue={progressValue}
                      submitApplicationModalVisible={submitApplicationModalVisible}
                      stripeConfirmed={stripeVerified}
                      handleTabChange={handleTabChange}
                      handleUploadPictureClick={handleUploadPictureClick}
                      handleProfileSubmit={handleProfileSubmit}
                      setSubmitApplicationModalVisible={setSubmitApplicationModalVisible}
                    />
                  )}
                  {tab === "My Account" && (
                    <AccountFreelancer
                      profile={profile}
                      getProfile={getProfile}
                      profileEditsLocked={profileEditsLocked}
                    />
                  )}

                  {tab === "My Profile" && (
                    <MyProfile
                      profile={profile}
                      getProfile={getProfile}
                      questionnaire={questionnaire}
                      getQuestionnaire={getQuestionnaire}
                      profileEditsLocked={profileEditsLocked}
                      handleProfileSubmit={handleProfileSubmit}
                    />
                  )}

                  {tab === "Work Availability" && (
                    <WorkAvailability
                      profile={profile}
                      getProfile={getProfile}
                      profileEditsLocked={profileEditsLocked}
                    />
                  )}

                  {tab === "Diversity ID" && (
                    <DiversityIDFreelancer
                      profile={profile}
                      profileEditsLocked={profileEditsLocked}
                    />
                  )}

                  {tab === "Payment Details" && (
                    <PaymentDetails
                      profile={profile}
                      setTab={setTab}
                      stripeVerified={stripeVerified}
                    />
                  )}
                </>
              )}

              {/* customer blocks */}
              {authContext?.role === "Customer" && (
                <>
                  {tab === "My Account" && (
                    <AccountCustomer profile={profile} getProfile={getProfile} />
                  )}
                  {tab === "Company Profile" && (
                    <CompanyProfile
                      profile={profile}
                      getProfile={getProfile}
                      questionnaire={questionnaire}
                      getQuestionnaire={getQuestionnaire}
                    />
                  )}
                  {
                    (tab === "Subscription" || tab === "subscription") &&
                      redirectToNextSubscriptionPage()
                    // <CustomerSubscription profile={profile} />
                  }
                  {/* tab === 'Settings' && <Settings profile={profile} />} */}{" "}
                </>
              )}

              {/* <Calendar /> */}
            </div>
            {/* <div className="text-left w-1/7">Calendar setting</div> */}
          </div>

          {/* bottom links */}
          <div className="flex mb-8 justify-evenly">
            <Link
              data-testid="freelancer-code-of-conduct"
              to="/freelancer-code-of-conduct"
              target="_blank"
              className="ml-1 text-prime hover:underline"
            >
              Cyber-expert Code of Conduct
            </Link>
            <Link
              data-testid="terms-and-conditions"
              to="/terms-and-conditions"
              target="_blank"
              className="ml-1 text-prime hover:underline"
            >
              Terms of Service
            </Link>
            <Link
              data-testid="privacy-policy"
              to="/privacy-policy"
              target="_blank"
              className="ml-1 text-prime hover:underline"
            >
              Privacy Policy
            </Link>
            <Link
              data-testid="cookie-policy"
              to="/cookie-policy"
              target="_blank"
              className="ml-1 text-prime hover:underline"
            >
              Cookie Policy
            </Link>
          </div>
        </div>
        {submitApplicationModalVisible && (
          <SubmitApplicationModal
            open={submitApplicationModalVisible}
            setOpen={setSubmitApplicationModalVisible}
            handleProfileSubmit={handleProfileSubmit}
          />
        )}
      </>
    );
  }
};

export default Profile;
