const isVisibleTo = (userRole, task) => {
    let visibiltyArray = []
    if (task.visibility.to_customer) visibiltyArray.push("Customer")
    if (task.visibility.to_freelancer) visibiltyArray.push("Cyber-expert")
    return visibiltyArray.join(' and ')

}

export {
    isVisibleTo
}