import { useEffect } from "react";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { useSearchParams, useLocation } from "react-router-dom";

const RedirectNextAuth = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const pathnameMapping = {
    "/signin": "/login",
  };

  const newPathname = pathnameMapping[location.pathname] || location.pathname;

  const searchString = searchParams.toString();
  const url = `/app/auth${newPathname}${searchString ? `?${searchString}` : ""}`;

  useEffect(() => {
    window.location.href = url;
  });

  return (
    <div className="flex mt-24 justify-center min-h-screen">
      <ArrowPathIcon className="animate-spin text-cyber-prime w-16 h-16" />
    </div>
  );
};

export default RedirectNextAuth;
