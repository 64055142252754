import React, { useCallback, useEffect, useRef, useState } from "react";
import { map, isEmpty, isEqual } from "lodash";
import moment from "moment";

// libraries

// services
import { Select, Button, Loader } from "../../components";
import {
  STATUS,
  APPLICATION_STATUSES,
  VIDEO_STATUSES,
  BACKGROUND_CHECK_STATUSES,
} from "../../constants/statuses";
import { useToast } from "../../context";
import { getProfileCompletion } from "../../functions/getProfileCompletion";
import { ProfileService, FreelancerService, UserService, errorMsgForUser } from "../../services";

// components
import ModalPayFreelancer from "./ModalPayFreelancer";
import ProfileData from "./ProfileData";

// helpers and functions
import { handleFreelanceStatusUpdate } from "./utils";

// hooks

const ModalFSM = ({
  uid,
  userData,
  roles,
  header = true,
  title,
  showCloseIcon = true,
  closeModal,
  tableRefresh,
  handleDeleteCEFirstClick,
}) => {
  // refs
  // if any update occurs, FSM Table component should gets refreshed
  const isRefreshNeeded = useRef(false);
  const mounted = useRef(false);

  // local
  // TODO: refactor these states. userData is already a state variable in main component
  const [data, setData] = useState(userData);
  const [initialStatuses, setInitialStatuses] = useState({});
  const [loader, setLoader] = useState(false);
  const [modalPayFreelancer, setModalPayFreelancer] = useState(false);

  // Toast hooks
  const { showToast } = useToast();

  // questions from freelancer questionnaire
  const questions = data?.questionnaire?.questions
    ? JSON.parse(data?.questionnaire?.questions)
    : null;

  // return object with keys that have value
  const processQuestionnaireSkills = (data) => {
    const onlyWithAValueObj = {};
    map(data, (v, k) => {
      if (!v || v !== "None") onlyWithAValueObj[k] = v;
    });
    return onlyWithAValueObj;
  };

  // Processed skills data
  const managementSkills = processQuestionnaireSkills(questions.management_skills);

  const experienceInIndustry = processQuestionnaireSkills(questions.experience_in_industry);

  const experienceInArea = processQuestionnaireSkills(questions.experience_in_area);

  // get Freelancer data
  const getFreelancer = useCallback(
    () =>
      FreelancerService.get({ uid })
        .then((r) => {
          setData(r);

          setInitialStatuses({
            applicationStatus: r?.profile?.application_status,
            backgroundCheckStatus: r?.profile?.background_check_status,
            videoStatus: r?.profile?.video_status,
          });
        })
        .catch((err) => {
          showToast("error", "Error occurred while getting profile");
          console.error(`Error occurred while updating getting: ${uid}`, err);
        }),
    [showToast, uid]
  );

  // send an email notification to freelancer that his freelance_status has changed
  const sendFreelanceStatusEmail = useCallback(
    (user_uid, freelanceStatus) =>
      // if (data?.profile?.application_status === 'In_review') return;

      FreelancerService.sendFreelanceStatusEmail({
        user_uid: user_uid,
        freelancer_status: freelanceStatus,
        email_type: "status_change",
      })
        .then((r) => r)
        .catch((err) => showToast("error", errorMsgForUser(err))),
    [showToast]
  );

  console.log(3 ** (2 ** 2));

  // update role for user
  const updateUserRole = useCallback(
    (application_status, background_check_status) => {
      // console.log('initialStatuses:', initialStatuses);

      // If FSM does not change values but clicks submit. This ensures modal closes without backend calls
      if (
        initialStatuses.applicationStatus === application_status &&
        initialStatuses.backgroundCheckStatus === background_check_status
      ) {
        closeModal();
        return;
      }

      // if roles are not gotten form server stop
      if (isEmpty(roles)) {
        return showToast("error", "Get Roles failed. Refresh table.");
      }


      // get role uids
      const roleEnthusiastUid = roles.find((n) => n.name === "Enthusiast")?.uid;
      const roleFreelancerUid = roles.find((n) => n.name === "Freelancer")?.uid;

      // user update Api - update role_uid
      setLoader(true);
      return UserService.update({
        uid: data?.user.uid,
        role_uid:
          application_status === "Expert" && background_check_status === "Approved"
            ? roleFreelancerUid
            : roleEnthusiastUid,
      })
        .then(() => {
          // console.log('ProfileService.update res:', res);
          tableRefresh(true);
          closeModal();
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader(false));
    },
    [closeModal, data?.user.uid, initialStatuses, roles, showToast, tableRefresh]
  );

  /**
   * Update Freelancer profile
   * @param key property of the profile object which
   * is going to be updated
   * @param value value of the key/property
   */
  const updateProfile = useCallback(
    (key, value) => {
      // if FSM click save but makes no changes - this makes sure nothing happend
      if (
        initialStatuses.applicationStatus === data.profile.application_status &&
        initialStatuses.backgroundCheckStatus === data.profile.background_check_status
      ) {
        closeModal();
        return;
      }

      const { freelanceStatus, appStatusUpdated, videoStatus, backgroundStatus } =
        handleFreelanceStatusUpdate(
          data.profile.application_status,
          data.profile.freelancer_status,
          data.profile.background_check_status
        );

      if (!data.profile.uid) {
        showToast("warning", "This user doesn't have profile entity yet");
        return;
      }

      let payload = {
        user_uid: data.profile.user_uid,
        application_status: appStatusUpdated || data.profile.application_status,
        // application_status: 'Not_reviewed',
        freelancer_status: freelanceStatus || data.profile.freelancer_status,
        video_status: videoStatus || data.profile.video_status,
        background_check_status: backgroundStatus || data.profile.background_check_status,

        // data.profile.application_status === 'Expert'
        //   ? data.profile.background_check_status
        //   : '',
      };

      if (key) {
        payload[key] = value;
      }

      // payload['video_status'] = videoStatus;
      return ProfileService.update(payload)
        .then((r) => {
          isRefreshNeeded.current = true;

          showToast("success", "Cyber-expert profile updated");

          // If freelancer status has been changed
          // we send an email notification to a freelancer
          if (
            r.profile.freelancer_status === "Enthusiast" ||
            r.profile.freelancer_status === "Expert" ||
            r.profile.freelancer_status === "Denied"
          ) {
            if (
              key !== "notes" &&
              r?.profile?.freelancer_status !== "" &&
              r?.profile?.freelancer_status !== initialStatuses?.profile?.freelancer_status &&
              data.profile.application_status !== "Not_reviewed"
            ) {
              if (
                r?.profile?.application_status === "Expert" &&
                r?.profile?.background_check_status === "Sent"
              ) {
                tableRefresh(true);
                closeModal();
                return;
              }

              sendFreelanceStatusEmail(data?.profile?.user_uid, r?.profile?.application_status);
            }
          }

          console.log("data.profile.application_status:", data.profile.application_status);

          // if 'application_status' is 'Not_reviewed'
          if (data.profile.application_status === "Not_reviewed") {
            getFreelancer();
            tableRefresh(true);
          }

          updateUserRole(r?.profile?.application_status, r?.profile?.background_check_status);
        })
        .catch((err) => {
          showToast("error", "Error occurred while updating profile");
          console.error("Error occurred while updating profile", err);
        });
    },
    [
      closeModal,
      data.profile.application_status,
      data.profile.background_check_status,
      data.profile.freelancer_status,
      data.profile.uid,
      data.profile.user_uid,
      data.profile.video_status,
      getFreelancer,
      initialStatuses?.profile?.freelancer_status,
      sendFreelanceStatusEmail,
      showToast,
      tableRefresh,
      updateUserRole,
    ]
  );

  useEffect(() => {
    if (mounted.current) return;

    mounted.current = true;
    if (uid) {
      getFreelancer();
    }
  }, [data.profile.application_status, getFreelancer, uid, updateProfile]);



  return (
    <div className="fixed top-0 left-0 w-[100%] h-[100%] p-8 overflow-x-hidden md:inset-0 z-50">
      <div className="bg-white relative max-w-full h-[100%] overflow-y-auto overflow-x-hidden box-border rounded-lg flex flex-col justify-start">
        {/* sticky header */}
        <div className="sticky top-0 left-0 z-40 flex items-center w-full p-4 bg-white border-b">
          {header && (
            <>
              {/* modla title */}
              {title && (
                <div className="flex items-center">
                  <div className="mr-2 w-[50px] h-[50px] grid place-content-center rounded-full overflow-hidden bg-prime">
                    {data?.profile?.profile_image ? (
                      <img alt="profile_image" src={data.profile.profile_image} width="40px" />
                    ) : (
                      <i className="text-xl text-white fa-solid fa-user-large" />
                    )}
                  </div>
                  <h3 className="ml-2 text-xl font-medium text-gray-900 dark:text-white">
                    {title}
                  </h3>
                </div>
              )}

              {/* close modal button */}
              {showCloseIcon && (
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={() => {
                    closeModal();
                    tableRefresh(isRefreshNeeded.current);
                  }}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </>
          )}
        </div>

        {/* Modal Body */}
        <div className="relative z-30 flex flex-col flex-1 p-4 bg-white md:flex-row">
          {/* loader */}
          {!!loader && <Loader themeColor="fill-prime" />}

          <div className="flex-1 overflow-y-auto">
            <div className="flex flex-1 flex-col h-auto md:h-[500px] mb-2">
              <div className="text-xl font-semibold">Cyber-expert Questionnaire Response</div>

              <div className="text-lg text-prime-light">
                Profile Completion{" "}
                {data?.questionnaire?.questions
                  ? getProfileCompletion(data?.profile, JSON.parse(data.questionnaire.questions))
                      .total
                  : "/"}
                %
              </div>

              {data?.profile?.last_login_dt && (
                <div className="text-md text-prime-light">
                  Last time on app{" "}
                  {moment.utc(data.profile.last_login_dt).local().format("MMM DD, YYYY")}
                </div>
              )}

              {/* spacer */}
              <div>&nbsp;</div>

              <ProfileData
                data={data}
                questions={questions}
                managementSkills={managementSkills}
                experienceInArea={experienceInArea}
                experienceInIndustry={experienceInIndustry}
              />
            </div>
          </div>

          <div className="md:w-1/4 h-[500px]">
            <div className="flex flex-col pl-0 md:h-auto md:pl-5">
              <div className="mb-8 text-xl font-semibold">Cyber-expert Video</div>
              <div className="flex flex-col items-start">
                <Select
                  className="w-full mb-2"
                  dataTestId="video-status-select"
                  name="video-status-select"
                  selectClassName="capitalize"
                  options={VIDEO_STATUSES}
                  keyForOptionValue="value"
                  keyForOptionLabel="label"
                  value={data.profile.video_status}
                  // disabled={!data.profile.media_url}
                  onChange={(e) =>
                    setData((data) => ({
                      ...data,
                      profile: {
                        ...data.profile,
                        video_status: e.target.value,
                      },
                    }))
                  }
                />

                {data.profile.media_url ? (
                  <div className="flex items-center my-2">
                    <i className="fas fa-play-circle text-prime" />
                    <a
                      href={data?.profile?.media_url || ""}
                      target="_blank"
                      className="flex items-center ml-2 underline text-prime-light underline-offset-2"
                      rel="noreferrer"
                    >
                      {`Watch: ${data?.profile?.responsible_person_first_name} ${data?.profile?.responsible_person_last_name}`}
                    </a>
                  </div>
                ) : (
                  <div className="my-2 text-error">No video uploaded yet</div>
                )}

                <div className="mt-5 text-xl">
                  <div>Notes</div>
                  <textarea
                    cols="30"
                    value={data.profile.notes || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        profile: {
                          ...data.profile,
                          notes: e.target.value,
                        },
                      }))
                    }
                    className="w-full h-[150px] rounded-md border-gray-400"
                  />
                  <Button
                    variant="outlined"
                    label="Update Notes"
                    disabled={!data.profile.notes}
                    onClick={() => updateProfile("notes", data.profile.notes)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal bottom */}
        <div className="z-50 flex flex-col items-start w-full p-4 mt-auto bg-white">
          <div className="flex flex-col sm:items-center sm:flex-row">
            <div className="sm:w-1/2 md:w-[200px]">Application Status</div>
            <Select
              className="w-[100%] md:w-[300px] mb-2"
              firstEmpty={false}
              dataTestId="application-status-select"
              name="application-status-select"
              selectClassName="capitalize"
              options={[...APPLICATION_STATUSES]}
              keyForOptionValue="value"
              keyForOptionLabel="label"
              value={data.profile.application_status}
              onChange={(e) => {
                setData((data) => ({
                  ...data,
                  profile: {
                    ...data.profile,
                    application_status: e.target.value,
                  },
                }));
              }}
            />
          </div>

          <div className="flex flex-col sm:items-center sm:flex-row">
            <div
              className={`sm:w-1/2 md:w-[200px] ${
                data.profile.application_status !== "Expert" && "text-prime-light-light"
              }`}
            >
              Background Check Status
            </div>
            <Select
              className="w-full md:w-[300px]"
              dataTestId="background-check-status"
              name="background-check-status"
              selectClassName="capitalize"
              disabled={data.profile.application_status !== "Expert"}
              options={BACKGROUND_CHECK_STATUSES}
              keyForOptionValue="value"
              keyForOptionLabel="label"
              value={
                data.profile.application_status === "Expert"
                  ? data.profile.background_check_status
                  : ""
              }
              onChange={(e) => {
                setData((data) => ({
                  ...data,
                  profile: {
                    ...data.profile,
                    background_check_status: e.target.value,
                  },
                }));
              }}
            />
          </div>

          <div className="flex w-full mt-6">
            <Button
              disabled={
                isEqual(
                  {
                    applicationStatus: data.profile.application_status,
                    backgroundCheckStatus: data.profile.background_check_status,
                  },
                  initialStatuses
                ) ||
                (data.profile.application_status === "Expert" &&
                  (!data.profile.background_check_status ||
                    data.profile.background_check_status === "null"))
              }
              onClick={updateProfile}
              label="Submit changes"
            />
            <Button
              className="ml-4"
              themecolor="warning"
              onClick={() => handleDeleteCEFirstClick()}
              label="Delete Cyber-Expert"
            />
            {/* <Button
                dataTestId="pay-freelancer"
                label="Pay freelancer"
                themecolor="sec"
                variant="full"
                onClick={() => {
                  setModalPayFreelancer(true);
                }}
              /> */}
          </div>

          {modalPayFreelancer && (
            <ModalPayFreelancer closeModal={() => setModalPayFreelancer(false)} data={data} />
          )}
        </div>
      </div>

      {/* overlayer */}
      <div className="absolute inset-0 top-0 left-0 z-20 bg-gray-900 bg-opacity-50 dark:bg-opacity-80" />
    </div>
  );
};

export default ModalFSM;
