import React, { useState } from "react";
import { map, isEmpty } from "lodash";

// components
import ResourceTimeZone from "../../resources/ResourceTimeZone";

const ProfileData = (props) => {
  const { data, questions, managementSkills, experienceInArea, experienceInIndustry } = props;

  const [timeZoneFormat, setTimeZoneFormat] = useState("");

  const renderSkills = (data) => (
    <>
      {!isEmpty(data) &&
        map(data, (v, k) => (
          <div key={k}>
            {k}: <span className="text-prime-light">{v}</span>
          </div>
        ))}
    </>
  );

  return (
    <div>
      {/* resource */}
      <ResourceTimeZone
        timeZone={data?.profile?.time_zone || ""}
        setTimeZoneFormat={setTimeZoneFormat}
      />

      <div className="my-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Country:</p>
        <p className="ml-4">{data?.profile?.country}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">State:</p>
        <p className="ml-4">{data?.profile?.state}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">City:</p>
        <p className="ml-4">{data?.profile?.city}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Postal Code:</p>
        <p className="ml-4">{data?.profile?.zip_code}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Address One:</p>
        <p className="ml-4">{data?.profile?.address_one}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Address Two:</p>
        <p className="ml-4">{data?.profile?.address_two || ""}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Timezone:</p>
        <p className="ml-4">{timeZoneFormat}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Preferred Language:</p>
        <p className="ml-4">{data?.profile?.prefered_language}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Postal Code:</p>
        <p className="ml-4">{data?.profile?.zip_code}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Social Media:</p>
        <div className="ml-4">
          {questions?.social_media?.map((smedia, index) => (
            <a
              key={index}
              className="block underline underline-offset-2 text-prime-light mr-3"
              href={smedia}
              target="_blank"
              rel="noreferrer"
            >
              {smedia}
            </a>
          ))}
        </div>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Resume:</p>
        {data?.profile?.document_file ? (
          <a
            className="ml-4 underline underline-offset-2 text-prime-light"
            href={data?.profile?.document_file}
            download={data?.profile?.document_file}
          >
            Download
          </a>
        ) : (
          "N/A"
        )}
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Where did you hear about us:</p>
        <p className="ml-4">
          {questions?.hear_about_us !== "Other"
            ? questions?.hear_about_us
            : questions?.hear_about_us_where}
        </p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">What led you to apply:</p>
        <p className="ml-4">{questions?.what_led_to_applay}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Experience:</p>
        <p className="ml-4">
          {questions?.years_of_experience || "N/A"} {questions?.years_of_experience ? "years" : ""}
        </p>
      </div>
      <div className="mb-2 mt-4 flex border-b">
        <p className="w-1/2 md:w-[200px]">Management skills:</p>
        <div className="ml-4">{renderSkills(managementSkills)}</div>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Experience in industry:</p>
        <div className="ml-4">{renderSkills(experienceInIndustry)}</div>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Experience in area:</p>
        <div className="ml-4">{renderSkills(experienceInArea)}</div>
      </div>
      <div className="mb-2 mt-4 flex border-b">
        <p className="w-1/2 md:w-[200px]">Certifications:</p>
        <div className="ml-4">
          {questions?.certifications?.map((cert, index) => (
            <div key={index} className="rounded border md mb-4 p-2">
              <div className="flex flex-col">
                <div className="flex">
                  <div className="mr-2 w-[100px]">Cert body:</div>
                  <div>{cert.certBody}</div>
                </div>
                <div className="flex">
                  <div className="mr-2 w-[100px]">Cert name:</div>
                  <div>{cert.certName}</div>
                </div>
                <div className="flex">
                  <div className="mr-2 w-[100px]">Cert number:</div>
                  <div>{cert.certNumber}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Other certifications:</p>
        <p className="ml-4">{questions?.certifications_Other}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">How many hours can user work:</p>
        <p className="ml-4">{questions?.hours_per_week} h</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Comfort level:</p>
        <p className="ml-4">{questions?.comfort_level}</p>
      </div>
      <div className="mb-2 flex border-b">
        <p className="w-1/2 md:w-[200px]">Accomplishments:</p>
        <p className="ml-4">{questions?.accomplishment}</p>
      </div>
    </div>
  );
};

export default ProfileData;
