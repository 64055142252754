import React from "react";
// import { useNavigate } from "react-router-dom";
import { Button, Paper, Typography } from "../../components";
import { useToast } from "../../context";
import b64toBlob from "../../functions/base64ToBlob";
import { ProjectService, DocumentService, errorMsgForUser } from "../../services";

// context

// components

const NextSteps = ({ user }) => {
  // console.log("user", user)
  const { showToast } = useToast();

  const getRoadmap = () => {
    ProjectService.getRoadmap({ user_uid: user.uid, role_uid: user.role_uid })
      .then((r) => {
        if (r.entity_uid) {
          DocumentService.get({ entity_uid: r.entity_uid, entity_name: r.entity_name })
            .then((d) => {
              b64toBlob(d.documents[0].file, d.documents[0].name);
            })
            .catch((err) => {
              console.error("error", err);
              showToast("error", errorMsgForUser(e));
            });
        } else {
          showToast("error", "There was an error with your Roadmap PDF. Please, contact your CSM.");
        }
      })
      .catch((err) => showToast("error", errorMsgForUser(err)));
  };

  return (
    <Paper className="flex-1 p-6 text-left bg-white border-2">
      <Typography type="title" className="my-5 text-terc">
        What's Next?
      </Typography>

      <p className="my-4 text-lg">
        Now that you've made your first step in improving your cybersecurity posture, we encourage
        you to keep moving forward through the recommended projects within your Peace of Mind
        Roadmap.
      </p>

      <Button
        dataTestId="subscribe-btn"
        label="View Your Roadmap"
        variant="outlined"
        themecolor="terc"
        className="block mx-auto"
        onClick={() => getRoadmap()}
      />
    </Paper>
  );
};

export default NextSteps;
