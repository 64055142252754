import React, { useState, createContext, useContext } from "react";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [authContext, setAuthContext] = useState({
    token: window.localStorage.getItem("token") || null,
    role: window.localStorage.getItem("role") || null,
    user: JSON.parse(window.localStorage.getItem("user")) || null,
  });

  // set roles
  const setRoles = ({ roles = [] }) => {
    // set context
    setAuthContext({ ...authContext, roles });
  };

  // set user entity
  const signIn = ({ token, role, user }) => {
    // set localStorage
    localStorage.setItem("token", token);
    localStorage.setItem("role", role);
    localStorage.setItem("user", JSON.stringify(user));

    // set context
    setAuthContext({
      ...authContext,
      token,
      role,
      user,
    });
  };

  const signOut = () => {
    // clear contexts - only api data
    setAuthContext({
      ...authContext,
      roles: null,
      token: null,
      user: null,
      role: null,
    });

    // clear localStorage - only api data
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
  };

  const verifyEmail = (email) => {
    let fixedEmail = email.trim(); // remove spaces

    const REGEX =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!REGEX.test(fixedEmail)) {
      return false;
    }
    return true;
  };

  const verifyPassword = (pass) => {
    const REGEX =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%-^&*+()_;=?])[A-Za-z\d!@#$%-^&*+()_;=?]{6,}$/;

    if (!REGEX.test(pass)) {
      return false;
    }
    return true;
  };

  const authContextActions = {
    setRoles,
    signIn,
    signOut,
    verifyEmail,
    verifyPassword,
  };

  return (
    <AuthContext.Provider value={{ authContext, setAuthContext, authContextActions }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
