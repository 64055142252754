// components
import { Textarea } from "../../components";

const QuestionnaireRadioList = ({
  question, // key user for mapping through all questions and returns question options (exept: 'management_skills' or "experience_in_indrustry");
  questions, // all questionnaire question
  currentAnswers,
  handleChangeValue,
}) => (
  <div>
    <table className="w-full mb-8">
      <thead className="text-prime-light text-sm font-normal border-b border-grey-light">
        <tr>
          <td className="w-full" />
          {questions[question]?.radio_options?.map((n) => (
            <td key={n} className="min-w-[84px] text-center">
              {n}
            </td>
          ))}
        </tr>
        <tr>
          <td className="w-full" />
          {questions[question]?.radio_options_sec_row?.map((n) => (
            <td key={n} className="text-center">
              {n}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* Map through all user answers */}

        {Object.entries(currentAnswers || [])?.map((q, i) => {
          if (q[0] === "Other") return null;
          /**
           * Here we return array of object entries with current answers
           * where one answer is in form [key, value] (ex: ["Cybersecurity Maturity Assessment", "Novice"])
           */
          const customId = `${question}-${q[0].replaceAll(" ", "-")}-${i}`;
          const questionOptions = questions[question]?.radio_options;
          const questionKey = q[0];

          return (
            <tr data-testid={customId} key={customId}>
              <td>{questionKey}</td>
              {questionOptions.map((radioOption, k) => (
                <td key={`${k}_${radioOption}`} className="px-1">
                  <div className="flex justify-center p-2">
                    <input
                      data-testid={`${k}_${radioOption}`}
                      id={`${k}_${radioOption}`}
                      type="radio"
                      className="text-prime focus:border-prime disabled:text-gray-500"
                      name={currentAnswers[question]}
                      value={radioOption}
                      checked={currentAnswers ? radioOption === currentAnswers[questionKey] : false}
                      onChange={() =>
                        handleChangeValue({
                          changeKey: question,
                          changeValue: {
                            ...currentAnswers,
                            [questionKey]: radioOption,
                          },
                        })
                      }
                    />
                  </div>
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>

    <Textarea
      dataTestId={`${question}_other`}
      type="text"
      label="Other"
      placeholder={questions[question]?.other_placeholder || ""}
      rows="2"
      maxCharacters={140}
      value={currentAnswers?.Other || ""}
      onChange={(e) => {
        handleChangeValue({
          changeKey: question,
          changeValue: {
            ...currentAnswers,
            Other: e.target.value,
          },
        });
      }}
    />
  </div>
);
export default QuestionnaireRadioList;
