import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

// resources
import { Button, Input, Select, ModalConfirm } from "../../../components";
import { useToast } from "../../../context";
import Resources from "../../../resources/Resources";

// components
import { ProfileService, errorMsgForUser } from "../../../services";

const Location = ({ profile = {}, getProfile, profileEditsLocked }) => {
  // context
  const { showToast } = useToast();

  // resources
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [languages, setLanguages] = useState([]);

  // local
  const [loader, setLoader] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [locationData, setLocationData] = useState({
    country: "USA",
    state: "",
    city: "",
    zip_code: "",
    time_zone: "",
    address_one: "",
    address_two: "",
    prefered_language: "English",
  });

  const handleUpdate = () => {
    setLoader(true);
    const profileObj = {
      uid: profile?.uid,
      user_uid: profile?.user_uid,
      ...locationData,
    };
    return ProfileService.update(profileObj)
      .then((res) => {
        console.log("ProfileService.update res:", res);
        showToast("success", "Profile updated");
        setModalConfirm(false);
        getProfile();
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))

      .finally(() => setLoader(false));
  };

  // set input values - locationData
  useEffect(() => {
    if (profile && !isEmpty(profile)) {
      setLocationData({
        country: profile.country || "",
        state: profile.state || "",
        city: profile.city || "",
        zip_code: profile.zip_code || "",
        time_zone: profile.time_zone || "",
        address_one: profile.address_one || "",
        address_two: profile.address_two || "",
        prefered_language: profile.prefered_language || "",
      });
    }
  }, [profile]);

  return (
    <div>
      {/* get resources */}
      <Resources
        countries={countries}
        setCountries={setCountries}
        alpha3Code={locationData?.country}
        setStates={setStates}
        setTimezones={setTimezones}
        languages={languages}
        setLanguages={setLanguages}
      />

      <Select
        dataTestId="country"
        label="Country *"
        className="mb-4"
        value={locationData?.country}
        options={countries}
        keyForOptionValue="alpha3Code"
        keyForOptionLabel="country"
        onChange={(e) =>
          setLocationData((n) => ({
            ...n,
            country: e.target.value,
          }))
        }
      />
      <Select
        dataTestId="state"
        label="State *"
        className="mb-4"
        options={states}
        value={locationData?.state}
        onChange={(e) =>
          setLocationData((n) => ({
            ...n,
            state: e.target.value,
          }))
        }
      />
      <div className="flex">
        <Input
          dataTestId="city"
          type="text"
          label="City *"
          className="flex-1 mb-4 mr-4 text-left"
          value={locationData?.city}
          onChange={(e) =>
            setLocationData((n) => ({
              ...n,
              city: e.target.value,
            }))
          }
        />
        <Input
          dataTestId="zip-code"
          type="text"
          label="Postal Code *"
          className="mb-4 text-left"
          value={locationData?.zip_code}
          onChange={(e) => setLocationData((n) => ({ ...n, zip_code: e.target.value }))}
        />
      </div>
      <Input
        dataTestId="address"
        type="text"
        label="Address 1 *"
        className="mb-4 text-left "
        value={locationData?.address_one}
        onChange={(e) =>
          setLocationData((n) => ({
            ...n,
            address_one: e.target.value,
          }))
        }
      />
      <Input
        dataTestId="address_two"
        type="text"
        label="Address 2 (Optional)"
        className="mb-4 text-left"
        value={locationData?.address_two}
        onChange={(e) =>
          setLocationData((n) => ({
            ...n,
            address_two: e.target.value,
          }))
        }
      />
      <Select
        dataTestId="time-zone"
        label="What timezone are you in? *"
        className="mb-4"
        keyForOptionValue="value"
        keyForOptionLabel="text"
        options={timezones}
        value={locationData?.time_zone}
        onChange={(e) =>
          setLocationData((n) => ({
            ...n,
            time_zone: e.target.value,
          }))
        }
      />
      <Select
        dataTestId="language"
        label="Preferred Language? *"
        className="mb-8"
        options={languages}
        value={locationData?.prefered_language}
        onChange={(e) =>
          setLocationData((n) => ({
            ...n,
            prefered_language: e.target.value,
          }))
        }
      />

      <Button
        className="block mx-auto"
        dataTestId="save-location-btn"
        label="Update"
        themecolor="prime"
        disabled={
          !locationData?.country ||
          !(isEmpty(states) || locationData.state) ||
          !locationData?.city ||
          !locationData?.zip_code ||
          !locationData?.address_one ||
          !locationData?.time_zone ||
          !locationData?.prefered_language ||
          profileEditsLocked
        }
        onClick={() => {
          setModalConfirm(true);
        }}
      />

      {/* modal confirm */}
      {modalConfirm && (
        <ModalConfirm
          title="Are you sure you want to change your information?"
          closeModal={() => setModalConfirm(false)}
          onConfirm={handleUpdate}
          loader={loader}
        />
      )}
    </div>
  );
};

export default Location;
