import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// components
import { Paper, Typography, Button, InputPassword, Loader } from "../../components";

// context
import { useAuth, useToast } from "../../context";

// services
import { UserService, errorMsgForUser } from "../../services";

const ResetPassword = () => {
  // router
  let navigate = useNavigate();
  const { search } = useLocation();

  // context
  const { authContextActions } = useAuth();
  const { showToast } = useToast();

  // local
  const [new_password, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const isSubmitDisabled = () => {
    if (new_password && repeatPassword) {
      return false;
    }
    return true;
  };

  const submitClicked = () => {
    // password validation
    if (!authContextActions.verifyPassword(new_password)) {
      console.error("Password NOT valid.");
      showToast(
        "error",
        `The password must contain at least:\n
        - 6 character,\n
        - one lower-case letter,\n
        - one upper-case letter,\n
        - one number,\n
        - one or more of these symbols (!@#$%^&*-+()_;=?)`
      );
      return;
    }

    // repeat password validation
    if (new_password !== repeatPassword) {
      console.error("Passwords NOT matching.");
      showToast("error", "Passwords do not match.");
      return;
    }

    // prepere token & user_uid
    const spitSearch = search.split("&user_uid=");
    const token = spitSearch[0].replace("?token=", "");
    const user_uid = spitSearch[1];

    setLoader(true);
    return UserService.resetPassword({ new_password, token, user_uid })
      .then(() => {
        showToast("success", "The password changed successfully.");
        navigate("/signin");
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  return (
    <div className="flex justify-center p-page">
      <Paper className="min-w-[400px]">
        {/* loader */}
        {loader && <Loader themeColor="fill-prime" />}

        <Typography type="title" className="text-center mb-8">
          Reset password
        </Typography>

        <InputPassword
          dataTestId="password"
          label="Password *"
          InfoVisible={true}
          value={new_password}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <InputPassword
          dataTestId="repeat-password"
          label="Repeat password *"
          className="mb-6"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && submitClicked()}
        />

        <Button
          dataTestId="reset-password-button"
          label="Reset password"
          variant="full"
          themecolor="prime"
          className="block mx-auto"
          onClick={submitClicked}
          disabled={isSubmitDisabled()}
        />
      </Paper>
    </div>
  );
};

export default ResetPassword;
