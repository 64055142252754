import React from "react";

const Divider = ({ className, lineColor, textColor, text }) =>
  text ? (
    <div className={`flex items-center ${className}`}>
      <div className={`flex-1 ${lineColor ? lineColor : "bg-grey"}`} style={{ height: "1px" }} />

      <div className={`px-8 ${textColor ? textColor : "text-prime-light"}`}>{text}</div>

      <div className={`flex-1 ${lineColor ? lineColor : "bg-grey"}`} style={{ height: "1px" }} />
    </div>
  ) : (
    <div
      className={`w-full ${lineColor ? lineColor : "bg-grey"} ${className}`}
      style={{ height: "1px" }}
    />
  );

export default Divider;
