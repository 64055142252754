/* NOTE: https://www.npmjs.com/package/react-phone-input-2
  style for input is in "src/index.css"
*/

import "react-phone-input-2/lib/material.css";

import ReactPhoneInput from "react-phone-input-2";
const PhoneInput = ReactPhoneInput.default ? ReactPhoneInput.default : ReactPhoneInput;

const InputPhone = ({
  label,
  placeholder = "",
  value,
  onChange,
  className = "mb-4",
  inputClassName = "",
  containerClassName = "",
  ...rest
}) => (
  <div className={className}>
    <label>
      {label && <span className="block text-prime-light">{label}</span>}

      <PhoneInput
        country="us"
        autoFormat={true}
        disableDropdown={true}
        containerClass={`${containerClassName}`}
        inputClass={`${inputClassName}`}
        value={value}
        regions={["america", "europe", "asia", "oceania", "africa"]}
        specialLabel=""
        placeholder={placeholder}
        // required
        // error={Boolean(errors.phoneNumber)}
        // helperText={errors.phoneNumber}
        onChange={onChange}
        // onChange={phone => {
        //   setErrors(e => ({
        //     ...e,
        //     phoneNumber: isPhoneValidFormat(values.phone_number),
        //   }));
        //   handleChange(`+${phone}`, 'phone_number');
        // }}
        // onBlur={() => {
        //   setErrors(e => ({
        //     ...e,
        //     phoneNumber: isPhoneValidFormat(values.phone_number),
        //   }));
        // }}
        {...rest}
      />
    </label>
  </div>
);

export default InputPhone;
