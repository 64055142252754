import { useEffect, useState } from "react";
import Button from "../Button";
import Modal from "../Modal";

const BuyConfirmationModal = ({
  displayedProject,
  onClick,
  closeModal,
  projectCredits,
  buyLoading,
}) => {
  const [redeem, setRedeem] = useState(false);

  useEffect(() => {
    setRedeem(
      projectCredits.find((credit) => credit.project_template_number === displayedProject.number)
    );
  });

  return (
    <Modal title=" " centerTitle={true} closeModal={closeModal}>
      {
        <>
          <div className="flex flex-col h-full pb-6">
            {/* project goal */}
            <div className="mb-2 text-xl font-light text-center">
              {redeem
                ? "You are about to redeem and start this project"
                : "You are about to buy and start this project"}

              <div className="mt-4 text-xl italic font-semibold">
                {displayedProject?.short_text}
              </div>
            </div>

            <div className="text-lg font-semibold text-center mb-9 text-gold">
              {redeem ? (
                <>
                  <p className="line-through">{displayedProject?.cost / 50} Pop tokens</p>
                  <p className="text-prime">
                    This project is credited to your account, so its free!
                  </p>
                </>
              ) : (
                <>
                  <i className="mr-2 text-xl fa-solid fa-coins" />
                  {`${displayedProject?.cost / 50} Pop-tokens`}
                </>
              )}
            </div>

            {/* purchase btn */}
            <div className="flex justify-center">
              <Button
                dataTestId={`purchase_project_${displayedProject?.short_text}`}
                label={buyLoading ? "Loading..." : `${redeem ? "Redeem" : "Buy"} project now`}
                className="hover:animate-pulse"
                variant="full"
                themecolor="prime"
                disabled={buyLoading}
                onClick={onClick}
              />
            </div>
          </div>
        </>
      }
    </Modal>
  );
};

export default BuyConfirmationModal;
