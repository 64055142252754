import { evalQuery } from "./index";

export const StripeService = {
  stripeSetupPaymentAccount: (request) =>
  evalQuery({
    url: "/stripe/setup-payment-account",
    request,
  }),
  verifyAccountSetup: (request) =>
  evalQuery({
    url: "/stripe/verify-payment-account",
    request,
  }),
  createLoginLink: (request) =>
  evalQuery({
    url: "/stripe/create-login-link",
    request,
  }),

  stripeCreateAccount: (request) =>
    evalQuery({
      url: "/stripe/stripe-create-customer",
      request,
    }),

  stripeSetPaymentMethod: (request) =>
    evalQuery({
      url: "/stripe/change-card",
      request,
    }),

  getAllPricesFromProductIdOrShortname: request =>
    evalQuery({
      url: '/stripe/get-all-prices-from-product-id-or-shortname',
      request,
    }),
  checkTrialStatus: request =>
    evalQuery({
      url: '/stripe/check-trial-status',
      request,
    }),

  getPlan: (request) =>
    evalQuery({
      url: "/stripe/get-plan",
      request,
    }),

  getDefaultPlan: (request) =>
    evalQuery({
      url: "/stripe/get-default-plan",
      request,
    }),
  // checkout
  createCheckoutSession: (request) =>
    evalQuery({
      url: "/stripe/create-checkout",
      request,
    }),
  getCheckoutSession: (request) =>
    evalQuery({
      url: "/stripe/get-checkout-session",
      request,
    }),
  // products are 'monthly', 'yearly', ...
  getProducts: (request) =>
    evalQuery({
      url: "/stripe/all-products",
      request,
    }),

  getPlans: (request) =>
    evalQuery({
      url: "/stripe/all-plans",
      request,
    }),

  planTriggerActivation: (request) =>
    evalQuery({
      url: "/stripe/plan-trigger-activation",
      request,
    }),

  createPlan: (request) =>
    evalQuery({
      url: "/stripe/create-plan",
      request,
    }),

  customerPayForProject: (request) =>
    evalQuery({
      url: "/stripe/customer-pay-for-project",
      request,
    }),

  // when user bay a plan
  createSubscription: (request) =>
    evalQuery({
      url: "/stripe/create-subscription",
      request,
    }),

  getSubscriptions: (request) =>
    evalQuery({
      url: "/stripe/all-subscriptions",
      request,
    }),

  getSubscription: (request) =>
    evalQuery({
      url: "/stripe/get-subscription",
      request,
    }),

  changeSubscription: (request) =>
    evalQuery({
      url: "/stripe/change-subscription",
      request,
    }),

  cancelSubscription: (request) =>
    evalQuery({
      url: "/stripe/cancel-subscription",
      request,
    }),

  // prop 'pop_token_type': 'temporary_30_days' or 'permanent'
  buyPoptokenPackage: (request) =>
    evalQuery({
      url: "/stripe/buy-poptoken-package",
      request,
    }),

  freelancerAddCard: (request) =>
    evalQuery({
      url: "/stripe/freelancer-add-card",
      request,
    }),

  getPaymentCards: (request) =>
    evalQuery({
      url: "/stripe/get-all-stripe-cards",
      request,
    }),

  getPaymentActiveCard: (request) =>
    evalQuery({
      url: "/stripe/get-stripe-card",
      request,
    }),

  deletePaymentCard: (request) =>
    evalQuery({
      url: "/stripe/delete-stripe-card",
      request,
    }),

  payoutToFreelancer: (request) =>
    evalQuery({
      url: "/stripe/freelancer-payout",
      request,
    }),
};
