import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import { Typography, Button, VideoPlayer } from "../../components";
import { customerDashboardTourVideoUrl } from "../../constants/videos";
import Stepper from "../questionnaire/Stepper";

// constants

const CustomerDashboardTourVideo = () => {
  // router
  let navigate = useNavigate();

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col items-center p-page pb-2">
      {/* steper */}
      <Stepper step="dashboard_tour" className="mb-8" itemClassName="px-2" />

      <div className="flex justify-center w-full" style={{ height: "calc(100vh - 270px)" }}>
        {/* left part */}
        <div className="flex flex-col justify-center px-4 w-1/3">
          <Typography type="title" className="mb-12">
            Thank you for making Cyber Pop-up part of your Cybersecurity journey.
          </Typography>

          <p className="text-sec mb-8">
            <i className="fa-solid fa-circle-play mr-2" />
            Watch Christine Izuakor discuss Cyber Pop-up
          </p>

          <Button
            dataTestId="go-to-dashboard-btn"
            label="Go To Dashboard"
            variant="full"
            themecolor="sec"
            disabled={false}
            onClick={() => navigate("/dashboard")}
          />
        </div>

        {/* right part */}
        <div className="flex flex-col justify-center w-full w-2/3">
          <VideoPlayer
            url={customerDashboardTourVideoUrl}
            className="max-w-page"
            width="100%"
            height="100%"
            controls={true}
            playing={false}
            onEnded={() => navigate("/dashboard")}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboardTourVideo;
