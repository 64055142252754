import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import RouterFile from "./RouterFile";

// context
import { ToastProvider, AuthProvider } from "./context";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "flowbite";

// NOTE: possible values: 'local' , 'development' , 'production'
const APP_ENV = import.meta.env.VITE_APP_ENV;
// console.log("APP_ENV:", APP_ENV);

// clears console in production mode
if (APP_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
}

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastProvider>
      <AuthProvider>
        <BrowserRouter>
          <RouterFile />
        </BrowserRouter>
      </AuthProvider>
    </ToastProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
