import React, { useState, useEffect } from "react";
import moment from "moment";

// components
import {
  Paper,
  Typography,
  Input,
  InputPassword,
  InputFile,
  InputImage,
  InputDate,
  InputDuration,
  InputPhone,
  Textarea,
  Checkbox,
  Radio,
  Switch,
  Select,
  Tags,
} from "../../components";

const Form = () => {
  const [inputImgPreview, setInputImgPreview] = useState("");
  const [inputImgValue, setInputImgValue] = useState("");

  const [text, setText] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState("");
  const [someFile, setSomeFile] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [duration, setdDuration] = useState("");
  const [note, setNote] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [radio, setRadio] = useState("two");
  const [switche, setSwitche] = useState(false);
  const [select, setSelect] = useState("two");
  const [tags, setTags] = useState([]);

  useEffect(() => {
    // console.log('inputImgPreview:', inputImgPreview);
    // console.log('inputImgValue:', inputImgValue);
    // console.log('text:', text);
    // console.log('email:', email);
    // console.log('password:', password);
    // console.log('number:', number);
    // console.log('someFile:', someFile);
    // console.log('zipCode:', zipCode);
    // console.log('phone:', phone);
    // console.log('date:', date);
    // console.log('dateTime:', dateTime);
    console.log("duration:", duration);
    // console.log('note:', note);
    // console.log('checkbox:', checkbox);
    // console.log('radio:', radio);
    // console.log('switche:', switche);
    // console.log('select:', select);
    // console.log('tags:', tags);
  }, [
    inputImgPreview,
    inputImgValue,
    text,
    email,
    password,
    number,
    someFile,
    phone,
    date,
    duration,
    time,
    note,
    checkbox,
    radio,
    switche,
    select,
    tags,
  ]);

  return (
    <div className="flex-1 flex justify-center items-center p-page">
      <Paper className="min-w-[400px]">
        <Typography type="title" className="text-center mb-8">
          Form
        </Typography>

        <InputImage
          dataTestId="input-image"
          width="w-24"
          height="h-24"
          // boxShadow="shadow"
          bgColor="bg-prime"
          // borderRadius="rounded-full"
          // holderClass=""
          label="Add Image"
          labelPosition="bottom"
          // labelClass="my-2"
          fullName="Wolter Woles"
          initialClass="text-4xl text-white"
          icon={<i className="fa-solid fa-user-large text-white text-4xl" />}
          // disabled={true}
          preview={inputImgPreview}
          setValue={(v) => setInputImgValue(v)}
          setPreview={(v) => setInputImgPreview(v)}
        />

        <Input
          dataTestId="text"
          type="text"
          label="Text"
          placeholder="Some Text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />

        <Input
          dataTestId="email"
          type="email"
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <InputPassword
          dataTestId="password"
          label="Password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Input
          dataTestId="number"
          type="number"
          label="Number"
          placeholder="Number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />

        <InputFile
          dataTestId="some-file"
          label="Add File"
          placeholder="Add File"
          onChange={(v) => setSomeFile(v)}
        />

        <InputPhone
          dataTestId="phone"
          label="Phone"
          placeholder="Phone"
          onChange={(v) => setPhone(v)}
        />

        <InputDate
          dataTestId="date"
          label="Enter Date"
          placeholder="Date"
          selected={date}
          onChange={(v) => setDate(v)}
        />

        <InputDate
          dataTestId="date-time"
          label="Enter Date Time"
          placeholder="Date Time"
          showTimeSelect
          timeFormat="HH:mm"
          selected={date}
          onChange={(v) => setDate(v)}
        />

        <InputDate
          dataTestId="month-year"
          dateFormat="MM / yyyy"
          showMonthYearPicker
          label="Enter Month/Year"
          placeholder="MM / YYYY"
          showYearSelect
          selected={date}
          onChange={(v) => setDate(v)}
        />

        <InputDate
          dataTestId="time-only"
          showTimeSelect
          showTimeSelectOnly
          timeCaption="Time"
          timeFormat="HH:mm"
          dateFormat="HH:mm"
          timeIntervals={10}
          label="Enter Time"
          placeholder="HH:mm"
          value={time}
          onChange={(v) => setTime(moment(v).format("HH:mm"))}
        />

        <InputDuration
          label="Task duration *"
          showDays={true}
          value={duration}
          onChange={(v) => setdDuration(v)}
        />

        {/* textarea */}
        <Textarea
          dataTestId="note"
          rows="2"
          label="Note"
          placeholder="Write a note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />

        {/* checkbox / radio / switch */}
        <Checkbox
          dataTestId="terms"
          label="Terms & Conditions"
          value="terms"
          checked={checkbox}
          onChange={(e) => setCheckbox(e.target.checked)}
        />

        <Radio
          name="someRadio"
          radios={[
            { dataTestId: "one", label: "One", value: "one" },
            { dataTestId: "two", label: "Two", value: "two" },
            { dataTestId: "three", label: "Three", value: "three" },
          ]}
          checked={radio}
          onChange={(e) => setRadio(e.target.value)}
        />

        <Switch
          dataTestId="show_the_component"
          label="Show the component"
          checked={switche}
          onChange={(e) => setSwitche(e.target.checked)}
        />

        <Select
          dataTestId="select"
          name="someSelect"
          label="Select Array of Strings"
          selectClassName="capitalize"
          options={["one", "two", "three"]}
          value={select}
          onChange={(e) => setSelect(e.target.value)}
        />

        <Select
          dataTestId="select"
          name="someSelect"
          label="Select Array of Objects"
          options={[
            { label: "One", value: "one" },
            { label: "Two", value: "two" },
            { label: "Three", value: "three" },
          ]}
          value={select}
          onChange={(e) => setSelect(e.target.value)}
        />

        <Tags
          id="Tags_tags"
          data-testid="some_testid"
          label="Tags"
          disabled={false}
          tags={tags}
          setTags={setTags}
        />
        <Tags
          id="Tags_tags"
          data-testid="some_testid"
          tagsAsList={true}
          label="Tags"
          disabled={false}
          tags={tags}
          setTags={setTags}
        />
      </Paper>
    </div>
  );
};

export default Form;
