import { evalQuery } from "./index";

export const ProjectService = {
  getAllProjectsByCustomer: (request) =>
    evalQuery({
      url: "/project/get-all-projects-by-customer",
      request,
    }),

  getAllProjectsByFreelancer: (request) =>
    evalQuery({
      url: "/project/get-all-projects-by-freelancer",
      request,
    }),

  getProject: (request) =>
    evalQuery({
      url: "/project/get",
      request,
    }),

  startProject: (request) =>
    evalQuery({
      url: "/project/start-project",
      request,
    }),

  startPOMR: (request) =>
    evalQuery({
      url: "/project/start-pomr",
      request,
    }),

  assignFreelancerToProject: (request) =>
    evalQuery({
      url: "/project/assign-freelancer-to-project",
      request,
    }),


  assignedContact: (request) =>
    evalQuery({
      url: "/project/project-contact",
      request,
    }),

  getStarterProjectUid: (request) =>
    evalQuery({
      url: "/project/get-starter-project-of-customer",
      request,
    }),

  getAssignedProjects: (request) =>
    evalQuery({
      url: "/project/all-assigned-projects",
      request,
    }),

  // for cms
  getCustomersProjects: (request) =>
    evalQuery({
      url: "/project/customers-projects",
      request,
    }),

  getActiveProjectsForUser: (request) =>
    evalQuery({
      url: "/project/get-active-projects-with-tasks",
      request,
    }),

  getRoadmap: (request) =>
    evalQuery({
      url: "/project/project-document",
      request,
    }),

};
