import React, { useState } from "react";

// components
import { Button, ContactModal } from "../../components";

const StillHaveQuestions = () => {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    // <Paper className="mb-8">
    <div className="mt-10">
      <p className="mb-4 text-4xl font-medium text-center text-prime">Still have questions?</p>

      <p className="mb-8 text-2xl font-medium text-center text-prime">
        Can't find the answer you're looking for? Chat with our friendly customer success team.
      </p>

      <Button
        dataTestId="get-in-touch-button"
        label="Chat with our Customer Success Team"
        variant="full"
        themecolor="terc"
        className="block mx-auto mb-2"
        onClick={() => setModalOpened(true)}
      />

      {/* modal contact CSM */}
      {modalOpened && (
        <ContactModal
          setModalOpened={setModalOpened}
          managerObj={{ email: "rolando+csm@cyberpopup.com" }}
        />
      )}
    </div>
    // </Paper>
  );
};

export default StillHaveQuestions;
