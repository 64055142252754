import React, { useState } from "react";
import { isEmpty, isNaN } from "lodash";
import moment from "moment";
import numeral from "numeral";
import InfiniteScroll from "react-infinite-scroll-component";

// components
// import Pagination from './Pagination';
import { Select, Input, Button, Switch } from "../../components";
import { readableDuration } from "../../functions/duration";

const badgeTypes = {
  In_progress: (
    <span className="px-3 py-2 text-xs font-semibold text-black bg-gold rounded-xl">
      In progress
    </span>
  ),
  Hiring_phase: (
    <span className="px-3 py-2 text-xs font-semibold text-white bg-error rounded-xl">
      Hiring phase
    </span>
  ),
  Not_started: (
    <span className="px-3 py-2 text-xs font-semibold text-white bg-grey rounded-xl">
      Not started
    </span>
  ),
  Started: (
    <span className="px-3 py-2 text-xs font-semibold text-white bg-terc rounded-xl dark:bg-blue-200 dark:text-blue-500">
      Started
    </span>
  ),
  Completed: (
    <span className="px-3 py-2 text-xs font-semibold text-white bg-terc rounded-xl dark:bg-blue-200 dark:text-blue-500">
      Completed
    </span>
  ),
  Settled: (
    <span className="px-3 py-2 text-xs font-semibold text-white bg-green-600 rounded-xl dark:bg-blue-200 dark:text-blue-500">
      Settled
    </span>
  ),
  Not_settled: (
    <span className="px-3 py-2 text-xs font-semibold text-white bg-red-500 rounded-xl dark:bg-blue-200 dark:text-blue-500">
      Not settled
    </span>
  ),
};

const EnhancedTable = ({
  // showTableTop,
  tableColumns,
  // pagination,
  hasMore,
  tableRows,
  loader,
  // openFreelancerModal,
  selectOptions,
  setOptions,
  tableHeight,
  freelanceOptions,
  showProjectQuestions,
  showFinalDeliverable,
  overlayLoader,
  rowClickable = true,
  noInfiniteScroll = false, // if the whole data are getting at once
  fetchMore = () => {},
  handleLinkClick = () => {},
  handleTableDataModalClick = () => {}, // do not use this and 'handleRowClick' in the same time
  handleRowClick = () => {},
}) => {
  // const [offset, setOffset] = useState(0); // pagination
  // const [search, setSearch] = useState(''); // search value
  // const [limit, setLimit] = useState(''); // pagination
  // const [count, setCount] = useState(''); // pagination
  const [sort, setSort] = useState("date_submitted"); // column variable name
  const [sortDirection, setSortDirection] = useState("asc"); // 'asc' or 'desc'

  // Sorting
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = sort === property && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSort(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const getComparator = (order, orderBy) =>
    order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  return (
    <>
      <div className="relative overflow-x-auto">
        <InfiniteScroll
          dataLength={tableRows.length}
          scrollThreshold={1}
          height={tableHeight || 550}
          hasMore={!hasMore}
          next={fetchMore}
          endMessage={
            <p className="my-4 text-center text-prime-light">
              {!loader && isEmpty(tableRows) ? "No data" : ""}
              {!loader && !isEmpty(tableRows) ? "End" : ""}
            </p>
          }
          loader={
            !noInfiniteScroll && (
              <div className="w-full my-3 text-center text-prime-light">Loading. . .</div>
            )
          }
        >
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-sm text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="sticky top-0 left-0 bg-gray-100">
                {tableColumns.map((headCell) => (
                  <th
                    key={headCell.id}
                    onClick={createSortHandler(headCell.id)}
                    scope="col"
                    className="relative px-6 py-3 font-normal text-gray-400 cursor-pointer"
                  >
                    <div className={headCell.headerStyle || "text-left"}>
                      <div>{headCell.label}</div>
                      {sort === headCell.id ? (
                        sortDirection === "desc" ? (
                          <i className="absolute right-0 ml-2 top-[50%] translate-y-[-50%] fas fa-angle-down" />
                        ) : (
                          <i className="absolute right-0 ml-2 top-[50%] translate-y-[-50%] fas fa-angle-up" />
                        )
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!loader &&
                !isEmpty(tableRows) &&
                stableSort(tableRows, getComparator(sortDirection, sort)).map((item, i) => (
                  <tr
                    data-testid={`${item["data-testid"]}_${i}`}
                    // key={item.id}
                    key={`${item.id}_${i}`}
                    tabIndex={-1}
                    className={`bg-white border-b-2 odd:bg-white even:bg-gray-50 dark:bg-gray-800 dark:border-gray-700 hover:opacity-70 ${
                      rowClickable ? "cursor-pointer" : ""
                    }`}
                    onClick={() => handleRowClick(item)}
                  >
                    {tableColumns.map((cell) => {
                      switch (cell.type.toString()) {
                        case "project,customer":
                          return (
                            <th key={cell.id} className="px-6 py-4 text-gray-700">
                              {item[cell.type[0]]} <br />
                              <p className={`text-gray-400 ${cell.style || ""}`}>
                                {item[cell.type[1]]}
                              </p>
                            </th>
                          );
                        case "date":
                          return (
                            <td key={cell.id} className={`px-6 py-4 ${cell.style || ""}`}>
                              {moment(item[cell.id]).format("ll")}
                            </td>
                          );
                        case "date-delta":
                          return (
                            <td
                              key={cell.id}
                              className={`px-6 py-4 text-right ${cell.style || ""}`}
                            >
                              {readableDuration(item[cell.id])}
                            </td>
                          );
                        case "number":
                          return (
                            <td
                              key={cell.id}
                              className={`px-6 py-4 text-right ${cell.style || ""}`}
                            >
                              {item[cell.id] || item[cell.id] === 0
                                ? numeral(item[cell.id]).format(cell.format || "0,0.00")
                                : ""}
                            </td>
                          );
                        case "currency":
                          return (
                            <td key={cell.id} className="px-6 py-4 text-right">
                              {!isEmpty(item[cell.id]) && !isNaN(item[cell.id])
                                ? Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  }).format(item[cell.id])
                                : "N/A"}
                            </td>
                          );

                        case "currency100":
                          return (
                            <td key={cell.id} className="px-6 py-4 text-right">
                              {!isEmpty(item[cell.id]) && !isNaN(item[cell.id])
                                ? Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  }).format(item[cell.id] / 100)
                                : "N/A"}
                            </td>
                          );
                        case "badge":
                          return (
                            <td key={cell.id} className={`px-6 py-4 ${cell.style || ""}`}>
                              {badgeTypes[item[cell.id]]}
                            </td>
                          );
                        case "label":
                          return (
                            <td key={cell.id} className={`px-6 py-4 ${cell.style || ""}`}>
                              {badgeTypes[item[cell.id]]}
                            </td>
                          );
                        case "string with image":
                          return (
                            <td
                              key={cell.id}
                              className={`flex items-center px-6 py-4 ${cell.style || ""}`}
                            >
                              <div className="w-[35px] h-[35px] grid place-content-center rounded-full overflow-hidden bg-prime">
                                {item.image ? (
                                  <img alt="profile_image" src={item["image"]} width="100%" />
                                ) : (
                                  <i className="text-white fa-solid fa-user text-md" />
                                )}
                              </div>
                              <div className="ml-4">{item[cell.id]}</div>
                            </td>
                          );

                        case "td-modal":
                          return (
                            <td
                              key={cell.id}
                              className={`px-6 py-4 ${cell.style || ""}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleTableDataModalClick(item, cell);
                              }}
                            >
                              {item[cell.id]}
                            </td>
                          );

                        case "td-modal-additional":
                          return (
                            <>
                              <td
                                key={cell.id}
                                className={`px-6 py-4 ${cell.style || ""}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleTableDataModalClick(item, cell);
                                }}
                              >
                                {item[cell.id]}
                                {item[cell.additional] && (
                                  <>
                                    <br />
                                    <span className="text-xs italic font-extralight text-prime-light">
                                      {item[cell.additional]}
                                    </span>
                                  </>
                                )}
                              </td>
                            </>
                          );

                        case "td-select":
                          return (
                            <td key={cell.id} className={`px-6 py-2 ${cell.style || ""}`}>
                              {/* {item[cell.id]} */}
                              <Select
                                options={selectOptions}
                                keyForOptionValue="csm_id"
                                keyForOptionLabel="csm_first_name"
                                value={item[cell.id]}
                                className="mb-0"
                                onChange={(e) =>
                                  setOptions(
                                    item.project_uid,
                                    item.freelance_assigned_uid,
                                    // item.csm_assigned_uid
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          );

                        case "freelancer":
                          return (
                            <td key={cell.id} className={`px-6 py-2 ${cell.style || ""}`}>
                              <Select
                                dataTestId="freelance_assigned"
                                options={freelanceOptions}
                                keyForOptionValue="freelancer_uid"
                                keyForOptionLabel="freelancer_full_name"
                                value={item[cell.id]}
                                className="mb-0"
                                onChange={(e) =>
                                  setOptions(
                                    item.project_uid,
                                    e.target.value,
                                    item.csm_assigned_uid
                                  )
                                }
                              />
                            </td>
                          );

                        case "url_link":
                          return (
                            <td
                              key={cell.id}
                              className={`px-6 py-2 relative ${cell.style || ""}`}
                              onClick={(e) => handleLinkClick(e, i, cell, (cell.index = i))}
                              onContextMenu={(e) => handleLinkClick(e, i, cell, (cell.index = i))}
                            >
                              <a
                                href={item[cell.id] ? item[cell.id] : "#"}
                                target={item[cell.id] ? "_blank" : "_self"}
                                rel="noreferrer"
                              >
                                {item[cell.id] ? "Link" : "Add Link"}
                              </a>
                              {showProjectQuestions && cell.index === i && (
                                <div>
                                  <Input
                                    className="mb-0"
                                    inputClassName="text-sm"
                                    dataTestId="url_input"
                                    type="text"
                                    placeholder="Add a Link https://"
                                    value={item[cell.id]}
                                    onChange={(e) => console.log(e.target.value)}
                                  />
                                </div>
                              )}
                            </td>
                          );

                        case "switch":
                          return (
                            <td key={cell.id} className={`px-6 py-2 ${cell.style || ""}`}>
                              <Switch
                                dataTestId="toggle_switch"
                                checked={item.active}
                                onChange={(e) => {
                                  item.switch_action(e, i, item["id"]);
                                }}
                              />
                            </td>
                          );

                        case "final_deliverable":
                          return (
                            <td
                              key={cell.id}
                              className={`px-6 py-2 relative ${cell.style || ""}`}
                              onClick={(e) => handleLinkClick(e, i, cell, (cell.index = i))}
                              onContextMenu={(e) => handleLinkClick(e, i, cell, (cell.index = i))}
                            >
                              <a
                                href={item[cell.id] ? item[cell.id] : "#"}
                                target={item[cell.id] ? "_blank" : "_self"}
                                rel="noreferrer"
                              >
                                {item[cell.id] ? "Link" : "Add Link"}
                              </a>
                              {showFinalDeliverable && cell.index === i && (
                                <div>
                                  <Input
                                    className="mb-0"
                                    inputClassName="text-sm"
                                    dataTestId="url_input"
                                    type="text"
                                    placeholder="Add a Link https://"
                                    value={item[cell.id]}
                                    onChange={(e) => console.log(e.target.value)}
                                  />
                                </div>
                              )}
                            </td>
                          );

                        case "actions":
                          return (
                            <td
                              key={cell.id}
                              className={`px-6 py-4 flex flex-no-wrap justify-end items-center h-full ${
                                cell.style || ""
                              }`}
                            >
                              {item.action_buttons.map((button, index) => {
                                if (button.type === "switch") {
                                  return (
                                    <Switch
                                      key={index}
                                      className="mb-0"
                                      checked={button.itemKey}
                                      onChange={button.action}
                                    />
                                  );
                                } else {
                                  return (
                                    <Button
                                      key={index}
                                      dataTestId={button.dataTestId}
                                      label={
                                        button.icon ? <i className={button.icon} /> : button.label
                                      }
                                      variant={button.variant || "icon-flat"}
                                      themecolor={button.themecolor || "prime"}
                                      width="w-10"
                                      height="h-10"
                                      textSize="text-lg"
                                      className={button.className || ""}
                                      disabled={button?.disabled || false}
                                      onClick={(e) => button.action(e, i, cell)}
                                    />
                                  );
                                }
                              })}
                            </td>
                          );

                        default:
                          return (
                            <td key={cell.id} className={`px-6 py-4 ${cell.style || ""}`}>
                              {item[cell.id]}
                            </td>
                          );
                      }
                    })}
                  </tr>
                ))}
              {/* overlayLoader */}
              {overlayLoader && (
                <>
                  <div className="absolute w-[100%] flex items-center opacity-[0.6] justify-center h-[100%] top-0 left-0 bg-gray-300" />
                  <div className="absolute w-[100%] flex justify-center h-[100%] items-center top-0 left-0">
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </>
              )}
            </tbody>
          </table>

          {/* if the whole data are getting at once (loader in Infinite Scroll will not work)*/}
          {!!loader && !!noInfiniteScroll && (
            <div className="w-full my-3 text-center text-prime-light">Loading . . .</div>
          )}
        </InfiniteScroll>

        {/* {pagination && <Pagination />} */}
      </div>
    </>
  );
};

export default EnhancedTable;
