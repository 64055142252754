import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

// TODO: Set up preferred language in the Location component

// context
import { useAuth } from "../../context/AuthContext";

const NavFreelancer = ({ tab, setTab, completion, profile = {} }) => {
  // router
  let navigate = useNavigate();

  // context
  const { authContext } = useAuth();

  const navigation = useMemo(
    () => [
      {
        label: "Cyber-expert status",
        completion: profile?.application_status === "Incomplete",
      },
      {
        label: "My Account",
        completion: completion?.account,
      },
      {
        label: "My Profile",
        completion: completion?.profile,
      },
      {
        label: "Work Availability",
        completion: completion?.work_availability,
      },
      {
        label: "Diversity ID",
      },
      {
        label: "Payment Details",
      },
      {
        label: "What to expect video",
        action: () => navigate("/what-to-expect-video"),
      },
      // {
      //   label: 'Onboarding video',
      //   action: () => {
      //     navigate(`/onboarding-video/${authContext?.role}`)
      //   },
      // },
    ],
    [completion?.account, completion?.profile, completion?.work_availability, navigate]
  );

  return (
    <div className="flex flex-col mt-8">
      {navigation.map((nav, i) => (
        <div className="relative flex items-center justify-between mb-2" key={i}>
          <button
            type="button"
            key={i}
            data-testid={`nav-${nav.label.replace(" ", "-")}`}
            className={` min-w-[240px] relative text-xl pl-4  text-left after:w-[2px] after:h-[100%] after:absolute after:top-0 after:left-0 after:leading-7 duration-300 after:opacity-0 hover:after:opacity-100 hover:pl-6 after:duration-300 ${
              tab === nav.label ? "after:opacity-100" : ""
            } ${nav?.disabled ? "text-prime-light" : "hover:text-prime after:bg-prime"}`}
            disabled={nav?.disabled}
            onClick={() => {
              if (nav.action) {
                nav.action();
              } else {
                setTab(nav.label);
              }
            }}
          >
            {nav.label}
          </button>

          {nav.completion || nav.completion === 0 ? (
            profile.application_status === "Incomplete" ? (
              nav.completion === 100 ? (
                <i className="absolute right-0 mr-1 text-green-500 fas fa-check text-md" />
              ) : (
                <i className="absolute right-0 fa-solid fa-circle-exclamation text-warning text-md" />
              )
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {/* show exclamation when Work Availability switch is off */}
          {nav?.label === "Work Availability" &&
            profile?.work_availability &&
            JSON.parse(profile.work_availability)?.available === false && (
              <i className="absolute right-0 fa-solid fa-circle-exclamation text-warning text-md" />
            )}
        </div>
      ))}

      {(authContext?.role === "Expert" || profile?.application_status === "Enthusiast") && (
        <div className="relative flex items-center justify-between mb-2">
          <button
            type="button"
            data-testid="on-boarding-video"
            className={` min-w-[240px] relative text-xl pl-4 hover:text-prime text-left after:w-[2px] after:h-[100%] after:bg-prime after:absolute after:top-0 after:left-0 after:leading-7 duration-300 after:opacity-0 hover:after:opacity-100 hover:pl-6 after:duration-300 ${
              tab === "Onboarding video" ? "after:opacity-100" : ""
            }`}
            onClick={() =>
              authContext?.role === "Expert"
                ? navigate("/onboarding-video/Expert")
                : navigate("/onboarding-video/Enthusiast")
            }
          >
            Onboarding video
          </button>
        </div>
      )}
    </div>
  );
};

export default NavFreelancer;
