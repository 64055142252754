import React, { forwardRef, useEffect } from "react";
import { isObject } from "lodash";

const Select = forwardRef(
  (
    {
      dataTestId,
      name,
      label,
      placeholder = "",
      options,
      keyForOptionValue = "option.value",
      keyForOptionLabel = "option.label",
      firstEmpty = true,
      value,
      onChange,
      className = "mb-4",
      selectClassName = "",
      optionsClassName = "",
      disabled = false,
      ...rest
    },
    ref
  ) => {
    useEffect(() => {
      // console.log('keyForOptionValue:', keyForOptionValue);
      // console.log('keyForOptionLabel:', keyForOptionLabel);
      // console.log('placeholder:', placeholder);
    }, []);

    return (
      <div className={className}>
        <label>
          {label && <span className="block text-prime-light">{label}</span>}

          <div className="relative inline-block w-full">
            <select
              data-testid={dataTestId}
              ref={ref}
              name={name}
              disabled={disabled}
              placeholder={placeholder || "-- Select --"}
              className={`block appearance-none w-full h-[2.6rem] bg-gray-50 border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded-md leading-tight focus:outline-none focus:shadow-outline ${selectClassName}`}
              value={value || ""}
              onChange={onChange}
              // onChange={e => console.log('e:', e.target.value)}
              {...rest}
            >
              {firstEmpty && (
                <option value="" className="text-prime-light">
                  {placeholder || "-- Select --"}
                </option>
              )}

              {options?.map((option, i) =>
                isObject(option) ? (
                  <option
                    key={`${option[keyForOptionValue]}_${i}`}
                    value={option[keyForOptionValue]}
                    className={`${optionsClassName}`}
                  >
                    {option[keyForOptionLabel]}
                  </option>
                ) : (
                  <option key={`${option}_${i}`} value={option} className={`${optionsClassName}`}>
                    {option}
                  </option>
                )
              )}
            </select>
          </div>
        </label>
      </div>
    );
  }
);

export default Select;
