import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// components
import Logo from "../../assets/img/logo/logo.svg";
import { useAuth } from "../../context";
// import LogoWhite from "../../assets/img/logo/logo-white.svg";
import { Button } from "../index";
import ModalContactSupport from "./ModalContactSupport";
import Navigation from "./Navigation";
import UserButton from "./UserButton";
// logos
// import ContactSupport from '../../assets/img/help-icon.png';
// import LogoForEmail from '../../assets/img/logo/logo.png';

// context

const Paper = () => {
  // router
  let navigate = useNavigate();
  let location = useLocation();

  // context
  const { authContext } = useAuth();

  // local
  const [modalContactSupport, setModalContactSupport] = useState(false);

  // show or not links and user button
  const onBoardingFinished = () => {
    if (
      location.pathname.includes("questionnaire") ||
      location.pathname.includes("risk-rating") ||
      location.pathname.includes("payment/") ||
      location.pathname.includes("order-review") ||
      location.pathname.includes("dashboard-tour") ||
      location.pathname.includes("video-interview") ||
      // location.pathname.includes('what-to-expect-video') ||
      location.pathname.includes("onboarding-expert")
    ) {
      return false;
    }
    return true;
  };

  const setByRole = () => {
    if (authContext?.role === "Fsm") {
      return false;
    }
    return true;
  };

  if (!authContext?.role && !authContext?.user) return null;

  return (
    <header
      className={`flex justify-center min-w-full ${
        authContext?.token ? "bg-white" : "bg-white"
      } shadow-md`}
    >
      <div className="flex w-full min-h-full my-auto max-w-page">
        {/* header left */}
        <div className="flex items-center h-full px-2">
          {/* If customer redirect to NextJS route else regular route */}
          <a href={authContext.role === "Customer" ? "/app/home/" : "/"}>
            <img
              // src={authContext?.token ? 'logo/logo-white.svg' : 'logo/logo.svg'}
              src={authContext?.token ? Logo : Logo}
              alt="logo"
              className={`h-16 my-2 max-w-none ${authContext?.token ? "" : "cursor-pointer"}`}
            />
          </a>
        </div>

        {/* header center */}
        <div className="flex-1 place-self-center">
          {onBoardingFinished() && setByRole() && <Navigation />}
        </div>

        {/* header right */}
        <div className="flex items-center px-2">
          {/* {!authContext?.token && (
            <div className="flex">
              <Button
                dataTestId="header-signup"
                label="Join as Cyber-expert"
                variant="full"
                className="mr-4"
                themecolor="prime"
                textColor="white"
                onClick={() => navigate("/signup")}
              />
              <Button
                dataTestId="header-signin"
                label="Log In"
                variant="flat"
                // themecolor="prime-light"
                textColor="prime"
                onClick={() => navigate("/signin")}
              />
            </div>
          )} */}

          <Button
            dataTestId="contact-support-btn"
            label={
              // <img
              //   src={ContactSupport}
              //   alt="logo"
              //   className="h-[inherit] w-[inherit]"
              // />
              <i className="text-2xl fa-regular fa-circle-question" />
            }
            variant="icon-flat"
            themecolor="prime"
            className="px-2 mx-2"
            width="w-10"
            height="h-10"
            textSize="text-xl"
            onClick={() => setModalContactSupport(true)}
          />

          {authContext?.role !== "Fsm" && authContext?.role !== "Csm" && onBoardingFinished() && (
            <UserButton />
          )}

          {(authContext?.role === "Fsm" || authContext?.role === "Csm") && (
            <Button
              data-testid="logout-btn"
              label={<i className="fa-solid fa-arrow-right-from-bracket" />}
              variant="icon-flat"
              themecolor="prime"
              width="w-10"
              height="h-10"
              textSize="text-xl"
              // className="text-prime-contrast"
              onClick={() => navigate("/signout")}
            />
          )}
        </div>
      </div>

      {/* modalContactSupport */}
      {modalContactSupport && <ModalContactSupport setModalOpened={setModalContactSupport} />}
    </header>
  );
};

export default Paper;
