import React from "react";

// components
import { Typography } from "../../components";

const FreelancerProjectTerms = () => (
  <div>
    <Typography type="title" className="mb-8 text-center">
      Project Terms
    </Typography>

    <p className="mb-4">
      THIS CYBER POP-UP FREELANCER PROJECT AGREEMENT (“AGREEMENT”) IS ENTERED INTO BY AND BETWEEN
      YOU (“FREELANCER”) AND CYBER POP-UP LLC ("CYBER POP-UP”), INDIVIDUALLY THE “PARTY” AND
      COLLECTIVELY “THE PARTIES”. FOR THE FOLLOWING PROJECT, THE PARTIES AGREE TO THE FOLLOWING AS
      SET FORTH BELOW, AND INCORPORATES BY REFERENCE THE TERMS OF SERVICE.
    </p>

    <p className="mb-4">
      IN CONSIDERATION OF the matters described above and of the mutual benefits and obligations set
      forth in this Agreement, the receipt and sufficiency of which consideration is hereby
      acknowledged, the Cyber Pop-up and the Cyber-expert (individually the "Party" and collectively
      the "Parties" to this Agreement) agree as follows:
    </p>
    <p className="font-bold">BACKGROUND</p>
    <ul className="mb-4 ml-6 list-decimal">
      <li>
        <p className="font-bold">Definitions:</p>
        <ul className="mb-4 ml-6 list-decimal">
          <li>
            “Cyber Pop-up” is defined as the party requesting a project from the Freelancer, on
            behalf of the Client.
          </li>
          <li>The “Client" is the party who is requesting Services from Cyber Pop-up.</li>
          <li>
            “Services” are Cybersecurity consulting Services, which are delivered using the Cyber
            Pop-up network of third-party sub-contractors in the form of crowd-sourced Cybersecurity
            practitioners ("Freelancer").
          </li>
        </ul>
      </li>
      <li>
        Cyber Pop-up is of the opinion that the Cyber-expert has the necessary qualifications,
        experience and abilities to provide consulting services to the Client(s), assuming that all
        information submitted by the Cyber-expert during the application process is accurate and true.
      </li>
      <li>
        The Cyber-expert is agreeable to providing the consulting services to the Cyber Pop-up
        included on the terms and conditions set forth in this Agreement, and attests that all
        information submitted during the application and verification process is accurate and
        maintained throughout the life of this Agreement.{" "}
      </li>
    </ul>

    <p className="font-bold">SERVICES PROVIDED</p>
    <p>
      Cyber Pop-up hereby agrees to engage the Cyber-expert to provide Cyber Pop-up and its Clients
      with the following Cybersecurity consulting services (the “Services"):
    </p>
    <ul className="mb-4 ml-6 list-decimal">
      <li>
        Services will include analyzing Client’s Cybersecurity needs and current business obstacles
        as requested, identifying short-term projects required, and supporting delivery of those
        projects.
      </li>
      <li>
        For any projects completed under Services, a detailed statement of work must be documented
        and mutually agreed upon by Parties before a Cyber-expert begins work.
      </li>
      <li>
        The Services will also include any other consulting tasks mutually agreed upon by the
        Parties. The Cyber-expert hereby agrees to provide such Services to Cyber Pop-up and Cyber
        Pop-up Client(s).
      </li>
    </ul>

    <p className="font-bold">TERM OF AGREEMENT; TERMINATION</p>
    <ul className="mb-4 ml-6 list-decimal">
      <li>
        The Term of this Agreement (the "Term") will begin on the date the Service is requested by
        the Cyber Pop-up and will remain in full force and effect indefinitely, or until this
        Agreement is terminated by either Party for any reason.
      </li>
      <li>
        Either Party may terminate this Agreement at any time, for any reason; however the
        terminating Party must provide 10 days written notice to the other Party.
      </li>
      <li>Except as otherwise provided in this Agreement, the obligations of the Consultant</li>
      <li>
        Except as otherwise provided in this Agreement, the obligations of the Consultant will end
        upon the termination of this Agreement.
      </li>
      <li>
        In the event that the Cyber-expert breaches a material provision under this Agreement, Cyber
        Pop-up may terminate this Agreement immediately and require the Cyber-expert to indemnify
        Cyber Pop-up against all reasonable damages.
      </li>
      <li>
        Except as otherwise provided in this Agreement, the obligations of the Cyber-expert will end
        upon the termination of this Agreement.
      </li>
    </ul>

    <p className="font-bold">PERFORMANCE</p>
    <p className="mb-4">
      The Parties agree to do everything necessary to ensure that the terms of this Agreement take
      effect.
    </p>

    <p className="font-bold">CURRENCY</p>
    <p className="mb-4">
      Except as otherwise provided in this Agreement, all monetary amounts referred to in this
      Agreement are in USD (US Dollars).
    </p>

    <p className="font-bold">COMPENSATION</p>
    <p>Cyber Pop-up will pay the Cyber-expert for the Services as follows (the "Compensation"):</p>
    <ul className="mb-4 ml-6 list-disc">
      <li>
        Upon project completion and acceptance by the Client, the Cyber-expert will be paid, at a rate
        agreed upon by the Parties prior to the start of Services.
      </li>
    </ul>

    <p className="font-bold">REIMBURSEMENT OF EXPENSES</p>
    <ul className="mb-4 ml-6 list-decimal">
      <li>
        The Cyber-expert will be reimbursed from time to time for reasonable and necessary expenses
        incurred by the Cyber-expert in connection with providing the Services.
      </li>
      <li>
        All expenses must be pre-approved by the Client, and Cyber-expert must provide an itemized
        invoice to Cyber Pop-Up in order to be reimbursed.
      </li>
    </ul>

    <p className="font-bold">CONFIDENTIALITY</p>
    <ul className="mb-4 ml-6 list-decimal">
      <li>
        Confidential information (the "Confidential Information") refers to any data or information
        relating to the business of Cyber Pop-up or its Clients which would reasonably be considered
        to be proprietary to Cyber Pop-up or its Clients including, but not limited to, accounting
        records, business processes, and records, and that is not generally known in the industry of
        Cyber Pop-up or its Clients and where the release of that Confidential Information could
        reasonably be expected to cause harm to Cyber Pop-up or its Clients.
      </li>
      <li>
        The Cyber-expert agrees that they will not disclose, divulge, reveal, report or use, for any
        purpose, any Confidential Information which the Cyber-expert has obtained, except as
        authorized by Cyber Pop-up or its Clients or as required by law. The obligations of
        confidentiality will apply during the Term and will survive indefinitely upon termination of
        this Agreement.
      </li>
      <li>
        All written and oral information and material disclosed or provided by Cyber Pop-up or its
        Clients to the Cyber-expert under this Agreement is Confidential Information regardless of
        whether it was provided before or after the date of this Agreement or how it was provided to
        the Freelancer.
      </li>
    </ul>

    <p className="font-bold">OWNERSHIP OF INTELLECTUAL PROPERTY</p>
    <ul className="mb-4 ml-6 list-decimal">
      <li>
        All intellectual property and related material, including any trade secrets, moral rights,
        goodwill, relevant registrations or applications for registration, and rights in any patent,
        copyright, trademark, trade dress, industrial design and trade name (the "Intellectual
        Property") that is developed or produced under this Agreement, is a "work made for hire" and
        will be the sole property of Cyber Pop-up or its Clients, as outlined in the Client Project
        Terms. The use of the Intellectual Property by Cyber Pop-up or its Clients will not be
        restricted in any manner, unless otherwise agreed upon by the Parties.
      </li>
      <li>
        The Cyber-expert may not use the Intellectual Property for any purpose other than that
        contracted for in this Agreement except with the written consent of Cyber Pop-up or it’s
        Clients. The Party at fault will be responsible for any damages resulting from the
        unauthorized use of the Intellectual Property.
      </li>
    </ul>

    <p className="font-bold">RETURN OF PROPERTY</p>
    <p className="mb-4">
      Upon the expiration or termination of this Agreement, the Cyber-expert will return to the Cyber
      Pop-up or its Clients any property, documentation, records, or Confidential Information which
      is the property of the Cyber Pop-up or its Clients.
    </p>

    <p className="font-bold">CAPACITY/INDEPENDENT CONTRACTOR</p>
    <p className="mb-4">
      By providing the Services under this Agreement it is expressly agreed that the Cyber-expert is
      acting as an independent contractor and not as an employee. The Cyber-expert and the Cyber
      Pop-up acknowledge that this Agreement does not create a partnership or joint venture between
      them, and is exclusively a contract for service. Cyber Pop-up is not required to pay, or make
      any contributions to, any social security, local, state or federal tax, unemployment
      compensation, workers' compensation, insurance premium, profit-sharing, pension or any other
      employee benefit for the Cyber-expert during the Term. The Cyber-expert is responsible for paying,
      and complying with reporting requirements for, all local, state and federal taxes related to
      payments made to the Cyber-expert under this Agreement.
    </p>

    <p className="font-bold">NO RIGHT OF SUBSTITUTION</p>
    <p className="mb-4">
      The Cyber-expert is prohibited from transferring any assignments to another party, without
      approval in writing from Cyber Pop-up, and will not hire or engage any third parties to assist
      with the provision of the Services.
    </p>

    <p className="font-bold">AUTONOMY</p>
    <p className="mb-4">
      Except as otherwise provided in this Agreement, the Cyber-expert will have full control over
      working time, methods, and decision making in relation to provision of the Services in
      accordance with the Agreement. However, the Cyber-expert must be responsive to the reasonable
      needs and concerns of Cyber Pop-up and its Clients.
    </p>

    <p className="font-bold">EQUIPMENT</p>
    <p className="mb-4">
      Except as otherwise provided in this Agreement, the Cyber-expert is responsible for ensuring
      they have the necessary tools, equipment, software, materials and any other supplies necessary
      to deliver the Services in accordance with the Agreement. When applicable and agreed upon
      prior to start of Services, the Client will provide at the Client’s own expense, any and all
      specialized or proprietary tools or access required to deliver Services.
    </p>

    <p className="font-bold">EXCLUSIVITY</p>
    <p className="mb-4">
      The Parties acknowledge that this Agreement is non-exclusive and that either Party will be
      free, during and after the Term, to engage or contract with third parties for the provision of
      services similar to the Services. However, if the Cyber-expert and the Client are connected
      through Cyber Pop-up, both parties are prohibited from conducting direct transactions with
      each other for Cyber-expert security services outside of Cyber Pop-up for up to two years after
      the initial connection. Freelancers are allowed to be hired full-time with Clients, in which
      case direct work is allowed.
    </p>

    <p className="font-bold">NOTICE</p>
    <p>
      Each party consents to the other party sending notices, requests or other communication
      required or permitted by the terms of this Agreement via e-mail at the following addresses: of
      an informational, advertisement or technical nature via e-mail and will be deemed to be
      properly delivered at the time the e-mail is sent:
    </p>
    <ul className="mb-4 ml-6 list-decimal">
      <li>
        <p>Freelancer</p>
        <p>The email address submitted with the Service request.</p>
      </li>
      <li>
        <p>Cyber Pop-up</p>
        <p>info@cyberpopup.com</p>
      </li>
    </ul>

    <p className="font-bold">INDEMNIFICATION/LIMITATION OF LIABILITY</p>
    <ul className="mb-4 ml-6 list-decimal">
      <li>
        Except to the extent paid in settlement from any applicable insurance policies, and to the
        extent permitted by applicable law, the Cyber-expert agrees to indemnify and hold harmless
        Cyber Pop-up, and its respective directors, shareholders, affiliates, officers, agents,
        employees, and permitted successors and assigns against any and all claims, losses, damages,
        liabilities, penalties, punitive damages, expenses, reasonable legal fees and costs of any
        kind or amount whatsoever, which result from or arise out of any act or omission of the
        Freelancer, its respective directors, shareholders, affiliates, officers, agents, employees,
        and permitted successors and assigns that occurs in connection with this Agreement. This
        indemnification will survive the termination of this Agreement.
      </li>
      <li>
        The Party requiring protection against any claim or damages that they may suffer while
        participating in the activity is the (“Indemnified Party"). The Party who promises to
        reimburse the Indemnified Party for any claim or damages that the Indemnified Party may
        suffer while participating in the activity is the (“Indemnifying Party").
      </li>
      <li>
        The Parties will not be entitled to indemnification for any expenses, judgments, fines,
        settlements and other amounts incurred as the result of their own participation in an
        activity where:
      </li>
      <li>
        In the case of a civil claim, the Party did not act in good faith and in a reasonable
        manner; the actions or conduct of the Party constituted willful misconduct or was knowingly
        fraudulent or deliberately dishonest; the Indemnified Party will or has received payment
        under their insurance policy or under a valid and enforceable indemnity clause, bylaw or
        agreement, except where payment under this insurance policy, clause, bylaw or agreement is
        not sufficient to fully indemnify the Party in which case the Indemnifying Party will be
        responsible for any shortfall in payment received, with a liability limitation of one
        million dollars; or an action or proceeding was initiated in whole or in part by the
        Indemnified Party whether alone or along with one or more other claimants unless the action
        or proceeding has the written consent of the Indemnifying Party.{" "}
      </li>
      <li>
        Cyber Pop-Up's liability to Cyber-expert shall not exceed 1 (one) times the amount of total
        money due to Cyber-expert for Services.
      </li>
    </ul>

    <p className="font-bold">TIME OF THE ESSENCE</p>
    <p className="mb-4">
      Time is of the essence in this Agreement. No extension or variation of this Agreement will
      operate as a waiver of this provision.
    </p>

    <p className="font-bold">ENTIRE AGREEMENT</p>
    <p className="mb-4">
      It is agreed that there is no representation, warranty, collateral agreement or condition
      affecting this Agreement except as expressly provided in this Agreement.
    </p>

    <p className="font-bold">ENUREMENT</p>
    <p className="mb-4">
      This Agreement will enure to the benefit of and be binding on the Parties and their respective
      heirs, executors, administrators and permitted successors and assigns.
    </p>

    <p className="font-bold">TITLES/HEADINGS</p>
    <p className="mb-4">
      Headings are inserted for the convenience of the Parties only and are not to be considered
      when interpreting this Agreement.
    </p>

    <p className="font-bold">GENDER</p>
    <p className="mb-4">
      Words in the singular mean and include the plural and vice versa. Words in the masculine mean
      and include the feminine and vice versa.
    </p>

    <p className="font-bold">GOVERNING LAW</p>
    <p className="mb-4">
      This Agreement will be governed by and construed in accordance with the laws of the State of
      Illinois.
    </p>

    <p className="font-bold">SEVERABILITY</p>
    <p className="mb-4">
      In the event that any of the provisions of this Agreement are held to be invalid or
      unenforceable in whole or in part, all other provisions will nevertheless continue to be valid
      and enforceable with the invalid or unenforceable parts severed from the remainder of this
      Agreement.
    </p>

    <p className="font-bold">WAIVER</p>
    <p className="mb-8">
      The waiver by either Party of a breach, default, delay or omission of any of the provisions of
      this Agreement by the other Party will not be construed as a waiver of any subsequent breach
      of the same or other provisions.
    </p>
  </div>
);

export default FreelancerProjectTerms;
