const parseDuration = (value, showDays = false) => {
  let _value = value || 0;
  let wholeDays = 0;
  let wholeHours = 0;
  let remainingMinutes = 0;

  if (showDays === true) {
    wholeDays = Math.floor(value / 96);
    _value = _value - wholeDays * 96;
  }
  // console.log('show days if: ', _value, wholeDays);
  // } else if (!!showHours) {
  wholeHours = Math.floor(_value / 4);
  _value = _value - wholeHours * 4;
  // console.log('show hours if: ', _value, wholeHours);
  // } else if (!!showMinutes) {
  remainingMinutes = _value;
  // console.log('show minutes if: ', _value, remainingMinutes);
  // }

  return [wholeDays, wholeHours, remainingMinutes];
};

const readableDuration = (value, format = "full") => {
  let displayDays = "";
  let displayHours = "";
  let displayMinutes = "";

  const [wholeDays, wholeHours, remainingMinutes] = parseDuration(value, true);

  if (wholeDays !== 0 && wholeDays !== "0" && wholeDays !== undefined && wholeDays !== null) {
    if (wholeDays === 1) {
      displayDays = `${wholeDays}${format === "full" ? " day" : "d"}`;
    } else {
      displayDays = `${wholeDays}${format === "full" ? " days" : "d"}`;
    }
  }

  if (wholeHours !== 0 && wholeHours !== "0" && wholeHours !== undefined && wholeHours !== null) {
    if (wholeHours === 1) {
      displayHours = `${wholeHours}${format === "full" ? " hour" : "h"}`;
    } else {
      displayHours = `${wholeHours}${format === "full" ? " hours" : "h"}`;
    }
  }

  if (
    remainingMinutes !== 0 &&
    remainingMinutes !== "0" &&
    remainingMinutes !== undefined &&
    remainingMinutes !== null
  ) {
    displayMinutes = `${remainingMinutes * 15}${format === "full" ? " minutes" : "m"}`;
  }

  return `${displayDays} ${displayHours} ${displayMinutes}`;
};

export { parseDuration, readableDuration };
