import { useDrop } from "react-dnd";

const Column = ({ children, title }) => {
  const [{ isOverCurrent }, drop] = useDrop({
    accept: "CARD",
    drop: () => {
      if (isOverCurrent) {
        return { targetPhase: title };
      }
    },
    collect: (monitor) => ({
      // isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      // didDrop: monitor.didDrop(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: () => true,
  });

  return (
    <div ref={drop} className="mb-10">
      <p className="mb-3 text-xl text-prime">{title}</p>
      {children}
    </div>
  );
};

export default Column;
