import React, { useState, useEffect } from "react";

import moment from "moment";

import { useAuth, useToast } from "../../context";

// functions
import {
  customerViewAssignedTo,
  taskTemplateAssignedTo,
  freelancerViewAssignedTo,
} from "../../functions/assignedTo";
import { readableDuration } from "../../functions/duration";
import { isVisibleTo } from "../../functions/visibility";

// Services
import {
  errorMsgForUser,
  ProjectService,
  ProjectTemplateService,
  StripeService,
  TaskService,
} from "../../services";

// Components
import { StripeServiceV2 } from "../../services/StripeServiceV2";
import ModalTailwind from "../ModalTailwind";
import { Paper, InputDuration, Button, Modal, Loader, CardSecure } from "../index";
import SimpleAlertWithCancelModal from "../modals-tailwindui/SimpleAlertWithCancelModal";
import UpgradePomrModal from "../project/UpgradePomrModal";
import StatusIcon from "./StatusIcon";

const MessageBadge = ({ task }) => {
  if (task.message_count === 0) return;

  let className =
    "inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset whitespace-nowrap";
  if (task.unread_count > 0) className += " text-red-700 ring-red-600/10 bg-red-50";
  else className += " bg-gray-100 text-gray-600 ring-gray-500/10";

  return (
    <span className={className}>
      {`${task.unread_count ? `${task.unread_count} unread` : `${task.message_count}`} ${
        task.message_count > 1 ? "comments" : "comment"
      }`}
    </span>
  );
};

function TaskTable({
  tableData,
  onRowClick,
  hasPomrPurchased,
  selectedPlan,
  productName,
  currentProject,
  subscribeLoader,
  redirectToCheckout,
  onClickLoader,
  updateList = () => {},
}) {
  const POMR_PROJECT_TEMPLATE_NUMBER = 1;

  const { authContext } = useAuth();
  const { showToast } = useToast();

  const [loader, setLoader] = useState(0);
  const [modalAddTime, setModalAddTime] = useState(null);
  const [modalUpgradeSubscription, setModalUpgradeSubscription] = useState(null);
  const [addTime, setAddTime] = useState("");
  const [upgradeSubModal, setUpgradeSubModal] = useState(false);

  const [pomr, setPomr] = useState(0);
  const [purchasePomrModal, setPurchasePomrModal] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);

  const addTimeOnTask = () => {
    setLoader((l) => l + 1);

    const newTotalTime = modalAddTime?.work_hours_spent + addTime;

    return TaskService.update({
      uid: modalAddTime?.uid,
      project_uid: modalAddTime?.project_uid,
      work_hours_spent: newTotalTime,
    })
      .then(() => {
        updateList(modalAddTime?.project_uid);
        setAddTime("");
        setModalAddTime(null);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const purchasePomr = async () => {
    try {
      setPurchaseLoading(true);

      const { checkout_session_url } = await StripeServiceV2.createCheckoutSessionPomr({
        company_uid: currentProject?.customer_uid,
        project_template_cost: pomr?.cost,
        project_template_uid: pomr?.uid,
        project_uid: currentProject?.uid,
        stripe_success_url: `/dashboard`,
        stripe_cancel_url: `/project/${currentProject?.uid}`,
      });
      window.location.replace(checkout_session_url);
    } catch (error) {
      console.log("error", error);
      showToast("error", errorMsgForUser(error));
    } finally {
      setPurchaseLoading(false);
    }
  };

  useEffect(() => {
    const getPomrPrice = async () => {
      const { project_template } = await ProjectTemplateService.getByTemplateNumber({
        project_template_number: POMR_PROJECT_TEMPLATE_NUMBER,
      });
      setPomr(project_template);
    };

    if (currentProject?.name === "Peace of Mind Roadmap") {
      getPomrPrice();
    }
  }, [currentProject]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalUpgradeSubscription]);

  return (
    <>
      <div className="relative">
        {!hasPomrPurchased &&
          authContext?.role === "Customer" &&
          currentProject?.name === "Peace of Mind Roadmap" && (
            <div className="absolute inset-0 z-10 flex items-center justify-center pointer-events-none">
              <Button
                label="Unlock full Peace of Mind Roadmap"
                variant="icon-full"
                textSize="text-lg"
                className="pointer-events-auto"
                onClick={() => setPurchasePomrModal(true)}
              />
            </div>
          )}

        {/* Table */}
        <table className="min-w-full divide-y divide-gray-200 relative">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Task
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center" />
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                Assigned to
              </th>
              {/* This column is not visible to the customer */}
              {authContext.role !== "Customer" && (
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Visibility
                </th>
              )}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                Due date
              </th>
              {authContext?.role === "Freelancer" && (
                <>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                    Time Budget
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                    Time Spent
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                    Add Time
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 relative">
            {/* IF there is tableData the map each section */}
            {tableData &&
              Object.keys(tableData).map((section) => (
                <>
                  <tr key={section} className="bg-white">
                    <td colSpan="100" className="px-6 py-3 text-lg font-medium">
                      {section}
                    </td>
                  </tr>
                  {/* For each section map the tasks with it */}

                  {tableData[section].map((task) => {
                    let freeTaskClass = "";
                    if (
                      task.tier === "paid" &&
                      !hasPomrPurchased &&
                      authContext.role === "Customer" &&
                      currentProject?.name === "Peace of Mind Roadmap"
                    )
                      freeTaskClass = "blur-sm";
                    return (
                      <tr
                        key={task.uid}
                        className={`${freeTaskClass} text-sm flex-nowrap hover:bg-gray-100 hover:cursor-pointer hover:animate-pulse ${
                          task.isSubtask && authContext.role !== "Customer" ? "italic" : ""
                        }`}
                        onClick={
                          task.tier === "paid" &&
                          !hasPomrPurchased &&
                          authContext.role === "Customer"
                            ? null
                            : () => onRowClick(task)
                        }
                      >
                        {/* Status favicon */}
                        <td
                          className={`px-6 py-4 whitespace-nowrap flex items-center ${
                            task.isSubtask && authContext.role !== "Customer" ? "ml-6" : ""
                          }`}
                        >
                          {task.isSubtask && authContext.role !== "Customer" ? (
                            <i
                              className={`rotate-90 fa-solid fa-arrow-turn-up mr-4 ${
                                task.status === "In Progress" ? "text-success" : "text-prime-light"
                              }`}
                            />
                          ) : (
                            <StatusIcon status={task.status} tier={task.tier} />
                          )}
                          <span className="">{task.name}</span>{" "}
                        </td>
                        <td>
                          <MessageBadge task={task} />
                        </td>
                        {/* Assignibiltiy - Conditioanly render who the task is assigned to */}
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center ">
                          {authContext?.role === "Csm" && taskTemplateAssignedTo(task)}
                          {authContext?.role === "Freelancer" && freelancerViewAssignedTo(task)}
                          {authContext?.role === "Customer" && customerViewAssignedTo(task)}
                        </td>
                        {/* Who the task is visible to */}
                        {/* This column is not visible to the customer */}
                        {authContext.role !== "Customer" && (
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-center ">
                            {isVisibleTo(authContext?.role, task)}
                          </td>
                        )}
                        {/* Task due date */}
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center ">
                          {task.completed_on ? (
                            moment(task.completed_on).format("MMM DD, YYYY")
                          ) : task.estimated_duration ? (
                            <span>
                              {"~" + moment(task.estimated_duration).format("MMM DD, YYYY")}
                            </span>
                          ) : (
                            "--"
                          )}
                        </td>

                        <td />
                        {/* IF a freelanceer - Time estimated */}
                        {authContext?.role === "Freelancer" && (
                          <>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-sm ">
                              {"~" + moment(task.estimated_duration).format("MMM DD, YYYY")}
                            </td>
                            <td className="text-center sm:w-16 text-sm ">
                              {task.work_hours_estimation
                                ? readableDuration(task.work_hours_estimation, "short")
                                : "--"}
                            </td>
                            <td className="text-center sm:w-16 text-sm">
                              {task.work_hours_spent
                                ? readableDuration(task.work_hours_spent, "short")
                                : "--"}
                            </td>
                            <td>
                              <Button
                                dataTestId="add_time_btn"
                                label={<i className="fa-solid fa-plus" />}
                                variant="icon-flat"
                                themecolor="prime"
                                width="w-10"
                                height="h-10"
                                textSize="text-xl"
                                className="block mx-auto"
                                disabled={false}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setModalAddTime(task);
                                }}
                              />
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </>
              ))}
          </tbody>
        </table>
      </div>
      {/* Subscribe button */}

      {modalAddTime && (
        <Modal
          title="Add Time"
          closeModal={() => setModalAddTime(null)}
          buttons={[
            <Button
              dataTestId="close"
              label="Close"
              variant="flat"
              themecolor="prime"
              className="mr-2"
              onClick={() => setModalAddTime(null)}
            />,
            <Button
              dataTestId="modal_add_time_btn"
              label="Add Time"
              variant="full"
              themecolor="prime"
              onClick={addTimeOnTask}
            />,
          ]}
        >
          {/* loader */}
          {!!loader && <Loader themeColor="fill-prime" />}

          <InputDuration showDays={false} value={addTime} onChange={(v) => setAddTime(v)} />
        </Modal>
      )}

      {modalUpgradeSubscription && (
        <Modal
          title="Upgrade subscription"
          className="flex justify-center items-center"
          closeModal={() => setModalUpgradeSubscription(null)}
          buttons={[
            <Button
              dataTestId="close"
              label="Close"
              variant="flat"
              themecolor="prime"
              className="mr-2"
              onClick={() => setModalUpgradeSubscription(null)}
            />,
            <Button
              dataTestId="upgrade-button"
              themecolor="prime"
              className="mr-2"
              width="w-full md:w-[6em]"
              height="h-10"
              onClick={() => redirectToCheckout()}
            >
              {subscribeLoader ? <Loader className="relative w-8 h-8" /> : "Upgrade"}
            </Button>,
          ]}
        >
          <div className="flex justify-center items-center">
            <CardSecure
              key={selectedPlan?.id}
              id={selectedPlan?.id}
              width={330}
              type="medium"
              textSizeSecondary="text-sm"
              currency={selectedPlan?.currency || "USD"}
              amount={
                selectedPlan?.recurring?.interval === "month"
                  ? selectedPlan.unit_amount / 100
                  : selectedPlan.unit_amount / 100 / 12
              }
              topTitle={selectedPlan?.metadata?.top_title || ""}
              title={productName}
              perMonthText={
                selectedPlan?.recurring?.interval === "month"
                  ? "per month"
                  : "per month, billed annually"
              }
              subscription={false}
              planInterval={
                selectedPlan?.recurring?.interval === "month" ? "Monthly" : "Annual Commitment"
              }
              saveText={selectedPlan?.metadata?.save_text}
              peaceOfMindText={selectedPlan?.metadata?.peace_of_mind_text}
              peaceOfMindTextTrial={selectedPlan?.metadata?.peace_of_mind_text_trial}
              extras={selectedPlan?.metadata?.extras}
              btnVariant="full"
              btnActive={selectedPlan?.active}
              featuresTitle={selectedPlan?.metadata?.features_title}
              strechPerks={true}
            />
          </div>
          {/* {selectedPlan.product} */}
        </Modal>
      )}

      {upgradeSubModal && (
        <ModalTailwind
          open={upgradeSubModal}
          setOpen={setUpgradeSubModal}
          selectedPlan={selectedPlan}
          productName={productName}
          redirectToCheckout={redirectToCheckout}
          onClickLoader={onClickLoader}
        />
      )}

      {purchasePomrModal && (
        <UpgradePomrModal
          open={purchasePomrModal}
          setOpen={setPurchasePomrModal}
          pomr={pomr}
          purchaseLoading={purchaseLoading}
          purchasePomr={purchasePomr}
        />
      )}
    </>
  );
}

export default TaskTable;
