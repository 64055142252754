import React, { useEffect, useState } from "react";

const ProgressBar = ({ step, maxStep, className }) => {
  const [progress, setProgress] = useState("0%");

  useEffect(() => {
    // console.log("progress", parseInt((step * 100) / maxStep))
    setProgress(`${parseInt((step * 100) / maxStep)}%`);
  }, [step, maxStep]);

  return (
    <div className="my-auto">

    <div className={`${className && className}`}>
      <div className="relative h-6 m-0 rounded-lg bg-grey-light">
        <div className="h-[100%] my-auto text-right rounded-lg bg-gradient-to-br from-terc to-sec" style={{ width: progress }} />
        <span className="absolute top-0 w-full mx-auto text-center text-white">{step} / {maxStep}</span>
      </div>
    </div>
    </div>
  );
};

export default ProgressBar;
