import { useState, useEffect } from "react";
import { isEmpty, isEqual } from "lodash";

// components
import { Button, Select, Input } from "../../../components";

import { freelancer_questions } from "../../questionnaire/freelancer_questions";

const CertificationsList = ({
  questions,
  certificationList,
  handleChangeValue,
  profileEditsLocked,
}) => {
  const initCertification = {
    certBody: "",
    certName: "",
    certNumber: "",
    certOtherBody: "",
  };
  const [newCertification, setNewCertification] = useState(initCertification);
  const [certification, setCertification] = useState({});

  /**
   * All Certifications
   */
  const allCertifications = freelancer_questions?.certifications.options;

  useEffect(() => {
    if (newCertification.certBody) {
      const certification = allCertifications.find((q) => q.value === newCertification.certBody);
      setCertification(certification);
    }
  }, [allCertifications, newCertification.certBody]);

  return (
    <div className="rounded">
      <div className="mb-4 rounded-md">
        <Select
          dataTestId="certification-body"
          className="mb-2"
          label="Certificate Body"
          options={freelancer_questions.certifications.options || []}
          keyForOptionValue="value"
          keyForOptionLabel="value"
          value={newCertification.certBody}
          onChange={(e) =>
            setNewCertification((n) => ({
              ...n,
              certBody: e.target.value,
              certName: "",
            }))
          }
        />

        {/* if certBody is 'Other */}
        {newCertification.certBody !== "Other" && (
          <Select
            dataTestId="certification-name"
            className="mb-4"
            label="Certificate Name"
            firstEmpty={true}
            options={certification?.certifications}
            keyForOptionValue="value"
            keyForOptionLabel="value"
            value={newCertification.certName}
            disabled={!newCertification.certBody}
            onChange={(e) => setNewCertification((n) => ({ ...n, certName: e.target.value }))}
          />
        )}

        {newCertification.certBody === "Other" && (
          <div>
            <Input
              dataTestId="certification-other-body"
              type="text"
              label="Which Certificate Body"
              value={newCertification.certOtherBody}
              className="mb-4"
              onChange={(e) =>
                setNewCertification((n) => ({
                  ...n,
                  certOtherBody: e.target.value,
                }))
              }
            />

            <Input
              dataTestId="certification-other-name"
              type="text"
              label="Which Certificate Name"
              value={newCertification.certName}
              className="mb-4"
              onChange={(e) =>
                setNewCertification((n) => ({
                  ...n,
                  certName: e.target.value,
                }))
              }
            />
          </div>
        )}

        <Input
          dataTestId="certification-number"
          type="text"
          label="Certificate Number"
          value={newCertification.certBody ? newCertification.certNumber : ""}
          disabled={!newCertification.certBody}
          className="mb-4"
          onChange={(e) => setNewCertification((n) => ({ ...n, certNumber: e.target.value }))}
        />

        <Button
          dataTestId="add-certificate-btn"
          variant="outlined"
          themecolor="prime"
          label="Add Certificate"
          className="block mb-8 mr-auto"
          disabled={
            isEqual(newCertification, questions) || !newCertification.certBody || profileEditsLocked
          }
          onClick={() => {
            const newCertWithUid = { ...newCertification, uid: Date.now() };
            handleChangeValue({
              changeKey: "certifications",
              changeValue: [newCertWithUid, ...certificationList],
            });
          }}
        />
      </div>

      <div>
        {!isEmpty(certificationList) &&
          certificationList.map((n) => (
            <div key={n.uid} className="flex p-4 mb-4 border rounded-md">
              <div className="flex-1">
                <p>
                  <span className="text-lg text-prime-light">Certificate</span>
                </p>
                <p>
                  <span className="text-prime-light">Body: </span>
                  {n.certBody !== "Other" ? n.certBody : n.certOtherBody}
                </p>
                <p>
                  <span className="text-prime-light">Name: </span>
                  {n.certName}
                </p>
                <p>
                  <span className="text-prime-light">Number: </span>
                  {n.certNumber}
                </p>
              </div>

              <div
                className={`flex items-center ${
                  profileEditsLocked ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              >
                <Button
                  dataTestId={`remove-certificate-${n}`}
                  label={
                    <i
                      className={`fa-regular fa-trash-can ${
                        profileEditsLocked ? "cursor-not-allowed" : "cursor-pointer"
                      }`}
                    />
                  }
                  variant="icon-flat"
                  themecolor="prime"
                  width="w-10"
                  height="h-10"
                  textSize="text-xl"
                  onClick={() => {
                    const removedCerts = certificationList.filter((cert) => cert.uid !== n.uid);
                    handleChangeValue({
                      changeKey: "certifications",
                      changeValue: removedCerts,
                    });
                  }}
                  // onClick={
                  //   profile?.application_status === "Pending_review"
                  //     ? null
                  //     : () => {
                  //         setConfirmRemoveCertificationModal(true);
                  //         setChangedCertification(n.uid);
                  //       }
                  // }
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CertificationsList;
