import { useState } from "react";
import { Loader, Paper, Typography } from "../../../components";
import { useAuth, useToast } from "../../../context";
import { StripeService } from "../../../services";

const SetupStripe = ({ profile }) => {
  const { authContext } = useAuth();
  const { showToast } = useToast();

  // * UI States
  const [loading, setLoading] = useState(false);
  const [hasClickedSetup, setHasClickedSetup] = useState(false);

  const handleSetupStripeClick = async () => {
    setLoading(true);
    setHasClickedSetup(true);
    try {
      const { redirect_url } = await StripeService.stripeSetupPaymentAccount({
        user_uid: authContext?.user?.uid,
        user_email: authContext?.user.email,
        profile,
      });
      window.location.replace(redirect_url);
    } catch (error) {
      showToast("error", "Uh oh! Apologies, but that didn't work. Please try again");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper className="flex flex-col h-1/2">
      <Typography type="title" className="mb-4">
        Setup your Stripe account
      </Typography>
      <p className="text-lg mb-4">
        We use Stripe to get you paid on time and keep your personal banking and card information
        secure
      </p>
      <div className="relative flex flex-grow justify-center items-center">
        <button
          type="button"
          className="inline-flex w-5/6 justify-center rounded-md bg-prime px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-prime-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
          onClick={() => handleSetupStripeClick()}
          disabled={loading}
        >
          {loading ? (
            <Loader className="relative w-7 h-7 " />
          ) : hasClickedSetup ? (
            <i className="fa-solid fa-check text-base" />
          ) : (
            "Setup payments"
          )}
        </button>
      </div>
    </Paper>
  );
};

export default SetupStripe;
