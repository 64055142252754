/* NOTE: how to use:
  // components
  import { TextEditor } from '../path/to/components';
  ...
  <TextEditor
    dataTestId="some_data_testid
    name="someVar"
    label="Some label"
    value={someVar || ''}
    onChange={v => setSomeVar(v)}
  />
  ...
  // in html after geting someVar:
  {someVar && (
    <div className="ql-editor" dangerouslySetInnerHTML={{ __html: someVar }}></div>
  )}
*/

import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";

import EditorToolbar, { formats } from "./EditorToolbox";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({
  dataTestId = "text_editor",
  className = "",
  label,
  value,
  onChange,
  placeholder,
}) => {
  const [modules, setModules] = useState(null);

  // undo and redo functions for Custom Toolbar
  // const undoChange = () => {
  //   this.quill.history.undo();
  // };

  // const redoChange = () => {
  //   this.quill.history.redo();
  // };

  useEffect(() => {
    const _modules = {
      toolbar: {
        container: "#" + dataTestId,
        // handlers: {
        //   undo: undoChange,
        //   redo: redoChange,
        // },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
    };

    setModules(_modules);
  }, []);

  return (
    <div className={`text-editor w-auto my-3 ${className}`} data-testid={dataTestId}>
      <span className="block text-prime-light">{label}</span>

      {modules && (
        <>
          <EditorToolbar id={dataTestId} />

          <ReactQuill
            theme="snow"
            placeholder={placeholder}
            // value={state.value}
            // onChange={handleChange}
            // placeholder={'Write something awesome...'}
            modules={modules}
            formats={formats}
            value={value}
            onChange={onChange}
          />
        </>
      )}
    </div>
  );
};

export default TextEditor;
