import React, { useState, useEffect } from "react";

// components
import { Button, Modal, Loader } from "../../components";

// context
import { useAuth } from "../../context";

// services
import { StripeService, UserService } from "../../services";

const ModalNoStripeAccount = () => {
  // context
  const { authContext, setAuthContext } = useAuth();

  // local
  const [loader, setLoader] = useState(0);
  const [modalNoStripeAccount, setModalNoStripeAccount] = useState(false);

  const updateUser = (stripe_account_id) => {
    setLoader((l) => l + 1);
    return UserService.update({
      uid: authContext?.user?.uid,
      stripe_account_id,
    })
      .then(() => {
        // update 'authContext.user'
        const newUserObj = { ...authContext.user, stripe_account_id };
        const newAuthObj = { ...authContext, user: newUserObj };
        setAuthContext(newAuthObj);

        // update 'user' in 'localStorage'
        localStorage.setItem("user", JSON.stringify(newUserObj));

        // close modal
        setModalNoStripeAccount(false);
      })
      .catch((err) => console.error("stripe err:", err))
      .finally(() => setLoader((l) => l - 1));
  };

  const stripeCreateAccount = () => {
    setLoader((l) => l + 1);
    return StripeService.stripeCreateAccount({
      email: authContext?.user?.email,
    })
      .then((res) => {
        // console.log('stripeCreateAccountCustomer res:', res);

        // update 'user'
        updateUser(res?.stripe_account_id);
      })
      .catch((err) => {
        console.error("stripe err:", err);
        setModalNoStripeAccount(true);
      })
      .finally(() => setLoader((l) => l - 1));
  };

  useEffect(() => {
    if (!authContext?.user?.stripe_account_id) {
      stripeCreateAccount();
    }
  }, [authContext?.user?.stripe_account_id]);

  return (
    <div>
      {modalNoStripeAccount && (
        <Modal
          title="Cyber Pop-up Subscription"
          // closeModal={() => closeModal(false)}
          showCloseIcon={false}
          buttons={[
            <Button
              dataTestId="close"
              label="Close"
              variant="flat"
              className={
                authContext?.role === "Enthusiast" || authContext?.role === "Freelancer"
                  ? ""
                  : "hidden"
              }
              themecolor="prime"
              onClick={() => setModalNoStripeAccount(false)}
            />,
            <Button
              dataTestId="try_again_btn"
              label="Try again"
              variant="full"
              themecolor="prime"
              onClick={stripeCreateAccount}
            />,
          ]}
        >
          <div className="relative">
            {/* loader */}
            {!!loader && <Loader themeColor="fill-prime" />}

            <p className="text-base leading-relaxed text-prime-light-dark dark:text-gray-400">
              There is some problem with stripe services. Please try again in a few minutes.
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ModalNoStripeAccount;
