import { useState, useEffect } from "react";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Typography } from "../../../components";
import TaskList from "../../../components/task/TaskList";

export default function ProfileCompletionTaskList({
  profile,
  progressValue,
  handleTabChange,
  handleUploadPictureClick,
  setSubmitApplicationModalVisible,
}) {
  let navigate = useNavigate();

  function CE_PROFILE_TASKS(
    progressValue,
    profile,
    handleTabChange,
    handleUploadPictureClick,
    setSubmitApplicationModalVisible
  ) {
    return [
      {
        key: "account_info",
        title: "Complete basic account information",
        description: "Enter basic contact and location information about yourself.",
        complete: progressValue.account === 100,
        onClick: () => handleTabChange("My Account"),
      },
      {
        key: "profile_completion",
        title: "Complete your Cyber-expert profile",
        description: 'Complete all sections in the "My Profile" tab',
        complete: progressValue.profile === 100,
        onClick: () => handleTabChange("My Profile"),
      },
      {
        key: "video_interview",
        title: "Upload video interview",
        description: "Submit your video answers for review.",
        complete: progressValue.video_interview === 100,
        onClick: () => navigate("/video-interview/profile"),
      },
      {
        key: "profile_image",
        title: "Add a profile picture",
        description: "Add a profile picture to your profile so customers can get to know you",
        complete: progressValue.profile_image === 100,
        onClick: () => handleUploadPictureClick(),
      },
      {
        key: "work_availability",
        title: "Complete Work Availability",
        description: "Add your availability and calendy link to the Work Availability tab",
        complete: progressValue.work_availability === 100,
        onClick: () => handleTabChange("Work Availability"),
      },
      {
        key: "submit_application",
        title: "Submit Application",
        description: "Complete your profile to 100% and submit your application for review.",
        complete: profile.application_status !== "Incomplete",
        onClick: () => setSubmitApplicationModalVisible(true),
        disabled: progressValue.total != 100,
        due_date: moment(profile?.created_at)
          .add(31 - moment().diff(moment(profile.created_at), "days"), "days")
          .format(`MMMM DD`),
      },
    ];
  }
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    setTasks(() =>
      CE_PROFILE_TASKS(
        progressValue,
        profile,
        handleTabChange,
        handleUploadPictureClick,
        setSubmitApplicationModalVisible
      )
    );
  }, [progressValue]);

  return (
    <>
      <>
        <Typography type="title" className="mb-6">
          Complete your profile to submit for review
        </Typography>

        <TaskList tasks={tasks} />
      </>
    </>
  );
}
