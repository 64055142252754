import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

export default function SingleLineWithAccentBorderAlert({
  infoText = "This is an alert",
  linkText = "Click here for info",
  onClick = () => console.log("Alert clicked!"),
}) {
  return (
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            {infoText}{" "}
            <a
              href="#"
              onClick={onClick}
              className="font-medium text-yellow-700 underline hover:text-yellow-600"
            >
              {linkText}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
