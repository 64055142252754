import { evalQuery } from "./index";

export const CompanyService = {
  onboardPartnerUser: (request) =>
    evalQuery({
      url: "/company/onboard-partner-user",
      request,
    }),
  signUpCompany: (request) =>
    evalQuery({
      url: "/company/signup-first-part",
      request,
    }),
  signUpCompanyStep2: (request) =>
    evalQuery({
      url: "/company/signup-second-part",
      request,
    }),
  updateCompany: (request) =>
    evalQuery({
      url: "/company/update",
      request,
    }),
  getCompany: (request) =>
    evalQuery({
      url: "/company/get",
      request,
    }),

  getAllCompanies: (request) =>
    evalQuery({
      url: "/company/get-all",
      request,
    }),

  deleteAllCompanyData: (request) =>
    evalQuery({
      url: "/company/delete-company-and-all-data",
      request,
    }),
  getCompanyByUserUid: (request) =>
    evalQuery({
      url: "/company/get-by-user-uid",
      request,
    }),
  getCompanyPlanProgram: (request) =>
    evalQuery({
      url: "/company/get-company-plan-program",
      request,
    }),
};
