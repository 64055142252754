// Customer Dashboard Tour Video
export const customerDashboardTourVideoUrl = "https://youtu.be/tku5bX78ZTk";
// Customer Dashboard Tour Video
export const customerFreeDashboardTourVideoUrl = "https://youtu.be/QALZyn0Nptw";

// Freelancer What To Expect Video
export const freelancerWhatToExpectVideoUrl = "https://youtu.be/DW3WwNf05O8";

// Freelancer Entuziast Onboarding Video
export const freelancerEntuziastOnboardingVideoUrl = "https://www.youtube.com/watch?v=WQyns8rvrTE";

// Freelancer Expert Onboarding Video
export const freelancerExpertOnboardingVideoUrl = "https://www.youtube.com/watch?v=FEof7Bn5g3k";
