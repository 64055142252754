import PrivateRoute from "../../components/privateRoute/PrivateRoute";
import ProjectView from "../../components/project/ProjectView";
import ComingSoon from "../ComingSoon";
import DashboardFreelancer from "../dashboard/DashboardFreelancer";
import FreelancerVideoInterview from "../onboarding/FreelancerVideoInterview";
import FreelancerWhatToExpectVideo from "../onboarding/FreelancerWhatToExpectVideo";
import SubmitApplication from "../onboarding/SubmitApplication";
import Profile from "../profile/Profile";
import ProjectsFreelancer from "../projects/freelancer/ProjectsFreelancer";
import FreelancerQuestionnaire from "../questionnaire/FreelancerQuestionnaire";

export default [
  {
    path: "/questionnaire/:blok",
    element: (
      // INFO
      // allowedRoles currently is not implemented
      // Which roles can se what is decided in App.jsx
      // It was meant for more precise control, but there were bugs and
      // later all needed was successfully implemented in current way.
      <PrivateRoute allowedRoles={["Freelancer", "Enthusiast"]}>
        <FreelancerQuestionnaire />
      </PrivateRoute>
    ),
  },
  {
    path: "/video-interview/:page",
    element: (
      <PrivateRoute allowedRoles={["Freelancer", "Enthusiast"]}>
        <FreelancerVideoInterview />
      </PrivateRoute>
    ),
  },
  {
    path: "/what-to-expect-video",
    element: (
      <PrivateRoute allowedRoles={["Freelancer", "Enthusiast"]}>
        <FreelancerWhatToExpectVideo />
      </PrivateRoute>
    ),
  },
  {
    path: "/submit-application",
    element: (
      <PrivateRoute allowedRoles={["Freelancer", "Enthusiast"]}>
        <SubmitApplication />
      </PrivateRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <PrivateRoute allowedRoles={["Freelancer", "Enthusiast"]}>
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute allowedRoles={["Freelancer", "Enthusiast"]}>
        <DashboardFreelancer />
      </PrivateRoute>
    ),
  },
  {
    path: "/projects",
    element: (
      <PrivateRoute allowedRoles={["Freelancer", "Enthusiast"]}>
        <ProjectsFreelancer />
      </PrivateRoute>
    ),
  },
  {
    path: "/project/:projectUid",
    element: (
      <PrivateRoute allowedRoles={["Freelancer", "Enthusiast"]}>
        <ProjectView />{" "}
      </PrivateRoute>
    ),
  },

  {
    path: "/project/:projectUid/:taskUid",
    element: (
      <PrivateRoute allowedRoles={["Freelancer", "Enthusiast"]}>
        <ProjectView />
      </PrivateRoute>
    ),
  },

  {
    path: "/payments",
    element: (
      <PrivateRoute allowedRoles={["Freelancer", "Enthusiast"]}>
        <ComingSoon />
      </PrivateRoute>
    ),
  },
];
