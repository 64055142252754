import { useNavigate } from "react-router-dom";
import { Button } from "../components";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="container flex-1 mx-auto p-page">
      <div className="min-h-full px-6 py-16 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
             <p className="text-4xl font-bold tracking-tight text-prime sm:text-5xl">  </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Coming Soon
                </h1>
                {/* <p className="mt-1 text-base text-gray-500">
                  Please check the URL in the address bar and try again.
                </p>*/}
              </div>
              <div className="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Button onClick={() => navigate("/")}>Go back home</Button>
                {/* <Button variant="outlined" onClick={() => navigate("/contact")}>
                  Contact
                </Button> */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
