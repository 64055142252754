import React from "react";
import { findLastIndex } from "lodash";

const Roadmap = ({ road, title }) => (
  <div className="py-12 max-w-page">
    <p className="text-center text-sec font-semibold text-3xl mb-8">
      {/* What can you expect after you subscribe. */}
      {title}
    </p>

    <div className="flex flex-wrap">
      {road.map((item, i) => (
        <div
          key={i}
          className={`flex ${findLastIndex(road) === i ? "max-w-[160px]" : "w-[240px]"}`}
        >
          <div className="flex flex-col items-center text-center">
            <img src={item.img} alt="error loading icon" style={{ height: 90, width: 90 }} />
            <p className="text-xl mt-3 font-semibold text-prime">{item.title}</p>
            <p className="font-roboto text-base mt-3 text-prime-light-dark">{item.text}</p>
          </div>

          {findLastIndex(road) !== i && (
            <img
              src={item.arrowImg}
              alt="error loading icon"
              style={{ height: 90, width: 90, marginLeft: "-10px" }}
            />
          )}
        </div>
      ))}
    </div>
  </div>
);

export default Roadmap;
