export const PROJECTS_TABLE_COLUMNS = [
  {
    id: "short_text",
    type: "td-modal",
    label: "Project Name",
    style: "underline text-terc",
  },
  {
    id: "number_and_version",
    type: "string",
    label: "Project number-version",
  },
  {
    id: "level",
    type: "string",
    label: "Level",
  },
  {
    id: "nist_level",
    type: "string",
    label: "NIST",
  },
  {
    id: "number",
    type: "string",
    label: "Number",
  },
  {
    id: "started_instances",
    type: "string",
    label: "Instances",
  },
  {
    id: "work_hours_estimation",
    type: "string",
    label: "Duration",
  },
  {
    id: "cost",
    type: "currency",
    label: "Cost",
  },
  {
    id: "status",
    type: "string",
    label: "Status",
  },
  {
    id: "actions",
    type: "actions",
    label: "",
  },
];
