import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// components
import { Button, Typography, Paper, VideoPlayer } from "../../components";

// constants
import {
  customerDashboardTourVideoUrl,
  customerFreeDashboardTourVideoUrl,
} from "../../constants/videos";
import { ProjectService } from "../../services";

const CompanyOnboardingVideo = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [firstTask, setFirstTask] = useState(null);

  const { project_uid, trial, goToProject } = location.state || "uid";

  const handleNavigate = () =>
    goToProject
      ? navigate(`/project/${project_uid}`, {
          state: {
            selectedTask: firstTask,
          },
        })
      : navigate("/dashboard");

  useEffect(() => {
    // This useEffect is used so if the user is going to project then the first task of the project is open
    const getTaskInfo = async () => {
      const { project } = await ProjectService.getProject({
        project_uid,
      });
      const taskRoadmap = JSON.parse(project.roadmap);
      setFirstTask(taskRoadmap[0]);
    };
    if (goToProject) {
      getTaskInfo();
    }
  }, []);

  return (
    <div className="flex items-center justify-center h-full p-page">
      <Paper className="w-full h-full lg:h-[800px] lg:w-[1080px]">
        <div className="flex flex-col-reverse h-full lg:flex-row">
          <div className="flex flex-col justify-center text-center lg:text-left space-y-2 lg:space-y-6 lg:h-[100%] lg:w-4/12 mr-2 mb-5">
            <Typography type="title" className="mb-2">
              Thank you for making Cyber&nbsp;Pop&#8209;up part of your cybersecurity journey.
            </Typography>

            <p className="mb-8 text-sec">
              <i className="mr-2 fa-solid fa-circle-play" />
              Watch Christine Izuakor discuss Cyber&nbsp;Pop&#8209;up
            </p>
            <div className="mx-auto lg:mx-0">
              {goToProject ? (
                <Button
                  dataTestId="company_onboarding_video_continue_to_project"
                  label="Continue to project"
                  variant="full"
                  themecolor="sec"
                  disabled={false}
                  onClick={() => handleNavigate()}
                />
              ) : (
                <Button
                  dataTestId="company_onboarding_video_go_to_dashboard"
                  label="Go To Dashboard"
                  variant="full"
                  themecolor="sec"
                  disabled={false}
                  onClick={() => handleNavigate()}
                />
              )}
            </div>
          </div>
          <div className="flex w-9/12 m-auto shadow-sm">
            <VideoPlayer
              url={trial ? customerFreeDashboardTourVideoUrl : customerDashboardTourVideoUrl}
              className="w-full h-auto aspect-video"
              width="100%"
              height="100%"
              controls={true}
              playing={false}
              onEnded={() => handleNavigate()}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default CompanyOnboardingVideo;
