/* NOTE:
  for type="number"
  className="input-no-arrows" - removes arrows
*/

import { forwardRef } from "react";

const Input = forwardRef(
  (
    {
      dataTestId,
      name,
      label,
      type = "text",
      placeholder = "",
      error = "",
      value,
      onChange,
      className = "mb-4",
      borderClass,
      inputClassName = "",
      ...rest
    },
    ref
  ) => (
    <div className={className}>
      <label>
        {label && <span className="block text-prime-light">{label}</span>}

        <input
          data-testid={dataTestId}
          ref={ref}
          type={type}
          format=""
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`w-full rounded-md shadow-sm bg-gray-50 focus:border disabled:opacity-50 focus:border-prime focus:outline-none ${
            borderClass || "border-gray-300"
          } ${inputClassName}`}
          {...rest}
        />

        {error && <div className="mt-1 mb-2 text-sm text-error">{error}</div>}
      </label>
    </div>
  )
);

export default Input;
