/* NOTE:
if in "radios" key "label" did not exist, value will be visible as label
*/

import { forwardRef } from "react";

const Radio = forwardRef(
  (
    {
      checked,
      radios = [],
      name,
      className = "mb-4",
      inputClassName = "",
      mainLabel,
      onChange,
      ...rest
    },
    ref
  ) => (
    <div className={className}>
      {mainLabel && <p className="block text-prime-light text-lg mb-2">{mainLabel}</p>}

      {radios.map((radio) => (
        <div key={radio.dataTestId} className="flex items-start mb-2">
          <input
            data-testid={radio.dataTestId}
            ref={ref}
            id={radio.dataTestId}
            type="radio"
            className={`mt-1 mr-4 text-prime focus:border-prime ${inputClassName}`}
            name={name}
            value={radio.value}
            checked={radio.value === checked ? true : false}
            onChange={onChange}
            {...rest}
          />

          <label className="block text-prime-light-dark" htmlFor={radio.dataTestId}>
            {radio.label || radio.value}
          </label>
        </div>
      ))}
    </div>
  )
);

export default Radio;
