import React from "react";
import { isObject } from "lodash";

const Tabs = ({
  dataTestId,
  className = "",
  classTextActive = "text-prime",
  classTextInactive = "text-prime-light",
  classBorderColorActive = "border-prime",
  classBorderColorInactive = "border-prime-light",
  value,
  tabs = [],
  onChange = () => {},
}) => (
  <div
    className={`text-sm font-medium text-center border-b-2 ${classBorderColorInactive} dark:text-gray-400 dark:border-gray-700 ${className}`}
  >
    <ul className="flex justify-between flex-wrap -mb-px">
      {tabs.map((tab) =>
        isObject(tab) ? (
          // if tabs is array of objects
          <li key={tab.value} className="flex-1" role="presentation">
            <button
              data-testid={`tab-${dataTestId}-${tab.value}`}
              id={`tab-${dataTestId}-${tab.value}`}
              className={`p-4 w-full rounded-t-lg border-b-2 whitespace-nowrap ${
                value === tab.value
                  ? `${classTextActive} font-bold ${classBorderColorActive}`
                  : `${classTextInactive}`
              }`}
              type="button"
              role="tab"
              aria-selected={value === tab.value}
              onClick={() => onChange(tab)}
            >
              {tab.counter !== undefined ? `${tab.label} (${tab.counter || 0})` : tab.label}
            </button>
          </li>
        ) : (
          // if tabs is array of strings
          <li key={tab} className="flex-1" role="presentation">
            <button
              data-testid={`tab-${dataTestId}-${tab}`}
              id={`tab-${dataTestId}-${tab}`}
              className={`p-4 w-full rounded-t-lg border-b-2 whitespace-nowrap ${
                value === tab
                  ? `${classTextActive} font-bold ${classBorderColorActive}`
                  : `${classTextInactive}`
              }`}
              type="button"
              role="tab"
              aria-selected={value === tab}
              onClick={() => onChange(tab)}
            >
              {tab}
            </button>
          </li>
        )
      )}
    </ul>
  </div>
);

export default Tabs;
