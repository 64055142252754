import { isEmpty } from "lodash";

export const getCountryFullName = (country, allCountries) => {
  let result = [{ country: "N/A" }];
  if (!isEmpty(allCountries)) {
    const filteredCountry = allCountries.filter(
      (n) => n.alpha2Code === country || n.alpha3Code === country
    );
    if (!isEmpty(filteredCountry)) {
      result = filteredCountry;
    }
  }

  return result[0].country;
};
