import React, { useState, useEffect, useCallback } from "react";
import { PopupButton } from "@typeform/embed-react";
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
// components
import { Paper, Typography, Button, Loader, ContactManager, TaskSlider } from "..";

// context
import { useAuth, useToast } from "../../context";

// services
import {
  TaskService,
  ProjectService,
  ProfileService,
  errorMsgForUser,
  StripeService,
  CompanyService,
  ZapierService,
  ProjectTemplateService,
} from "../../services";

import TaskTable from "../draggable/TaskTable";

let nistOptions = [
  { label: "Level 1", value: "L1" },
  { label: "Level 2", value: "L2" },
  { label: "Level 3", value: "L3" },
];

let projectOptions = [
  { label: "Level 1", value: "L1" },
  { label: "Level 2", value: "L2" },
  { label: "Level 3", value: "L3" },
  { label: "Level 4", value: "L4" },
  { label: "Level 5", value: "L5" },
  { label: "Level 6", value: "L6" },
  { label: "Level 7", value: "L7" },
  { label: "Level 8", value: "L8" },
  { label: "Level 9", value: "L9" },
  { label: "Level 10", value: "L10" },
];

const ProjectCustomer = () => {
  // router
  let { projectUid, taskUid } = useParams();
  let navigate = useNavigate();
  const location = useLocation();

  // console.log({projectUid, taskUid})

  // useEffect(() => {
  //   console.log('project uid', projectUid);
  // }, [projectUid]);

  // context
  const { authContext } = useAuth();
  const { showToast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  // local
  const [loader, setLoader] = useState(0);
  const [projectRoadmap, setProjectRoadmap] = useState([["initiation", []]]);
  const [taskItems, setTaskItems] = useState();
  const [currentProject, setCurrentProject] = useState({
    level: projectOptions[0].value,
    name: "",
    nist_level: nistOptions[0].value,
    work_hours_estimation: 10,
    description: "",
    cost: "",
    freelancer_payment: "",
    number: "",
    number_and_version: "",
    status: "Draft",
  });
  const [selectedTask, setSelectedTask] = useState(location?.state?.selectedTask || null);
  const [calendlyLink, setCalendlyLink] = useState("");
  const [csmUid, setCsmUid] = useState("");
  const [customerUid, setCustomerUid] = useState("");
  const [company, setCompany] = useState(null);
  const [freelancerUid, setFreelancerUid] = useState("");
  const [fromName, setFromName] = useState("");
  const [currentSubscription, setSubscription] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const [paidSub, setPaidSub] = useState(true);
  const [hasPomrPurchased, setHasPomrPurchased] = useState(true);
  const [onClickLoader, setOnClickLoader] = useState(false);

  const getTasksForTheProject = useCallback(
    (projectUid) => {
      setLoader((l) => l + 1);
      return TaskService.getByProject({
        project_uid: projectUid,
      })
        .then((res) => {
          setProjectRoadmap([...res.tasks]);

          const obj = {};
          let counter = 0;
          // This makes the first task a free task and the tasks are sorted into three sections, inititation, in progress, close
          // Also currently subtasks are defined as tasks with a estimated_duration_delta of 0
          // So this sets the task to be a subtask or not based on that delta
          // TODO: Refactor. This is unreadable.
          res.tasks.forEach((e) => {
            if (counter++ < 1) {
              e.tier = "free";
            } else {
              e.tier = "paid";
            }
            e.isSubtask = e.estimated_duration_delta ? false : true;
            obj[e.phase] = obj[e.phase] ? [...obj[e.phase], e] : [e];
          });

          setTaskItems(obj);

          if (taskUid) {
            const taskFromPath = res.tasks.filter((n) => n.uid === taskUid);
            // console.log({taskFromPath})
            getTask({ ...taskFromPath[0] });
          }
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader((l) => l - 1));
    },
    [showToast]
  );

  const getProject = useCallback(
    (projectUid) => {
      setLoader((l) => l + 1);
      return ProjectService.getProject({ project_uid: projectUid })
        .then((res) => {
          setCsmUid(res?.project?.csm_uid);
          setCustomerUid(res?.project?.customer_uid);
          setFreelancerUid(res?.project?.freelancer_uid);

          setCurrentProject(res?.project);
          getTasksForTheProject(projectUid);
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader((l) => l - 1));
    },
    [getTasksForTheProject, showToast]
  );

  // Get calendly_link from freelancer profile and sets it to be used in <TaskSlider/> on Schedual a Meeting part
  const getFreelancer = () => {
    setLoader((l) => l + 1);

    return ProfileService.get({
      user_uid: currentProject?.freelancer_uid,
    })
      .then((res) => {
        setCalendlyLink(res.profile.calendly_link);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const getProfile = useCallback(() => {
    // get profile Api
    setLoader((l) => l + 1);
    return ProfileService.get({ user_uid: authContext?.user?.uid })
      .then((res) => {
        const { responsible_person_first_name, responsible_person_last_name } = res.profile;
        const fullName = `${responsible_person_first_name} ${responsible_person_last_name}`;

        setFromName(fullName);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  }, [authContext?.user?.uid, showToast]);

  const getTask = (task) => {
    // console.log('task:', task);

    setSelectedTask({ ...task });
    //   setLoader(l => l + 1);
    //   TaskService.get({ project_taak_uid: taskUid })
    //     .then(res => {
    //       console.log('ProjectService.get res: ', res);
    //       setSelectedTask(res?.task);
    //     })
    //     .catch(err => showToast('error', errorMsgForUser(err)))
    //     .finally(() => setLoader(l => l - 1));
  };

  const redirectToCheckout = async () => {
    setOnClickLoader(!onClickLoader);
    const { checkout_session_url } = await StripeService.createCheckoutSession({
      plan_id: currentSubscription.items.data[0].price.id,
      stripe_customer_id: currentSubscription.customer,
      is_trial: false,
      include_plan_addons: true,
      cancel_endpoint: `/project/${projectUid}`,
      success_endpoint: `/project/${projectUid}`,
    });
    window.location.replace(checkout_session_url);
  };

  const changeMessageReadStateUi = (taskUid) => {
    const findChangedIndex = () => {
      if (taskItems) {
        for (const key of Object.keys(taskItems)) {
          const foundIndex = taskItems[key].findIndex((e) => e.uid === taskUid);
          if (foundIndex !== -1) {
            return [key, foundIndex];
          }
        }
      }
      // if no messages need update return -1
      return -1;
    };

    setTaskItems((prevState) => {
      const newState = { ...prevState };
      const foundIndex = findChangedIndex();
      if (foundIndex !== -1) {
        newState[foundIndex[0]][foundIndex[1]].unread_count = 0;
        return newState;
      }
    });
  };

  useEffect(() => {
    if (projectUid) {
      getProject(projectUid);
    }
  }, [getProject, projectUid]);

  useEffect(() => {
    if (currentProject.freelancer_uid) {
      getFreelancer();
    }
  }, [currentProject]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  useEffect(() => {
    const checkPomrPurchase = async () => {
      const { has_purchased_pomr } = await ProjectTemplateService.checkPomrPurchase({
        company_uid: customerUid,
      });
      setHasPomrPurchased(has_purchased_pomr);
    };
    checkPomrPurchase();
  }, [customerUid, currentProject]);

  // useEffect(() => {
  //   const getAllData = async () => {
  //     // TODO: This needs to be migrated to the backend
  //     let subscriptionData = null;

  //     const { subscription } = await StripeService.getSubscription({
  //       user_uid: authContext?.user?.uid,
  //     });

  //     if (searchParams.get("checkout_session_id")) {
  //       const { checkout_session } = await StripeService.getCheckoutSession({
  //         checkout_session_id: searchParams.get("checkout_session_id"),
  //       });

  //       if (checkout_session.subscription !== subscription.id) {
  //         await StripeService.cancelSubscription({
  //           subscription_id: subscription.id,
  //           user_uid: authContext?.user?.uid,
  //         });

  //         const { company } = await CompanyService.getCompanyByUserUid({
  //           user_uid: authContext?.user?.uid,
  //         });

  //         await CompanyService.updateCompany({
  //           uid: company?.uid,
  //           stripe_subscription_id: checkout_session?.subscription,
  //         });

  //         const res = await StripeService.getSubscription({
  //           user_uid: authContext?.user?.uid,
  //         });
  //         subscriptionData = res.subscription;

  //         // * Notify zapier about customer upgrade
  //         await ZapierService.notifyOfSubscriptionUpgrade({
  //           project_uid: projectUid,
  //         });
  //       } else {
  //         subscriptionData = subscription;
  //       }
  //     } else {
  //       subscriptionData = subscription;
  //     }

  //     const getPlanResponse = await StripeService.getPlan({
  //       plan_id: subscriptionData?.plan?.id,
  //     });
  //     setCurrentPlan(getPlanResponse);
  //     setSubscription(subscriptionData || {});
  //   };
  //   getAllData();
  // }, []);

  useEffect(() => {
    // Handles special case if the use is on the NYDFS plan, which has a trial but is paid
    let paidSubChange = paidSub;
    // If there is a trial then blur
    if (currentSubscription?.trial_end) paidSubChange = false;
    // This is to enable legacy plans to have an unblurred project view
    if (currentPlan?.plan.active === false) paidSubChange = true;
    // TODO: Put special plan names in a confog file
    if (currentPlan?.product.name === "NYDFS Package") paidSubChange = true;

    setPaidSub(paidSubChange);
  }, [currentSubscription]);

  useEffect(() => {
    const getCompany = async () => {
      const { company } = await CompanyService.getCompany({
        company_uid: customerUid,
      });
      setCompany(company);
    };
    getCompany();
  }, [customerUid]);

  return (
    <>
      <div className="relative flex flex-1">
        {/* loader */}
        {!!loader && <Loader themeColor="fill-prime" />}

        <div className="flex-1 w-full overflow-y-auto leading-page-height overflow-x-none">
          <div className="container mx-auto p-page max-w-page">
            {/* loader */}
            {!!loader && <Loader themeColor="fill-prime" />}

            {/* top part */}
            <div className="flex items-center mb-6">
              <Button
                dataTestId="back_btn"
                label={<i className="fa-solid fa-arrow-left" />}
                variant="icon-flat"
                themecolor="prime"
                width="w-10"
                height="h-10"
                textSize="text-xl"
                className="mr-2"
                onClick={() => navigate(-1)}
              />
              <div className="flex flex-col">
                <Typography type="title">
                  Project - <span className="text-sec">{currentProject?.name || ""}</span>
                </Typography>
                {authContext.role !== "Customer" && (
                  <Typography type="subtitle" className="mt-4">
                    Company Name: <span className="text-sec">{company?.name || ""}</span>
                  </Typography>
                )}
              </div>
            </div>

            {/* project info */}
            <Paper>
              <TaskTable
                tableData={taskItems}
                paidSub={paidSub}
                hasPomrPurchased={hasPomrPurchased}
                selectedPlan={currentSubscription?.items?.data[0]?.price}
                productName={currentPlan?.product?.name}
                currentProject={currentProject}
                redirectToCheckout={redirectToCheckout}
                onClickLoader={onClickLoader}
                onRowClick={(task) => getTask(task)}
                updateList={(projectUid) => getProject(projectUid)}
              />
            </Paper>
            {/* support */}
            {authContext.role === "Customer" && (
              <Paper className="w-full p-6 border-2 z-0 mt-4">
                <Typography type="subtitle" className="mb-4 text-gray-300 text-center">
                  Need Help or Have a Question?
                </Typography>

                <div className="flex justify-evenly">
                  <ContactManager role="Csm" showHelpText={false} />
                </div>
              </Paper>
            )}
          </div>
        </div>

        {/* task slider */}
        <TaskSlider
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
          getProject={getProject}
          csmUid={csmUid}
          customerUid={customerUid}
          freelancerUid={freelancerUid}
          calendlyLink={calendlyLink}
          currentProject={currentProject}
          taskList={projectRoadmap}
          fromName={fromName}
          changeMessageReadStateUi={changeMessageReadStateUi}
          paidSub={paidSub}
          company={company}
        />
      </div>
    </>
  );
};

export default ProjectCustomer;
