import React, { useState, forwardRef } from "react";
import { isEmpty } from "lodash";

// componets
import Button from "../Button";
import Input from "./Input";

const Tags = forwardRef(
  (
    {
      dataTestId,
      name,
      label,
      type = "text",
      placeholder = "",
      error = "",
      tags = [],
      setTags,
      className = "mb-4",
      inputClassName = "",
      inputBorderClass,
      tagsAsList = false,
      tagMaxWidth = "w-full", // inherit
      ...rest
    },
    ref
  ) => {
    const [newTag, setNewTag] = useState("");

    const addTag = (removeComma) => {
      if (!newTag) return;

      const newTags = [...tags, removeComma || newTag];
      setTags(newTags);

      setTimeout(() => {
        setNewTag("");
      }, 10);
    };

    const deleteTag = (tag) => {
      console.log("tag:", tag);

      const filteredTags = tags.filter((n) => n !== tag);
      setTags(filteredTags);
    };

    const handleKeyUp = (e) => {
      // console.log('e.code:', e.code);
      if (!e.code) return;

      if (e.code !== "Escape") {
        e.stopPropagation();
      }
      if (e.key === "Enter") {
        if (!e.target.value && document.querySelector(".save_btn")) {
          addTag();
        }

        setNewTag("");
        addTag();
      } else if (e.code === "Comma") {
        const removeComma = e?.target?.value?.replace(",", "");
        addTag(removeComma);
      }
    };

    return (
      <div className={className}>
        <label>
          {label && <span className="block text-prime-light">{label}</span>}

          <div className="flex mb-4">
            <Input
              data-testid={dataTestId}
              ref={ref}
              type={type}
              format=""
              name={name}
              placeholder={placeholder}
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              className={`flex-1 w-full rounded-md  shadow-sm bg-gray-50 focus:border focus:border-prime focus:outline-none ${inputClassName}`}
              borderClass={inputBorderClass}
              onKeyUp={(e) => handleKeyUp(e)}
              autoComplete="off"
              {...rest}
            />
            <Button
              dataTestId="add_tag_btn"
              label={<i className="fa-solid fa-plus" />}
              variant="icon-flat"
              themecolor="prime"
              width="w-10"
              height="h-10"
              className="ml-2"
              onClick={() => addTag()}
            />
          </div>

          <div
            className={`${
              !tagsAsList ? "flex flex-wrap max-w-[fit-content]" : ""
            } mt-[-0.5rem] min-h-5`}
          >
            {!isEmpty(tags) &&
              tags.map((tag, i) =>
                !tagsAsList ? (
                  // normal tags
                  <div key={tag + i}>
                    <div
                      className={`bg-prime text-white px-2 py-1 rounded-full mr-1 mb-2 leading-[1.2] break-all ${tagMaxWidth}`}
                    >
                      {tag}
                      <i
                        className="fa-solid fa-xmark ml-2 mt-0.5 cursor-pointer"
                        onClick={() => deleteTag(tag)}
                      />
                    </div>
                  </div>
                ) : (
                  // tags as list
                  <div
                    key={tag + i}
                    className="flex items-center w-full p-2 border-b border-grey-light"
                  >
                    <p className={`flex-1 flex items-center mr-1 break-all ${tagMaxWidth}`}>
                      {tag}
                    </p>
                    <i
                      className="ml-2 cursor-pointer fa-regular fa-trash-can"
                      onClick={() => deleteTag(tag)}
                    />
                  </div>
                )
              )}
          </div>

          {error && <div className="mb-2 text-sm text-error">{error}</div>}
        </label>
      </div>
    );
  }
);

export default Tags;
