import { evalQuery } from "./index";

export const StripeServiceV2 = {
    createCheckoutSession: (request) =>
        evalQuery({
            url: "/stripev2/create-checkout-session",
            request,
        }),
    getAppUrl: (request) =>
        evalQuery({
            url: "/stripev2/get-app-url",
            request,
        }),
    createCheckoutSessionPomr: (request) =>
        evalQuery({
            url: "/stripev2/create-checkout-session-pomr",
            request,
        }),
}