import { Navigate } from "react-router-dom";
// import { useAuth } from "../../context";

// const PrivateRoute = ({ children, allowedRoles }) => {
//   const { token, role } = localStorage
//
const PrivateRoute = ({ children }) => children;

// {
//   const { token } = localStorage

//   // console.log("Token was not found, you will be redirected to signin page");
//   // TODO checking allowerRoles in this place will have to waith for refactor
//   // since currently produces bugs
//   // return  token && allowedRoles?.includes(role)  ? (
//   return  token   ? (
//     children
//   ) : (
//     <Navigate to="/signin" />
//   );
// };

export default PrivateRoute;
