import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { isEmpty } from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DiscountSelectDropdown({
  discounts,
  selectedDiscounts,
  handleDiscountSelect,
  setSelectedDiscounts,
}) {
  return (
    <>
      <Listbox onChange={handleDiscountSelect}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              Discounts
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">--Select--</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute max-h-40 overflow-y-auto z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {discounts.map((discount) => (
                    <Listbox.Option
                      key={discount.uid}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-8 pr-4"
                        )
                      }
                      value={discount}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {discount.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {!isEmpty(selectedDiscounts) && (
        <>
          <table className="w-full">
            <thead>
              <tr className="flex justify-between">
                <th className="text-sm flex-1">Discount Name</th>
                <th className="text-sm flex-1 text-right">Delete</th>
              </tr>
              <tr className="flex justify-between border-b border-gray-300">
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody className="justify-center">
              {selectedDiscounts.map((discount) => (
                <tr className="flex justify-between items-center mt-2" key={discount.uid}>
                  <td className="text-sm flex-1">{discount.name}</td>
                  <td className="text-sm flex-1 text-right">
                    <button
                      type="button"
                      onClick={() =>
                        setSelectedDiscounts(
                          selectedDiscounts.filter((item) => item.uid !== discount.uid)
                        )
                      }
                    >
                      X
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}
