export const getProfileCompletion = (profile, questions) => {
  // const questions = questionnaire?.questions;

  const workloadAvailability = profile?.work_availability && JSON.parse(profile?.work_availability);

  if (!profile || !questions || !workloadAvailability) return;

  // console.log('questions:', questions);

  let itemsForCompletion = {
    account: {
      completion: 0,
      data: {
        responsible_person_first_name: profile.responsible_person_first_name,
        responsible_person_last_name: profile.responsible_person_last_name,
        address_one: profile.address_one,
        // address_two: profile.address_two,
        // country: profile.country,
        state: profile.state,
        city: profile.city,
        zip_code: profile.zip_code,
        time_zone: profile.time_zone,
        prefered_language: profile.prefered_language,
      },
    },
    // profile_image: {data: { profile_image: profile.profile_image }},
    profile_image: {
      data: {
        profile_image: profile.profile_image
      }
    },
    video_interview: {
      data:{
        video_is_reviewed: profile.video_status === "Reviewed" || profile.video_status === "Submitted"
        ? true
        : false
      }
    },

    profile: {
      data: {
        about_me: profile.about_me,
        // social_media: questions.social_media?.length,
        // certifications: questions.certifications?.length,
      },
    },
    work_availability: {
      data: {
        available: workloadAvailability?.available,
        calendly_link: !!profile.calendly_link,
      },
    },
    bank_information: {
      data: {
        // comming soon
      },
    },
  };

  const calculateCompletion = (data) => {
    let completion = 0;
    let total = (data && Object.values(data)?.length) || 0;

    if (!data) return;
    Object.values(data).forEach((value) => {
      if (value) {
        completion += 1;
      }
    });
    return Math.round((completion / total) * 100);
  };

  let totalItems = {
    ...itemsForCompletion?.account?.data,
    ...itemsForCompletion?.profile?.data,
    ...itemsForCompletion?.video_interview?.data,
    ...itemsForCompletion?.profile_image?.data,
    ...itemsForCompletion?.work_availability?.data,
    // ...itemsForCompletion?.bank_information.data,
  };

  return {
    total: calculateCompletion(totalItems),
    account: calculateCompletion(itemsForCompletion.account?.data),
    profile: calculateCompletion(itemsForCompletion.profile?.data),
    video_interview: calculateCompletion(itemsForCompletion?.video_interview.data),
    profile_image: calculateCompletion(itemsForCompletion?.profile_image.data),
    work_availability: calculateCompletion(itemsForCompletion.work_availability?.data),
    profile_submitted: profile.application_status !== "Incomplete"
    // bank_information: calculateCompletion(itemsForCompletion?.account.data),
  };
};
