import { useState, useEffect } from "react";
import { Paper } from "../../../components";
import { ProjectTemplateService } from "../../../services";
import { DiscountService } from "../../../services/DiscountService";
import { ProgramService } from "../../../services/ProgramService";
import DiscountSelectDropdown from "./DiscountSelectDropdown";
import ProjectSelectDropdown from "./ProjectSelectDropdown";

const defaultValues = {
  program_name: "",
  program_price: 0,
  program_status: "active",
  program_initial_poptokens: 0,
  program_monthly_poptokens: 0,
  program_short_description: "",
  program_long_description: "",
};

const defaultDEVVALUES = {
  program_name: "test",
  program_price: 123,
  program_status: "active",
  program_initial_poptokens: 123,
  program_monthly_poptokens: 123,
  program_short_description: "sdf",
  program_long_description: "sdf",
};

function ProgramForm({ setProgramFormVisible, setPrograms }) {
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setselectedProjects] = useState([]);

  const [discounts, setDiscounts] = useState([]);
  const [selectedDiscounts, setSelectedDiscounts] = useState([]);

  // ! PROD VALUES
  const [values, setValues] = useState(defaultValues);

  // ! DEV VALUES
  // const [values, setValues] = useState(defaultDEVVALUES);

  const handleSelectChange = (e) => {
    e.project_start_behavior = "autostart";
    setselectedProjects((prev) => [...prev, e]);
  };

  const handleDiscountSelect = (e) => {
    setSelectedDiscounts((prev) => [...prev, e]);
  };

  const onProjectStartChange = (e, projectUid) => {
    const newSelectedProjects = selectedProjects.map((project) => {
      if (project.uid === projectUid) {
        project.project_start_behavior = e;
      }
      return project;
    });
    setselectedProjects(newSelectedProjects);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { program } = await ProgramService.create({
      ...values,
      program_project_templates: selectedProjects,
      program_discounts: selectedDiscounts,
    });

    setValues(defaultValues);
    setPrograms((prev) => [...prev, program]);
    setselectedProjects([]);
    setProgramFormVisible(false);
  };

  useEffect(() => {
    const getTemplates = async () => {
      const { project_templates } = await ProjectTemplateService.getPublished();
      setProjects(project_templates);
    };
    const getDiscounts = async () => {
      const { discounts } = await DiscountService.getAll();
      setDiscounts(discounts);
    };
    getTemplates();
    getDiscounts();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-center items-center">
          <div className="w-full sm:w-80">
            <h2 className="text-base font-semibold leading-7 text-gray-900 mb-6 text-center">
              Add Program
            </h2>

            <div className="space-y-4">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={values.program_name}
                onChange={(e) => setValues({ ...values, program_name: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <ProjectSelectDropdown
                projects={projects}
                selectedProjects={selectedProjects}
                setselectedProjects={setselectedProjects}
                handleSelectChange={handleSelectChange}
                onProjectStartChange={onProjectStartChange}
              />

              <DiscountSelectDropdown
                discounts={discounts}
                selectedDiscounts={selectedDiscounts}
                setSelectedDiscounts={setSelectedDiscounts}
                handleDiscountSelect={handleDiscountSelect}
              />

              <label htmlFor="cost" className="block text-sm font-medium leading-6 text-gray-900">
                Cost
              </label>
              <input
                type="number"
                name="cost"
                id="cost"
                value={values.program_price}
                onChange={(e) => setValues({ ...values, program_price: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
                Status
              </label>
              <input
                type="text"
                name="status"
                id="status"
                value={values.program_status}
                onChange={(e) => setValues({ ...values, program_status: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <label
                htmlFor="initial-poptokens"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Initial Poptokens
              </label>
              <input
                type="number"
                name="initial-poptokens"
                id="initial-poptokens"
                value={values.program_initial_poptokens}
                onChange={(e) =>
                  setValues({ ...values, program_initial_poptokens: e.target.value })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <label
                htmlFor="monthly-poptokens"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Monthly Poptokens
              </label>
              <input
                type="number"
                name="monthly-poptokens"
                id="monthly-poptokens"
                value={values.program_monthly_poptokens}
                onChange={(e) =>
                  setValues({ ...values, program_monthly_poptokens: e.target.value })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <label
                htmlFor="short-description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Short Description
              </label>
              <input
                type="text"
                name="short-description"
                id="short-description"
                value={values.program_short_description}
                onChange={(e) =>
                  setValues({ ...values, program_short_description: e.target.value })
                }
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <label
                htmlFor="long-description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Long Description
              </label>
              <input
                type="text"
                name="long-description"
                id="long-description"
                value={values.program_long_description}
                onChange={(e) => setValues({ ...values, program_long_description: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />

              <div className="flex justify-center">
                <button
                  type="button"
                  className="mx-4 block rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => setProgramFormVisible(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className=" mx-4 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default ProgramForm;
