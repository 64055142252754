import React from "react";

// components
import { Typography } from "../../components";

const FreelancerCodeOfConduct = () => (
  <div className="mb-8 ml-2">
    <Typography type="title" className="mb-8 text-center">
      Cyber-expert Code Of Conduct
    </Typography>

    <p className="font-bold">Cyber Pop-up Code of Conduct for Freelancers</p>
    <p className="mb-4">
      By signing up as a Cyber-expert or accessing the platform, you certify that you have read and agree to the entire Terms of Service and will comply with the following standard at all times.
    </p>

    <p className="font-semibold">Be Ethical:</p>
    <ul className="mb-4 ml-6 list-disc">
      <li>Only apply to and accept projects you are qualified and capable of completing.</li>
      <li>Only use Client information for the purpose explicitly outlined in the project.</li>
      <li>Notify your project success manager if you have any issues completing your project as early as possible and, in any event, within any deadlines or due dates provided by a client or success manager.</li>
      <li>Avoid conflicts of interest and circumstances that reasonably present the appearance of a conflict.</li>
    </ul>

    <p className="font-semibold">Be Secure:</p>
    <ul className="mb-4 ml-6 list-disc">
      <li>Upon completion of every project, delete and render unreadable all information from the Client.</li>
      <li>Respect confidentiality and privacy, and do not share any Client information with unauthorized 3rd parties.</li>
      <li>Do not share your access with others or outsource your assignment to other parties.</li>
      <li>Do not disclose information or documents you have acquired other than as required by law or where authorization is given by Cyber Pop-up Inc. or its Client(s).</li>
    </ul>

    <p className="font-semibold">Be Kind:</p>
    <ul className="mb-4 ml-6 list-disc">
      <li>Keep communications and interactions with Clients and other Freelancers professional and kind. Avoid exaggeration, derogatory remarks, and inappropriate references.</li>
      <li>Be empathetic and listen openly, always.</li>
      <li>Harassment of any kind is prohibited.  Do not engage in personal attacks, negative or other unfair criticism, or unprofessional conduct.</li>
    </ul>

    <p className="font-semibold">Be Compliant:</p>
    <ul className="mb-6 ml-6 list-disc">
      <li>Ensure you comply with your local work and tax requirements before accepting projects. You are responsible for ensuring that you can legally perform Cyber-expert work.</li>
      <li>Read the Terms of Service for any subsequent updates made available by Cyber Pop-up Inc.</li>
      <li>Observe all copyright and license agreements and do not copy, transfer, rename, add, or delete information or programs belonging to other users unless given express permission to do so by Cyber Pop-up Inc., the Client(s), and the copyright owner.</li>
    </ul>

    <p className="mb-4 font-semibold">
      Failure to adhere to this Code of Conduct will result in the Cyber-expert being asked to cease the behavior immediately. It may include the removal of the Cyber-expert from the project and legal action where warranted.
    </p>
    <p className="mb-4 font-semibold">
      Failure to comply with the specific conduct guidelines below may result in a permanent ban from using our services and legal action where warranted.
    </p>
    <p className="mb-4 font-semibold">
      Cyber Pop-up Inc. also reserves the right to refuse Freelancer’s participation in future projects based on violations of the Code of Conduct.
    </p>
  </div>
);

export default FreelancerCodeOfConduct;
