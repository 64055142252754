import React, { useState } from "react";

// components
import { Modal, Button, Input, Loader } from "../../components";

// context
import { useAuth, useToast } from "../../context";

// services
import { UserService, errorMsgForUser } from "../../services";

const ModalForgotPassword = ({ closeModal }) => {
  // context
  const { authContextActions } = useAuth();
  const { showToast } = useToast();

  // local
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const submitClicked = () => {
    // email validation
    if (!authContextActions.verifyEmail(email)) {
      console.error("Email NOT valid.");
      showToast("error", "Email is not valid");
      return;
    }

    setLoader(true);
    return UserService.forgotPassword({ email })
      .then(() => {
        showToast("success", "Request sent");
        closeModal(false);
      })
      .catch((err) => {
        if (err?.status === "error" && err?.message === "User not found") {
          showToast("success", "Request sent");
        } else {
          showToast("error", errorMsgForUser(err));
        }
      })
      .finally(() => setLoader(false));
  };

  const isSubmitDisabled = () => {
    if (email) {
      return false;
    }
    return true;
  };

  return (
    <>
      {/* loader */}
      {loader && <Loader themeColor="fill-prime" />}

      <Modal
        title="Forgot password?"
        closeModal={closeModal}
        maxWidth="max-w-xl"
        buttons={[
          <Button
            dataTestId="close"
            label="Close"
            variant="flat"
            themecolor="prime"
            onClick={closeModal}
          />,
          <Button
            dataTestId="submit"
            label="Submit"
            variant="full"
            themecolor="prime"
            className="ml-4"
            onClick={submitClicked}
            disabled={isSubmitDisabled()}
          />,
        ]}
      >
        <div>
          <p className="mb-4 text-base leading-relaxed text-prime-light-dark dark:text-prime-light-dark">
            If we have an account for this email, we will email a link to reset the account
            password. Make sure to check your spam folder if you have not received your password
            reset email.
          </p>

          <Input
            dataTestId="email"
            type="email"
            label="Email *"
            placeholder=""
            className="text-left"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && submitClicked()}
          />
        </div>
      </Modal>
    </>
  );
};

export default ModalForgotPassword;
