import React from "react";
import { ContactManager, Paper, Typography } from "../../../components";

const OutOfUsa = () => (
  <>
    <Paper className="flex flex-col h-1/2">
      <Typography type="title" className="mb-4">
        Oops! Looks like you are outside the United States
      </Typography>
      <p className="text-lg mb-4">
        Currently in-app payments are only supported for US based Cyber-experts
      </p>
      <p className="text-lg mb-4">
        Not to worry though, if you complete a project your FSM will reach out to you to arrange
        payment via PayPal
      </p>
    </Paper>
    <Paper className="w-full p-6 border-2 z-0 mt-6">
      <Typography type="subtitle" className="mb-4 text-prime text-center">
        Still need help or have a question?
      </Typography>

      <div className="flex justify-evenly">
        <ContactManager role="Csm" showHelpText={false} />
      </div>
    </Paper>
  </>
);

export default OutOfUsa;
