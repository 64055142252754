// NOTE: if landing route is changed - change also 'OnMount.js' and 'TwoFA.js'

import { Routes, Route } from "react-router-dom";

// auth pages
import Header from "./components/header/Header";
import { useAuth } from "./context/AuthContext";
import FSMTable from "./pages/FSM/FSMTable";
import NotFound from "./pages/NotFound";
// import LandingPage from "./pages/auth/LandingPage";
// import ApplicationShell from "./pages/appShell/ApplicationShell";
import CompanySignUp from "./pages/auth/CompanySignUp";
import OnMount from "./pages/auth/OnMount";
import RedirectNextAuth from "./pages/auth/RedirectOnboarding";
import ResetPassword from "./pages/auth/ResetPassword";
import SignIn from "./pages/auth/SignIn";
import SignOut from "./pages/auth/SignOut";
import SignUp from "./pages/auth/SignUp";
import SignUpCompanyFinish from "./pages/auth/SignUpCompanyFinish";
import TwoFA from "./pages/auth/TwoFA";
import VerifyEmail from "./pages/auth/VerifyEmail";

// onboarding pages

// project overview

// logged page
// import DashboardCustomer from "./pages/dashboard/DashboardCustomer";

// import PaymentsFreelancer from './pages/payments/PaymentsFreelancer';

// FSM

// CSM
// import ProjectView from './pages/CSM/ProjectView';

// demo pages
import Buttons from "./pages/demo/Buttons";
import Cards from "./pages/demo/Cards";
import Chat from "./pages/demo/Chat";
import Components from "./pages/demo/Components";
import Form from "./pages/demo/Form";
import Table from "./pages/demo/Table";
import CompanyOnboardingVideo from "./pages/onboarding/CompanyOnboardingVideo";
// import CustomerDashboardTourVideo from "./pages/onboarding/CustomerDashboardTourVideo";
// import CustomerPayment from "./pages/onboarding/CustomerPayment";
// import CustomerRiskRating from "./pages/onboarding/CustomerRiskRating";
import FreelancerEntuziastOnboardingVideo from "./pages/onboarding/FreelancerEntuziastOnboardingVideo";

// import ProjectsCustomer from "./pages/projects/customer/ProjectsCustomer";

// import CustomerQuestionnaire from "./pages/questionnaire/CustomerQuestionnaire";

import ProgramOnboarding from "./pages/partnerOnboarding/ProgramOnboarding";
import CsmRoutes from "./pages/routes/CsmRoutes";
import CustomerRoutes from "./pages/routes/CustomerRoutes";
import FreelancerRoutes from "./pages/routes/FreelancerRoutes";
import CookiePolicy from "./pages/terms-privacies/CookiePolicy";
import FreelancerCodeOfConductPage from "./pages/terms-privacies/FreelancerCodeOfConductPage";
import PrivacyPolicy from "./pages/terms-privacies/PrivacyPolicy";
import TermsAndConditions from "./pages/terms-privacies/TermsAndConditions";

// components

// context

function App() {
  localStorage.setItem("version:", "1.1.298");

  // use auth context
  const { authContext } = useAuth();

  // local storage
  // const { token } = localStorage;

  return (
    <>
      {/* validation and collecting unnecessary data */}
      {/* <OnMount /> */}

      {/* <OnMount /> */}
      <Header />

      <div className="App">
        <Routes>
          {/* auth pages */}
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/user/verify/:token/:userUid" element={<VerifyEmail />} />
          <Route path="/user/verify/:token/:userUid/customer" element={<VerifyEmail />} />
          <Route path="/signin" element={<RedirectNextAuth />} />
          <Route path="/user/change-password" element={<ResetPassword />} />
          {/* <Route path="/signup-company" element={<SignUpCompany />} /> */}
          <Route path="/signup-company" element={<RedirectNextAuth />} />
          <Route path="/signup-company/:plan_uid" element={<RedirectNextAuth />} />

          <Route
            path="/signup-company-finish/:company_uid/:user_uid"
            element={<RedirectNextAuth />}
          />
          <Route
            path="/two-factor-authorization/:profileUid/:questionnaireUid"
            element={<NotFound />}
          />
          <Route path="/signout" element={<RedirectNextAuth />} />

          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/freelancer-code-of-conduct" element={<FreelancerCodeOfConductPage />} />
          <Route path="/onboarding-video/:role" element={<FreelancerEntuziastOnboardingVideo />} />
          <Route path="/company-onboarding-video" element={<CompanyOnboardingVideo />} />
          <Route path="/onboarding/:partner" element={<RedirectNextAuth />} />
          {/* <Route path="/app" element={<ApplicationShell />} /> */}

          {/* private pages */}
          {/* if user not logged in */}

          <>
            {/* Customer routes */}
            {authContext?.role === "Customer" &&
              CustomerRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}

            {/* Freelancer / Enthusiast */}
            {(authContext?.role === "Enthusiast" || authContext?.role === "Freelancer") &&
              FreelancerRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}

            {/* FSM */}
            {authContext?.role === "Fsm" && (
              <>
                <Route path="/fsm/freelancers" element={<FSMTable />} />
              </>
            )}

            {/* CSM */}
            {authContext?.role === "Csm" &&
              CsmRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}

            {/* demo pages */}
            <Route path="/demo/buttons" element={<Buttons />} />
            <Route path="/demo/form" element={<Form />} />
            <Route path="/demo/components" element={<Components />} />
            <Route path="/demo/cards" element={<Cards />} />
            <Route path="/demo/table" element={<Table />} />
            <Route path="/demo/chat" element={<Chat />} />

            {/*remix component pages */}
            {/*
              <Route path="/remix/next-steps" element={<NextSteps />} />
              <Route path="/remix/stepper" element={<Stepper />} />
              <Route path="/remix/footer" element={<FooterRemix />} />
              */}
          </>

          {/* <Route path="/contact-support" element={<ContactSupport />} /> */}

          {/* no mach */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
