// import { forwardRef } from 'react';
// import { ProjectTemplateService } from '../../services';

import React from "react";
import Select from "react-select";

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' },
// ];

const SelectWithSearch = ({
  label,
  options,
  placeholder,
  dataTestId,
  className = "mb-4",
  onChange,
  isDisabled,
  value,
}) => (
  <div className={`select_with_search ${className}`}>
    <span className="block text-prime-light">{label}</span>
    <Select
      dataTestId={dataTestId}
      styles={styles}
      value={value}
      label={label}
      onChange={onChange}
      isMulti
      isDisabled={isDisabled}
      placeholder={placeholder}
      options={options}
    />
  </div>
);

let styles = {
  container: (provided) => ({
    ...provided,
    borderRadius: "13px",
  }),
  control: (provided) => ({
    ...provided,
    background: "rgb(249, 250, 251)",
    minHeight: "2.6rem",
    borderRadius: "0.375rem",
  }),
};

export default SelectWithSearch;
