import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import { EnhancedTable, Tabs, Typography } from "../../../components";

// context
import { useToast, useAuth } from "../../../context";

// services
import { ProjectService, errorMsgForUser } from "../../../services";
import CustomerView from "../../CSM/CustomerView";
import ModalCmsWillBeInContact from "../ModalCmsWillBeInContact";

const PROJECTS_TABLE_COLUMNS = [
  {
    id: "project",
    type: "",
    label: "Project",
  },
  {
    id: "customer_name",
    type: "td-modal",
    label: "Client",
    style: "hover:underline text-terc",
  },
  {
    id: "due_date",
    type: "date",
    label: "Due date",
  },
  {
    id: "status",
    type: "badge",
    label: "Project status",
    style: "min-w-[200px]",
  },
  {
    id: "hours",
    type: "date-delta",
    label: "Hours",
    style: "min-w-[200px] text-center",
  },
];

const ProjectsFreelancer = () => {
  let navigate = useNavigate();

  // context
  const { showToast } = useToast();
  const { authContext } = useAuth();

  // local
  const [loader, setLoader] = useState(0);
  // const [page, setPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  // const [searchTerm, setSearchTerm] = useState('');
  const [projects, setProjects] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    window.history.state.usr?.tab || {
      value: "Started",
      label: "Current",
    }
  );
  const [modalComingSoon, setModalComingSoon] = useState(false);
  const [modalCustomerView, setModalCustomerView] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const fetchCustomerProjects = useCallback(() => {
    setLoader((l) => l + 1);

    ProjectService.getAllProjectsByFreelancer({
      freelancer_uid: authContext?.user?.uid,
      project_status: currentTab?.value,
    })
      .then((r) => {
        setProjects([...r.projects]);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  }, [authContext?.user?.uid, currentTab?.value, showToast]);

  const handleTabChange = (tab) => {
    if (tab.label !== currentTab.label) {
      // setPage(1);
      setProjects([]);
      // navigate(window.location.pathname, { state: { tab } });
      setCurrentTab(tab);
      // getAllProjects(1, tab);
    }
  };

  const handleTableDataModalClick = (item, cell) => {
    if (cell?.id === "customer_name") {
      setSelectedCustomer({ ...item, id: item.customer_uid });
      setModalCustomerView(true);
    }
  };

  const handleRowClick = useCallback(
    (project) => {
      setModalCustomerView(true);
      // console.log('Project', project);

      // if (import.meta.env.VITE_APP_ENV === 'production') {
      //   setModalComingSoon(true);
      //   return;
      // }

      navigate(`/project/${project.id}`);
    },
    [navigate]
  );

  // Preporcessed data needed for EnhancedTable component
  const tableRows = projects.map((item) => ({
    "data-testid": item.uid,
    id: item.uid,
    project: item.name,
    customer_name: item.customer_company_name,
    custemer_responsible_person: item.customer_full_name,
    customer_uid: item.customer_uid,
    due_date: item.due_date,
    status: item.project_status,
    hours: item.work_hours_spent,
  }));

  useEffect(() => {
    fetchCustomerProjects();
  }, [currentTab, fetchCustomerProjects]);

  return (
    <div className="container flex-1 mx-auto p-page">
      {/* loader */}
      {/* {!!loader && <Loader themeColor="fill-prime" />} */}

      <Typography type="title" className="mb-8 text-center">
        Projects
      </Typography>

      <div>
        <Tabs
          dataTestId="some-id"
          className="mb-4"
          tabs={[
            {
              value: "Started",
              label: "Current",
            },
            {
              value: "Completed",
              label: "Closed",
            },
          ]}
          value={currentTab.value}
          onChange={(tab) => handleTabChange(tab)}
        />
        <EnhancedTable
          showTableTop={false}
          tableColumns={PROJECTS_TABLE_COLUMNS}
          tableRows={tableRows}
          loader={loader}
          noInfiniteScroll={true}
          handleTableDataModalClick={(item, cell) => handleTableDataModalClick(item, cell)}
          handleRowClick={(project) => handleRowClick(project)}
          // hasMore={projects.length === counters[currentTab.value]}
          // fetchMore={() => {
          //   setPage(p => p + 1);
          // }}
          pagination={false}
        />
      </div>

      {modalComingSoon && <ModalCmsWillBeInContact closeModal={() => setModalComingSoon(false)} />}

      {modalCustomerView && (
        <CustomerView
          item={selectedCustomer}
          closeModal={() => {
            setModalCustomerView(false);
          }}
        />
      )}
    </div>
  );
};

export default ProjectsFreelancer;
