import React, { useEffect, useState } from "react";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";

interface PageNumberFooterProps {
  projectsPerPage: number;
  totalProjects: number;
  currentPage: number;
  paginate: any;
}

const PageNumberFooter: React.FC<PageNumberFooterProps> = ({
  projectsPerPage,
  totalProjects,
  currentPage,
  paginate,
}) => {
  const [active, setActive] = useState(currentPage);
  const [pages, setPages] = useState<number[]>([1]);

  const handlePaginateClick = (page: number) => {
    if (page <= 0) page = 1;
    if (page >= pages.length) page = pages.length;
    setActive(page);
    paginate(page);
  };

  useEffect(() => {
    const newPages: number[] = [];
    for (let i = 1; i <= Math.ceil(totalProjects / projectsPerPage); i++) {
      newPages.push(i);
    }
    setPages(newPages);
  }, [totalProjects]);

  return (
    <>
      {totalProjects > projectsPerPage && (
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
          <div className="-mt-px flex w-0 flex-1">
            <p
              onClick={() => handlePaginateClick(currentPage - 1)}
              className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-lg font-medium text-prime-500 hover:border-gray-300 hover:text-gray-700 hover:cursor-pointer"
            >
              <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              Previous
            </p>
          </div>

          <div className="hidden md:-mt-px md:flex">
            <ul>
              {pages.map((page) => {
                let className =
                  "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-prime-500 hover:border-gray-300 hover:text-gray-700 hover:cursor-pointer";
                if (page === active) {
                  className =
                    "inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600 hover:cursor-pointer";
                }
                return (
                  <li
                    key={page}
                    className={className}
                    onClick={() => handlePaginateClick(page)}
                    aria-current="page"
                  >
                    {page}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="-mt-px flex w-0 flex-1 justify-end">
            <p
              onClick={() => handlePaginateClick(currentPage + 1)}
              className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-lg font-medium text-prime-500 hover:border-gray-300 hover:text-gray-700 hover:cursor-pointer"
            >
              Next
              <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </p>
          </div>
        </nav>
      )}
    </>
  );
};

export default PageNumberFooter;
