import React from "react";
import { isEmpty } from "lodash";
import PropTypes, { object } from "prop-types";

// Components
import Button from "../Button";

const Card = ({
  title,
  subtitle,
  subtitleClass,
  customClass = "text-md text-gray-500 font-medium",
  icon,
  wrapperWidth,
  children,
  description,
  subdescription,
  buttons,
}) => (
  <div
    style={{ width: wrapperWidth }}
    className={`shadow-md bg-white p-8 rounded-2xl ${customClass}`}
  >
    <div className="mb-8">
      {/* Icon */}
      {icon && <div className="mt-5">{icon}</div>}
      {/* Title */}
      {title && <div className="text-3xl font-medium text-left text-prime">{title}</div>}

      {/* subtitle */}
      {subtitle && <div className={subtitleClass}>{subtitle}</div>}
    </div>
    <div className="mb-8">
      {description}
      {subdescription && <div>{subdescription}</div>}
    </div>
    <div>{children}</div>

    {/* Button */}
    {!isEmpty(buttons) && (
      <div className="mt-8">
        {buttons.map((button, index) => (
          <Button
            key={index}
            dataTestId="card-button"
            label={button.btnLabel}
            variant={button.btnVariant}
            themecolor={button.btnColor}
            onClick={button.action}
            className={`mb-4 block mx-auto ${button.className || ""}`}
            disabled={button.disabled}
          />
        ))}
      </div>
    )}
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string || PropTypes.node,
  subtitle: PropTypes.string || PropTypes.node,
  description: PropTypes.string || PropTypes.node,
  subdescription: PropTypes.string || PropTypes.node,
  wrapperWidth: PropTypes.string,
  buttons: PropTypes.arrayOf(object),
};

export default Card;
