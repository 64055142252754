import React, { useState } from "react";
import { Card } from "../../../components";

// components
import CalendlyBlock from "./CalendlyBlock";
import WorkAvailabilityBlock from "./WorkAvailabilityBlock";

const WorkAvailability = ({ profile, getProfile, profileEditsLocked }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Card title="Work Availability" subtitle="For project matching purposes only">
        <WorkAvailabilityBlock
          profile={profile}
          getProfile={getProfile}
          profileEditsLocked={profileEditsLocked}
        />
      </Card>

      <Card
        customClass="mt-8"
        title="Connect your calendar"
        subtitle="For customer and customer success manager meetings."
        buttons={[
          {
            btnLabel: "Add Link to Calendly",
            btnVarinat: "outlined",
            className: profile?.calendly_link ? "" : "border-2 border-error",
            action: () => setOpen(true),
            disabled: profileEditsLocked,
          },
        ]}
      >
        <CalendlyBlock
          open={open}
          setOpen={setOpen}
          profile={profile}
          getProfile={getProfile}
          profileEditsLocked={profileEditsLocked}
        />
      </Card>
    </>
  );
};

export default WorkAvailability;
