import { evalQuery } from "./index";

export const ProfileService = {
  create: (request) =>
    evalQuery({
      url: "/profile/create",
      request,
    }),

  get: (request) =>
    evalQuery({
      url: "/profile/get",
      request,
    }),

  getByEmail: (request) =>
    evalQuery({
      url: "/profile/get-by-email",
      request,
    }),

  update: (request) =>
    evalQuery({
      url: "/profile/update",
      request,
    }),

  delete: (request) =>
    evalQuery({
      url: "/profile/delete",
      request,
    }),

  getNotes: (request) =>
    evalQuery({
      url: "/profile/get-note-about-customer",
      request,
    }),

  updateNotes: (request) =>
    evalQuery({
      url: "/profile/update-note-for-customer",
      request,
    }),

  getAllCsm: (request) =>
    evalQuery({
      url: "/profile/get-all-csm",
      request,
    }),

  getFreelancers: (request) =>
    evalQuery({
      url: "/profile/get-freelancers",
      request,
    }),
};
