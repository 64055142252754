import { useLocation } from "react-router-dom";
import App from "./App";
const RouterFile = () => {
  let location = useLocation();

  if (location.pathname === "/") {
    window.location.href = "/app/home";
  } else {
    return <App />;
  }
};

export default RouterFile;
