import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// components
import {
  Paper,
  Button,
  CardSecure,
  Loader,
  Divider,
  Typography,
  StripeElements,
  ModalNoStripeAccount,
} from "../../components";
import { testProductIds, liveProductIds } from "../../constants/stripe";
import { useAuth, useToast } from "../../context";
import { StripeService, errorMsgForUser } from "../../services";
import Stepper from "../questionnaire/Stepper";

// context

// services

// constants
const APP_ENV = import.meta.env.VITE_APP_ENV;

const CustomerPayment = () => {
  // router
  let navigate = useNavigate();
  let { planId } = useParams();

  // context
  const { authContext } = useAuth();
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(false);
  const [cardObj, setCardObj] = useState(null);

  const getSelectedProduct = () => {
    // ser product_ids for different envirement
    let product_ids = testProductIds;

    if (APP_ENV === "production") {
      product_ids = liveProductIds;
    }

    // get plans from Stripe Api
    setLoader(true);
    return StripeService.getPlans({ product_ids })
      .then((res) => {
        // set active plan
        const allPlans = res?.stripe_plans || [];
        const findPlan = allPlans.find((n) => n.id === planId);

        if (findPlan) {
          setCardObj(findPlan);
          console.log("selected plan:", findPlan);
        }
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  const submitePayment = (stripePaymentMethod) => {
    const subscriptionObj = {
      user_uid: authContext?.user?.uid,
      plan_id: cardObj?.id,
      stripe_account_id: authContext?.user?.stripe_account_id,
      stripe_payment_method_id: stripePaymentMethod?.id,
    };

    // subscribe to plan Stripe Api
    setLoader(true);
    return StripeService.createSubscription(subscriptionObj)
      .then(() => {
        // success message
        showToast("success", "Your subscription was successful.");

        // reroute
        navigate("/dashboard-tour-video");
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  const setPerks = (perks) => {
    const splitPerks = perks.split(",");
    return splitPerks;
  };

  const montlyFeeToBeDisplayed = (cardObj) => {
    let result = 0;
    if (cardObj?.metadata?.amount_shown) {
      result = Number(cardObj?.metadata?.amount_shown / 100);
    } else {
      result = Number(cardObj?.amount / 100);
    }

    return result;
  };

  const totalPriceToBeDisplayed = (cardObj) => {
    let result = 0;
    let monthly = 0;
    let oneTimeFee = 0;

    monthly = montlyFeeToBeDisplayed(cardObj);

    if (cardObj?.metadata?.peace_of_mind_roadmap_fee) {
      oneTimeFee = Number(cardObj.metadata.peace_of_mind_roadmap_fee) / 100;
    }

    result = monthly + oneTimeFee;

    return result;
  };

  // get products
  useEffect(() => {
    getSelectedProduct();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center p-page">
      {/* steper */}
      <Stepper step="payment" className="mb-8" itemClassName="px-2" />

      <Paper className="w-full max-w-page">
        <Typography type="title" className="mb-8">
          Payment Information
        </Typography>

        <div className="flex mb-8 justify-evenly">
          {/* left side - stripe element */}
          <div className="relative mr-8">
            {/* loader */}
            {loader && <Loader themeColor="fill-prime" />}

            <Typography type="subtitle" className="mb-3 text-prime-light">
              Today you will be charged
            </Typography>

            <Paper className="w-full mb-8 border-2 shadow-lg">
              <p className="flex justify-between w-full mb-1">
                Subcription Fee (per month):{" "}
                <span className="text-prime">${montlyFeeToBeDisplayed(cardObj) || 0}</span>
              </p>

              <p className="flex justify-between w-full mb-1">
                Peace of Mind Roadmap (one-time fee):{" "}
                <span className="text-prime">
                  {`$${
                    cardObj?.metadata?.peace_of_mind_roadmap_fee
                      ? Number(cardObj.metadata.peace_of_mind_roadmap_fee) / 100
                      : 0
                  }`}
                </span>
              </p>

              <Divider className="mt-1 mb-2" lineColor="bg-grey" />

              <p className="flex justify-between w-full font-bold">
                Total Due Today:{" "}
                <span className="text-prime">${totalPriceToBeDisplayed(cardObj) || 0}</span>
              </p>
            </Paper>

            <Typography type="subtitle" className="mb-3 text-prime-light">
              Please add your payment information
            </Typography>

            <StripeElements submitePayment={submitePayment} />
          </div>

          {/* left side - choosen subscription card */}
          {cardObj ? (
            <div>
              <Typography type="subtitle" className="mb-3 ml-3 text-prime-light">
                Selected {cardObj?.interval === "year" ? "Annual" : "Month-to-month"} Plan
              </Typography>

              <CardSecure
                dataTestingId={cardObj?.title}
                id={cardObj?.id}
                width={350}
                type="medium"
                textSizeSecondary="text-sm"
                bgColor="bg-white"
                currency={cardObj?.currency}
                amount={
                  cardObj.metadata.amount_shown
                    ? cardObj.metadata.amount_shown / 100
                    : cardObj?.amount / 100
                }
                topTitle={cardObj?.metadata?.top_title}
                title={cardObj?.metadata?.title}
                subscription={false}
                planInterval={cardObj.metadata.amount_shown ? "" : "per month"}
                perMonthText={cardObj?.metadata?.per_month_text}
                saveText={cardObj?.metadata?.save_text}
                peaceOfMindText={cardObj?.metadata?.peace_of_mind_text}
                extras={cardObj?.metadata?.extras}
                strechExtras={true}
                noRecommandedBlock={true}
                featuresTitle={cardObj?.metadata?.features_title}
                perks={cardObj?.metadata?.perks ? setPerks(cardObj.metadata.perks) : []}
                strechPerks={false}
              />
            </div>
          ) : (
            <div>
              <Typography type="subtitle" className="mb-3 ml-3 text-prime-light">
                Selected ...
              </Typography>

              <CardSecure
                dataTestingId=""
                width={350}
                type="medium"
                cardClass="min-h-[500px]"
                bgColor="bg-white"
                isLoading={true}
                subscription={false}
                strechExtras={true}
                noRecommandedBlock={true}
                perks={[]}
                strechPerks={false}
              />
            </div>
          )}
        </div>

        {/* bottom buttons */}
        <div className="flex justify-around">
          <Button
            dataTestId="card-button"
            variant="flat"
            themecolor="prime"
            label="Back"
            onClick={() => navigate(-1)}
          />
        </div>
      </Paper>

      {/* modal no stripe account */}
      <ModalNoStripeAccount />
    </div>
  );
};

export default CustomerPayment;
