import { Button, Paper, Typography } from "../../components";
import NextSteps from "./NextSteps";
import Subscribe from "./Subscribe";

const messages = {
  hsb: "",
};

function WelcomeBlurb({ companyProgram, customer, profile }) {
  if (companyProgram?.name === "HSB") {
    return (
      <Paper className="flex-1 p-6 text-left bg-white border-2">
        <Typography type="title" className="my-5 text-prime">
          Sit back & relax!
        </Typography>

        <p className="my-4 text-lg">
          We're matching you with your perfect cyber-expert. You'll receive an email within 1-3
          business days to schedule a time to meet.
        </p>
        <p className="my-4 mt-2 text-lg">
          In the meantime, feel free to explore our selection of cybersecurity projects that are
          available for purchase. This is a great way to protect your data and systems
        </p>
      </Paper>
    );
  }

  if (companyProgram?.program_code === "get-secured") {
    return (
      <Paper className="flex-1 p-6 text-left bg-white border-2">
        <Typography type="title" className="my-5 text-prime">
          Welcome to Cyber Pop-up!
        </Typography>

        <p className="my-4 text-lg">COPY HERE ABOUT TALK TO EXPERT</p>
        <p className="my-4 mt-2 text-lg">
          In the meantime, feel free to explore our selection of cybersecurity projects that are
          available for purchase. This is a great way to protect your data and systems
        </p>
      </Paper>
    );
  }

  if (profile?.peace_of_mind_roadmap_finished) {
    return <NextSteps user={customer} />;
  }

  return <Subscribe />;
}

export default WelcomeBlurb;
