import React, { useState, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

// components
import { Paper, Typography, Button, Input, InputPassword, Loader } from "../../components";

// context
import { useAuth, useToast } from "../../context";

// services
import { UserService, errorMsgForUser } from "../../services";
// import LoginWithGoogle from "./LoginWithGoogle";
import ModalForgotPassword from "./ModalForgotPassword";

const SignIn = () => {
  // reference
  const inputEmail = useRef(null);

  // router
  let navigate = useNavigate();
  let location = useLocation();

  // context
  const { authContextActions } = useAuth();
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [loginScreen, setLoginScreen] = useState(true);
  const [password, setPassword] = useState("");
  const [modalForgotPwdOpened, setModalForgotPwdOpened] = useState(false);

  const isUserComingFromMailLink = (location) => {
    let result = false;
    if (location.pathname !== "/" && location.pathname !== "/signin") {
      result = true;

      return result;
    }
  };

  const isSigninDisabled = () => {
    if (email && password) {
      return false;
    }
    return true;
  };

  const signinClicked = () => {
    // check required fields
    if (isSigninDisabled()) return;

    // email validation
    if (!authContextActions.verifyEmail(email.toLowerCase())) {
      console.error("Email NOT valid.");
      showToast("error", "Email is not valid");
      return;
    }

    // password validation
    const REGEX = /^.{6,}$/;
    if (!REGEX.test(password)) {
      console.error("Password NOT valid.");
      showToast("error", "The minimum password length is 6 characters.");
      return;
    }

    // signIn Api
    setLoader(true);
    return UserService.signIn({ email: email.toLowerCase(), password })
      .then((res) => {
        // console.log('signIn res:', res);

        // consol twoFA code in non production envirements
        if (import.meta.env.VITE_APP_ENV !== "production") {
          const twoFACode = res.two_factor_code.split("code: ")[1];
          console.log("twoFACode:", twoFACode);
        }

        const { is_active, verified, profile_uid, questionnaire_uid } = res;

        // set email to localStorage
        localStorage.setItem("email", email.toLowerCase());

        // is user active
        if (!is_active) {
          return showToast("info", "Your account is not active.");
        }

        // is email verified
        if (!verified) {
          return showToast(
            "info",
            "Your email is not verified. Check your Email. Spam folder too."
          );
        }

        // route to two-factor-authorization page
        return navigate(`/two-factor-authorization/${profile_uid}/${questionnaire_uid}`, {
          state: { comingFromMailLink: isUserComingFromMailLink(location), email },
        });
      })
      .catch((err) => {
        if (err?.message === "User not found" || err?.message === "Wrong credentials.") {
          showToast("error", "Incorrect credentials, please try again.");
        } else if (err?.message === "Account is not verified") {
          UserService.resendVerificationEmail({ email })
            .then(() => {
              showToast(
                "error",
                "Account email not verified. Please check your email, another verification email has been sent!"
              );
            })
            .catch((err) => showToast("error", errorMsgForUser(err)));
        } else {
          showToast("error", errorMsgForUser(err));
        }
      })
      .finally(() => setLoader(false));
  };

  const resendVerificationClicked = (email) => {
    if (!email) {
      inputEmail.current.focus();
      setLoginScreen(false);
      showToast("warning", "Please fill in your email address.");
    } else {
      setLoader(true);
      UserService.resendVerificationEmail({ email })
        .then(() => {
          showToast("success", "Verification link sent. Please check your email.");
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader(false));
    }
  };

  // useEffect(() => {
  //   const { token } = localStorage;
  //   if (token) {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <div className="flex justify-center p-page">
      <Paper className="relative min-w-[400px]">
        {/* loader */}
        {loader && <Loader themeColor="fill-prime" />}

        <Typography type="title" className="mb-8 text-center">
          {loginScreen ? "Log in" : "Resend verification link"}
        </Typography>

        <Input
          dataTestId="email"
          type="email"
          name="email"
          label="Email *"
          value={email}
          ref={inputEmail}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && signinClicked()}
        />
        {loginScreen ? (
          <>
            <InputPassword
              dataTestId="password"
              className="mb-10"
              label="Password *"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && signinClicked()}
            />

            <Button
              dataTestId="signin-button"
              label="Log In"
              variant="full"
              themecolor="prime"
              className="block mx-auto mb-12"
              disabled={isSigninDisabled()}
              onClick={signinClicked}
            />

            <div className="text-xs text-center">
              <div className="mr-2 text-sm font-semibold text-prime-light">Not signed up?</div>{" "}
              <br />
              <Link
                data-testid="signup-button"
                to="https://www.cyberpopup.com/pricing"
                className="text-xs text-prime hover:underline"
              >
                <span className="underline">Click here</span> to start your cybersecurity journey.
              </Link>{" "}
              <br />
              <Link
                data-testid="signup-button"
                to="/signup"
                className="text-xs text-prime hover:underline"
              >
                <span className="underline">Click here</span> to join us as a cyber-expert.
              </Link>
            </div>

            <hr className="h-px my-2 mb-4 bg-gray-200 border-0 dark:bg-gray-700" />

            {/* // <Divider
            //   className="text-xs"
            //   text="or"
            //   lineColor="grey-light"
            //   textColor="text-grey-light"
            // /> */}

            <Button
              dataTestId="forgot-password-button"
              label="Forgot Password"
              variant="flat"
              themecolor="prime-light"
              className="block pb-2 mx-auto text-xs"
              onClick={() => setModalForgotPwdOpened(true)}
            />

            <p className="text-sm text-center">
              <span className="mr-2 text-xs text-center text-prime-light">
                Didn't verified email?
              </span>
              <span
                className="text-xs text-prime hover:underline hover:cursor-pointer"
                onClick={() => resendVerificationClicked(email)}
              >
                Resend verification
              </span>
            </p>
          </>
        ) : (
          <>
            <Button
              dataTestId="resend-verification-button"
              label="Resend verification"
              variant="full"
              themecolor="prime"
              className="block mx-auto mt-6 mb-6"
              onClick={() => resendVerificationClicked(email)}
            />

            <Button
              dataTestId="forgot-password-button"
              label="return to Log In"
              variant="flat"
              themecolor="prime"
              className="block pb-2 mx-auto text-xs"
              onClick={() => setLoginScreen(true)}
            />
          </>
        )}
        {/* or sign in with google */}
        {/* <LoginWithGoogle /> */}
      </Paper>

      {/* Modal Forgot Password */}
      {modalForgotPwdOpened && (
        <ModalForgotPassword closeModal={() => setModalForgotPwdOpened(false)} />
      )}
    </div>
  );
};

export default SignIn;
