import { evalQuery } from "./index";

export const UserService = {
  signUp: (request) =>
    evalQuery({
      url: "/user/sign-up",
      request,
    }),

  resendVerificationEmail: (request) =>
    evalQuery({
      url: "/user/resend-verification-email",
      request,
    }),

  verifyEmail: (request) =>
    evalQuery({
      url: "/user/verify-email",
      request,
    }),

  signIn: (request) =>
    evalQuery({
      url: "/user/sign-in",
      request,
    }),

  signInWithGoogle: (request) =>
    evalQuery({
      url: "/user/google-callback",
      request,
    }),

  forgotPassword: (request) =>
    evalQuery({
      url: "/user/forgot-password",
      request,
    }),

  resetPassword: (request) =>
    evalQuery({
      url: "/user/change-password",
      request,
    }),

  changePassword: (request) =>
    evalQuery({
      url: "/user/change-user-password",
      request,
    }),

  get: (request) =>
    evalQuery({
      url: "/user/get",
      request,
    }),

  update: (request) =>
    evalQuery({
      url: "/user/update",
      request,
    }),

  signInTwoFa: (request) =>
    evalQuery({
      url: "/user/sign-in-two-fa",
      request,
    }),

  resendTwoFa: (request) =>
    evalQuery({
      url: "/user/resend-two-fa-code",
      request,
    }),

  delete: (request) =>
    evalQuery({
      url: "/user/delete",
      request,
    }),

  updatePhone: (request) =>
    evalQuery({
      url: "/user/change-phone-number",
      request,
    }),

  verifyUpdatePhone: (request) =>
    evalQuery({
      url: "/user/update-phone-number",
      request,
    }),

  sendEmail: (request) =>
    evalQuery({
      url: "/user/send-email",
      request,
    }),

  contactCsmMessage: (request) =>
    evalQuery({
      url: "/user/contact-csm-message",
      request,
    }),

};
