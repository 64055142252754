import React, { forwardRef } from "react";
import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";

const TooltipWrapper = forwardRef(({ children, placement }, ref) => (
  <Tippy
    placement={placement}
    reference={ref}
    animation="shift-away"
    className="arrow-dark"
    interactive
    content={children}
  />
));

TooltipWrapper.defaultProps = {
  placement: "top",
};

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.string.isRequired,
};

export default TooltipWrapper;
