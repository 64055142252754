import React from "react";
import { includes } from "lodash";

// components
import { Input, Checkbox } from "../../components";

// constants
import { customer_questions } from "./customer_questions";

const CustomerQuestionnaireAdditionalQuestions = ({
  blok,
  profile,
  setProfile,
  setInputElement,
}) => {
  const checkboxChanged = (e, checkedValue) => {
    let newAnswerObj = [];
    if (e.target.checked) {
      newAnswerObj = [...profile.vendor_collaboration_tools, checkedValue];
    } else {
      newAnswerObj = profile.vendor_collaboration_tools.filter((n) => n !== checkedValue);
    }
    setProfile((n) => ({
      ...n,
      vendor_collaboration_tools: newAnswerObj,
    }));
  };

  return (
    <div className="mb-6">
      {/* is cybersecurity_strategy  */}
      {blok === "6" &&
        (profile.formal_cyber_security_strategy === "We have one, but it needs work" ||
          profile.formal_cyber_security_strategy ===
            "We have a world-class Cybersecurity program") && (
          <div>{setInputElement("cybersecurity_strategy")}</div>
        )}

      {/* is cyber_maturity  */}
      {blok === "7" &&
        profile.cyber_maturity ===
          "We have a 5 star program and the cyber attackers can bring it on!" && (
          <div>{setInputElement("cyber_security_controls")}</div>
        )}

      {/* is vendor_collaboration_tools  */}
      {blok === "9" && (
        <div>
          <div className="text-prime-light mb-2">
            {customer_questions?.vendor_collaboration_tools?.label || ""}{" "}
            {customer_questions?.vendor_collaboration_tools?.required && "*"}
          </div>

          <Checkbox
            dataTestId="vendor_collaboration_tools-0"
            label="Cloud email (i.e. Google Workspace, Microsoft O365, ZohoMail, etc.)"
            className="mb-4"
            value="vendor_collaboration_tools-0"
            // checked={profile[questionObj.value]}
            checked={includes(
              profile.vendor_collaboration_tools,
              "Cloud email (i.e. Google Workspace, Microsoft O365, ZohoMail, etc.)"
            )}
            onChange={(e) =>
              checkboxChanged(
                e,
                "Cloud email (i.e. Google Workspace, Microsoft O365, ZohoMail, etc.)"
              )
            }
          />

          {includes(
            profile.vendor_collaboration_tools,
            "Cloud email (i.e. Google Workspace, Microsoft O365, ZohoMail, etc.)"
          ) && (
            <Input
              dataTestId="cloud_email_tool"
              type="text"
              label="Which one?"
              value={profile.cloud_email_tool}
              className="mb-6"
              onChange={(e) => setProfile((n) => ({ ...n, cloud_email_tool: e.target.value }))}
            />
          )}

          <Checkbox
            dataTestId="vendor_collaboration_tools-1"
            label="Cloud collaboration tools (i.e. Slack, Teams, Google chat)"
            className="mb-4"
            value="vendor_collaboration_tools-1"
            // checked={profile[questionObj.value]}
            checked={includes(
              profile.vendor_collaboration_tools,
              "Cloud collaboration tools (i.e. Slack, Teams, Google chat)"
            )}
            onChange={(e) =>
              checkboxChanged(e, "Cloud collaboration tools (i.e. Slack, Teams, Google chat)")
            }
          />

          {includes(
            profile.vendor_collaboration_tools,
            "Cloud collaboration tools (i.e. Slack, Teams, Google chat)"
          ) && (
            <Input
              dataTestId="cloud_collaboration_tool"
              type="text"
              label="Which one?"
              value={profile.cloud_collaboration_tool}
              className="mb-6"
              onChange={(e) =>
                setProfile((n) => ({
                  ...n,
                  cloud_collaboration_tool: e.target.value,
                }))
              }
            />
          )}

          <Checkbox
            dataTestId="vendor_collaboration_tools-2"
            label="Cloud file storage solutions (i.e. Google Drive, Box, OneDrive)"
            className="mb-4"
            value="vendor_collaboration_tools-2"
            // checked={profile[questionObj.value]}
            checked={includes(
              profile.vendor_collaboration_tools,
              "Cloud file storage solutions (i.e. Google Drive, Box, OneDrive)"
            )}
            onChange={(e) =>
              checkboxChanged(e, "Cloud file storage solutions (i.e. Google Drive, Box, OneDrive)")
            }
          />

          {includes(
            profile.vendor_collaboration_tools,
            "Cloud file storage solutions (i.e. Google Drive, Box, OneDrive)"
          ) && (
            <Input
              dataTestId="cloud_file_storage_tool"
              type="text"
              label="Which one?"
              value={profile.cloud_file_storage_tool}
              className="mb-6"
              onChange={(e) =>
                setProfile((n) => ({
                  ...n,
                  cloud_file_storage_tool: e.target.value,
                }))
              }
            />
          )}

          <Checkbox
            dataTestId="vendor_collaboration_tools-3"
            label="Vendor hosted website (i.e. Wix, Wordpress, Squarespace, Shopify)"
            className="mb-4"
            value="vendor_collaboration_tools-3"
            // checked={profile[questionObj.value]}
            checked={includes(
              profile.vendor_collaboration_tools,
              "Vendor hosted website (i.e. Wix, Wordpress, Squarespace, Shopify)"
            )}
            onChange={(e) =>
              checkboxChanged(
                e,
                "Vendor hosted website (i.e. Wix, Wordpress, Squarespace, Shopify)"
              )
            }
          />

          {includes(
            profile.vendor_collaboration_tools,
            "Vendor hosted website (i.e. Wix, Wordpress, Squarespace, Shopify)"
          ) && (
            <Input
              dataTestId="vendor_hosted_website_tool"
              type="text"
              label="Which one?"
              value={profile.vendor_hosted_website_tool}
              className="mb-6"
              onChange={(e) =>
                setProfile((n) => ({
                  ...n,
                  vendor_hosted_website_tool: e.target.value,
                }))
              }
            />
          )}
        </div>
      )}

      {/* is how_did_you_hear_about_us */}
      {blok === "13" && profile.how_did_you_hear_about_us === "Other" && (
        <div>{setInputElement("how_did_you_hear_about_us_other")}</div>
      )}
    </div>
  );
};

export default CustomerQuestionnaireAdditionalQuestions;
