import React, { useState, useEffect } from "react";

// components
import {
  Paper,
  Typography,
  Button,
  Modal,
  Tabs,
  ProgressBar,
  Divider,
  Accordion,
} from "../../components";

// context
import { useToast } from "../../context";

const Components = () => {
  // context
  const { showToast } = useToast();

  const [modalOpened, setModalOpened] = useState(false);

  const [tab, setTab] = useState("One");

  useEffect(() => {
    // console.log('tab:', tab);
  }, [tab]);

  return (
    <>
      <div className="flex-1 flex justify-center p-page">
        <Paper className="min-w-[400px]">
          <Typography type="subtitle" className="text-center mt-8">
            Modal
          </Typography>
          <Button
            dataTestId="open-modal-some"
            label="Open Some Modal"
            variant="full"
            themecolor="prime"
            className="block m-auto"
            onClick={() => setModalOpened(true)}
          />
          {modalOpened && (
            <Modal
              title="Modal Title"
              closeModal={() => setModalOpened(false)}
              buttons={[
                <Button
                  dataTestId="close"
                  label="Close"
                  variant="flat"
                  themecolor="prime"
                  className="mr-2"
                  onClick={() => setModalOpened(false)}
                />,
                <Button
                  dataTestId="accept"
                  label="Accept"
                  variant="full"
                  themecolor="prime"
                  onClick={() => setModalOpened(false)}
                />,
              ]}
            >
              <p className="text-base leading-relaxed text-prime-light-dark dark:text-gray-400">
                With less than a month to go before the European Union enacts new consumer privacy
                laws for its citizens, companies around the world are updating their terms of
                service agreements to comply.
              </p>
            </Modal>
          )}

          <Typography type="subtitle" className="text-center mt-8">
            Toasts
          </Typography>
          <Button
            dataTestId="open-toast-some"
            label="Open Success Toast"
            variant="full"
            themecolor="success"
            className="mb-4 block mx-auto"
            onClick={() => showToast("success", "Success message")}
          />
          <Button
            dataTestId="open-toast-some"
            label="Open Error Toast"
            variant="full"
            themecolor="error"
            className="mb-4 block mx-auto"
            onClick={() => showToast("error", "Error message")}
          />
          <Button
            dataTestId="open-toast-some"
            label="Open Warning Toast"
            variant="full"
            themecolor="gold"
            className="mb-4 block mx-auto"
            onClick={() => showToast("warning", "Warning message")}
          />

          <Typography type="subtitle" className="text-center mt-8">
            Progress Bar
          </Typography>
          <ProgressBar
            text="1 of 20"
            className="mb-8"
            bgcolor="bg-sec"
            height={17}
            maxStep={23}
            step={2}
          />

          <Divider className="mt-8 mb-8" text="Divider with text" lineColor="bg-grey" />

          <Typography type="subtitle" className="text-center mt-8">
            Tabs
          </Typography>
          <Tabs
            dataTestId="some-id"
            className="mt-2"
            tabs={["One", "Two", "Three", "Four"]}
            value={tab}
            onChange={(tab) => setTab(tab)}
          />

          <Typography type="subtitle" className="text-center mb-4 mt-8">
            Accordion
          </Typography>

          <Accordion
            menuTitle="What To Expect"
            className="py-4"
            classNameBtn="font-medium text-prime-light"
          >
            <div className="text-prime-light text-lg">
              <p className="mb-1">First text</p>
              <p className="mb-1">First text</p>
              <p className="mb-1">Third text</p>
            </div>
          </Accordion>
        </Paper>
      </div>
    </>
  );
};

export default Components;
