// Product keys for geting plans
//           product ids:      yearly                  monthly
// * These are the legacy stripe product ids
// TODO: Migrate all places in the app that use legacy stripe product ids
export const testProductIds = ["prod_MO8t3p8dp0qUvs", "prod_MO8s3AInYuYgJl"];
export const liveProductIds = ["prod_MDgRhrf7vtyo3z", "prod_MDgRj01mr6hTVr"];

// * New stripe product ids
export const testProductIdsStripev2 = [
  "prod_NfDwSEYX9v0U5p",
  "prod_NfDuXdoDR3EX09",
  "prod_NfBmmUxHFyv4Xd",
];