import PrivateRoute from "../../components/privateRoute/PrivateRoute";
import ProjectView from "../../components/project/ProjectView";
import CsmAddEditPlans from "../CSM/CSMDashboard/CsmAddEditPlans";
import CsmDashboard from "../CSM/CsmDashboard";
import CsmManagePlansPrograms from "../CSM/CsmManagePlansPrograms";
import CsmTabActiveAndDrafts from "../CSM/CsmTabActiveAndDrafts";
import CsmTabArchives from "../CSM/CsmTabArchives";
import CsmUserManagementTable from "../CSM/CsmUserManagementTable";
import CustomerTable from "../CSM/CustomerTable";
import ProjectTemplateCreateEdit from "../CSM/ProjectTemplateCreateEdit";

export default [
  {
    path: "/dashboard",
    element: (
      // INFO
      // allowedRoles currently is not implemented
      // Which roles can se what is decided in App.jsx
      // It was meant for more precise control, but there were bugs and
      // later all needed was successfully implemented in current way.
      <PrivateRoute allowedRoles={["Csm"]}>
        <CsmAddEditPlans />
      </PrivateRoute>
    ),
  },
  {
    path: "/user-management",
    element: (
      <PrivateRoute allowedRoles={["Csm"]}>
        <CsmUserManagementTable />
      </PrivateRoute>
    ),
  },
  {
    path: "/project-templates",
    element: (
      <PrivateRoute allowedRoles={["Csm"]}>
        <CsmTabActiveAndDrafts />
      </PrivateRoute>
    ),
  },

  {
    path: "/project-templates/archived",
    element: (
      <PrivateRoute allowedRoles={["Csm"]}>
        <CsmTabArchives />
      </PrivateRoute>
    ),
  },

  {
    path: "/project-template/:projectUid",
    element: (
      <PrivateRoute allowedRoles={["Csm"]}>
        <ProjectTemplateCreateEdit />
      </PrivateRoute>
    ),
  },

  {
    path: "/project/:projectUid",
    element: (
      <PrivateRoute allowedRoles={["Csm"]}>
        <ProjectView />
      </PrivateRoute>
    ),
  },

  {
    path: "/project/:projectUid/:taskUid",
    element: (
      <PrivateRoute allowedRoles={["Csm"]}>
        <ProjectView />
      </PrivateRoute>
    ),
  },
];
