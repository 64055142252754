import { evalQuery } from "./index";

export const CreditService = {
    getCreditsByCompany: (request) =>
        evalQuery({
            url: "/credit/get-by-company",
            request,
        }),

    redeemCredit: (request) =>
        evalQuery({
            url: "/credit/redeem",
            request,
        }),
}