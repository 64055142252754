// components
import { Card } from "../../../components";
import { useAuth } from "../../../context";
import AccountInfo from "./AccountInfo";
import Location from "./Location";

// context

const AccountFreelancer = ({ profile = {}, getProfile, profileEditsLocked }) => {
  // context
  const { authContext } = useAuth();

  return (
    <div>
      <Card title="Account Info" subtitle={authContext?.user.email} customClass="mb-8">
        <AccountInfo profile={profile} profileEditsLocked={profileEditsLocked} />
      </Card>
      <Card
        title="Location and Language"
        subtitle="Location and language information"
        customClass="mb-8"
      >
        <Location
          profile={profile}
          getProfile={getProfile}
          profileEditsLocked={profileEditsLocked}
        />
      </Card>

      {/* <Card
        title="Notification Center"
        subtitle=""
        // subtitle="Please note: Email notifications for collaboration interactions and task items assigned to you are mandatory."
        customClass="mb-8"
      >
        <NotificationCenter
          profile={profile}
          getProfile={getProfile}
          profileEditsLocked={profileEditsLocked}
        />
      </Card> */}
    </div>
  );
};

export default AccountFreelancer;
