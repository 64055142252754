import React from "react";

// components
import { Paper, Typography } from "../../components";

const PrivacyPolicy = () => (
  <div className="p-4 lg:p-12 bg-white">
    <h1 className="text-2xl lg:text-4xl font-bold mb-6">PRIVACY POLICY</h1>
    <p className="text-gray-600 mb-4">Effective Date: August 31, 2023</p>

    {/* ... Add the rest of the sections in a similar format ... */}

    <div className="p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">SCOPE</h1>

      <p className="mb-3">
        This Privacy Policy (“Policy”) describes how Cyber Pop-Up treats personal information on our
        websites and mobile applications where the Policy is located (the “Platform”).
      </p>

      <p>
        This Platform is operated in the United States and intended for use by individuals in the
        United States.
      </p>
    </div>

    <div className="p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">TYPES OF INFORMATION WE COLLECT</h1>

      <p className="mb-3">
        We collect information from you and about you. Here are some examples of the information we
        may collect:
      </p>

      <ul className="list-disc pl-5">
        <li className="mb-2">
          <strong>Contact Information.</strong> For example, we may collect your name, mailing
          address, telephone number, and email address. We may also collect your mobile phone
          number.
        </li>
        <li className="mb-2">
          <strong>Information You Submit.</strong> We may collect information when you send us a
          message through the “Contact Us” page or similar features on our Platform.
        </li>
        <li className="mb-2">
          <strong>Device Information.</strong> For example, we may collect the type of device you
          use to access our Platform. We may also collect your device identifier, IP address, or
          mobile operating system.
        </li>
        <li className="mb-2">
          <strong>Employment Information.</strong> For example, we may collect your resume,
          employment history, and references. We may also collect information relating to your
          current job title and company.
        </li>
        <li className="mb-2">
          <strong>Background Checks.</strong> If you apply to be a freelancer, we may collect any
          information included in a credit or criminal background check.
        </li>
        <li className="mb-2">
          <strong>Commercial Information.</strong> We may collect personal information that could
          identify you and relates to the services in which you are interested or have considered.
          We may also collect personal information about your consuming history or tendencies.
        </li>
        <li className="mb-2">
          <strong>Account Information.</strong> Certain portions of the Platform allow you to create
          a user account. In connection with that account, we may collect your username and
          password.
        </li>
        <li>
          <strong>Other Information.</strong> If you use our website, we may collect information
          about the browser you are using. We might look at what site you came from or what site you
          visit when you leave us.
        </li>
      </ul>
    </div>

    <div className="p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">HOW WE COLLECT YOUR INFORMATION</h1>

      <p className="mb-3">
        We collect your information in different ways. Below are some examples of how we may collect
        your information.
      </p>

      <ul className="list-disc pl-5 mb-3">
        <li className="mb-2">
          <strong>Directly From You.</strong> For example, when you:
        </li>
        <ul className="list-disc pl-5">
          <li>Register for an account;</li>
          <li>Submit an inquiry to us;</li>
          <li>Apply to be a freelancer;</li>
          <li>Download our mobile application;</li>
          <li>Interact with our social media accounts; or</li>
          <li>Become a partner.</li>
        </ul>
        <li className="mb-2">
          <strong>Passively.</strong> For example, when you visit and navigate our Platform on any
          device. We may also collect user information over time and across different websites and
          devices when you use the Platform.
        </li>
        <li className="mb-2">
          <strong>From Third Parties.</strong> We may receive information about you from other
          sources. For example, this may include receiving information from our business partners,
          including analytics vendors. We may also receive information from third-party recruiters
          or career agencies. If we are providing services in relation to a cyber insurance policy,
          we may receive information about you from the insurance carrier.
        </li>
        <li>
          <strong>By Combining Information.</strong> For example, we may combine information we
          collect offline with information we collect through our Platform. We may also combine the
          information we collect about you from the different devices you use to access our
          Platform.
        </li>
      </ul>
    </div>
    <div className="p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">HOW WE USE YOUR INFORMATION</h1>

      <p className="mb-3">Examples of how we may use your information include:</p>

      <ul className="list-disc pl-5 mb-3">
        <li className="mb-2">
          <strong>To Run and Improve Our Platform and Business.</strong> We may use your information
          to make our Platform and business better. This includes using your information to match
          freelancers and partners. We may also use your information to provide you with information
          about our business.
        </li>
        <li className="mb-2">
          <strong>To Respond to Your Requests or Questions.</strong> This may include responding to
          your feedback.
        </li>
        <li className="mb-2">
          <strong>To Communicate With You.</strong> We may communicate with you about your account
          or our relationship. We may also contact you about this Policy or our Platform terms and
          conditions.
        </li>
        <li className="mb-2">
          <strong>For Marketing Purposes.</strong> We may provide you with information about our
          company and our business initiatives. For more information about your choices related to
          these communications, see the Choices section below.
        </li>
        <li className="mb-2">
          <strong>For Security Purposes.</strong> This could include protecting our company and
          consumers who use our products and services. It may also include protecting our Platform,
          such as contacting your cell phone via SMS for multi-factor authentication purposes.
        </li>
        <li className="mb-2">
          <strong>As Otherwise Permitted By Law or As We May Notify You.</strong>
        </li>
        <li>
          <strong>As Requested or Directed By You.</strong>
        </li>
      </ul>
    </div>
    <div className="p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">HOW WE SHARE YOUR INFORMATION</h1>

      <p className="mb-3">We may share your information in the following ways:</p>

      <ul className="list-disc pl-5 mb-3">
        <li className="mb-2">
          <strong>Internally.</strong> We may share your information with our parent, subsidiary,
          and affiliate entities.
        </li>
        <li className="mb-2">
          <strong>With Our Service Providers.</strong> We may share your information with third
          parties who perform services on our behalf. For example, this may include companies that
          send emails on our behalf or help us run our Platform.
        </li>
        <li className="mb-2">
          <strong>With Our Customers.</strong> If you are a freelancer, we may share your
          information with our customers who may be interested in your services. Likewise, if you
          are a business customer, we may share information about you and your company with
          interested freelancers.
        </li>
        <li className="mb-2">
          <strong>With Our Business Partners.</strong> For example, we may also share personal
          information with social media sites, advertisers, media buying agencies, advertising
          networks, and advertising service providers for the purposes described in this Policy. We
          may share personal information with third party partners and/or suppliers who we work
          together with to provide the product or service requested by you. We will only share the
          specific information that is necessary to provide and complete your requested product or
          service. We may also share personal information with companies that help us learn more
          about our customers. If we are providing services in relation to a cyber insurance policy,
          we may share information about you with your insurance carrier.
        </li>
        <li className="mb-2">
          <strong>With Any Successors to All or Part of Our Business.</strong> For example, if we
          merge with, are acquired by, or sell part of our business to another entity. This may
          include an asset sale, corporate reorganization, or other change of control.
        </li>
        <li className="mb-2">
          <strong>To Comply With the Law or To Protect Ourselves.</strong> For example, this could
          include responding to a court order or subpoena. It could also include sharing information
          if a government agency or investigatory body requests. We might share information when we
          are investigating potential fraud.
        </li>
        <li className="mb-2">
          <strong>
            For Other Reasons, We May Describe to You, As Requested or Directed By You.
          </strong>
        </li>
        <li className="mb-2">
          <strong>Opt-Out.</strong> You have the right to opt-out of our disclosure of your personal
          information unless it is required by law. However, we are not responsible or liable for
          any costs paid to the point of your opt-out, if we cannot provide your requested product
          or service due to your opt-out. If you want to opt-out of our disclosure and sharing of
          your information, please email your request to: support@cyberpopup.com within 30 days of
          your receipt of this notice.
        </li>
      </ul>

      <p className="mb-3">
        For purposes of illustration only, third parties may unlawfully intercept or access
        transmissions or private communications, or users may abuse or misuse your information that
        they collect from the Site. Therefore, although we use industry standard practices to
        attempt to achieve the stated goals of our privacy policies, as a matter of practicality we
        cannot and we do not promise, and you should not expect, that your personally identifiable
        information or private communications will always remain private.
      </p>
    </div>
    <div className="p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">CHILDREN UNDER 13</h1>

      <p className="mb-3">
        The Platform where this Policy is located is meant for adults. We do not knowingly collect
        personally identifiable data from persons under the age of 13 and strive to comply with the
        provisions of COPPA (The Children’s Online Privacy Protection Act). If you are a parent or
        legal guardian and think your child under 13 has provided us with information, please
        contact us at{" "}
        <a href="mailto:legal@cyberpopup.com" className="underline text-blue-500">
          legal@cyberpopup.com
        </a>
        . You can also write to us at the address listed at the end of this website Policy. Please
        mark your inquiries as “COPPA Information Request.” Parents, you can learn more about how to
        protect children’s privacy online{" "}
        <a
          href="https://consumer.ftc.gov/articles/protecting-your-childs-privacy-online"
          className="underline text-blue-500"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
    </div>
    <div className="p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">CHOICES REGARDING YOUR INFORMATION</h1>

      <p className="mb-3">
        You have certain choices about how we use your information. Certain choices you make are
        browser and device-specific.
      </p>

      <h2 className="text-xl font-semibold mb-2">Marketing Communications:</h2>
      <p className="mb-3">
        You can opt out of receiving our marketing communications. Note that you will still receive
        transactional messages from us, including information about your account and responses to
        your inquiries.
      </p>

      <h2 className="text-xl font-semibold mb-2">Cookies & Other Tracking Technologies:</h2>
      <p className="mb-3">
        For more information about our use of tracking technologies, including our Do Not Track
        policy, please see our Cookie Policy.
      </p>

      <h2 className="text-xl font-semibold mb-2">Your Privacy Rights</h2>
      <p className="mb-3">
        Depending on your state or country of residence, you may have certain privacy rights under
        applicable law.
      </p>
      <p className="mb-3">You can request:</p>
      <ul className="list-disc pl-5 mb-3">
        <li>Further details about the personal information we collect about you;</li>
        <li>Access to, the rectification of, or deletion of certain personal information; or</li>
        <li>That we stop sharing or using your personal information in certain circumstances.</li>
      </ul>
      <p className="mb-3">
        If you would like to exercise your privacy rights, please contact us at{" "}
        <a href="mailto:legal@cyberpopup.com" className="underline text-blue-500">
          legal@cyberpopup.com
        </a>{" "}
        or at the address listed below.
      </p>
      <p className="mb-3">
        Note that we may take steps to verify your identity before processing your request. Where
        required by law, we may accept a privacy request from someone acting on your behalf;
        however, we may still contact you to verify that this individual is authorized to make the
        request for you.
      </p>
      <p className="mb-3">
        We strive to process privacy requests in compliance with applicable law. However, in some
        circumstances, we may not be legally required or able to comply with your request.
      </p>
      <p className="mb-3">
        You can file a complaint with a local data protection authority or regulator regarding our
        processing of your personal information. That stated, if you have concerns or complaints,
        please let us know so we can work to address the issue.
      </p>
    </div>
    <div className="p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-4">SECURITY</h1>
      <p className="mb-3">
        The Internet is not 100% secure. We cannot promise that your use of our Platform will be
        completely safe. We encourage you to use caution when using the Internet. We use reasonable
        means to safeguard personal information under our control. A user id and a password are
        needed to access certain areas of our Platform. It is your responsibility to protect your
        username and password.
      </p>

      <h1 className="text-2xl font-bold mb-4">STORAGE OF INFORMATION</h1>
      <p className="mb-3">
        Information we maintain may be stored in or outside of the United States. If you live
        outside of the United States, you understand and agree that we may transfer your personal
        information to the United States. This Platform is intended for use in the United States and
        is subject to the laws of the United States, which may not provide the same level of
        protection as those in your own country.
      </p>

      <h1 className="text-2xl font-bold mb-4">LINKS</h1>
      <p className="mb-3">
        Our Platform may contain links to other third-party sites not governed by this Policy. If
        you click on a link to a third-party site, you will be taken to a site we do not control. We
        are not responsible for the privacy practices used by third-party sites. We suggest that you
        read the privacy policies of those sites carefully. We are not responsible for these
        third-party sites.
      </p>

      <h1 className="text-2xl font-bold mb-4">HOW TO CONTACT US</h1>
      <p className="mb-3">
        If you have any questions, comments, or concerns with respect to our privacy practices or
        this Policy or wish to update your information, please feel free to contact us at{" "}
        <a href="mailto:legal@cyberpopup.com" className="underline text-blue-500">
          legal@cyberpopup.com
        </a>
        . You may also write to us at the following address:
      </p>
      <address className="pl-5 mb-3">
        222 W Merchandise Mart
        <br />
        Suite 1212
        <br />
        Chicago, IL 60605
        <br />
        <span className="italic">Re: Cyber Pop-Up Privacy Policy</span>
      </address>

      <h1 className="text-2xl font-bold mb-4">CHANGES IN POLICY</h1>
      <p className="mb-3">
        From time to time, we may change our Policy. We will notify you of any material changes to
        our Policy as required by law. We will also post an updated copy on our Platform. Please
        check our Platform periodically for updates.
      </p>
    </div>
  </div>
);

export default PrivacyPolicy;
