import { Paper, Typography } from "../../../components";

function LineItem({ label, value }) {
  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-lg font-medium leading-6 text-prime">{label}</dt>
      <dd className="mt-1 text-terc leading-6 text-prime sm:col-span-2 sm:mt-0">{value}</dd>
    </div>
  );
}

const CyberExpertStatusDisplay = ({
  applicationStatus,
  applicationStatusLabel,
  backgroundCheckStatusLabel,
  cyberExpertStatusLabel,
}) => (
  <Paper>
    <div>
      <div className="px-4 sm:px-0">
        <Typography type="title" className="mb-6">
          Your Cyber-expert application status
        </Typography>
      </div>

      {applicationStatus === "Pending_review" && (
        <p className="text-lg">
          Your profile is currently under review. Please allow 3 - 5 business days for us to review
          your profile. Once reviewed, we will email you with your application status!
        </p>
      )}

      {backgroundCheckStatusLabel === "Approved" && (
        <p className="text-lg">
          Congratulations! You have been fully and vetted into our Cyber-expert team!
        </p>
      )}

      <div className="mt-6 border-t border-gray-300">
        <dl className="divide-y divide-gray-300">
          <LineItem label="Application Status" value={applicationStatusLabel} />
          {applicationStatus === "Expert" && (
            <LineItem label="Background check status" value={backgroundCheckStatusLabel} />
          )}
          <LineItem label="Cyber-expert status" value={cyberExpertStatusLabel} />
        </dl>
      </div>
    </div>
  </Paper>
);

export default CyberExpertStatusDisplay;
