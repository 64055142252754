import React from "react";
import { Button } from "../../components";
import ProjectPurchaseButton from "./ProjectPurchaseButton";

interface ProjectListProps {
  totalPopTokens: number;
  projectCredits: any[];
  purchaseMoreTokens: any;
  handleBuyProjectClick: any;
  handleLearnMoreClick: any;
  projects: any[];
}

const ProjectList: React.FC<ProjectListProps> = ({
  projects,
  projectCredits,
  totalPopTokens,
  purchaseMoreTokens,
  handleBuyProjectClick,
  handleLearnMoreClick,
}) => (
  <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
    {projects.map((project, idx) => (
      <div key={idx} className="flex flex-col justify-between rounded-lg bg-white shadow ">
        <div className="">
          <div className="flex items-center space-x-3 mx-4 mt-4">
            <h3 className="text-clip text-lg font-medium text-prime-900">{project.short_text}</h3>
            <span className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800">
              <i className="fa-solid fa-coins" />
              {project.cost / 50} Pop-tokens
            </span>
          </div>
          {/* <p className="mt-1 truncate text-sm text-gray-500">{person.title}</p> */}
        </div>
        <div className="text-sm mx-4 flex-grow ">
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: project.project_goal,
            }}
          />
        </div>
        <div className="mb-6">
          <p
            className="my-4 text-center text-terc  hover:underline hover:cursor-pointer"
            onClick={() => handleLearnMoreClick(project)}
          >
            Learn More
          </p>
          <ProjectPurchaseButton
            project={project}
            totalPopTokens={totalPopTokens}
            projectCredits={projectCredits}
            purchaseMoreTokens={purchaseMoreTokens}
            handleBuyProjectClick={handleBuyProjectClick}
          />
        </div>
      </div>
    ))}
  </ul>
);

export default ProjectList;
