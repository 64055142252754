import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import { InputPassword, Button, Loader } from "../../../components";

// context
import { useToast, useAuth } from "../../../context";
// services
import { errorMsgForUser, UserService } from "../../../services";

const ModalNewPwd = ({ dataTestId, header = true, title, showCloseIcon = true, closeModal }) => {
  // router
  let navigate = useNavigate();

  // context
  const { showToast } = useToast();
  const { authContext, authContextActions } = useAuth();

  // local
  const [loader, setLoader] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepatPassword] = useState("");

  const submitClicked = () => {
    // password match validation
    const REGEX = /^.{6,}$/;
    if (!REGEX.test(currentPassword)) {
      console.error("Password NOT valid.");
      showToast("error", "The minimum password length is 6 characters.");
      return;
    }

    // password validation
    if (!authContextActions.verifyPassword(password)) {
      console.error("Password NOT valid.");
      showToast(
        "error",
        `The password must contain at least:\n
      - 6 character,\n
      - one lower-case letter,\n
      - one upper-case letter,\n
      - one number,\n
      - one or more of these symbols (!@#$%^&*-+()_;=?)`
      );
      return;
    }

    // repeat password validation
    if (password !== repeatPassword) {
      console.error("Passwords NOT matching.");
      showToast("error", "Passwords do not match.");
      return;
    }

    const changePasswordObj = {
      uid: authContext?.user?.uid,
      curent_password: currentPassword,
      new_password: password,
    };

    // change password Api
    setLoader(true);
    return UserService.changePassword(changePasswordObj)
      .then((res) => {
        console.log("UserService.changePassword res:", res);
        showToast("success", "Password Updated");
        closeModal();
        navigate("/signout");
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  return (
    <>
      <div
        id={dataTestId}
        tabIndex="1"
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
      >
        {/* loader */}
        {!!loader && <Loader themeColor="fill-prime" />}

        <div className="relative w-full h-full max-w-2xl p-4 md:h-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Modal header */}
            {header && (
              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                {/* Modla title */}
                {title && (
                  <h3 className="text-xl font-medium text-gray-900 dark:text-white">{title}</h3>
                )}
                {/* Close modal button */}
                {showCloseIcon && (
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={() => closeModal()}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
              </div>
            )}

            {/* Modal body */}
            <div className="p-6 space-y-6">
              <InputPassword
                dataTestId="current-pwd"
                className="text-left"
                label="Current Password *"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <InputPassword
                dataTestId="new-pwd"
                className="text-left"
                label="New Password *"
                InfoVisible={true}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputPassword
                dataTestId="repeat-pwd"
                className="text-left"
                label="Confirm New Password *"
                value={repeatPassword}
                onChange={(e) => setRepatPassword(e.target.value)}
              />
            </div>

            {/* Modal footer */}
            <div className="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <Button
                dataTestId="cancel-change-pwd-btn"
                label="Cancel"
                variant="flat"
                themecolor="prime"
                onClick={() => closeModal()}
              />
              <Button
                dataTestId="accept-change-pwd-btn"
                label="Submit"
                variant="full"
                themecolor="prime"
                disabled={!currentPassword || !password || !repeatPassword}
                onClick={submitClicked}
              />
            </div>
          </div>
        </div>
      </div>

      {/* overlayer */}
      <div className="fixed inset-0 z-40 bg-gray-900 bg-opacity-50 dark:bg-opacity-80" />
    </>
  );
};

export default ModalNewPwd;
