import React, { useCallback, useEffect, useState, useRef } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// libraries

// services
import { EnhancedTable, Tabs } from "../../components";
import { APPLICATION_STATUSES, FREELANCER_STATUSES } from "../../constants/statuses";
import { useToast } from "../../context";
import { getProfileCompletion } from "../../functions/getProfileCompletion";
import { FreelancerService, RoleService, UserService, errorMsgForUser } from "../../services";

// components
import ModalFSM from "../FSM/ModalFSM";

// functions

// hooks

// helpers variables and functions
import DeleteCEModal from "./DeleteCEModal";
import { FREELANCER_TABLE_TABS, FSM_TABLE_COLUMNS } from "./utils";

const FSMTable = () => {
  // component state
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  // const [searchTerm, setSearchTerm] = useState("");
  const [currentTab, setCurrentTab] = useState(
    window.history.state.usr?.tab || {
      key: "application_status",
      value: "Not_reviewed",
      label: "Not Reviewed",
    }
  );
  const [modalOpened, setModalOpened] = useState(false);
  const [counters, setCounters] = useState({
    Expert: 0,
    Enthusiast: 0,
    Denied: 0,
    Sent: 0,
    Pending_review: 0,
    Not_reviewed: 0,
    Incomplete: 0,
    Resubmitted: 0,
  });
  const [loader, setLoader] = useState(false);
  const [freelancers, setFreelancers] = useState([]);
  const [freelancer, setFreelancer] = useState({});
  const [roles, setRoles] = useState([]);
  const [modalDeleteCE, setModalDeleteCE] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  // Hook
  const { showToast } = useToast();
  const navigate = useNavigate();

  // mounted ref hook
  const mounted = useRef();
  // const searchInput = useRef(null);

  const handleRowClick = (item) => {
    // open modal
    setFreelancer(freelancers.find((fl) => fl.user.uid === item.id));
    setModalOpened(true);
  };

  /**
   * Get all freelancers
   * @param tab key for filtering freelancers by statuses
   */
  const getAllFreelancers = useCallback(
    (page, tab) => {
      setLoader(true);
      return FreelancerService.getAll({
        page: page,
        per_page: itemsPerPage,
        sort_direction: "ASC",
        sort_by: "responsible_person_last_name",
        [tab.key]: tab.value,
      })
        .then((res) => {
          setFreelancers(res.freelancers);
          // TODO: Add to the backend to count not submitted
          setCounters({
            Pending_review: res.application_status.pending_review,
            Resubmitted: res.application_status.resubmitted,
            Incomplete: res.application_status.incomplete,
            Sent: res.background_check_status.sent,
            Enthusiast: res.application_status.enthusiast,
            Expert: res.freelancer_status_count.Expert,
            Denied: res.application_status.denied,
          });
        })
        .catch(() => showToast("error", "Error occurred while fetching freelancers"))
        .finally(() => setLoader(false));
    },
    [itemsPerPage, showToast]
  );

  const fetchMore = useCallback(
    (page, itemsPerPage) =>
      FreelancerService.getAll({
        page: page,
        per_page: itemsPerPage,
        sort_direction: "ASC",
        sort_by: "responsible_person_last_name",
        [currentTab.key]: currentTab.value,
      })
        .then((res) => {
          setFreelancers((prevState) => [...prevState, ...res.freelancers]);
          setCounters({
            Pending_review: res.application_status.pending_review,
            Incomplete: res.application_status.incomplete,
            Resubmitted: res.application_status.resubmitted,
            Sent: res.background_check_status?.sent,
            Enthusiast: res.application_status.enthusiast,
            Expert: res.freelancer_status_count.Expert,
            Denied: res.application_status.denied,
          });
          setLoader(true);
        })
        .catch(() => showToast("error", "Error occurred while fetching freelancers"))
        .finally(() => {
          setLoader(false);
        }),
    [currentTab.key, currentTab.value, showToast]
  );

  /**
   * Handle tab changing and fetching data
   * @param tab current active table tab
   */
  const handleTabChange = (tab) => {
    if (tab.label !== currentTab.label) {
      setPage(1);
      setFreelancers([]);
      navigate(window.location.pathname, { state: { tab } });
      setCurrentTab(tab);
      getAllFreelancers(1, tab);
    }
  };

  /**
   * Re-fetch table data
   * @param needRefresh ref value returned from FSM modal which
   * flags if table refresh is needed
   */
  const tableRefresh = useCallback(
    (needRefresh) => {
      if (needRefresh) {
        setFreelancers([]);
        fetchMore(1, page * itemsPerPage);
      }
    },
    [fetchMore, itemsPerPage, page]
  );

  /**
   * Generated table rows for EnhancedTable component
   */
  const tableRows = freelancers.map((fl) => {
    let fullName = "N/A";
    if (fl.profile.responsible_person_first_name && fl.profile.responsible_person_last_name) {
      fullName = `${fl.profile.responsible_person_first_name} ${fl.profile.responsible_person_last_name}`;
    }

    let applicationStatus = APPLICATION_STATUSES.find(
      (status) => status.value === fl.profile.application_status
    );
    let freelancerStatus = FREELANCER_STATUSES.find(
      (status) => status.value === fl.profile.freelancer_status
    );

    const questions = fl?.questionnaire?.questions ? JSON.parse(fl.questionnaire.questions) : null;
    // console.log('questions:', questions);

    const profileCompletion = getProfileCompletion(fl?.profile, questions).total || "";

    const setCompletitionCell = () => (
      <div
      // className={
      //   (questions?.certifications &&
      //     isEmpty(questions.certifications) &&
      //     profileCompletion === 93) ||
      //   profileCompletion === 100
      //     ? 'text-success'
      //     : 'text-error'
      // }
      >
        <span className="mr-1 font-bold">{profileCompletion}%</span>
        {/* <span>
          {questions?.certifications && !isEmpty(questions.certifications)
            ? 'Has certification'
            : 'No certification'}
        </span> */}
      </div>
    );

    return {
      "data-testid": fl.user.uid,
      id: fl.user.uid,
      name: fullName,
      email: fl.user.email,
      image: fl?.profile?.profile_image,
      date_submitted: fl.user.created_at,
      questionnaire_status: fl.profile.questionnaire_status || "N/A",
      resume: "Submited",
      video: fl.profile.video_status || "N/A",
      application_status: applicationStatus.label || "N/A",
      background_status: fl.profile.background_check_status || "N/A",
      freelancer_status: freelancerStatus?.label,
      days_inactive: Math.abs(moment(fl.profile.last_login_dt).diff(moment(), "days")) || "N/A",
      profile_completion: setCompletitionCell(),
    };
  });

  // Handle search functionality
  // const handleSearchTermChange = debounce((searchTerm) => {
  //   setSearchTerm(searchTerm);
  // }, 1000);

  /**
   * Handle search
   * @param {string} term searh term
   * Filtering freelancers list
   */
  // const handleSearch = useCallback(
  //   (term) => {
  //     if (!term) {
  //       return;
  //     }

  //     let copyArr = [...freelancers];

  //     copyArr = copyArr.filter((item) =>
  //       item.name ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) : item
  //     );

  //     setFreelancers(copyArr);
  //   },
  //   [freelancers, searchTerm]
  // );

  // useEffect(() => {
  //   handleSearch(searchTerm);
  // }, [handleSearch, searchTerm]);
  const handleDeleteCEFirstClick = () => {
    setModalOpened(false);
    setModalDeleteCE(true);
  };

  const deleteCEConfirm = async (data) => {
    try {
      setDeleteLoader(true);
      await UserService.delete({ user_uid: data.user.uid });
      const removeCEfromState = freelancers.filter((e) => e.user.uid !== data.user.uid);
      setFreelancers(removeCEfromState);
    } catch (error) {
      showToast("error", errorMsgForUser(error));
    } finally {
      setModalDeleteCE(false);
      setDeleteLoader(false);
    }
  };

  useEffect(() => {
    if (mounted.current) {
      return;
    }
    mounted.current = true;

    if (currentTab) {
      getAllFreelancers(page, currentTab);
    }
  }, [getAllFreelancers, currentTab, page]);

  // get roles - important for user update role in 'user' entity
  const getRoles = useCallback(
    () =>
      RoleService.getRoles()
        .then((res) => {
          setRoles(res?.roles);
        })
        .catch((err) => console.error("err:", err)),
    []
  );

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  return (
    <div className="pg-page h-[100vh] flex items-start bg-white">
      <div className="container mx-auto mt-20">
        {/* FSM table wrapper */}
        <div className="p-6 border-2">
          <div>
            <div className="mb-3 text-xl">Cyber-expert Review List</div>
            {/* <Input
              inputRef={searchInput}
              onChange={e => handleSearchTermChange(e.target.value)}
            /> */}
          </div>

          <Tabs
            dataTestId="some-id"
            className="mb-4"
            tabs={[
              FREELANCER_TABLE_TABS(counters).INCOMPLETE,
              FREELANCER_TABLE_TABS(counters).PENDING_REVIEW,
              FREELANCER_TABLE_TABS(counters).RESUBMITTED,
              FREELANCER_TABLE_TABS(counters).EXPERT_PENDING,
              FREELANCER_TABLE_TABS(counters).EXPERT,
              FREELANCER_TABLE_TABS(counters).ENTHUSIAST,
              FREELANCER_TABLE_TABS(counters).DENIED,
            ]}
            value={currentTab.value}
            onChange={(tab) => handleTabChange(tab)}
          />

          <EnhancedTable
            showTableTop={false}
            tableColumns={FSM_TABLE_COLUMNS}
            loader={loader}
            tableRows={tableRows}
            handleRowClick={handleRowClick}
            hasMore={freelancers.length === counters[currentTab.value]}
            fetchMore={() => {
              setPage((p) => p + 1);
              fetchMore(page + 1, itemsPerPage);
            }}
            pagination={false}
          />

          {modalOpened && (
            <ModalFSM
              title={`${freelancer?.profile?.responsible_person_first_name} ${freelancer?.profile?.responsible_person_last_name} - ${freelancer?.user?.email}`}
              closeModal={() => {
                setModalOpened(false);
                setFreelancer({});
              }}
              userData={freelancer}
              roles={roles}
              uid={freelancer.user.uid}
              tableRefresh={(e) => tableRefresh(e)}
              handleDeleteCEFirstClick={handleDeleteCEFirstClick}
            />
          )}
          {modalDeleteCE && (
            <DeleteCEModal
              open={modalDeleteCE}
              setOpen={setModalDeleteCE}
              data={freelancer}
              deleteLoader={deleteLoader}
              deleteCEConfirm={deleteCEConfirm}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FSMTable;
