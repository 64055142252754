import { useState, useEffect, useCallback } from "react";
import { Select } from "../../components";
import { parseDuration } from "../../functions/duration";

const InputDuration = ({
  dataTestId,
  name,
  label,
  placeholder = "",
  error = "",
  className = "mb-4",
  borderClass,
  inputClassName = "",
  showDays = false,
  disabled = false,
  value,
  onChange,
  ...rest
}) => {
  // const [wholeDays, wholeHours, remainingMinutes] = parseDuration(value);
  let [valueDays, setValueDays] = useState(0);
  let [valueHours, setValueHours] = useState(0);
  let [valueMinutes, setValueMinutes] = useState(0);

  const selectableMinuteValues = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "15",
    },
    {
      value: 2,
      label: "30",
    },
    {
      value: 3,
      label: "45",
    },
    // {
    //   value: 4,
    //   label: '60',
    // },
  ];

  // function parseDuration(value) {
  //   let _value = value || 0;
  //   let wholeDays = 0;
  //   let wholeHours = 0;
  //   let remainingMinutes = 0;

  //   if (showDays === true) {
  //     wholeDays = Math.floor(value / 96);
  //     _value = _value - wholeDays * 96;
  //   }
  //   // console.log('show days if: ', _value, wholeDays);
  //   // } else if (!!showHours) {
  //   wholeHours = Math.floor(_value / 4);
  //   _value = _value - wholeHours * 4;
  //   // console.log('show hours if: ', _value, wholeHours);
  //   // } else if (!!showMinutes) {
  //   remainingMinutes = _value;
  //   // console.log('show minutes if: ', _value, remainingMinutes);
  //   // }

  //   return [wholeDays, wholeHours, remainingMinutes];
  // }

  const calculateValue = useCallback(() => {
    let result = parseInt(valueDays * 96) + parseInt(valueHours * 4) + parseInt(valueMinutes);
    // console.log('calculate value result: ', result);

    onChange(result);
  }, [valueDays, valueHours, valueMinutes]);

  useEffect(() => {
    if (value) {
      // console.log('parseDuration', parseDuration());
      const [wholeDays, wholeHours, remainingMinutes] = parseDuration(value, showDays);
      setValueDays(wholeDays);
      setValueHours(wholeHours);
      setValueMinutes(remainingMinutes);
    }
  }, [value]);

  useEffect(() => {
    calculateValue();
  }, [valueDays, valueHours, valueMinutes]);

  return (
    <div className={className}>
      <label>
        {label && <span className="block text-prime-light">{label}</span>}

        <div className="flex flex-wrap items-baseline">
          {showDays ? (
            <>
              <input
                data-testid={dataTestId + "_days"}
                className={`w-16 mr-1 rounded-md  leading-tight text-sm shadow-sm bg-gray-50 focus:border disabled:opacity-50 focus:border-prime focus:outline-none ${
                  borderClass || "border-gray-300"
                } ${inputClassName}`}
                type="number"
                format=""
                min={0}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                value={valueDays}
                onChange={(e) => setValueDays(e.target.value)}
                {...rest}
              />
              <span className="block text-prime-light mr-4 text-sm">Days</span>
            </>
          ) : (
            <span className="mx-8 pl-7 pr-6" />
          )}

          <>
            <input
              data-testid={dataTestId + "_hours"}
              className={`w-16 mr-1 rounded-md  leading-tight text-sm shadow-sm bg-gray-50 focus:border disabled:opacity-50 focus:border-prime focus:outline-none ${
                borderClass || "border-gray-300"
              } ${inputClassName}`}
              type="number"
              format=""
              disabled={disabled}
              min={0}
              name={name}
              placeholder={placeholder}
              value={valueHours}
              onChange={(e) => setValueHours(e.target.value)}
              {...rest}
            />
            <span className="block text-sm text-prime-light mr-4">Hours</span>
          </>

          <>
            <Select
              dataTestId={dataTestId + "_minutes"}
              className="mr-1 text-sm  leading-tight"
              firstEmpty={false}
              keyForOptionValue="value"
              keyForOptionLabel="label"
              disabled={disabled}
              options={selectableMinuteValues}
              value={valueMinutes}
              onChange={(e) => setValueMinutes(e.target.value)}
            />
            <span className="block text-sm text-prime-light">Minutes</span>
          </>
        </div>

        {error && <div className="mb-2 mt-1 text-sm text-error">{error}</div>}
      </label>
    </div>
  );
};

export default InputDuration;
