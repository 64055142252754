import { useState, useEffect, useCallback } from "react";
import { isEmpty } from "lodash";
import { useNavigate, useParams, useLocation } from "react-router-dom";

// components
import {
  Typography,
  Paper,
  Button,
  Input,
  RoadmapList,
  Textarea,
  Select,
  Loader,
  SelectWithSearch,
  TextEditor,
  Modal,
  CardProject,
} from "../../components";

// services
import { useToast } from "../../context";
import { ProjectTemplateService, TaskTemplateService, errorMsgForUser } from "../../services";

// context

const optionsLevel = [
  { label: "Level 1", value: "L1" },
  { label: "Level 2", value: "L2" },
  { label: "Level 3", value: "L3" },
];

const optionsNist = [
  { label: "Level 1", value: "L1" },
  { label: "Level 2", value: "L2" },
  { label: "Level 3", value: "L3" },
  { label: "Level 4", value: "L4" },
  { label: "Level 5", value: "L5" },
  { label: "Level 6", value: "L6" },
  { label: "Level 7", value: "L7" },
  { label: "Level 8", value: "L8" },
  { label: "Level 9", value: "L9" },
  { label: "Level 10", value: "L10" },
];

const optionsProjectGrouping = [
  { label: "Access Control (AC)", value: "AC" },
  { label: "Awareness and Training (AT)", value: "AT" },
  { label: "Audit and Accountability (AU)", value: "AU" },
  { label: "Configuration Management (CM)", value: "CM" },
  { label: "Identification and Authentication (IA)", value: "IA" },
  { label: "Incident Response (IR)", value: "IR" },
  { label: "Maintenance (MA)", value: "MA" },
  { label: "Media Protection (MP)", value: "MP" },
  { label: "Personnel Security (PS)", value: "PS" },
  { label: "Physical Protection (PE)", value: "PE" },
  { label: "Risk Assessment (RA)", value: "RA" },
  { label: "Security Assessment (CA)", value: "CA" },
  { label: "System and Communication Protection (SC)", value: "SC" },
  { label: "System and Information Integrity (SI)", value: "SI" },
];

const optionsDefaultRisk = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
  { label: "Very High", value: "very_high" },
];

const ProjectCreateEdit = () => {
  // router
  let navigate = useNavigate();
  let location = useLocation();
  let { projectUid } = useParams();

  // context
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(0);
  const [modalPreview, setModalPreview] = useState(false);
  const [changedRoadmap, setChangedRoadmap] = useState([]);

  useEffect(() => {
    // console.log('changedRoadmap', changedRoadmap);
    console.log(
      "changedRoadmap!",
      changedRoadmap.map((n) => n)
    );
  }, [changedRoadmap]);

  const [optionsFreelanceCertifications, setOptionsFreelanceCertifications] = useState("[]");

  const [optionsFreelanceMgmtSkills, setOptionsFreelanceMgmtSkills] = useState("[]");

  const [optionsFreelanceTechSkills, setOptionsFreelanceTechSkills] = useState("[]");
  const [currentProjectTemplate, setCurrentProjectTemplate] = useState({
    short_text: "",
    level: optionsLevel[0].value,
    priority: 1,
    default_risk: optionsDefaultRisk[0].value,
    project_grouping: optionsProjectGrouping[0].value,
    nist_level: optionsNist[0].value,
    title: "",
    project_goal: "",
    project_why: "",
    project_expectation: "",
    cost: "",
    freelancer_certifications: [],
    freelancer_mgmt_skills: [],
    freelancer_tech_skills: [],
    number: "",
    version: "",
    status: "Draft",
  });

  // add uid if project_template already exists
  // to update or create if projetc_tempate.uid not exist

  const createProject = () => {
    if (currentProjectTemplate.cost % 50 !== 0) {
      showToast("error", "The project cost value must be divisible by 50");
      return;
    }

    if (currentProjectTemplate?.number < 0) {
      showToast("error", "The project number must have a positive value");
      return;
    }

    setLoader((l) => l + 1);
    return ProjectTemplateService.create({
      ...currentProjectTemplate,
      roadmap: [],
      project_template_status: "Draft",
    })
      .then((res) => {
        // console.log('res from create project', res);
        showToast("success", "Your project is successfully created");
        // setProjectUid(res.project_template.uid);
        navigate(`/project-template/${res.project_template.uid}`);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const createNewDraftFromProject = () => {
    const { ...updateProjectTemplate } = currentProjectTemplate;

    setLoader((l) => l + 1);
    return ProjectTemplateService.inheritFromPublished({
      project_template_uid: updateProjectTemplate.uid,
      new_number: updateProjectTemplate.number,
      new_version: updateProjectTemplate.version,
    })
      .then((res) => {
        // showToast('success', 'Your project is successfully updated');
        setCurrentProjectTemplate({ ...res.project_template });
        navigate(`/project-template/${res.project_template.uid}`);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const copyProject = async () => {
    try {
      console.log("CURRENT PROJ BEFORE CREATE", currentProjectTemplate);
      const { project_template } = await ProjectTemplateService.create({
        ...currentProjectTemplate,
        project_template_status: "Draft",
      });
      // console.log("COPY PROJ CREATE", project_template);

      const updatedTaskUids = project_template.roadmap.map((task) => ({
        ...task,
        project_template_uid: project_template.uid,
      }));

      const updatedTasks = [];
      updatedTaskUids.forEach(async (task) => {
        const { task_template } = await TaskTemplateService.create({
          ...task,
          project_template_uid: project_template.uid,
        });
        updatedTasks.push(task_template);
      });
      // console.log("UPDATED TASKED!!!", updatedTasks);

      await ProjectTemplateService.update({
        ...project_template,
        uid: project_template.uid,
        roadmap: updatedTaskUids,
      });

      showToast("success", "Project copied, redirecting to new project");
      setTimeout(() => {
        navigate(`/project-template/${project_template.uid}`);
      }, 2000);
    } catch (error) {
      console.log(error);
      showToast("error", errorMsgForUser(error));
    }
  };

  const updateProject = (projectStatus) => {
    // if (projectStatus === 'Published')
    // const { roadmap, ...updateProjectTemplate } = currentProjectTemplate;

    // payload prep
    let updateObj = {
      ...currentProjectTemplate,
      roadmap: [...changedRoadmap],
    };
    if (projectStatus === "Published") {
      updateObj = {
        ...currentProjectTemplate,
        roadmap: [...changedRoadmap],
        status: "Published",
      };
    }
    console.log("UPDATE OBJ IN UPDATE PROJ", updateObj);

    setLoader((l) => l + 1);
    return ProjectTemplateService.update(updateObj)
      .then((res) => {
        if (projectStatus === "Published") {
          showToast("success", "Your project is successfully published.");
          ProjectTemplateService.latestPublished({
            project_template_uid: updateObj.uid,
          }).then(() => {
            navigate("/project-templates");
          });
          // reroute
        } else {
          // refresh data
          showToast("success", "Your project is successfully updated");
          console.log("UPDATE RES", res);
          setCurrentProjectTemplate({ ...res.project_template });
        }
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const getProjectTemplate = useCallback(() => {
    if (projectUid && projectUid !== "create") {
      console.log("getProjectTemplate");
      setLoader((l) => l + 1);
      return ProjectTemplateService.get({
        project_template_uid: projectUid,
      })
        .then((r) => {
          setCurrentProjectTemplate({ ...r.project_template });
          console.log("setCurrentProjectTemplate", r.project_template);
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader((l) => l - 1));
    }
  }, [projectUid, showToast]);

  useEffect(() => {
    getProjectTemplate();
  }, [getProjectTemplate]);

  const getOptionsFreelanceCertifications = useCallback(() => {
    ProjectTemplateService.requirementsForProject({
      filter_type: "certifications",
    }).then((r) => {
      setOptionsFreelanceCertifications([...r.certifications]);
    });
  }, []);

  const getOptionsFreelanceMgmtSkills = useCallback(() => {
    ProjectTemplateService.requirementsForProject({
      filter_type: "mgmt_skills",
    }).then((r) => {
      setOptionsFreelanceMgmtSkills([...r.mgmt_skills]);
    });
  }, []);

  const getOptionsFreelanceTechSkills = useCallback(() => {
    ProjectTemplateService.requirementsForProject({
      filter_type: "tech_skills",
    }).then((r) => {
      setOptionsFreelanceTechSkills([...r.tech_skills]);
    });
  }, []);

  useEffect(() => {
    getOptionsFreelanceCertifications();
  }, [getOptionsFreelanceCertifications]);

  useEffect(() => {
    getOptionsFreelanceMgmtSkills();
  }, [getOptionsFreelanceMgmtSkills]);

  useEffect(() => {
    getOptionsFreelanceTechSkills();
  }, [getOptionsFreelanceTechSkills]);

  return (
    <div className="container relative flex-1 mx-auto p-page max-w-page">
      {/* top part */}
      <div className="flex items-center mb-4">
        <Button
          dataTestId="back_btn"
          label={<i className="fa-solid fa-arrow-left" />}
          variant="icon-flat"
          themecolor="prime"
          width="w-10"
          height="h-10"
          textSize="text-xl"
          className="mr-2"
          onClick={() => {
            location.pathname.includes("archived")
              ? navigate("/project-templates/archived")
              : navigate("/project-templates");
          }}
        />

        <Typography type="subtitle">
          {projectUid === "create" ? "Add New Project" : currentProjectTemplate?.short_text}
          {" - "}
          <span
            className={`${currentProjectTemplate.status === "Draft" ? "text-sec-light" : ""}  ${
              currentProjectTemplate.status === "Published" ? "text-success" : ""
            } ${currentProjectTemplate.status === "Archived" ? "text-prime" : ""}`}
          >
            {" "}
            {currentProjectTemplate.status}
          </span>
        </Typography>
      </div>

      <Paper className="w-full mb-6">
        {/* loader */}
        {!!loader && <Loader themeColor="fill-prime" />}

        <div className="flex">
          <div className="w-3/6 px-2">
            <div className="mb-1" />
            <Input
              dataTestId="project_short_text"
              type="text"
              label={`Project Short Text (${currentProjectTemplate.short_text.length}/60) *`}
              value={currentProjectTemplate.short_text}
              error={
                currentProjectTemplate.short_text.length > 60 ? "Max number of character is 60" : ""
              }
              onChange={(e) =>
                setCurrentProjectTemplate((prev) => ({
                  ...prev,
                  short_text: e.target.value,
                }))
              }
            />

            <Select
              dataTestId="project_level"
              label="Project level *"
              firstEmpty={false}
              options={optionsLevel}
              keyForOptionValue="value"
              keyForOptionLabel="label"
              value={currentProjectTemplate.level}
              className="mb-4"
              onChange={(e) =>
                setCurrentProjectTemplate((prev) => ({
                  ...prev,
                  level: e.target.value,
                }))
              }
            />

            <Input
              dataTestId="project_priority"
              type="number"
              min={1}
              max={50}
              step={1}
              label="Priority *"
              value={currentProjectTemplate.priority}
              onChange={(e) =>
                setCurrentProjectTemplate((prev) => ({
                  ...prev,
                  priority: e.target.value,
                }))
              }
            />

            <Select
              dataTestId="project_default_risk"
              label="Default Risk *"
              firstEmpty={false}
              options={optionsDefaultRisk}
              keyForOptionValue="value"
              keyForOptionLabel="label"
              value={currentProjectTemplate.default_risk}
              className="mb-4"
              onChange={(e) =>
                setCurrentProjectTemplate((prev) => ({
                  ...prev,
                  default_risk: e.target.value,
                }))
              }
            />

            <Select
              dataTestId="project_grouping"
              label="Project Grouping *"
              firstEmpty={false}
              options={optionsProjectGrouping}
              keyForOptionValue="value"
              keyForOptionLabel="label"
              value={currentProjectTemplate.project_grouping}
              className="pb-5"
              onChange={(e) =>
                setCurrentProjectTemplate((prev) => ({
                  ...prev,
                  project_grouping: e.target.value,
                }))
              }
            />

            <Select
              dataTestId="nist_level"
              label="NIST level *"
              firstEmpty={false}
              options={optionsNist}
              keyForOptionValue="value"
              keyForOptionLabel="label"
              value={currentProjectTemplate.nist_level}
              className="pb-5"
              onChange={(e) =>
                setCurrentProjectTemplate((prev) => ({
                  ...prev,
                  nist_level: e.target.value,
                }))
              }
            />
          </div>

          <div className="w-3/6 px-2">
            <div className="mb-1" />
            <span className="flex">
              <Input
                dataTestId="project_number_input"
                type="number"
                step="1"
                label="Project number *"
                className="mr-4"
                value={currentProjectTemplate.number}
                onChange={(e) =>
                  setCurrentProjectTemplate((prev) => ({
                    ...prev,
                    number: e.target.value,
                  }))
                }
              />
              <Input
                dataTestId="project_version_input"
                type="text"
                label="Project version *"
                value={currentProjectTemplate.version}
                onChange={(e) =>
                  setCurrentProjectTemplate((prev) => ({
                    ...prev,
                    version: e.target.value,
                  }))
                }
              />
            </span>
            <Input
              dataTestId="project_cost_input"
              type="number"
              placeholder="$"
              label="Project cost ($) *"
              value={currentProjectTemplate.cost}
              min={0}
              step={50}
              onChange={(e) =>
                setCurrentProjectTemplate((prev) => ({
                  ...prev,
                  cost: e.target.value,
                }))
              }
              onBlur={() => {
                if (currentProjectTemplate.cost % 50 !== 0) {
                  showToast("error", "The project cost value must be divisible by 50");
                }
              }}
            />

            <SelectWithSearch
              dataTestId="project_freelancer_certification"
              placeholder="Tags"
              options={optionsFreelanceCertifications}
              label="Certifications Required *"
              value={currentProjectTemplate.freelancer_certifications}
              isDisabled={currentProjectTemplate.status !== "Draft"}
              onChange={(e) => {
                setCurrentProjectTemplate((prev) => ({
                  ...prev,
                  freelancer_certifications: [...e],
                }));
              }}
            />

            <SelectWithSearch
              dataTestId="project_freelancer_mgmt_skills"
              placeholder="Tags"
              options={optionsFreelanceMgmtSkills}
              label="Cyber-expert MGTM Skills"
              value={currentProjectTemplate.freelancer_mgmt_skills}
              isDisabled={currentProjectTemplate.status !== "Draft"}
              onChange={(e) => {
                setCurrentProjectTemplate((prev) => ({
                  ...prev,
                  freelancer_mgmt_skills: [...e],
                }));
              }}
            />

            <SelectWithSearch
              dataTestId="project_freelancer_mgmt_skills"
              placeholder="Tags"
              options={optionsFreelanceTechSkills}
              label="Cyber-expert Tech Skills"
              value={currentProjectTemplate.freelancer_tech_skills}
              isDisabled={currentProjectTemplate.status !== "Draft"}
              onChange={(e) => {
                setCurrentProjectTemplate((prev) => ({
                  ...prev,
                  freelancer_tech_skills: [...e],
                }));
              }}
            />
          </div>
        </div>

        <div className="flex-1 px-2 mb-8">
          {/* Project title */}
          <Textarea
            dataTestId="project_title"
            name="project_title"
            label="Project Title *"
            placeholder="Write Longer Title"
            rows={1}
            value={currentProjectTemplate.title || ""}
            onChange={(e) =>
              setCurrentProjectTemplate((prev) => ({
                ...prev,
                title: e.target.value,
              }))
            }
          />

          {/* project goal */}
          <TextEditor
            dataTestId="project_goal"
            name="project_goal"
            label="Project goal *"
            value={currentProjectTemplate.project_goal || ""}
            onChange={(v) => setCurrentProjectTemplate((n) => ({ ...n, project_goal: v }))}
          />

          {/* project Why  */}
          <TextEditor
            dataTestId="project_why"
            name="project_why"
            label="Project why *"
            value={currentProjectTemplate.project_why || ""}
            onChange={(v) => setCurrentProjectTemplate((n) => ({ ...n, project_why: v }))}
          />

          {/* what to expect  */}
          <TextEditor
            dataTestId="project_expectations"
            name="project_expectations"
            label="What to Expect *"
            value={currentProjectTemplate.project_expectations || ""}
            onChange={(v) =>
              setCurrentProjectTemplate((n) => ({
                ...n,
                project_expectations: v,
              }))
            }
          />
        </div>

        {projectUid === "create" && (
          <div className="flex px-2">
            <Button
              dataTestId="cancel_btn"
              themecolor="prime"
              label="Add new project template"
              className="mr-4"
              disabled={
                !currentProjectTemplate.short_text ||
                currentProjectTemplate.short_text.length > 60 ||
                !currentProjectTemplate.number ||
                !currentProjectTemplate.version ||
                !currentProjectTemplate.cost ||
                !currentProjectTemplate.level ||
                !currentProjectTemplate.priority ||
                !currentProjectTemplate.default_risk ||
                !currentProjectTemplate.project_grouping ||
                !currentProjectTemplate.nist_level ||
                !currentProjectTemplate.title ||
                !currentProjectTemplate.project_goal ||
                !currentProjectTemplate.project_why ||
                !currentProjectTemplate.project_expectations ||
                isEmpty(currentProjectTemplate.freelancer_certifications)
              }
              onClick={() => createProject(false)}
            />

            <Button
              dataTestId="preview_btn"
              label="Preview"
              variant="outlined"
              className="mr-4"
              themecolor="prime-light"
              onClick={() => setModalPreview(true)}
            />

            <Button
              dataTestId="cancel_btn"
              label="Cancel"
              variant="flat"
              themecolor="prime-light"
              disabled={false}
              onClick={() => {
                location.pathname.includes("archived")
                  ? navigate("/project-templates/archived")
                  : navigate("/project-templates");
              }}
            />
          </div>
        )}
      </Paper>

      <RoadmapList
        // items={projectRoadmap}
        projectUid={projectUid}
        initialRoadmap={currentProjectTemplate.roadmap}
        changedRoadmap={changedRoadmap}
        setChangedRoadmap={setChangedRoadmap}
        updateList={getProjectTemplate}
        editable={currentProjectTemplate.status === "Draft" ? true : false}
      />

      <div className="flex mt-6">
        {currentProjectTemplate.status === "Draft" ? (
          <>
            <Button
              themecolor="prime"
              label="Save changes"
              className="mr-4"
              onClick={() => updateProject()}
            />
          </>
        ) : (
          <Button
            themecolor="prime"
            label="Save as new Draft"
            className="mr-4"
            onClick={() => createNewDraftFromProject(false)}
          />
        )}

        {projectUid === "create" && (
          <Button
            themecolor="prime"
            label="Publish Project"
            className="mr-4"
            disabled
            onClick={() => createProject(true)}
          />
        )}

        {currentProjectTemplate.status === "Draft" && projectUid !== "create" && (
          <>
            <Button
              themecolor="success"
              label="Publish Project"
              variant="outlined"
              className="mr-4"
              onClick={() => updateProject("Published")}
            />
            <Button
              themecolor="prime-light"
              label="Save project as"
              variant="outlined"
              className="mr-4"
              onClick={() => copyProject()}
            />
          </>
        )}

        {currentProjectTemplate.status === "Published" && (
          <Button
            themecolor="success"
            label="Published Project"
            className="mr-4"
            onClick={() =>
              showToast(
                "error",
                "Your project is already published if you'd like to publish a new version first save it as a new draft."
              )
            }
          />
        )}

        {currentProjectTemplate.status === "Archived" && (
          <Button
            themecolor="grey"
            label="Archived Project"
            className="mr-4"
            onClick={() =>
              showToast(
                "error",
                "Your project is archived changing state can be done from the projects table or create a new draft."
              )
            }
          />
        )}

        <Button
          dataTestId="test-id"
          label="Cancel"
          variant="outlined"
          themecolor="prime-light"
          disabled={false}
          onClick={() => navigate("/project-templates")}
        />
      </div>

      {modalPreview && (
        <Modal title="Project preview" closeModal={() => setModalPreview(false)}>
          <div className="flex items-center justify-center">
            <CardProject project_template={currentProjectTemplate} minWidth="w-[350px]" />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ProjectCreateEdit;
