import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// components
import {
  Paper,
  Typography,
  Button,
  Input,
  InputPassword,
  InputPhone,
  Checkbox,
  Loader,
  Modal,
} from "../../components";

// context
import { useAuth, useToast } from "../../context";

// services
import { UserService, errorMsgForUser } from "../../services";

const SignUp = () => {
  // router
  let navigate = useNavigate();

  // context
  const { authContext, authContextActions } = useAuth();
  const { showToast } = useToast();

  // local
  // ! DEV VALUES
  // const companyNumber = Math.floor(Math.random() * 10000);
  // const [profile, setProfile] = useState({
  //   role: "Freelancer",
  //   email: "stephen+test_ce_" + companyNumber + "@cyberpopup.com",
  //   password: "Test123!",
  //   phone: "14058245218",
  //   referral_code: "",
  // });
  // const [terms, setTerms] = useState(true);
  // const [privacyPolicy, setPrivacyPolicy] = useState(true);
  // const [cookiePolicy, setCookiePolicy] = useState(true);

  // ! PROD VALUES
  const [profile, setProfile] = useState({
    role: "Freelancer",
    email: "",
    password: "",
    phone: "",
    referral_code: "",
  });
  const [terms, setTerms] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [cookiePolicy, setCookiePolicy] = useState(false);

  const [loader, setLoader] = useState(false);
  const [repeatPassword, setRepatPassword] = useState("");

  // const [codeOfConduct, setCodeOfConduct] = useState(false);
  const [subscriptionNewslatter, setSubscriptionNewslatter] = useState(true);
  const [modalSignupSuccessful, setModalSignupSuccessful] = useState(false);
  const [resendVerificationEmailData, setResendVerificationEmailData] = useState(null);

  const signup = () => {
    // no big letters in email
    // const regexBigLetters = /^(?=.*[A-Z]).+$/;
    // if (regexBigLetters.test(profile.email)) {
    //   console.error("Email have a big letters.");
    //   showToast("error", "Email can't have capital letters.");
    //   return;
    // }

    // email validation
    if (!authContextActions.verifyEmail(profile.email.toLowerCase())) {
      console.error("Email NOT valid.");
      showToast("error", "Email is not valid");
      return;
    }

    // password validation
    if (!authContextActions.verifyPassword(profile.password)) {
      console.error("Password NOT valid.");
      showToast(
        "error",
        `The password must contain at least:\n
        - 6 characters\n
        - One lower-case letter\n
        - One upper-case letter\n
        - One number\n
        - one or more of these symbols (!@#$%^&*-+()_;=?)`
      );
      return;
    }

    // repeat password validation
    if (profile.password !== repeatPassword) {
      console.error("Passwords NOT matching.");
      showToast("error", "Passwords do not match.");
      return;
    }

    const signupObj = {
      role_name: "Enthusiast",
      email: profile.email.toLowerCase(),
      password: profile.password,
      phone: `+${profile.phone}`,
      referral_code: profile.referral_code || null,
      subscription_newslatter: subscriptionNewslatter,
    };

    setLoader(true);
    return UserService.signUp(signupObj)
      .then((res) => {
        // console.log('UserService.signUp:', res);

        // set data for resend request
        setResendVerificationEmailData({
          user_uid: res?.user?.uid,
          email: res?.user?.email,
        });

        setModalSignupSuccessful(true);
      })
      .catch((err) => {
        if (err.message === "User already exists") {
          showToast("error", "User already exists, redirecting to signin");
          setTimeout(() => navigate("/signin"), 3000);
        } else {
          showToast("error", errorMsgForUser(err));
        }
      })
      .finally(() => setLoader(false));
  };

  const resendVerificationEmail = () => {
    setLoader(true);
    return UserService.resendVerificationEmail(resendVerificationEmailData)
      .then(() => {
        // console.log('UserService.resendVerificationEmail:', res);

        showToast("success", "The verification message is resent");
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  return (
    <div className="flex justify-center p-page">
      <Paper className="min-w-[520px]">
        {/* loader */}
        {loader && <Loader themeColor="fill-prime" />}

        {/* tab buttons */}
        <div className="flex mb-6">
          <Button
            dataTestId="annual-plans-btn"
            label="Sign-up as Customer"
            variant={profile.role === "Customer" ? "full" : "outlined"}
            themecolor="sec"
            className="flex-1 border rounded-r-none border-sec"
            onClick={() => setProfile((n) => ({ ...n, role: "Customer" }))}
          />
          <Button
            dataTestId="month-to-month-btn"
            label="Join as Cyber-expert"
            variant={profile.role === "Freelancer" ? "full" : "outlined"}
            themecolor="sec"
            className="flex-1 border rounded-l-none border-sec"
            onClick={() => setProfile((n) => ({ ...n, role: "Freelancer" }))}
          />
        </div>

        <Typography type="title" className="mb-8 text-center">
          {profile.role === "Customer" ? "Sign-up as a Customer" : "Sign-up as Cyber-expert"}
        </Typography>

        {/* form */}
        {profile.role === "Freelancer" && (
          <>
            <Input
              dataTestId="email"
              type="email"
              label="Email *"
              value={profile.email}
              onChange={(e) => setProfile((n) => ({ ...n, email: e.target.value }))}
              onKeyDown={(e) => e.key === "Enter" && SignUp()}
            />
            <InputPassword
              dataTestId="password"
              label="Password *"
              InfoVisible={true}
              value={profile.password}
              onChange={(e) => setProfile((n) => ({ ...n, password: e.target.value }))}
              onKeyDown={(e) => e.key === "Enter" && SignUp()}
            />
            <InputPassword
              dataTestId="repeat-password"
              label="Confirm password *"
              value={repeatPassword}
              onChange={(e) => setRepatPassword(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && SignUp()}
            />
            <InputPhone
              dataTestId="phone"
              label="Mobile Phone (for 2 Factor Authentication) *"
              placeholder="Phone"
              value={profile.phone}
              onChange={(v) => setProfile((n) => ({ ...n, phone: v }))}
              onKeyDown={(e) => e.key === "Enter" && SignUp()}
            />
            <Input
              dataTestId="referral-code"
              type="text"
              label="Partner Referral Code (Optional)"
              className="mb-4"
              value={profile.referral_code}
              onChange={(e) => setProfile((n) => ({ ...n, referral_code: e.target.value }))}
              onKeyDown={(e) => e.key === "Enter" && SignUp()}
            />

            {/* checkboxes */}
            <div>
              <div className="flex item-center">
                <Checkbox
                  dataTestId="subscribe-newsletter"
                  label="Subscribe to our Newsletter"
                  labelClassName="text-sec"
                  value="cookiePolicy"
                  checked={subscriptionNewslatter}
                  onChange={(e) => setSubscriptionNewslatter(e.target.checked)}
                />
              </div>

              <div className="flex item-center">
                <Checkbox
                  dataTestId="terms"
                  label="I agree to the"
                  value="terms"
                  checked={terms}
                  onChange={(e) => setTerms(e.target.checked)}
                />
                <Link
                  data-testid="terms-link"
                  to="/terms-and-conditions"
                  target="_blank"
                  className="ml-1 text-prime hover:underline"
                >
                  Terms and Conditions
                </Link>
                *
              </div>

              <div className="flex item-center">
                <Checkbox
                  dataTestId="privacy-policy"
                  label="I agree to the"
                  value="privacyPolicy"
                  checked={privacyPolicy}
                  onChange={(e) => setPrivacyPolicy(e.target.checked)}
                />
                <Link
                  data-testid="privacy-policy-link"
                  to="/privacy-policy"
                  target="_blank"
                  className="ml-1 text-prime hover:underline"
                >
                  Privacy Policy
                </Link>
                *
              </div>

              <div className="flex item-center">
                <Checkbox
                  dataTestId="cookie-policy"
                  label="I agree to the"
                  value="cookiePolicy"
                  checked={cookiePolicy}
                  onChange={(e) => setCookiePolicy(e.target.checked)}
                />
                <Link
                  data-testid="cookie-policy-link"
                  to="/cookie-policy"
                  target="_blank"
                  className="ml-1 text-prime hover:underline"
                >
                  Cookie Policy
                </Link>
                *
              </div>

              {/*
          <div className="flex mb-4 item-center">
            <Checkbox
              dataTestId="code-of-conduct"
              label="I agree to the"
              value="codeOfConduct"
              checked={codeOfConduct}
              onChange={e => setCodeOfConduct(e.target.checked)}
            />
            <Link
              data-testid="code-of-conduct-link"
              to="/code-of-conduct"
              target="_blank"
              className="ml-1 text-prime hover:underline"
            >
              Code of Conduct
            </Link>
          </div>
          */}
            </div>

            {/* signup button */}
            <Button
              dataTestId="signup-button"
              label="Sign Up"
              variant="full"
              themecolor="prime"
              className="block mx-auto mb-4"
              disabled={
                !profile.role ||
                !profile.email ||
                !profile.password ||
                !repeatPassword ||
                !profile.phone ||
                !terms ||
                !privacyPolicy ||
                !cookiePolicy
                // !codeOfConduct
              }
              onClick={signup}
            />

            {/* upon signup text */}
            <p className="mb-8 text-center text-prime-light">
              Upon Sign Up,
              <br /> please check your email to verify your account.
            </p>

            {/* have an account */}
            <p className="mb-10 text-center text-gray" onClick={() => navigate("/signin")}>
              <span className="mr-2">Have an account?</span>
              <Link data-testid="signin-button" to="/signin" className="text-sec hover:underline">
                Log In
              </Link>
            </p>
          </>
        )}

        {/* // <p className="max-w-sm text-center text-gray" onClick={() => navigate("/signin")}>
        //   <span className="mr-2">If intending to sign-up as a customer, please visit this</span>
        //   <Link data-testid="pricint-button" to="https://www.cyberpopup.com/pricing" className="text-sec hover:underline">
        //    link
        //   </Link>
        //   <span> and select a plan.</span>
        // </p> */}

        {profile.role === "Customer" && (
          <p className="max-w-[450px] text-center">
            If intending to sign-up as a customer,
            <br /> please visit this&nbsp;
            <Link
              data-testid="pricint-button"
              to="https://www.cyberpopup.com/pricing"
              className="text-sec hover:underline"
            >
              link
            </Link>
            &nbsp; and select a plan.
          </p>
        )}
      </Paper>

      {/* modal signup successful */}
      {modalSignupSuccessful && (
        <Modal
          title="The verification message is sent."
          maxWidth="max-w-2xl"
          closeModal={() => setModalSignupSuccessful(false)}
          showCloseIcon={false}
          buttons={[
            <Button
              dataTestId="close"
              label="Close"
              variant="flat"
              themecolor="prime"
              onClick={() => setModalSignupSuccessful(false)}
            />,
          ]}
        >
          <div className="relative">
            {/* loader */}
            {loader && <Loader themeColor="fill-prime" />}

            <p className="mb-8 text-base leading-relaxed text-prime-light-dark dark:text-gray-400">
              Please check your email (spam folder too) and verify your email.
            </p>

            <Button
              dataTestId="resend_verification_email_btn"
              label="Resend verification email"
              variant="outlined"
              themecolor="prime"
              className="block mx-auto"
              onClick={resendVerificationEmail}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SignUp;
