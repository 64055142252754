//
// Function to download base64 file
//

const b64toBlob = (
  b64Data,
  filename = "file.pdf",
  contentType = "application/pdf",
  sliceSize = 512
) => {
  let splicedBlob = b64Data.slice(28);
  const byteCharacters = atob(splicedBlob);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });

  let a = document.createElement("a");

  let objectURL = URL.createObjectURL(blob);

  a.href = objectURL;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(objectURL);
};

export default b64toBlob;
