import React from "react";
import PropTypes from "prop-types";

export default function DropdownItem({ children, contentClassName = "", icon, onClick }) {
  return (
    <div
      className={`flex w-full text-sm py-3 px-4 font-normal cursor-pointer whitespace-no-wrap text-prime hover:text-bold hover:bg-grey-light transition-all duration-300 ${contentClassName}`}
      onClick={onClick}
    >
      <div style={{ width: "30px" }}>{icon}</div>
      {children}
    </div>
  );
}

DropdownItem.defaultProps = {
  contentClassName: "",
  icon: "",
};

DropdownItem.propTypes = {
  children: PropTypes.node.isRequired,
  contentClassName: PropTypes.string,
  icon: PropTypes.node.isRequired,
};
