import { evalQuery } from "./index";

export const TaskTemplateService = {
  create: (request) =>
    evalQuery({
      url: "/task-template/create",
      request,
    }),

  edit: (request) =>
    evalQuery({
      url: "/task-template/update",
      request,
    }),

  getByProjectTemplate: (request) =>
    evalQuery({
      url: "/task-template/get-by-project-template",
      request,
    }),

  getAll: (request) =>
    evalQuery({
      url: "/task-template/get-all",
      request,
    }),

  get: (request) =>
    evalQuery({
      url: "/task-template/get",
      request,
    }),

  update: (request) =>
    evalQuery({
      url: "/task-template/update",
      request,
    }),

  delete: (request) =>
    evalQuery({
      url: "/task-template/delete",
      request,
    }),
};
