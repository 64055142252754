// INFO
// helper function to hide messages that aren't intended for users.
//

/* NOTE how to use:
  // services
  import { errorMsgForUser } from '../../services';
  ...
  .catch(err => showToast('error', errorMsgForUser(err)))
*/
export const errorMsgForUser = (err) => {
  let result = "Something went wrong.";
  if (err.status === "error" && !!err.message) {
    result = err.message;
  }
  return result;
};
