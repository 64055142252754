/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

// components
import { Button, Select, Input } from "../../components";
import { freelancer_questions } from "./freelancer_questions";

const FreelancerQuestionnaireCertificate = ({
  questions,
  profile,
  setProfile,
  setCertificateBlockLocked,
}) => {
  const initCertification = {
    certBody: "",
    certName: "",
    certNumber: "",
    certOtherBody: "",
  };
  const [newCertification, setNewCertification] = useState(initCertification);
  const [certification, setCertification] = useState({});
  const [certificationList, setCertificationList] = useState([]);

  // all Certifications
  const allCertifications = freelancer_questions?.certifications.options;

  // handle update
  const addCertificateClicked = () => {
    // add new certificate
    const newCertWithUid = { ...newCertification, uid: Date.now() };
    const newCert = [...profile.certifications, newCertWithUid];
    setProfile((n) => ({ ...n, certifications: newCert }));

    // reset form
    setNewCertification(initCertification);

    // unlock next button
    setCertificateBlockLocked(false);
  };

  const removeCertificateClicked = (uid) => {
    const removedCert = profile?.certifications?.filter((n) => n.uid !== uid);
    setProfile((n) => ({ ...n, certifications: removedCert }));
  };

  const isAddCertificateDisabled = () => {
    let disableButton = true;

    if (
      newCertification.certBody &&
      newCertification.certBody !== "Other" &&
      newCertification.certName &&
      newCertification.certNumber
    ) {
      disableButton = false;
    }

    if (
      newCertification.certBody === "Other" &&
      newCertification.certOtherBody &&
      newCertification.certName &&
      newCertification.certNumber
    ) {
      disableButton = false;
    }

    return disableButton;
  };

  useEffect(() => {
    setCertificationList(questions);
  }, [questions]);

  useEffect(() => {
    if (newCertification.certBody) {
      const certification = allCertifications.find((q) => q.value === newCertification.certBody);
      setCertification(certification);
    }
  }, [allCertifications, newCertification.certBody]);

  useEffect(() => {
    if (!isEmpty(profile.certifications)) {
      // lock next button
      setCertificateBlockLocked(false);
    } else {
      // lock next button
      setCertificateBlockLocked(true);
    }
  }, [profile?.certifications]);

  return (
    <div className="mb-16 rounded">
      <div className="mb-8 rounded-md">
        <Select
          dataTestId="certification-body"
          className="mb-2"
          label="Certificate Body"
          options={freelancer_questions.certifications.options || []}
          keyForOptionValue="value"
          keyForOptionLabel="value"
          value={newCertification.certBody}
          onChange={(e) =>
            setNewCertification((n) => ({
              ...n,
              certBody: e.target.value,
              certName: "",
            }))
          }
        />

        {/* if certBody is 'Other */}
        {newCertification.certBody !== "Other" && (
          <Select
            dataTestId="certification-name"
            className="mb-4"
            label="Certificate Name"
            firstEmpty={true}
            options={certification?.certifications}
            keyForOptionValue="value"
            keyForOptionLabel="value"
            value={newCertification.certName}
            disabled={!newCertification.certBody}
            onChange={(e) => setNewCertification((n) => ({ ...n, certName: e.target.value }))}
          />
        )}

        {newCertification.certBody === "Other" && (
          <div>
            <Input
              dataTestId="certification-other-body"
              type="text"
              label="Which Certificate Body"
              value={newCertification.certOtherBody}
              className="mb-4"
              onChange={(e) =>
                setNewCertification((n) => ({
                  ...n,
                  certOtherBody: e.target.value,
                }))
              }
            />

            <Input
              dataTestId="certification-other-name"
              type="text"
              label="Which Certificate Name"
              value={newCertification.certName}
              className="mb-4"
              onChange={(e) =>
                setNewCertification((n) => ({
                  ...n,
                  certName: e.target.value,
                }))
              }
            />
          </div>
        )}

        <Input
          dataTestId="certification-number"
          type="number"
          label="Certificate Number"
          value={newCertification.certBody ? newCertification.certNumber : ""}
          disabled={!newCertification.certBody}
          className="mb-4"
          onChange={(e) => setNewCertification((n) => ({ ...n, certNumber: e.target.value }))}
        />

        <div className="flex justify-evenly">
          <Button
            dataTestId="add-certificate-btn"
            variant="full"
            themecolor="sec"
            label="Add Certificate"
            disabled={isAddCertificateDisabled()}
            onClick={addCertificateClicked}
          />

          <Button
            dataTestId="donot-have-certificate-btn"
            variant="flat"
            themecolor="sec"
            label="I do not have a certificate"
            disabled={!isEmpty(profile.certifications)}
            onClick={() => setCertificateBlockLocked(false)}
          />
        </div>
      </div>

      <div>
        {!isEmpty(profile?.certifications) &&
          profile.certifications.map((n) => (
            <div key={n.uid} className="flex px-4 py-2 mb-2 border rounded-xl">
              <div className="flex-1">
                <p>
                  <span className="text-lg text-prime-light">Certificate</span>
                </p>
                <p>
                  <span className="text-prime-light">Body: </span>
                  {n.certBody !== "Other" ? n.certBody : n.certOtherBody}
                </p>
                <p>
                  <span className="text-prime-light">Name: </span>
                  {n.certName}
                </p>
                <p>
                  <span className="text-prime-light">Number: </span>
                  {n.certNumber}
                </p>
              </div>

              <div className="flex items-center">
                <Button
                  dataTestId={`remove-certificate-${n}`}
                  label={<i className="fa-regular fa-trash-can" />}
                  variant="icon-flat"
                  themecolor="prime"
                  width="w-10"
                  height="h-10"
                  textSize="text-xl"
                  onClick={() => removeCertificateClicked(n.uid)}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FreelancerQuestionnaireCertificate;
