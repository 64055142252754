import React, { useEffect, useState } from "react";

const NavCustomer = ({ tab, setTab, userProgram }) => {
  const [navigation, setNavigation] = useState([
    { label: "My Account" },
    { label: "Company Profile" },
    { label: "Subscription" },
    // { label: "Team members", disabled: true },
    // { label: 'Order History' },
  ]);

  useEffect(() => {
    if (userProgram?.program_code === "get-secured") {
      const newNavigation = [...navigation].filter((nav) => nav.label !== "Subscription");

      setNavigation(newNavigation);
    }
  }, [userProgram]);

  return (
    <div className="flex flex-col mt-8">
      {navigation.map((nav, i) => (
        <div className="relative flex items-center justify-between mb-2" key={i}>
          <button
            key={i}
            type="button"
            data-testid={`nav-${nav.label.replace(" ", "-")}`}
            className={` min-w-[240px] relative text-xl pl-4 hover:text-prime text-left after:w-[2px] after:h-[100%] after:bg-prime after:absolute after:top-0 after:left-0 after:leading-7 duration-300 after:opacity-0 hover:after:opacity-100 hover:pl-6 after:duration-300 ${
              tab === nav.label ? "after:opacity-100" : ""
            } ${nav?.disabled ? "text-prime-light" : "hover:text-prime after:bg-prime"}`}
            disabled={nav?.disabled}
            onClick={() => {
              setTab(nav.label);
            }}
          >
            {nav.label}
          </button>
        </div>
      ))}
    </div>
  );
};

export default NavCustomer;
