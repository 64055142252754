import React from "react";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

// components
import { Paper, Typography, ProgressBar } from "../../components";

const LineProjectInfo = ({ title, projectUid, step, maxStep, currentTask, currentTaskUid }) => {
  const navigate = useNavigate();

  return (
    <>
      <span
        className="my-auto mr-2 hover:underline hover:cursor-pointer"
        onClick={() => navigate(`/project/${projectUid}`)}
      >
        {title}
      </span>
      <ProgressBar step={step} maxStep={maxStep} />
      <span
        className="my-auto text-center hover:underline hover:cursor-pointer"
        onClick={() => navigate(`/project/${projectUid}/${currentTaskUid}`)}
      >
        {currentTask}
      </span>
    </>
  );
};

const CurrentProjects = ({ projects }) => {
  const navigate = useNavigate();
  return (
    <Paper className="flex-1 p-6 text-left bg-white border-2">
      <Typography type="title" className="my-5 text-prime">
        Current Projects
      </Typography>

      <div className="grid grid-cols-3 gap-4">
        <span className="mr-2"> </span>
        <span className="mr-2 text-center text-grey">Progress</span>
        <span className="mr-2 text-center text-grey">Current Task</span>

        {!isEmpty(projects) &&
          projects.map((r, i) => (
            <LineProjectInfo
              title={r.project}
              projectUid={r.project_uid}
              step={r.activeTask}
              maxStep={r.totalTasks}
              currentTask={r.current_task_name}
              currentTaskUid={r.current_task_uid}
              key={i}
            />
          ))}
      </div>
      <div
        className="w-full m-auto mt-4 text-center hover:underline text-terc hover:cursor-pointer"
        onClick={() => navigate("/projects")}
      >
        View more
      </div>
    </Paper>
  );
};

export default CurrentProjects;
