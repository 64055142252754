/* eslint-disable no-dupe-else-if */
import { isEmpty, isString } from "lodash";

const isAssignedToYou = (yourRole, task) => {
  let csm, customer, freelancer;

  if (!isEmpty(task) && task !== undefined && task !== null && !!task.assignability) {
    let assignability = {};
    if (!isString(task.assignability)) {
      assignability = task.assignability;
    } else {
      assignability = JSON.parse(task.assignability);
    }
    csm = assignability.to_csm;
    customer = assignability.to_customer;
    freelancer = assignability.to_freelancer;
  } else {
    csm = task.assigned_to_csm;
    customer = task.assigned_to_customer;
    freelancer = task.assigned_to_freelancer;
  }

  if (yourRole === "Freelancer") {
    return freelancer;
  } else if (yourRole === "Csm") {
    return csm;
  } else if (yourRole === "Customer") {
    return customer;
  } else {
    return false;
  }
};

const taskTemplateAssignedTo = (taskTemplate) => {
  // console.log("task template", taskTemplate)
  let result = "---";

  let csm, customer, freelancer;

  if (
    !isEmpty(taskTemplate) &&
    taskTemplate !== undefined &&
    taskTemplate !== null &&
    // !!task.assignability
    !!taskTemplate.assignability
  ) {
    let assignability = {};
    if (!isString(taskTemplate.assignability)) {
      assignability = taskTemplate.assignability;
    } else {
      assignability = JSON.parse(taskTemplate.assignability);
    }
    csm = assignability.to_csm;
    customer = assignability.to_customer;
    freelancer = assignability.to_freelancer;
  } else {
    csm = taskTemplate.assigned_to_csm;
    customer = taskTemplate.assigned_to_customer;
    freelancer = taskTemplate.assigned_to_freelancer;
  }

  if (csm && customer && freelancer) {
    result = "Customer, Cyber-expert, and CSM";
  } else if (customer && freelancer) {
    result = "Customer and Cyber-expert";
  } else if (csm && freelancer) {
    result = "CSM and Cyber-expert";
  } else if (csm && customer) {
    result = "Customer and CSM";
  } else if (freelancer) {
    result = "Cyber-expert";
  } else if (customer) {
    result = "Customer";
  } else if (csm) {
    result = "CSM";
  } else {
    result = "N/A";
  }

  return result;
};

const customerViewAssignedTo = (task) => {
  let result = "---";
  if (!isEmpty(task) && task !== undefined && task !== null && !!task.assignability) {
    let assignability = {};
    if (!isString(task.assignability)) {
      assignability = task.assignability;
    } else {
      assignability = JSON.parse(task.assignability);
    }
    let csm = assignability.to_csm;
    let customer = assignability.to_customer;
    let freelancer = assignability.to_freelancer;

    result = "N/A";

    if (customer) {
      result = "You";
    } else if (csm || freelancer) {
      result = "Cyber Pop-up";
    } else {
      result = "You and Cyber Pop-up";
    }
  }

  return result;
};

const freelancerViewAssignedTo = (task) => {
  let result = "---";
  if (!isEmpty(task) && task !== undefined && task !== null && !!task.assignability) {
    let assignability = {};
    if (!isString(task.assignability)) {
      assignability = task.assignability;
    } else {
      assignability = JSON.parse(task.assignability);
    }
    let csm = assignability.to_csm;
    let customer = assignability.to_customer;
    let freelancer = assignability.to_freelancer;

    if (freelancer) {
      result = "You";
    } else if (csm) {
      result = "Cyber Pop-up";
    } else if (customer && csm) {
      result = "Customer and Cyber Pop-up";
    } else if (customer && freelancer) {
      result = "You and Customer";
    } else if (customer && freelancer && csm) {
      result = "You, Customer and Cyber Pop-up";
    } else {
      result = "N/A";
    }
  }

  return result;
};

export {
  taskTemplateAssignedTo,
  customerViewAssignedTo,
  freelancerViewAssignedTo,
  isAssignedToYou,
};
