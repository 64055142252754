import React, { useEffect, useState } from "react";

import { ProjectService } from "../../services";

// components
import { Button } from "../index";
import ContactModal from "./ContactModal";
// image
import DefaultAvatar from "./default-avatar.png";

const ContactManager = ({ projectUid, role, showHelpText = true }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const [manager, setManager] = useState({});
  const [managerProfile, setManagerProfile] = useState({});
  const [fullName, setFullName] = useState("");
  const [assignedByDefault, setAssignedByDefault] = useState(true);

  const userUid = JSON.parse(localStorage.getItem("user"));

  // useEffect(() => {
  //   console.log({ assignedByDefault });
  // }, []);

  useEffect(() => {
    ProjectService.assignedContact({
      user_uid: userUid.uid,
      role_name: role,
      project_uid: projectUid,
    }).then((r) => {
      setManager(r.user);
      setAssignedByDefault(r.csm_default);
      setManagerProfile(r.profile);
      setFullName(
        r.profile?.responsible_person_first_name + " " + r.profile?.responsible_person_last_name
      );
    });
  }, [role, projectUid]);

  return (
    <div className="w-[320px] p-6">
      {showHelpText && (
        <p className="my-3 text-xl font-medium text-prime">Need Help or have a question?</p>
      )}

      <div className="flex items-center">
        <img
          src={managerProfile?.profile_image || DefaultAvatar}
          alt="contact"
          className="w-12 h-12 mr-4 rounded-full"
        />

        {
          // If role is Fsm ignore assignedByDefault, since we dont have a way to currently assign Fsm - show default one
          (managerProfile?.responsible_person_first_name && role === "Fsm") ||
          managerProfile?.responsible_person_first_name ? (
            <div>
              <p className="font-medium">
                {assignedByDefault !== true
                  ? fullName
                  : managerProfile?.responsible_person_first_name}
              </p>
              <p className="text-prime-light">
                {role === "Csm"
                  ? assignedByDefault === true
                    ? ""
                    : "Customer success manager"
                  : role === "Fsm"
                  ? "Cyber-expert success manager"
                  : "Cyber-expert"}
              </p>
            </div>
          ) : (
            <div>
              {" "}
              <p className="text-prime-light">
                {role === "Csm"
                  ? "CSM not assigned"
                  : role === "Fsm"
                  ? "Cyber-expert success manager not assigned"
                  : "Cyber-expert not assigned"}
              </p>{" "}
              <p> </p>
            </div>
          )
        }
      </div>

      {managerProfile?.responsible_person_first_name && (
        <Button
          dataTestId="message-csm-button"
          label="Send a message"
          variant="outlined"
          themecolor="terc"
          className="block mx-auto my-4"
          onClick={() => setModalOpened(true)}
        />
      )}

      {/* modal contact CSM */}
      {modalOpened && (
        <ContactModal
          setModalOpened={setModalOpened}
          managerEmail={manager.email}
          fullName={fullName}
        />
      )}
    </div>
  );
};

export default ContactManager;
