/* NOTE:
  for type="number"
  className="InputCurrency-no-arrows" - removes arrows
*/

import { forwardRef, useEffect, useState } from "react";

const InputCurrency = forwardRef(
  (
    {
      dataTestId,
      name,
      label,
      placeholder = "",
      error = "",
      value,
      onChange,
      className = "mb-4",
      borderClass,
      inputClassName = "",
      ...rest
    },
    ref
  ) => {
    const displayValue = value / 100;

    const [val, setVal] = useState(displayValue);

    useEffect(() => {
      onChange(val);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val]);

    return (
      <div className={className}>
        <label>
          {label && <span className="block text-prime-light">{label}</span>}

          <input
            data-testid={dataTestId}
            ref={ref}
            type="number"
            format=""
            name={name}
            placeholder={placeholder}
            value={val}
            onChange={(e) => setVal(e.target.valueAsNumber)}
            className={`w-full rounded-md shadow-sm bg-gray-50 focus:border disabled:opacity-50 focus:border-prime focus:outline-none ${
              borderClass || "border-gray-300"
            } ${inputClassName}`}
            {...rest}
          />

          {error && <div className="mt-1 mb-2 text-sm text-error">{error}</div>}
        </label>
      </div>
    );
  }
);

export default InputCurrency;
