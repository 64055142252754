export const freelancer_questions = {
  titles: {
    about_you: "We are glad to meet you! Tell us about yourself.",
    cyber_security: "Let's talk about what you've done to date in Cybersecurity!",
    more_about_you: ["Almost done, just a few questions left to help us learn more about you!"],
    diversity_id: ["Diversity ID"],
  },
  question_bloks: {
    1: [
      "responsible_person_first_name",
      "responsible_person_last_name",
      "country",
      "state",
      "city",
      "zip_code",
      "address_one",
      "address_two",
      "time_zone",
      "prefered_language",
    ],
    2: ["social_media", "resume_upload"],
    3: ["hear_about_us", "hear_about_us_where,", "what_led_to_applay"],
    4: ["years_of_experience", "management_skills"],
    5: ["experience_in_industry"],
    6: ["experience_in_area"],
    7: ["certifications"],
    8: ["hours_per_week", "comfort_level"],
    9: ["accomplishment"],
    10: ["gender", "race", "race_other", "veteran_status"],
    11: ["project_terms"],
    12: ["code_of_conduct"],
  },
  responsible_person_first_name: {
    uid: 1,
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "First name",
    value: "responsible_person_first_name",
    required: true,
  },
  responsible_person_last_name: {
    uid: 2,
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "Last name",
    value: "responsible_person_last_name",
    required: true,
  },
  country: {
    uid: 3,
    title: "about_you",
    input_element: "select",
    input_type: null,
    label: "Country",
    options: "countries",
    keyForOptionValue: "alpha3Code",
    keyForOptionLabel: "country",
    value: "country",
    required: true,
  },
  state: {
    uid: 4,
    title: "about_you",
    input_element: "select",
    input_type: null,
    label: "State",
    value: "state",
    options: "states",
    required: false,
  },
  city: {
    uid: 5,
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "City",
    value: "city",
    required: true,
  },
  zip_code: {
    uid: 6,
    title: "about_you",
    input_element: "input",
    input_type: "text",
    min: "0",
    label: "Postal Code",
    value: "zip_code",
    required: true,
  },
  address_one: {
    uid: 7,
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "Address 1",
    value: "address_one",
    required: true,
  },
  address_two: {
    uid: "7a",
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "Address 2",
    value: "address_two",
    required: false,
  },
  time_zone: {
    uid: 8,
    title: "about_you",
    input_element: "select",
    input_type: null,
    label: "What timezone are you in?",
    options: "timezones",
    keyForOptionValue: "value",
    keyForOptionLabel: "text",
    value: "time_zone",
    required: true,
  },
  prefered_language: {
    uid: 9,
    title: "about_you",
    input_element: "select",
    input_type: null,
    label: "What language do you prefer for interactions and meetings?",
    options: "languages",
    value: "prefered_language",
    firstEmpty: false,
    required: true,
  },
  social_media: {
    uid: 10,
    title: "about_you",
    input_element: "tags",
    tagsAsList: true,
    tagMaxWidth: "inherit",
    label: "Link to your LinkedIn profile, Github or personal website: (optional)",
    value: "social_media",
    required: false,
  },
  resume_upload: {
    uid: 11,
    title: "about_you",
    input_element: "input-file",
    input_type: null,
    accept: "application/pdf,application/vnd.ms-excel,.doc,.docx",
    label: "Upload your Resume (PDF, MS-Excel, DOC or DOCX only).",
    value: "resume_upload",
    required: true,
  },
  hear_about_us: {
    uid: 12,
    title: "about_you",
    input_element: "select",
    input_type: null,
    label: "How did you hear about Cyber Pop-up?",
    options: ["Web Search ", "Social Media", "Job Site", "Friend or Family", "Other"],
    value: "hear_about_us",
    required: true,
  },
  hear_about_us_where: {
    uid: "12-e",
    title: "about_you",
    input_element: "input",
    input_type: "text",
    label: "Please specify where you heard about us. *",
    value: "hear_about_us_where",
    required: false,
  },
  what_led_to_applay: {
    uid: 13,
    title: "about_you",
    input_element: "textarea",
    input_type: null,
    label: "What led you to apply to join Cyber Pop-up?",
    value: "what_led_to_applay",
    rows: "2",
    required: true,
    maxCharacters: "600",
  },
  years_of_experience: {
    uid: 14,
    title: "cyber_security",
    input_element: "select",
    input_type: null,
    label: "How many years of Cybersecurity experience do you have?",
    options: ["0 - 3", "3 - 7", "7 - 10", "10+"],
    value: "years_of_experience",
    required: true,
  },
  management_skills: {
    uid: 15,
    title: "cyber_security",
    input_element: "radio-list",
    input_type: null,
    label: "What level of experience do you have in the management areas listed below?",
    value: "management_skills",
    radio_options: ["None", "Novice", "Intermediate", "Expert"],
    radio_options_sec_row: ["", "<2yrs", "2-5yrs", "5+yrs"],
    other_placeholder: "Add management skill and level of experience",
    required: true,
  },
  experience_in_industry: {
    uid: 16,
    title: "cyber_security",
    input_element: "radio-list",
    input_type: null,
    label: "What level experience you do you have in the industries listed below?",
    radio_options: ["None", "Novice", "Intermediate", "Expert"],
    radio_options_sec_row: ["", "<2yrs", "2-5yrs", "5+yrs"],
    value: "experience_in_industry",
    other_placeholder: "Add industry and level of experience",
    required: true,
  },
  experience_in_area: {
    uid: 17,
    title: "cyber_security",
    input_element: "radio-list",
    input_type: null,
    label: "What level of experience do you have in the technical areas listed below?",
    radio_options: ["None", "Novice", "Intermediate", "Expert"],
    radio_options_sec_row: ["", "<2yrs", "2-5yrs", "5+yrs"],
    other_placeholder: "Add area and level of experience",
    value: "experience_in_area",
    required: true,
  },

  certifications: {
    uid: 18,
    title: "cyber_security",
    input_element: "certifications",
    input_type: null,
    label:
      "Do you possess any certifications with the following certification bodies? If so, which certifications? Choose all that apply.",
    options: [
      {
        value: "GIAC",
        dataTestId: "certification_GIAC",
        certifications: [
          "Certified Incident Handler (GCIH)",
          "Security Essentials Certification (GSEC)",
          "Advisory Board",
          "Certified Forensic Analyst (GCFA)",
          "Certiified Penetration Tester (GPEN)",
          "Certified Intrusion Analyst (GCIA)",
          "Certified Forensic Examiner (GCFE)",
          "Web Application Penetration Tester (GWAPT)",
          "Engineering Malware (GREM)",
          "Security Leadership (GSLC)",
          "Global Industrial Cyber Security Professional (GICSP)",
          "Certified Enterprise Defender (GCED)",
          "Continuous Monitoring Certification (GMON)",
          "Network Forensic Analyst (GNFA)",
          "Information Security Fundamentals (GISF)",
          "Exploit Researcher and Advanced Penetration Tester (GXPN)",
          "Critical Controls Certification (GCCC)",
          "Cyber Threat Intelligence (GCTI)",
          "Certified Windows Security Administrator (GCWN)",
          "Systems and Network Auditor (GSNA)",
          "Strategic Planning, Policy, and Leadership (GSTRT)",
          "Defending Advanced Threats (GDAT)",
          "Information Security Professional (GISP)",
          "Python Coder (GPYC)",
          "Mobile Device Security Analyst (GMOB)",
          "Certified Detection Analyst (GCDA)",
          "Defensible Security Architecture (GDSA)",
          "Response and Industrial Defense (GRID)",
          "Gold Research Paper",
          "Cloud Security Automation Certification (GCSA)",
          "Advanced Smartphone Forensics (GASF)",
          "Certified Web Application Defender (GWEB)",
          "Foundational Cybersecurity Technologies (GFACT)",
          "Assessing and Auditing Wireless Networks (GAWN)",
          "Certified UNIX Security Administrator (GCUX)",
          "Law of Data Security & Investigations (GLEG)",
          "Cloud Penetration Tester (GCPN)",
          "Cloud Security Essentials (GCLD)",
          "Certified Project Management (GCPM)",
          "Certified Perimeter Protection Analyst (GPPA)",
          "Open Source Intelligence (GOSI)",
          "Secure Software Programmer- Java (GSSP-JAVA)",
          "Enterprise Vulnerability Assessor (GEVA)",
          "Critical Infrastructure Protection (GCIP)",
          "Public Cloud Security (GPCS)",
          "Security Expert (GSE)",
          "Battlefield Forensics and Acquisition (GBFA)",
          "Secure Software Programmer-.Net (GSSP-.NET)",
          "Security Operations Certified (GSOC)",
          "Security Operations Manager (GSOM)",
        ],
      },
      {
        value: "(ISC)2",
        dataTestId: "certification_(ISC)2",
        certifications: [
          "Certified Information Systems Security Professional (CISSP)",
          "Associate of (ISC)2",
          "Certified Cloud Security Professional (CCSP)",
          "Systems Security Certified Practitioner (SSCP)",
          "Certified Authorization Professional (CAP)",
          "Certified Secure Software Lifecycle Professional (CSSLP)",
          "Information Systems Security Architecture Professional (CISSP-ISSAP)",
          "Healthcare Information Security and Privacy Practitioner (HCISPP)",
          "Information Systems Security Management Professional (CISSP-ISSMP)",
          "Information Systems Security Engineering Professional (CISSP-ISSEP)",
          "Certified Cyber Forensics Professional - United States (CCFP)",
          "Secure Software Practitioner - Java",
          "Secure Software Practitioner - Mobile",
          "Certified Cyber Forensics Professional - European Union (CCFP-EU)",
          "Secure Software Practitioner - .NET",
          "Certified Cyber Forensics Professional - South Korea (CCFP-KR)",
          "Secure Software Practitioner- Software Architect",
          "Secure Software Practitioner - PHP",
          "Secure Software Practitioner - QA",
          "Certified Cyber Forensics Professional - India (CCFP-IN)",
          "Secure Software Practitioner - Android",
          "Secure Software Practitioner - C++",
          "Secure Software Practitioner - iOS",
          "Secure Software Practitioner - Project Manager",
        ],
      },
      {
        value: "ISACA",
        dataTestId: "certification_ISACA",
        certifications: [
          "Certified Information Systems Auditor (CISA)",
          "Certified Information Security Manager (CISM)",
          "Certified in Risk and Information Systems Control (CRISC)",
          "Certified Data Privacy Solutions Engineer (CDPSE)",
          "Certified in Governance of Enterprise IT (CGEIT)",
          "Cybersecurity Fundamentals Certificate",
          "COBIT 2019 Foundation Certificate",
          "Cybersecurity Audit Certificate",
          "Cybersecurity Practitioner Certification",
          "COBIT 2019 Design & Implementation Certificate",
          "Certificate of Cloud Auditing Knowledge",
          "IT Risk Fundamentals Certificate",
          "Cloud Fundamentals Certificate",
          "Blockchain Fundamentals Certificate",
          "Artificial Intelligence Fundamentals Certificate",
          "IoT Fundamentals Certificate",
          "Implementing the NIST Cybersecurity Framework Using COBIT 2019 Certificate",
          "Computing Fundamentals Certificate",
          "Networks and Infrastructure Fundamentals Certificate",
          "Software Development Fundamentals Certificate",
          "Data Science Fundamentals Certificate",
          "Information Technology Certified Associate (ITCA)",
        ],
      },
      {
        value: "CompTIA",
        dataTestId: "certification_CompTIA",
        certifications: [
          "Security+ Certification",
          "Network+ Certification",
          "IT Operations Specialist - CIOS Stackable - Certification",
          "Secure Infrastructure Specialist - CSIS Stackable Certification",
          "IT Fundamentals (ITF+) Certification",
          "Security Analytics Professional - CSAP Stackable Certification",
          "Advanced Security Practitioner (CASP+) CE Certification",
          "IT Fundamentals Certification",
          "A+ Certification",
          "Linux+ Certification",
          "Network Infrastructure Professional - CNIP Stackable Certification",
          "Cloud Essentials Certification",
          "Linux Network Professional - CLNP Stackable Certification",
          "Systems Support Specialist - CSSS Stackable Certification",
          "Network Vulnerability Assessment Professional - CVNP Stackable Certification",
          "Secure Cloud Professional - CSCP Stackable Certification",
          "Cloud Admin Professional - CCAP Stackable Certification",
          "Cloud Essentials+ Certification",
          "Network Security Professional - CNSP Stackable Certification",
          "IT Fundamentals",
          "Security Analytics Expert - CSAE Stackable Certification",
          "Server+ Certification",
          "Linux+ Certification",
          "Infrastructure Security Expert - CSIE Stackable Certification",
          "CTT+ Classroom Trainer Certification",
          "Project+ Certification",
          "CTT+ Virtual Classroom Trainer Certification",
          "Certification Governance Committee",
          "Certification SME Technical Advisory Committee",
        ],
      },
      {
        value: "EC-Council",
        dataTestId: "certification_EC-Council",
        certifications: [
          "Certified Ethical Hacker (CEH)",
          "Certified Network Defender (CND)",
          "Certified Penetration Tester (CPENT)",
          "Computer Hacking Forensic Investigator (CHFI)",
          "Certified Incident Handler",
        ],
      },
      {
        value: "Other",
        dataTestId: "certification_Other",
      },
    ],
    value: "certifications",
    sub_question: true,
    required: false,
  },

  hours_per_week: {
    uid: 19,
    title: "more_about_you",
    input_element: "select",
    input_type: null,
    label: "How many hours per week are you willing to dedicate to freelancer projects?",
    options: ["5-10", "10-20", "20-30", "30-40"],
    value: "hours_per_week",
    required: true,
  },
  comfort_level: {
    uid: 20,
    title: "more_about_you",
    input_element: "select",
    input_type: null,
    label: "Please rate your level of comfort with consulting independently.",
    options: [
      "1 - Very Uncomfortable",
      "2 - Uncomfortable",
      "3 - Indifferent",
      "4 - Comfortable",
      "5 - Very Comfortable",
    ],
    value: "comfort_level",
    required: true,
  },
  accomplishment: {
    uid: 21,
    title: "more_about_you",
    input_element: "textarea",
    input_type: null,
    rows: "2",
    maxCharacters: "600",
    label: "What thing in your life are you most proud of accomplishing?",
    value: "accomplishment",
    required: true,
  },
  gender: {
    uid: 22,
    title: "more_about_you",
    input_element: "radio",
    input_type: null,
    label: "1) Please let us know what you most closely identify with?",
    options: [
      {
        value: "Male",
        dataTestId: "Male",
      },
      {
        value: "Female",
        dataTestId: "Female",
      },
      {
        value: "Genderqueer / Non-Binary",
        dataTestId: "Genderqueer",
      },
      {
        value: "Other",
        dataTestId: "Other",
      },
      {
        value: "Prefer not to disclose",
        dataTestId: "prefer-not-to-disclose",
      },
    ],
    value: "gender",
    required: false,
  },
  race: {
    uid: 23,
    title: "more_about_you",
    input_element: "checkbox",
    input_type: "multiple",
    label: "2) Please self-classify your race",
    options: [
      {
        value: "American Indian or Alaska Native",
        dataTestId: "american-indian-alaska",
      },
      {
        value: "Asian",
        dataTestId: "Asian",
      },
      {
        value: "Black or African American",
        dataTestId: "black",
      },
      {
        value: "Native Hawaiian or other Pacific Islander",
        dataTestId: "native-hawaiian-or-pacific islander",
      },
      {
        value: "White",
        dataTestId: "White",
      },
      {
        value: "Hispanic or Latino",
        dataTestId: "hispanic-or-latino",
      },
      {
        value: "Prefer not to disclose",
        dataTestId: "prefer-not-to-disclose",
      },
    ],
    value: "race",
    required: false,
  },
  race_other: {
    uid: "23a",
    title: "more_about_you",
    input_element: "input",
    input_type: "text",
    label: "Other race",
    value: "race_other",
    required: false,
  },
  veteran_status: {
    uid: 24,
    title: "more_about_you",
    input_element: "radio",
    input_type: null,
    label: "3) What is your veteran status?",
    options: [
      {
        value: "I am a veteran",
        dataTestId: "i-am-veteran",
      },
      {
        value: "I am not a veteran",
        dataTestId: "i-am-not-veteran",
      },
      {
        value: "Prefer not to disclose",
        dataTestId: "prefer-not-to-disclose",
      },
    ],
    value: "veteran_status",
    required: false,
  },
  project_terms: {
    uid: 25,
    title: "more_about_you",
    input_element: "checkbox",
    input_type: null,
    label: "Do you agree to abide by our Cyber-expert Project Terms?",
    options: [
      {
        value: "I Accept",
        dataTestId: "project_terms-accept",
      },
    ],
    value: "project_terms",
    required: true,
  },
  code_of_conduct: {
    uid: 26,
    title: "more_about_you",
    input_element: "checkbox",
    input_type: null,
    label: "Do you agree to abide by our Cyber-expert Code of Conduct?",
    options: [
      {
        value: "I Accept",
        dataTestId: "code_of_conduct-accept",
      },
    ],
    value: "code_of_conduct",
    required: true,
  },
};
