import { CheckIcon } from "@heroicons/react/20/solid";
import Loader from "../Loader";

const includedFeatures = [
  "Customized Cyber Security Roadmap",
  "Step-by-step guidance for each project",
  "Dedicated customer support manager",
  "Vetted Cyber-Expert guiding you through the process",
];

export default function PomrUpgradeCard({ pomr, purchaseLoading, purchasePomr }) {
  return (
    <div className="mx-auto max-w-7xl">
      <div className="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
        <div className="p-8 sm:p-10 lg:flex-auto">
          <h3 className="text-2xl font-bold tracking-tight text-gray-900">
            Unlock the full Peace of Mind Roadmap
          </h3>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Unlock our custom-tailored Peace of Mind Roadmap&trade;. Merging your business insights
            with our cybersecurity expertise, we deliver bite-sized, impactful security solutions.
            You lead, we guide - ensuring protection at every turn.
          </p>
          <div className="mt-10 flex items-center gap-x-4">
            <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
              What’s included
            </h4>
            <div className="h-px flex-auto bg-gray-100" />
          </div>
          <ul
            role="list"
            className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
          >
            {includedFeatures.map((feature) => (
              <li key={feature} className="flex gap-x-3">
                <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                {feature}
              </li>
            ))}
          </ul>
        </div>
        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
          <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
            <div className="mx-auto max-w-xs px-8">
              <p className="text-base font-semibold text-gray-600">
                Pay once, own your Roadmap forever{" "}
              </p>
              <p className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-gray-900">
                  ${pomr?.cost}
                </span>
                <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                  USD
                </span>
              </p>
              <a
                onClick={() => purchasePomr()}
                className="relative mt-10 block w-full flex justify-center items-center rounded-md bg-indigo-600 px-3 h-12 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
              >
                {purchaseLoading ? (
                  <Loader className="absolute w-6 h-6 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                ) : (
                  <span className="relative text-white">Get access</span>
                )}
              </a>
              <p className="mt-6 text-xs leading-5 text-gray-600">
                Invoices and receipts available for easy company reimbursement
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
