import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ProgramForm from "./ProgramForm";

function AddProgramModal({ open, setOpen, setPrograms }) {
  return (
    <Dialog
      as="div"
      className="relative z-10"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 "
                onClick={() => {
                  setOpen(false);
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <ProgramForm setProgramFormVisible={setOpen} setPrograms={setPrograms} />
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}

export default AddProgramModal;
