import React from "react";
import { includes, isEmpty } from "lodash";

// components
import { Input, InputFile, Textarea, Select, Radio, Checkbox, Tags } from "../../components";
import FreelancerQuestionnaireCertificate from "./FreelancerQuestionnaireCertificate";
import FreelancerRadioList from "./FreelancerRadioList";

const SetInputElement = ({
  question,
  questions,
  profile = {},
  setProfile,
  countries = [],
  states = [],
  languages = [],
  timezones = [],
  setCertificateBlockLocked,
}) => {
  const setInputElement = () => {
    const questionObj = questions[question];

    const inputElement = questionObj?.input_element;
    if (!inputElement) return "";

    // set lable
    let label = questionObj?.label || "";
    if (questionObj?.required) {
      label = label + " *";
    }
    // for state (contry that have states)
    if (questionObj?.options === "states" && !isEmpty(states)) {
      label = label + " *";
    }

    const setLabel = () => <div className="mb-2 text-black">{label}</div>;

    // set sublable
    const sublabel = questionObj?.sublabel || "";

    const setSublabel = () => <div className="mb-2 text-prime-light">{sublabel}</div>;

    const setExtraInput = (option) => (
      <div>
        <div className="mb-2 text-prime-light">
          Enter: Certifications body, Certification Name (Certification Number).
        </div>

        <Input
          dataTestId={option?.dataTestId}
          type="text"
          value={profile[option?.dataTestId]}
          placeholder="Exp: CertBody, ABC (123456)"
          className="mb-8"
          onChange={(e) =>
            setProfile((n) => ({
              ...n,
              [option?.dataTestId]: e.target.value,
            }))
          }
        />
      </div>
    );

    switch (inputElement) {
      case "input":
        return (
          <div>
            {setLabel()}
            {sublabel && setSublabel()}
            <Input
              dataTestId={questionObj?.value}
              type={questionObj?.input_type}
              min={questionObj?.min || ""}
              max={questionObj?.max || ""}
              value={profile[questionObj.value]}
              className="mb-8"
              onChange={(e) =>
                setProfile((n) => ({
                  ...n,
                  [questionObj.value]: e.target.value,
                }))
              }
            />
          </div>
        );

      case "input-file":
        return (
          <div>
            {setLabel()}
            {sublabel && setSublabel()}
            <InputFile
              dataTestId={questionObj?.value}
              className="mb-8"
              accept={questionObj?.accept}
              onChange={(v) => setProfile((n) => ({ ...n, [questionObj.value]: v }))}
            />
          </div>
        );

      case "textarea":
        return (
          <div>
            {setLabel()}
            {sublabel && setSublabel()}
            <Textarea
              dataTestId={questionObj?.value}
              rows={questionObj.rows || "2"}
              className={`${questionObj.maxCharacters ? "mb-0" : "mb-8"}`}
              maxCharacters={questionObj.maxCharacters}
              value={profile[questionObj.value]}
              onChange={(e) =>
                setProfile((n) => ({
                  ...n,
                  [questionObj.value]: e.target.value,
                }))
              }
            />
          </div>
        );

      case "select":
        return (
          <div>
            {setLabel()}
            {sublabel && setSublabel()}
            <Select
              dataTestId={questionObj?.value}
              name={questionObj?.value}
              className="mb-8"
              options={
                questionObj?.options === "countries"
                  ? countries
                  : questionObj?.options === "states"
                  ? states
                  : questionObj?.options === "timezones"
                  ? timezones
                  : questionObj?.options === "languages"
                  ? languages
                  : questionObj?.options
              }
              keyForOptionValue={questionObj?.keyForOptionValue}
              keyForOptionLabel={questionObj?.keyForOptionLabel}
              firstEmpty={questionObj.firstEmpty ? questionObj?.firstEmpty : true}
              value={profile[questionObj.value]}
              onChange={(e) =>
                setProfile((n) => ({
                  ...n,
                  [questionObj.value]: e.target.value,
                }))
              }
            />
          </div>
        );

      case "radio":
        return (
          <div>
            {setLabel()}
            {sublabel && setSublabel()}
            <Radio
              name={questionObj?.value}
              className="mb-8"
              radios={questionObj?.options}
              checked={profile[questionObj.value]}
              onChange={(e) => {
                let changedValue = e.target.value;
                if (changedValue === "true" || changedValue === "false") {
                  setProfile((n) => ({
                    ...n,
                    [questionObj.value]: JSON.parse(changedValue),
                  }));
                } else {
                  setProfile((n) => ({
                    ...n,
                    [questionObj.value]: changedValue,
                  }));
                }
              }}
            />
          </div>
        );

      case "checkbox":
        return (
          <div>
            {setLabel()}
            {sublabel && setSublabel()}
            {questionObj?.options?.map((option) => {
              if (option.heading) {
                return (
                  <p className="mb-2 text-prime-light" key={option.dataTestId}>
                    {option.value}
                  </p>
                );
              } else {
                return (
                  <div key={option.dataTestId}>
                    <Checkbox
                      dataTestId={option.dataTestId}
                      label={option.value}
                      className="mb-2"
                      value={questionObj.dataTestId}
                      // checked={profile[questionObj.value]}
                      checked={includes(profile[questionObj.value], option.value)}
                      onChange={(e) => {
                        let newAnswerObj = [];
                        if (e.target.checked) {
                          newAnswerObj = [...profile[questionObj.value], option.value];
                        } else {
                          newAnswerObj = profile[questionObj.value].filter(
                            (n) => n !== option.value
                          );
                        }
                        setProfile((n) => ({
                          ...n,
                          [questionObj.value]: newAnswerObj,
                        }));
                      }}
                    />
                    {questionObj.sub_question &&
                      includes(profile[questionObj.value], option.value) &&
                      setExtraInput(option)}
                  </div>
                );
              }
            })}
          </div>
        );

      case "tags":
        return (
          <div>
            {setLabel()}
            {sublabel && setSublabel()}
            <Tags
              id={questionObj?.uid}
              data-testid={questionObj?.uid}
              tagsAsList={questionObj?.tagsAsList || false}
              tagMaxWidth={questionObj?.tagMaxWidth}
              className="mb-8"
              tags={profile[questionObj.value]}
              setTags={(v) => setProfile((n) => ({ ...n, [questionObj.value]: v }))}
            />
          </div>
        );

      case "radio-list":
        return (
          <div>
            {setLabel()}
            {sublabel && setSublabel()}
            <FreelancerRadioList
              question={question}
              questions={questions}
              profile={profile}
              setProfile={setProfile}
            />
          </div>
        );

      case "certifications":
        return (
          <div>
            {setLabel()}
            {sublabel && setSublabel()}
            <FreelancerQuestionnaireCertificate
              question={question}
              questions={questions}
              profile={profile}
              setProfile={setProfile}
              setCertificateBlockLocked={setCertificateBlockLocked}
            />
          </div>
        );

      default:
        return "";
    }
  };

  return setInputElement();
};

export default SetInputElement;
