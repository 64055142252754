// components
import { useEffect, useState, useRef } from "react";
import { isEqual } from "lodash";
import { Button, Card, Typography } from "../../../components";
import SimpleAlertWithCancelModal from "../../../components/modals-tailwindui/SimpleAlertWithCancelModal";
import { useAuth } from "../../../context";
import { ProfileService, QuestionnaireService } from "../../../services";
import QuestionnaireRadioList from "../../questionnaire/QuestionnaireRadioList";
import { freelancer_questions } from "../../questionnaire/freelancer_questions";
import AboutYou from "./AboutYou";
import CertificationsList from "./CertificationsList";

// services and stores

// constants

// context

const MyProfile = ({
  profile,
  getProfile,
  questionnaire,
  getQuestionnaire,
  profileEditsLocked,
  handleProfileSubmit,
}) => {
  // context
  const { authContext } = useAuth();

  /**
   * Handle Questionnaire update request
   * @param {string} key key for question option ex: 'management_skills'
   * @param {object} data object that contains new answers
   */

  const [currentAnswers, setCurrentAnswers] = useState({});
  const [triggerProfileResubmit, setTriggerProfileResubmit] = useState(false);
  const [answersHaveChanged, setAnswersHaveChanged] = useState(false);

  // * Modal States
  const [updateProfileModalVisible, setUpdateProfileModalVisible] = useState(false);

  const initialAnswers = useRef();

  const handleQuestionnaireUpdate = async () => {
    if (!isEqual(currentAnswers.about_me, initialAnswers.initial.about_me)) {
      await ProfileService.update({
        user_uid: authContext?.user.uid,
        about_me: currentAnswers.about_me,
      });
      getProfile();
    }

    const {
      management_skills,
      experience_in_industry,
      experience_in_area,
      certifications,
      social_media,
    } = currentAnswers;

    const questionnaireUpdateObj = {
      ...questionnaire.questions,
      social_media,
      management_skills,
      experience_in_area,
      experience_in_industry,
      certifications,
    };

    await QuestionnaireService.update({
      uid: questionnaire.uid,
      questions: questionnaireUpdateObj,
    });

    await getQuestionnaire();
  };

  const handleChangeValue = ({ changeKey, changeValue }) => {
    setCurrentAnswers((prevState) => ({
      ...prevState,
      [changeKey]: changeValue,
    }));
  };

  const handleSaveChangesClick = () => {
    setUpdateProfileModalVisible(false);
    handleQuestionnaireUpdate();

    if (triggerProfileResubmit) {
      handleProfileSubmit("Resubmitted");
    }
  };

  useEffect(() => {
    // If the user has input any changes
    let hasChanged = false;
    if (!isEqual(currentAnswers, initialAnswers.initial)) {
      hasChanged = true;
    }
    setAnswersHaveChanged(hasChanged);

    // If anything other than the "About me" section has changed, the profile needs to be resubmitted to the CSM
    let changeTriggerValue = false;
    if (currentAnswers && initialAnswers.initial) {
      const { about_me: initialAboutMe, ...initialOtherSections } = initialAnswers.initial;
      const { about_me: currentAboutMe, ...currentOtherSections } = currentAnswers;

      if (
        (!isEqual(initialOtherSections, currentOtherSections) &&
          profile?.application_status === "Expert") ||
        profile?.application_status === "Enthusiast"
      ) {
        changeTriggerValue = true;
      }
    }
    setTriggerProfileResubmit(changeTriggerValue);
  }, [currentAnswers]);

  useEffect(() => {
    const userAnswers = {
      about_me: profile?.about_me,
      social_media: questionnaire?.questions?.social_media,
      certifications: questionnaire?.questions?.certifications,
      management_skills: questionnaire?.questions?.management_skills,
      experience_in_industry: questionnaire?.questions?.experience_in_industry,
      experience_in_area: questionnaire?.questions?.experience_in_area,
    };

    setCurrentAnswers(userAnswers);
    initialAnswers.initial = userAnswers;
  }, [questionnaire, profile]);

  return (
    <>
      <div>
        <Card title="Your Cyber-exper profile">
          <div className="relative flex flex-col space-y-2 sm:space-y-10">
            <div>
              <Typography type="subtitle">About you</Typography>
              <AboutYou
                aboutMe={currentAnswers.about_me}
                socialMedia={currentAnswers.social_media}
                handleChangeValue={handleChangeValue}
              />
            </div>
            <div>
              <Typography type="subtitle">Industry Certifications</Typography>
              <p>Industry certifications are required for project opportunites.</p>
              <CertificationsList
                questions={questionnaire?.questions?.certifications || []}
                certificationList={currentAnswers.certifications}
                handleChangeValue={handleChangeValue}
                profileEditsLocked={profileEditsLocked}
              />
            </div>
            <div>
              <Typography type="subtitle">Managment Skills</Typography>
              <QuestionnaireRadioList
                question="management_skills"
                questions={freelancer_questions}
                currentAnswers={currentAnswers?.management_skills}
                handleChangeValue={handleChangeValue}
              />
            </div>
            <div>
              <Typography type="subtitle">Industry Experience</Typography>

              <QuestionnaireRadioList
                question="experience_in_industry"
                questions={freelancer_questions}
                currentAnswers={currentAnswers?.experience_in_industry}
                handleChangeValue={handleChangeValue}
              />
            </div>
            <div>
              <Typography type="subtitle">Technical Experience</Typography>

              <QuestionnaireRadioList
                question="experience_in_area"
                questions={freelancer_questions}
                currentAnswers={currentAnswers?.experience_in_area}
                handleChangeValue={handleChangeValue}
              />
            </div>
            <div
              className={`p-4 bg-white ${
                isEqual(currentAnswers, initialAnswers.initial) ? "" : "sticky bottom-0"
              }`}
            >
              <Button
                dataTestId="update-managment-skills"
                label="Save changes"
                className="block mx-auto"
                disabled={!answersHaveChanged || profileEditsLocked}
                variant="full"
                themecolor="prime"
                onClick={() => setUpdateProfileModalVisible(true)}
              />
            </div>
          </div>
        </Card>
      </div>

      {updateProfileModalVisible && (
        <SimpleAlertWithCancelModal
          open={updateProfileModalVisible}
          setOpen={setUpdateProfileModalVisible}
          title={profile?.application_status !== "Incomplete" ? "Heads up!" : "Confirm changes"}
          text={
            profile?.status === "Incomplete" || !triggerProfileResubmit
              ? "Do you want to save these changes?"
              : "You are about to update your profile. Please note that all updates must be evaluated by our team before they are published. This process typically takes 1-2 business days. During this time, your profile will be in a pending state. You will not be able to make any further changes to your profile until it has been reviewed and approved."
          }
          onConfirmClick={handleSaveChangesClick}
        />
      )}
    </>
  );
};

export default MyProfile;
