import React, { useState } from "react";

// components
import { Button, Input, Modal, Loader } from "../../../components";

// context
import { useAuth, useToast } from "../../../context";

// services
import { ProfileService } from "../../../services";

const CalendlyBlock = ({ profile, getProfile, open, setOpen, profileEditsLocked }) => {
  // context
  const { authContext } = useAuth();
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState("");
  // const [link, setLink] = useState('');

  const addLink = () => {
    // setLink(value);

    // profile update Api
    setLoader(true);
    return ProfileService.update({
      user_uid: authContext.user.uid,
      calendly_link: "https://calendly.com/" + value,
    })
      .then(() => {
        showToast("success", "Calendly link successfully added");
        getProfile();
      })
      .catch(() => showToast("error", "Error occurred while adding calendly link"))
      .finally(() => {
        setOpen(false);
        setLoader(false);
      });
  };

  const removeLink = () => {
    // setLink(value);

    setLoader(true);
    // profile update Api
    return ProfileService.update({
      user_uid: authContext.user.uid,
      calendly_link: "",
    })
      .then(() => {
        showToast("success", "Calendly link successfully removed");
        getProfile();
      })
      .catch((err) => {
        showToast("error", "Error occurred while removing calendly link");
        console.error("err:", err);
      })
      .finally(() => {
        setOpen(false);
        setLoader(false);
      });
  };

  return (
    <>
      {/* {link && <InlineWidget url={link} />} */}

      {profile.calendly_link && (
        <div className="flex items-center justify-start w-full">
          <a
            className="mr-3 text-blue-500 underline text-md underline-offset-1"
            href={profile.calendly_link}
            target="_blank"
            rel="noreferrer"
          >
            {profile.calendly_link}
          </a>
          <i
            onClick={profileEditsLocked ? null : () => removeLink()}
            className={`${
              profileEditsLocked ? "cursor-not-allowed" : "cursor-pointer"
            } fas fa-close text-warning text-md`}
          />
        </div>
      )}

      {open && (
        <Modal
          buttons={[
            <Button
              label="Confirm"
              // disabled={!value || !value.includes('calendly.com')
              disabled={!value}
              onClick={() => addLink()}
            />,
          ]}
          closeModal={() => setOpen(false)}
          title="Add your calendly link"
        >
          {/* loader */}
          {loader && <Loader themeColor="fill-prime" />}
          <div className="flex items-center">
            <p className="mr-2 text-lg">https://calendly.com/</p>
            <Input
              className=""
              type="text"
              placeholder="Calendly URL"
              value={value}
              error={value === ""}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default CalendlyBlock;
