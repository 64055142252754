import React from "react";

// components
import { Paper, Typography, EnhancedTable } from "../../components";

const tableColumns = [
  {
    id: "project",
    type: ["project", "customer"],
    label: "Project/Customer",
  },
  {
    id: "payment_status",
    type: "badge",
    label: "Payment Status",
  },
  {
    id: "payment_amount",
    type: "number",
    label: "Payment Amount",
  },
  {
    id: "date_submitted",
    type: "date",
    label: "Date Submitted",
  },
  {
    id: "date_paid",
    type: "date",
    label: "Date Paid",
  },
  {
    id: "billing_type",
    type: "string",
    label: "Billing Type",
  },
];

const rows = [
  {
    "data-testid": "1",
    id: "1",
    project: "Information Security Policy",
    customer: "Asana",
    payment_status: "paid",
    payment_amount: 20000,
    date_submitted: "5.7.2022",
    date_paid: "5.7.2022",
    billing_type: "Standard",
  },
  {
    "data-testid": "2",
    id: "2",
    project: "Incident Response",
    customer: "NerdWaller",
    payment_status: "pending",
    payment_amount: 30230,
    date_submitted: "12.07.2022",
    date_paid: "12.07.2022",
    billing_type: "Standard",
  },
  {
    "data-testid": "3",
    id: "3",
    project: "CISO Service",
    payment_status: "upcoming",
    customer: "KnoBe4",
    payment_amount: 15000,
    date_submitted: "04.10.2021",
    date_paid: "04.10.2021",
    billing_type: "Standard",
  },
  {
    "data-testid": "4",
    id: "4",
    project: "Incident Response",
    customer: "Pacaso",
    payment_status: "pending",
    payment_amount: 65000,
    date_submitted: "02.12.2019",
    date_paid: "02.12.2019",
    billing_type: "Standard",
  },
];

const Table = () => (
  <div className="flex justify-center p-page">
    <Paper className="min-w-[800px] h-fit">
      <Typography type="title">Table</Typography>

      <EnhancedTable showTableTop={true} tableColumns={tableColumns} tableRows={rows} />
    </Paper>
  </div>
);

export default Table;
