import { forwardRef } from "react";

const Textarea = forwardRef(
  (
    {
      dataTestId,
      name,
      label,
      rows,
      placeholder = "",
      error = "",
      className = "mb-4",
      inputClassName = "",
      maxCharacters,
      borderClass,
      value,
      onChange,
      ...rest
    },
    ref
  ) => (
    <div className={className}>
      <label>
        {label && <span className="block text-prime-light">{label}</span>}
        <textarea
          data-testid={dataTestId}
          ref={ref}
          rows={rows}
          name={name}
          placeholder={placeholder}
          className={`w-full h-full rounded-md shadow-sm bg-gray-50 focus:border focus:border-prime focus:outline-none ${
            borderClass || "border-gray-300"
          } ${inputClassName}`}
          value={value}
          onChange={onChange}
          {...rest}
        />

        {error && <div className="mb-2 text-sm text-error">{error}</div>}

        {maxCharacters && (
          <div
            className={`mb-8 ml-1 text-sm ${
              value?.length > maxCharacters ? "text-error" : "text-prime-light"
            }`}
          >
            {value?.length || 0}/ {maxCharacters}
          </div>
        )}
      </label>
    </div>
  )
);

export default Textarea;
