// components
import { Paper, Typography, ChatUsers, ChatConversations } from "../../components";

const messagesDemo = [
  {
    uid: "askdfgn",
    message:
      "Second mesage Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy",
    from_user_uid: "5b714b65-9933-4f70-b0eb-271fe623f256",
    from_first_name: "Nenad",
    to_user_uid: "321",
    created_at: "2022-08-03T07:51:45.106557Z",
  },
  {
    uid: "fgfghd",
    message: "Fourt mesage",
    from_user_uid: "5b714b65-9933-4f70-b0eb-271fe623f256",
    from_first_name: "Nenad",
    to_user_uid: "321",
    created_at: "2022-08-03T07:54:45.106557Z",
  },
  {
    uid: "lddfgk",
    message:
      "First mesage Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy",
    from_user_uid: "123",
    from_first_name: "Marko",
    to_user_uid: "5b714b65-9933-4f70-b0eb-271fe623f256",
    created_at: "2022-08-03T07:50:45.106557Z",
  },
  {
    uid: "lhdssjlth",
    message: "Third mesage",
    from_user_uid: "123",
    from_first_name: "Marko",
    to_user_uid: "5b714b65-9933-4f70-b0eb-271fe623f256",
    created_at: "2022-08-03T07:52:45.106557Z",
  },
];

const Chat = () => {
  const addNewMsg = (type, value) => {
    if (type === "string") {
      // TODO: call BE
      return console.log("string:", value);
    }

    if (type === "file") {
      // TODO: call BE
      return console.log("file:", value);
    }
  };

  return (
    <div className="flex justify-center p-page">
      <Paper className="w-full">
        <Typography type="subtitle" className="text-center">
          Chat
        </Typography>

        <ChatUsers />

        {/* NOTE: set wrapper width and style */}
        <div className="max-w-sm block mx-auto border p-4 rounded-2xl">
          <ChatConversations
            className=""
            maxHeightClass="max-h-96"
            messagesData={messagesDemo || []}
            addNewMsg={addNewMsg}
          />
        </div>
      </Paper>
    </div>
  );
};

export default Chat;
