import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// https://reactdatepicker.com/

const InputDate = forwardRef(
  (
    {
      dataTestId,
      name,
      label,
      placeholder = "",
      showTimeSelect,
      dateFormat,
      error = "",
      onChange,
      className = "mb-4",
      inputClassName = "",
      ...rest
    },
    ref
  ) => {
    const setDateFormat = () => {
      if (dateFormat) {
        return dateFormat;
      }
      if (showTimeSelect) {
        return "dd. MMM yyyy. HH:mm";
      }
      return "dd. MMM yyyy.";
    };

    return (
      <div className={className}>
        <label>
          {label && <span className="block text-prime-light">{label}</span>}

          <div className="relative mb-4">
            <DatePicker
              data-testid={dataTestId}
              id={dataTestId}
              ref={ref}
              dateFormat={setDateFormat()}
              name={name}
              placeholderText={placeholder}
              showTimeSelect={showTimeSelect}
              onChange={(v) => onChange(v)}
              className={`w-full rounded-md border-gray-300 shadow-sm bg-gray-50 focus:border focus:border-prime focus:outline-none pr-8 ${inputClassName}`}
              {...rest}
            />
            {/* icon */}
            <div className="absolute top-0 right-0 mt-2 mr-2">
              {rest.showTimeSelectOnly ? (
                <i className="fa-regular fa-clock text-prime-light mt-1" />
              ) : (
                <i className="far fa-calendar-alt text-prime-light mt-1" />
              )}
            </div>
          </div>

          {error && <div className="mb-2 text-sm text-error">{error}</div>}
        </label>
      </div>
    );
  }
);

export default InputDate;
