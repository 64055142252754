import axios from "axios";

export const evalQuery = ({ url, request, config }) => {
  const endPointUrl = `/app/api/cpu-api${url}`

  const r = axios

    .post(
      endPointUrl,
      {
        ...request,

      },
      config
    )
    .then((res) => {

      if (res?.data?.status === "success") {
        return res?.data?.data;
      } else if (res?.data?.status === "error") {
        if (res?.data?.message === "Unauthorized: Wrong credentials!") {
          localStorage.removeItem("email")
          localStorage.removeItem("role")
          localStorage.removeItem("token")
          localStorage.removeItem("user")
        }
      }
      // on status error
      throw res?.data;
    })
    .catch((err) => {
      console.error("Server Error:", err);
      throw err;
    });

  return r;
};
