import React, { useState, useEffect, useCallback, useRef } from "react";
import { isEmpty } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { isEmpty } from "lodash";

// components
import roadMapImage from "../../assets/img/roadmap.png";
import {
  Loader,
  ModalConfirm,
  ContactManager,
  Paper,
  Button,
  BuyConfirmationModal,
  CardProjectDetailsModal,
} from "../../components";
import AccordionDefault from "../../components/accordion/AccordionDefault";
import { useToast, useAuth } from "../../context";

// context

// img

// services
import {
  ProfileService,
  UserService,
  errorMsgForUser,
  ProjectService,
  ProjectTemplateService,
  StripeService,
  TransactionService,
  CompanyService,
} from "../../services";

import { CreditService } from "../../services/CreditService";
import { DiscountService } from "../../services/DiscountService";
import { PurchaseService } from "../../services/PurchaseService";
import CurrentProjects from "./CurrentProjects";
import CyberInsurance from "./CyberInsurance";
import CybersecurityResources from "./CybersecurityResources";

// services
import PopTokenExplainer from "./PopTokenExplainer";
import ProjectBrowser from "./ProjectBrowser";
import StillHaveQuestions from "./StillHaveQuestions";
import WelcomeBlurb from "./WelcomeBlurb";

const resources = [
  {
    hrefTitle: "Whitepapers",
    hrefTitle2: "Learn more",
    href: "https://www.canva.com/design/DAEUXpLzlV8/X8B1ZovibWEaK2jPOsaW8w/view?utm_campaign=designshare&utm_medium=email&_hsmi=112083244&_hsenc=p2ANqtz-_tmjrupVDeJ7xdxZTgEiYazen82X3JIcwIZz8ZfTaC8NoV_lBahceW4YNGu_NC5QC9CP9xdDy_JtAHq4GSC5EUYEHhYw&utm_content=112083244&utm_source=hs_automation",
    text: "Our thought leaders share the latest insights on reaserch and trends in the cybersecurity industry along with practical tips for adapting",
  },
  {
    hrefTitle: "Awareness Materials",
    hrefTitle2: "Learn more",
    href: "https://cyberpopup.docsend.com/view/s/wf7brg8ekds8x2zf",
    text: "These materials explain cybersecurity concepts and the steps small and medium sized should take to stay secure. The part? No tech-speak. Always simple and easy to digest",
  },
  {
    hrefTitle: "Free Resources",
    hrefTitle2: "Learn more",
    href: "https://drive.google.com/file/u/2/d/1CQ-WSIF_LTMJnM5WCFowEz8qb2f3LoLc/view?utm_medium=email&_hsmi=112079099&_hsenc=p2ANqtz-8IfZIka1YjwSQPaIS0GM27ZKSak7MHILvI8-y7eDlE8noJZVOGLAjstkWTB-SK5Qvmnj6D4YpqiyG6VwgM4I02dImIVQ&utm_content=112079099&utm_source=hs_automation",
    text: "We've pulled together a database of free collection to help small and medium size businesses learn about and implement security strategies on a budget",
  },
];

const StartProjectAlert = ({ startProject }) => (
  <div className="flex w-full p-8 mb-8 bg-yellow-100 shadow-md rounded-2xl">
    <div className="w-4/5">
      {/* <div className="mb-2 text-lg font-bold">
        //   Hate to pop your bubble, but the ball is in your court.
        // </div>*/}
      <div className="text-lg">
        Please complete your Peace of Mind Roadmap questionnaire to keep the ball rolling. We will
        use this information to match you with a cyber-expert-on-demand.
      </div>
    </div>
    <div className="flex items-center justify-center w-1/5">
      <Button onClick={startProject} variant="outlined" themecolor="prime" className="bg-white">
        Start this Project
      </Button>
    </div>
  </div>
);

const VerifyEmailAlert = () => {
  const { showToast } = useToast();

  const customer = JSON.parse(localStorage?.user || null);

  const resendLink = () => {
    UserService.resendVerificationEmail({
      user_uid: customer.uid,
      email: customer.email,
    })
      .then(() => {
        showToast("success", "Verification link resent successfully.");
      })
      .catch((err) => {
        showToast("error", errorMsgForUser(err));
      });
  };

  return (
    <div className="flex w-full p-8 mb-8 shadow-md bg-sec-pale rounded-2xl">
      <div className="w-4/5">
        <div className="text-lg">
          Please complete your registration by verifying your email. An email was sent upon
          registration.
        </div>
      </div>
      <div className="flex items-center justify-center w-1/5">
        <Button onClick={resendLink} variant="outlined" themecolor="prime" className="bg-white">
          Resend verification link
        </Button>
      </div>
    </div>
  );
};

const RoadmapSection = ({ startProject }) => {
  const [isAccordionsOpen, setIsAccordionsOpen] = useState({
    0: false,
    1: false,
  });

  return (
    <Paper className="mb-8">
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="">
          <div className="mb-2 text-xl text-red-500">Your next step</div>
          <h1 className="mb-2 text-4xl text-prime">Start Your Peace of Mind Roadmap™</h1>
          <p className="mb-2 text-xl">
            The roadmap is a productive approach to help secure you business and put your mind at
            ease.
          </p>
          <p className="mb-8 text-md">
            Starting the project that will match you with the perfect cybersecurity expert, and
            launch a questionnaire that will take approximately 20 minutes to complete.
          </p>
          <div className="h-auto sm:h-[200px] overflow-y-auto">
            <AccordionDefault
              title="See what information you need"
              titleColor="text-prime"
              isOpen={isAccordionsOpen[0]}
              setIsOpen={() => setIsAccordionsOpen((v) => ({ ...v, 0: !v[0] }))}
              content={
                <ul className="flex flex-col space-y-4">
                  <li className="flex items-center ml-2 gap-2 md:ml-4">
                    How many other vendors do you leverage to run your business?
                  </li>
                  <li className="flex items-center ml-2 gap-2 md:ml-4">
                    Does your company collect, process, or maintain any sensitive information?
                  </li>
                  <li className="flex items-center ml-2 gap-2 md:ml-4">
                    Do you have a formal cybersecurity strategy and policy?
                  </li>
                  <li className="flex items-center ml-2 gap-2 md:ml-4">
                    What technology does your company currently leverage today?
                  </li>
                  <li className="flex items-center ml-2 gap-2 md:ml-4">
                    How much of your workforce is remote?
                  </li>
                </ul>
              }
            />

            <AccordionDefault
              title="What to expect"
              titleColor="text-prime"
              isOpen={isAccordionsOpen[1]}
              setIsOpen={() => setIsAccordionsOpen((v) => ({ ...v, 1: !v[1] }))}
              content={
                <ul className="flex flex-col space-y-4">
                  <li className="flex items-center ml-2 gap-2 md:ml-4">
                    60 minute information gathering call
                  </li>
                  <li className="flex items-center ml-2 gap-2 md:ml-4">
                    Review of business model, operating environment and documentation
                  </li>
                  <li className="flex items-center ml-2 gap-2 md:ml-4">
                    Creation of a customized recommended prioritization and roadmap
                  </li>
                  <li className="flex items-center ml-2 gap-2 md:ml-4">
                    Detailed report of findings
                  </li>
                  <li className="flex items-center ml-2 gap-2 md:ml-4">
                    30 minute debrief <span className="font-light text-gray-400">(Optional)</span>
                  </li>
                </ul>
              }
            />
          </div>
          <Button themecolor="sec" className="mx-auto mb-10" onClick={() => startProject()}>
            Go to project
          </Button>
        </div>
        <div className="flex items-center justify-center">
          <img alt="roadmap_img" src={roadMapImage} />
        </div>
      </div>
    </Paper>
  );
};

const DashboardCustomer = () => {
  // * Context
  const { showToast } = useToast();
  const { authContext } = useAuth();
  const mounted = useRef(false);
  let navigate = useNavigate();

  // * CONST
  const customer = JSON.parse(localStorage.user);

  // * State for main component
  const [userCompany, setUserCompany] = useState(null);
  const [isTrial, setTrialState] = useState(null);
  const [companyPlan, setCompanyPlan] = useState(null);
  const [companyProgram, setCompanyProgram] = useState({});
  const [credits, setCredits] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [profile, setProfile] = useState({
    peace_of_mind_roadmap_started: true,
    peace_of_mind_roadmap_finished: false,
  });

  // * UI & Modal States
  const [loader, setLoader] = useState(0);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [buyProjectModalOpen, setBuyProjectModalOpen] = useState(false);
  const [learnMoreModalOpen, setLearnMoreModalOpen] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);

  // * State for <CurrentProjects /> Tracker
  const [activeProjects, setActiveProjects] = useState([]);

  // * State for <ProjectBrowser />
  const [totalPopTokens, setTotalPopTokens] = useState(null);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});

  // * Function for <RoadmapSection> and <StartProjectAlert>
  const startProject = () => {
    ProjectService.startPOMR({
      customer_uid: customer.uid,
      project_template_uid: "POMR",
      trial: isTrial,
    })
      .then((r) => {
        console.log("Start pomr res", r);
        navigate(`/project/${r.project.uid}`);
        getProfile();
        showToast("success", "Project has been started.");
      })
      .catch((err) => {
        showToast("error", errorMsgForUser(err));
      });
  };
  // * Function for <modalConfirm /> popup
  const sendRequestConfirmed = () => {
    // send email
    // const subjects = {
    //   peace_of_mind_roadmap: 'Peace of Mind Roadmap',
    //   information_security_policy: 'Information Security Policy',
    //   email_security: 'Email Security Project',
    // };

    // 'to_emails' on local and development ENV is user email
    let to_emails = "rolando+csm@cyberpopup.com";

    if (
      import.meta.env.VITE_APP_ENV === "local" ||
      import.meta.env.VITE_APP_ENV === "development"
    ) {
      to_emails = authContext?.user?.email;
    }

    const sendEmailObj = {
      from_email: authContext?.user?.email,
      // to_emails: 'rolando+csm@cyberpopup.com',
      to_emails,
      subjects: authContext?.user?.email,
      // subjects: subjects[projectName],
      message: `User ${authContext?.user?.email} has sent you a request for a Peace of Mind Roadmap project
      Company info:
      Company name - ${profile?.company_name},
      First name - ${profile?.responsible_person_first_name},
      Last name - ${profile?.responsible_person_last_name}.

      `,
    };

    // send email Api
    setLoader((l) => l + 1);
    return UserService.sendEmail(sendEmailObj)
      .then(() => {
        // message on local and development ENV
        if (
          import.meta.env.VITE_APP_ENV === "local" ||
          import.meta.env.VITE_APP_ENV === "development"
        ) {
          showToast(
            "success",
            `Your submission has been received and your Customer Success Manager will be in touch with you soon.
            In the local and development environment requests will be sent to the registration email`
          );
        } else {
          showToast(
            "success",
            "Your submission has been received and your Customer Success Manager will be in touch with you soon."
          );
        }
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1), setModalConfirm(false));
  };

  // * Functions for <ProjectBrowser />
  const getTotalPopTokens = () => {
    TransactionService.getCountOfPopToken({
      user_uid: authContext?.user?.uid,
    }).then((res) => {
      setTotalPopTokens(res.permanent_pop_tokens);
    });
  };

  const getAvailableTemplates = () => {
    setLoader((l) => l + 1);
    ProjectTemplateService.getAvailableTemplates({
      customer_uid: customer.uid,
    })
      .then((r) => {
        const filterAvailableProjects = r?.project_templates.filter((n) => !n.bought);
        setAvailableProjects(filterAvailableProjects);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const purchaseMoreTokens = async (quantity) => {
    setLoader((l) => l + 1);
    try {
      const { checkout_session_url } = await StripeService.createCheckoutSession({
        plan_id: "poptokens",
        quantity,
        stripe_customer_id: userCompany.stripe_account_id,
        is_trial: false,
        include_plan_addons: false,
        mode: "payment",
        discounts,
        cancel_endpoint: `/dashboard`,
        success_endpoint: `/dashboard`,
      });
      setLoader((l) => l - 1);
      window.location.replace(checkout_session_url);
    } catch (error) {
      console.log("error in purchaseMoreTokens", error);
      showToast("error", errorMsgForUser(error));
    }
  };

  const handleBuyProjectClick = (project) => {
    setSelectedProject(project);
    setBuyProjectModalOpen(true);
  };

  const handleLearnMoreClick = (project) => {
    setSelectedProject(project);
    setLearnMoreModalOpen(true);
  };

  const initiateBuyProject = async (projectInfo) => {
    setLoader((l) => l + 1);
    setBuyLoading(true);
    try {
      // If they don't have the required pop tokens show error

      const hasCreditForProject = credits.find(
        (credit) => credit.project_template_number === selectedProject.number
      );
      if (totalPopTokens < selectedProject.cost / 50 && !hasCreditForProject)
        throw new Error(
          "Not enough tokens on your account. You can buy tokens on the Subscription page."
        );

      // Start the selected project
      const { project } = await ProjectService.startProject({
        project_template_uid: projectInfo.uid,
        customer_uid: authContext?.user?.uid,
      });

      const { purchase } = await PurchaseService.recordPurchase({
        project_template_uid: projectInfo.uid,
        company_uid: userCompany.uid,
        cost: projectInfo.cost,
      });

      // Remove poptoken from account.
      // Note: this has nothing to do with Stripe - just is in the StripeService object
      if (!hasCreditForProject) {
        await StripeService.customerPayForProject({
          customer_uid: authContext?.user?.uid,
          project_uid: project.uid,
        });
      }

      if (hasCreditForProject) {
        await CreditService.redeemCredit({
          credit_uid: hasCreditForProject.uid,
        });
      }
      // Success and re-direct
      showToast("success", `You successfully started ${project.name}`);
      navigate(`/project/${project?.uid}`);
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setLoader((l) => l - 1);
      setBuyLoading(false);
    }
  };

  // * In useEffect to set userCompany - userCompany used to purchaseMoreTokens()
  // TODO: Refactor into purchaseMoreTokens - userCompany does not need to be a state variable
  const getCompany = async () => {
    const { company } = await CompanyService.getCompanyByUserUid({
      user_uid: customer.uid,
    });
    setUserCompany(company);
  };

  // * In useEffect - passed to <StartProjectAlert />
  const getProfile = useCallback(() => {
    // get profile Api
    setLoader((l) => l + 1);
    return ProfileService.get({ user_uid: authContext?.user?.uid })
      .then((res) => {
        // console.log('ProfileService.get res:', res);
        // set profile again
        const resProfile = {
          ...res.profile,
          image: !res.image || res.image === "NoneNone" ? null : res.image,
        };
        setProfile(resProfile);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  }, [authContext?.user?.uid, showToast]);

  // const checkSubscriptionStatus = async () => {
  //   try {
  //     let trialing = isTrial;
  //     if (userCompany) {
  //       const { is_free_trial } = await StripeService.checkTrialStatus({
  //         company_uid: userCompany.uid,
  //       });
  //       trialing = is_free_trial;
  //     }
  //     setTrialState(trialing);
  //   } catch (error) {
  //     showToast("error", errorMsgForUser(error));
  //   }
  // };

  // useEffect(() => {
  //   checkSubscriptionStatus();
  // }, [userCompany]);

  useEffect(() => {
    const getPlanProgram = async () => {
      if (!userCompany) return;
      const { plan, program } = await CompanyService.getCompanyPlanProgram({
        company_uid: userCompany?.uid,
      });
      setCompanyProgram(program);
      setCompanyPlan(plan);
    };
    getPlanProgram();
  }, [userCompany]);

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    if (mounted.current) {
      return;
    }
    mounted.current = true;

    // get profile
    getCompany();
    getProfile();
    getAvailableTemplates();
    getTotalPopTokens();
    // checkSubscriptionStatus();
  }, [getProfile]);

  useEffect(() => {
    ProjectService.getActiveProjectsForUser({ customer_uid: customer.uid }).then((r) => {
      if (r?.active_projects) {
        setActiveProjects(r.active_projects);
      }
    });
  }, []);

  useEffect(() => {
    const getCredits = async () => {
      if (!userCompany) return;
      const { project_credits } = await CreditService.getCreditsByCompany({
        company_uid: userCompany?.uid,
      });
      setCredits(project_credits);
    };
    getCredits();
  }, [userCompany]);

  useEffect(() => {
    const getDiscounts = async () => {
      if (!userCompany) return;
      const { discounts } = await DiscountService.getCompanyDiscounts({
        company_uid: userCompany?.uid,
      });
      setDiscounts(discounts);
    };
    getDiscounts();
  }, [userCompany]);

  return (
    <>
      {/* New Dashboard Layout */}
      <div className="mt-4 mx-auto p-page max-w-page">
        <div className="grid grid-cols-12 gap-4">
          {/* <!-- Full width component --> */}
          <div className="col-span-12">{!customer?.verified && <VerifyEmailAlert />}</div>
          <div className="col-span-8">
            <WelcomeBlurb
              companyPlan={companyPlan}
              companyProgram={companyProgram}
              profile={profile}
              customer={customer}
            />
          </div>
          <ContactManager role="Csm" />
          <div className="col-span-12">
            {!isEmpty(activeProjects) && <CurrentProjects projects={activeProjects} />}
          </div>
          {companyProgram?.program_code !== "get-secured" && (
            <>
              <div className="col-span-12">
                <PopTokenExplainer />
              </div>
              <div className="col-span-12">
                <ProjectBrowser
                  projectCredits={credits}
                  totalPopTokens={totalPopTokens}
                  availableProjects={availableProjects}
                  purchaseMoreTokens={purchaseMoreTokens}
                  handleBuyProjectClick={handleBuyProjectClick}
                  handleLearnMoreClick={handleLearnMoreClick}
                />
              </div>
            </>
          )}
          <div className="col-span-12">
            <CyberInsurance />
          </div>
          <div className="col-span-12">
            <CybersecurityResources resources={resources} title="Cybersecurity Resources" />
          </div>
          <div className="col-span-12">
            <StillHaveQuestions />
          </div>
        </div>
      </div>

      {/* Modal Confirm Peace of Mind Roadmap Project Request */}
      {modalConfirm && (
        <ModalConfirm
          dataTestId="modal-confirm-request-peace-of-mind-project"
          title="Request the Peace of Mind Roadmap Project"
          confirmBtnText="Send Request"
          closeModal={() => setModalConfirm(false)}
          onConfirm={() => sendRequestConfirmed()}
        />
      )}

      {/* Buy Project modal for Project Browser */}
      {buyProjectModalOpen && (
        <BuyConfirmationModal
          projectCredits={credits}
          displayedProject={selectedProject}
          onClick={() => initiateBuyProject(selectedProject)}
          closeModal={() => setBuyProjectModalOpen(false)}
          buyLoading={buyLoading}
        />
      )}

      {learnMoreModalOpen && (
        <CardProjectDetailsModal
          displayedProject={selectedProject}
          closeModal={() => setLearnMoreModalOpen(false)}
        />
      )}
    </>
  );
};

export default DashboardCustomer;
