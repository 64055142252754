import { evalQuery } from "./index";

export const TaskService = {
  getAll: (request) =>
    evalQuery({
      url: "/task/get-all",
      request,
    }),
  get: (request) =>
    evalQuery({
      url: "/task/get",
      request,
    }),

  getByProject: (request) =>
    evalQuery({
      url: "/task/get-by-project",
      request,
    }),

  update: (request) =>
    evalQuery({
      url: "/task/update",
      request,
    }),

  startNextTask: (request) =>
    evalQuery({
      url: "/task/start-next-task",
      request,
    }),
};
