import { evalQuery } from "./index";

export const FreelancerService = {
  getAll: (request) =>
    evalQuery({
      url: "/freelancer/get-freelancers",
      request,
    }),
  get: (request) =>
    evalQuery({
      url: "/freelancer/get-freelancer",
      request,
    }),
  sendFreelanceStatusEmail: (request) =>
    evalQuery({
      url: "/freelancer/send-email-freelancer",
      request,
    }),
};
