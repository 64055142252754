import { useCallback, useEffect, useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

// components
import {
  Modal,
  Button,
  Divider,
  Input,
  InputDuration,
  Checkbox,
  Select,
  Textarea,
  Documents,
  Loader,
} from "../../components";

// services
import { useToast } from "../../context";
import { TaskTemplateService, errorMsgForUser } from "../../services";

// context

const TaskCreateEdit = ({ projectUid, editTaskUid, updateList, isEditable = true, closeModal }) => {
  // router
  let navigate = useNavigate();

  // context
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(false);
  // const [file, setFile] = useState("");
  // const [isEditable, setIsEditable] = useState(true);

  const [documentsIds, setDocumentsIds] = useState([]);
  const [newTask, setNewTask] = useState({
    name: "",
    phase: "Initiation",
    description: "",
    comments_widget: false,
    allowed_comments: 5,
    work_hours_estimation: 0,
    notify: false,
    estimated_duration_delta: 0,
    typeform_link: "",
    assigned_to_csm: true,
  });

  // UI stat
  const [typeFormFocus, setTypeFormFocus] = useState(false);

  const phaseOptions = [
    { label: "Initiation", value: "Initiation" },
    { label: "Main phase", value: "In Progress" },
    { label: "Closing", value: "Close" },
  ];

  // const taskTypeOptions = [
  //   { label: "New Task", value: "new-task" },
  //   { label: "Import Task", value: "import-task" },
  // ];

  // const importOptions = [
  //   { label: '60 min Meeting', value: '60' },
  //   { label: '30 min Meeting', value: '30' },
  // ];

  const createEditTask = () => {
    setLoader(true);

    if (newTask.uid) {
      return TaskTemplateService.update(newTask, newTask.phase, documentsIds)
        .then(() => {
          closeModal();
          // navigate(`/project-template/${projectUid}`);
          updateList(projectUid);
          showToast("success", "Task successfully updated.");
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader(false));
    } else {
      return TaskTemplateService.create({
        ...newTask,
        project_template_uid: projectUid,
        docs_list: documentsIds,
      })
        .then(() => {
          updateList(projectUid);
          // updateRoadmap(projectUid, [
          //   ...roadmap,
          //   {
          //     ...res.task_template,
          //     uid: res.task_template.uid,
          //     project_template_uid: projectUid,
          //   },
          // ]);
          closeModal();
          navigate(`/project-template/${projectUid}`);
          showToast("success", "Task added.");
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader(false));
    }
  };

  const getTaskTemplate = useCallback(
    () =>
      TaskTemplateService.get({
        task_template_uid: editTaskUid,
      })
        .then((r) => {
          setNewTask({ ...r.task_template });
          // console.log('Task template status', r.task_template.status);
          // setIsEditable(r.task_template.status === 'Draft' ? false : true);
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader(false)),
    [editTaskUid, showToast]
  );

  useEffect(() => {
    if (editTaskUid) {
      getTaskTemplate();
    }
  }, [editTaskUid, getTaskTemplate]);

  return (
    <Modal
      title="Create task for project"
      closeModal={closeModal}
      maxWidth="max-w-5xl"
      buttons={[
        <Button
          dataTestId="cancel"
          className="mr-4"
          borderColor="grey-light"
          variant="outlined"
          label={isEditable ? "Cancel" : "Ok"}
          onClick={closeModal}
        />,
        isEditable && (
          <Button
            dataTestId="save"
            themecolor="primary"
            label="Save"
            disabled={
              !newTask?.name || !newTask?.description
              // !(newTask?.estimated_duration_delta || newTask?.include_time)
            }
            onClick={createEditTask}
          />
        ),
      ]}
    >
      <div className="container relative flex-1 mx-auto">
        {/* loader */}
        {!!loader && <Loader themeColor="fill-prime" />}

        <div className="flex p-4">
          <div className="w-1/2 px-3">
            {/* <Select
              label="Type"
              firstEmpty={false}
              className="mb-4"
              options={taskTypeOptions}
              keyForOptionValue="value"
              keyForOptionLabel="label"
              value={newTask.task_type}
              disabled={!isEditable}
              onChange={e =>
                setNewTask({ ...newTask, task_type: e.target.value })
              }
            /> */}
            <Input
              label="Name *"
              value={newTask.name}
              disabled={!isEditable}
              onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
            />

            <Select
              label="Project phase"
              firstEmpty={false}
              className="mb-4"
              options={phaseOptions}
              keyForOptionValue="value"
              keyForOptionLabel="label"
              value={newTask.phase}
              disabled={!isEditable || editTaskUid}
              onChange={(e) => setNewTask({ ...newTask, phase: e.target.value })}
            />

            <Textarea
              label="Task description *"
              className="mb-4"
              rows={8}
              value={newTask.description}
              disabled={!isEditable}
              onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
            />

            <Input
              label="Typeform link"
              className="mb-4"
              value={newTask.typeform_link}
              disabled={!isEditable}
              onFocus={() => setTypeFormFocus(true)}
              onBlur={() => setTypeFormFocus(false)}
              onChange={(e) => setNewTask({ ...newTask, typeform_link: e.target.value })}
            />
            {typeFormFocus && (
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Do not enter customized TypeForm links. Use the standard TypeForm link
                      (Example of a standard link: https://cyberpopup.typeform.com/to/spGEbanQ)
                    </h3>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="w-1/2 px-3">
            <div className="pb-4">
              <span className="block text-prime-light">Components</span>
              <Checkbox
                label="Calendar"
                className="m-1 text-sm"
                checked={newTask.calendar_widget}
                disabled={!isEditable}
                onChange={(e) =>
                  setNewTask({
                    ...newTask,
                    calendar_widget: e.target.checked,
                  })
                }
              />
              <Checkbox
                label="Document upload"
                className="m-1 text-sm"
                checked={newTask.docs_upload_widget}
                disabled={!isEditable}
                onChange={(e) =>
                  setNewTask({
                    ...newTask,
                    docs_upload_widget: e.target.checked,
                  })
                }
              />
              {/* <Checkbox
                  label="Link"
                  className="m-1 text-sm"
                  checked={newTask.link_widget}
                  onChange={e =>
                    setNewTask({
                      ...newTask,
                      link_widget: e.target.checked,
                    })
                  }
                /> */}
              <div className="flex flex-no-wrap items-baseline width-3">
                <Checkbox
                  label="Allow comments"
                  className="items-baseline m-1 text-sm"
                  checked={newTask.comments_widget}
                  disabled={!isEditable}
                  onChange={(e) =>
                    setNewTask({
                      ...newTask,
                      comments_widget: e.target.checked,
                    })
                  }
                />
                <Input
                  type="number"
                  className="w-24 ml-2"
                  value={newTask.allowed_comments}
                  disabled={!newTask.comments_widget || !isEditable}
                  onChange={(e) =>
                    setNewTask({
                      ...newTask,
                      allowed_comments: e.target.value,
                    })
                  }
                />
                <span className="inline-block mx-2 text-sm self-baseline">Allowed</span>
              </div>
              {/* FIXME: Should be in line with allow comments, and to have displayed text after. */}
              {/* <Input label="" type="number" value={5} className="w-4" /> */}
              {/* <Checkbox
                label="Close project"
                className="m-1 text-sm"
                checked={newTask.close_project}
                disabled={!isEditable}
                onChange={e =>
                  setNewTask({
                    ...newTask,
                    close_project: e.target.checked,
                  })
                }
              /> */}
            </div>

            <Divider className="mb-4" lineColor="bg-grey-pale" />

            <div>
              <span className="block text-prime-light">Permissions</span>
              <div className="flex justify-between pb-8">
                <span className="flex-col block text-prime-light">
                  Assigned to:
                  <div>
                    <Checkbox
                      label="CSM"
                      className="m-1 text-sm"
                      checked={newTask.assigned_to_csm}
                      // disabled={!isEditable}
                      disabled={true}
                      onChange={(e) =>
                        setNewTask({
                          ...newTask,
                          assigned_to_csm: e.target.checked,
                        })
                      }
                    />
                    <Checkbox
                      label="Freelancer"
                      className="m-1 text-sm"
                      checked={newTask.assigned_to_freelancer}
                      disabled={!isEditable}
                      onChange={(e) =>
                        setNewTask({
                          ...newTask,
                          assigned_to_freelancer: e.target.checked,
                        })
                      }
                    />
                    <Checkbox
                      label="Customer"
                      className="m-1 text-sm"
                      checked={newTask.assigned_to_customer}
                      disabled={!isEditable}
                      onChange={(e) =>
                        setNewTask({
                          ...newTask,
                          assigned_to_customer: e.target.checked,
                        })
                      }
                    />
                  </div>
                </span>

                <span className="flex-col block text-prime-light">
                  Who can view this task:
                  <div>
                    <Checkbox
                      label="Freelancer"
                      className="m-1 text-sm"
                      checked={newTask.visibility_freelancer}
                      disabled={!isEditable}
                      onChange={(e) =>
                        setNewTask({
                          ...newTask,
                          visibility_freelancer: e.target.checked,
                        })
                      }
                    />
                    <Checkbox
                      label="Customer"
                      className="m-1 text-sm"
                      checked={newTask.visibility_customer}
                      disabled={!isEditable}
                      onChange={(e) =>
                        setNewTask({
                          ...newTask,
                          visibility_customer: e.target.checked,
                        })
                      }
                    />
                  </div>
                </span>
              </div>
            </div>

            <Divider className="mb-4" lineColor="bg-grey-pale" />

            <div>
              {/* <Checkbox
                label="Do not include in time duration"
                className="mx-1 mb-4 text-sm"
                checked={newTask.include_time}
                disabled={!isEditable}
                onChange={e =>
                  setNewTask({
                    ...newTask,
                    include_time: e.target.checked,
                  })
                }
              /> */}

              <InputDuration
                dataTestId="task_estimated_duration"
                label="Task duration (estimated) *"
                showDays={true}
                disabled={!isEditable}
                value={newTask.estimated_duration_delta}
                onChange={(v) => setNewTask({ ...newTask, estimated_duration_delta: v })}
              />

              <InputDuration
                dataTestId="task_work_hours_estimation"
                label="Work hours (estimated) *"
                // showDays={true}
                disabled={!isEditable}
                value={newTask.work_hours_estimation}
                onChange={(v) => setNewTask({ ...newTask, work_hours_estimation: v })}
              />

              {/* <Divider className="mb-4" lineColor="bg-grey-pale" /> */}

              {/* <div className="flex flex-no-wrap items-center">
                <Checkbox
                  label="Auto-complete with "
                  className="mx-1 mr-4 text-sm"
                  checked={newTask.complete_with}
                  disabled={!isEditable}
                  onChange={e =>
                    setNewTask({
                      ...newTask,
                      complete_with: e.target.checked,
                    })
                  }
                />

                <Select
                  placeholder="Select a task"
                  className=""
                  disabled={!newTask.complete_with || !isEditable}
                  onChange={e => {}} // TODO:finish select task
                />
              </div> */}
            </div>
            <Checkbox
              label="Send mail notifications"
              className="m-1 text-sm"
              checked={newTask.notify}
              disabled={!isEditable}
              onChange={(e) =>
                setNewTask({
                  ...newTask,
                  notify: e.target.checked,
                })
              }
            />
          </div>
        </div>

        <Divider className="mb-4" lineColor="bg-grey-pale" />

        <div className="flex flex-col">
          {/*TODO: Upload files component*/}
          <div className="px-4">
            <Documents
              accept="application/pdf"
              entityName="TaskTemplate"
              entityUid={editTaskUid}
              getDocumentsIds={(id) => setDocumentsIds(id)}
              onChange={() => {}}
            />

            {/* <p> Documents Uploaded</p>

              <Button
                themecolor="prime-light"
                label="Attach Documents"
                variant="outlined"
                className="mb-8"
                onClick={() => navigate(`/projects/create-task`)}
              /> */}
          </div>
          {/*TODO: create dragable component*/}
        </div>
      </div>
    </Modal>
  );
};

export default TaskCreateEdit;
