import React from "react";

// components
import { Paper, Typography } from "../../components";

const CookiePolicy = () => (
  <div className="flex-1 flex justify-center p-page">
    <Paper className="max-w-[1000px]">
      <Typography type="title" className="text-center mb-16">
        Cookie Policy
      </Typography>
      <p className="text-left mb-8">Effective Date: July 23, 2022</p>
      <p className="underline font-semibold text-xl mb-4">We Use Certain Tracking Technologies</p>
      <p className="mb-4">
        We collect personal information about users over time and across different websites when you
        use this website or service. We also have third parties that collect personal information
        this way. To do this, we use several common tracking tools. These may include browser
        cookies. We may also use web beacons, flash cookies, and similar technologies.
      </p>
      <p className="mb-4">
        Our vendors may also use these tools. In this policy “we” and “us” refers to Cyber Pop-Up,
        our business partners, and/or our vendors.{" "}
      </p>
      <p className="underline font-semibold text-xl mb-4">
        We Use Tracking Technologies for a Variety of Reasons
      </p>
      <p>We use tracking tools:</p>
      <ul className="list-disc ml-8 mb-6">
        <li>To recognize new or past customers.</li>
        <li>
          To create custom audiences to target our advertisements. These advertisements may be
          served online or by email (see below for instructions on how to opt-out of these
          advertising activities, including how to control cookies we may use).
        </li>
        <li>To store your password if you are registered on our site.</li>
        <li>To improve our website and to better understand your visits on our platforms.</li>
        <li>To understand how you interact with our email messages.</li>
        <li>To measure the effectiveness of our marketing and advertising campaigns.</li>
        <li>To integrate with third-party social media websites.</li>
        <li>
          To serve you with interest-based or targeted advertising (see below for more on
          interest-based advertising).
        </li>
        <li>To identify how you found our website.</li>
        <li>
          To observe your behaviors and browsing activities over time across email, multiple
          websites or other platforms.
        </li>
        <li>To better understand the interests of our customers and our website visitors</li>
      </ul>
      <p className="underline font-semibold text-xl mb-4">
        We Engage in Interest-Based Advertising
      </p>
      <p className="mb-4">
        We display interest-based advertising using information gathered about you over time across
        multiple websites or other platforms.
      </p>
      <p className="mb-4">
        Interest-based advertising or “online behavioral advertising” includes ads served to you
        after you leave our website, encouraging you to return. They also include ads we think are
        relevant based on your browsing habits or online activities. These ads might be served on
        websites. They might also be served in emails. We might serve these ads, or third parties
        may serve ads. They might be about our products or other companies’ products.
      </p>
      <p className="underline font-semibold text-xl mb-4">
        How Do We Gather Relevant Information About You for Interest-Based Advertising?
      </p>
      <p className="mb-4">
        To decide what is relevant to you, we use information you make available to us when you
        interact with us, our affiliates, and other third parties. We gather this information using
        the tracking tools described above. For example, we might look at your browsing behaviors.
        We might also look at your location. We might look at these activities on our platforms or
        the platforms of others.
      </p>
      <p className="mb-4">
        We work with third parties who help gather this information. These third parties might link
        your name or email address to other information they collect. That might include past
        purchases made offline or online. Or, it might include online usage information.
      </p>
      <p className="underline font-semibold text-xl mb-4">You Can Control Certain Tracking Tools</p>
      <p className="mb-4">
        Cookies: You can adjust your browser to control cookies to require your affirmative
        acceptance for new cookies, automatically reject cookies and/or delete or disable existing
        cookies. How you do this depends on the type of cookie and the browser that you are using.
        For more information on how to control browser cookies,{" "}
        <a
          className="text-blue-500"
          href="https://www.networkadvertising.org/understanding-online-advertising/what-are-my-options"
          target="_blank"
          rel="noreferrer"
        >
          click here
        </a>
        . If information about your browser is not available through this page, please search your
        browser for instructions about how to opt-out of cookie collection.
      </p>
      <p className="mb-4">
        <span className="font-semibold">Flash Cookies:</span> You may need to take an additional
        action to manage flash cookies, which you can learn more about{" "}
        <a
          className="text-blue-500"
          href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        . Why? Because flash cookies cannot be controlled through your browser settings.
      </p>
      <p className="mb-4">
        <span className="font-semibold">Google Analytics:</span> We may use Google Analytics on our
        Platform to help us understand how you use our Platform. You can learn about how to opt-out
        of Google Analytics{" "}
        <a
          className="text-blue-500"
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <p className="mb-4">
        <span className="font-semibold">Facebook: </span> We may use Facebook cookies to target ads
        to you. You can learn about how to opt-out of receiving targeted advertisements from
        Facebook{" "}
        <a
          className="text-blue-500"
          href="https://www.facebook.com/ds/preferences/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        ..
      </p>
      <p className="mb-4">
        <span className="font-semibold">Email Tools: </span> You can also change your email settings
        to block the automatic download of images in email messages, as such images may contain
        technologies that help us understand how you interact with the message.
      </p>
      <p className="mb-4">
        Note that deleting or blocking cookies may impact your experience on our website, as some
        features may not be available. Certain options you select are browser and device specific.
      </p>
      <p className="mb-4">
        <span className="font-semibold">
          {" "}
          Our Do Not Track Policy: Some browsers have “do not track” features that allow you to tell
          a website not to track you. These features are not all uniform. We do not currently
          respond to those signals.{" "}
        </span>
        If you block cookies, certain features on our sites may not work. If you block or reject
        cookies, not all of the tracking described here will stop.
      </p>
      <p className="underline font-semibold text-xl mb-4">
        You Can Opt-Out of Interest-Based Advertising
      </p>
      <p className="mb-4">
        You can submit an opt-out request to the Digital Advertising Alliance’s (DAA)
        Self-Regulatory Program for Online Behavioral Advertising program{" "}
        <a
          href="http://optout.aboutads.info"
          target="_blank"
          className="text-blue-500"
          rel="noreferrer"
        >
          {" "}
          click here
        </a>{" "}
        and/or the National Advertising Initiative (NAI){" "}
        <a
          href="https://optout.networkadvertising.org/?c=1"
          target="_blank"
          className="text-blue-500"
          rel="noreferrer"
        >
          {" "}
          click here
        </a>
        . These are tools that provide individuals with the ability to opt-out of having their
        online behavior recorded and used for advertising purposes by participating companies.
      </p>
      <p className="mb-4">
        You may also choose to disable cookies on your device or browser as explained above. Please
        see the instructions in
        <span className="font-semibold"> You Can Control Certain Tracking Tools</span> for more
        information about adjusting cookie settings. Note that if you opt out or disable cookies,
        you may continue to see ads from us or our customers, but they may not be tailored to your
        interests. Certain choices you make are both browser and device specific.
      </p>
      <p className="mb-4">
        Note the tools described above may not opt you out of non-cookie online advertising. For
        further instructions on how we use and share your information, and for additional
        instructions on how you can control your information, please review our Privacy Policy.
      </p>
    </Paper>
  </div>
);

export default CookiePolicy;
