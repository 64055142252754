import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import DropdownItem from "./DropdownItem";

export default function Dropdown({
  options,
  button,
  placement = "bottom-start",
  className = "",
  contentClassName = "",
}) {
  const [instance, setInstance] = useState(null);

  const DropdownContent = ({ hide }) => (
    <div
      className={`bg-white text-base z-50 float-left list-none text-left shadow-lg -mt-1 overflow-hidden transition-all duration-500 ${className}`}
      style={{ minWidth: "10rem" }}
    >
      {options?.map((option, index) => (
        <DropdownItem
          key={index}
          icon={option.icon}
          contentClassName={contentClassName}
          onClick={() => {
            option.action();
            hide();
          }}
        >
          {option.label}
        </DropdownItem>
      ))}
    </div>
  );
  return (
    <Tippy
      onCreate={setInstance}
      content={instance ? <DropdownContent hide={instance.hide} /> : ""}
      animation="scale"
      trigger="click"
      offset={[0, 10]}
      placement={placement}
      duration={[300, 100]}
      interactive
    >
      {button}
    </Tippy>
  );
}

Dropdown.defaultProps = {
  options: [],
  placement: "bottom-start",
  className: "",
  contentClassName: "",
};

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  placement: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  button: PropTypes.node.isRequired,
};
