// components
import { Paper, Typography, CardSecure } from "../../components";
// import Card from '../../components/card/Card';

const Cards = () => (
  <div className=" flex justify-center p-page">
    <Paper>
      <Typography type="subtitle" className="text-center">
        Cards
      </Typography>

      <div className="flex flex-row">
        <div>
          <CardSecure
            type="medium"
            width={300}
            beforeTitle="Ideal for new users"
            title="Launch secure"
            price="$199"
            subscription={false}
            subOne="or save 10% by subscribing annualy"
            subTwo="Ideal for recently opened bussines"
            btnLabel="Buy now"
            btnVariant="outlined"
            textArray={["Low price", "Great quality", "Never late"]}
          />
        </div>

        <CardSecure
          type="full"
          width={350}
          beforeTitle="Ideal for new users"
          title="Launch secure"
          price="$199"
          subscription={true}
          subOne="or save 10% by subscribing annualy"
          subTwo="Ideal for recently opened bussines"
          btnLabel="Buy now"
          btnVariant="full"
          textArray={["Low price", "Great quality", "Never late"]}
          recommend={true}
        />

        <div>
          <CardSecure
            type="medium"
            width={300}
            title="Launch secure"
            price="$199"
            subscription={true}
            subOne="or save 10% by subscribing annualy"
            subTwo="Ideal for recently opened bussines"
            textArray={["(90) expert credits per month", "Support during standard business hours"]}
          />
        </div>

        <div>
          <CardSecure
            type="medium"
            width={300}
            title="HIPAA Information Security Policy"
            price="$3.000"
            btnLabel="Get started"
          />
        </div>

        <div>
          <CardSecure type="medium" width={300} title="Project 1" subTwo="Respond" />
        </div>
      </div>

      {/* <div>
          <Card
            title={'HIPAA Information Security Policy'}
            price={`250 Pop Coins`}
            description={`Best seller. Ideal for businesses getting started or sole proprietors.`}
            features={[
              `Access to all basic features`,
              `Basic reporting and analytics`,
              `Up to 10 individual users`,
            ]}
            width={334}
            btnVariant={`full`}
            btnLabel={'Get started'}
            btnColor={`prime`}
          />
        </div> */}
    </Paper>
  </div>
);

export default Cards;
