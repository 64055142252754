/* NOTE: stripe elements documentation
  https://stripe.com/docs/stripe-js/react
*/
import React, { useState, useMemo, forwardRef, useImperativeHandle } from "react";

// stripe
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
// import { loadStripe } from '@stripe/stripe-js';
import { loadStripe } from "@stripe/stripe-js/pure"; // disable advancedFraudSignals

// components
import CyberPopupStripe from "../../assets/img/cyber-popup-stripe-icon.png";
import { Loader } from "../../components";
import { useToast } from "../../context";

// context

const APP_ENV = import.meta.env.VITE_APP_ENV;

const stripe_publishable_key_test =
  "pk_test_51KmLu1EVoawK75O7DFJzNlB3KKU3IdOVvX9WMMrxXtFztT8idY3gAa7FUfZIMFncZHneL3Tq3djEdSnoKxOjMfHS00uQ0CM63r";
const stripe_publishable_key_live =
  "pk_live_51KmLu1EVoawK75O7WBys9yRFGYVSgWTupSE2crJm6dRLDDLii7tewGlSn9L5Mt7bxyN6nlhlzvoxr5onrLxwwgX500HlGO39oS";

// ser product_ids for different envirement
let publishable_key = stripe_publishable_key_test;

if (APP_ENV === "production") {
  publishable_key = stripe_publishable_key_live;
}

// disable advancedFraudSignals
// loadStripe.setLoadParameters({ advancedFraudSignals: false });

// stripePromise - loadStripe
const stripePromise = loadStripe(publishable_key);

const useOptions = () => {
  // const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: "16px",
          // color: '#424770',
          letterSpacing: "0.025em",
          // fontFamily: 'Source Code Pro, monospace',
          // '::placeholder': {
          //   color: '#aab7c4',
          // },
          border: "1px solid red",
          backgroundColor: "#fff",
          padding: 4,
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const StripeForm = forwardRef((props, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [loader, setLoader] = useState(false);

  // context
  const { showToast } = useToast();

  const handleSubmit = async () => {
    console.log("stripe child function reached");
    const cardElement = elements.getElement(CardNumberElement);

    // stripe's create payment method Api
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    console.log("stripe child function returned", error, paymentMethod);
    if (error) {
      showToast("error", error.message);
      return false;
    } else {
      setLoader(false);
      return { paymentMethod };
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(),
  }));

  return (
    <div className="">
      {/* loader */}
      {loader && <Loader themeColor="fill-prime-light" />}

      {/* stripe forma */}
      <div className="mb-10">
        <div className="mb-4">
          <p className="text-prime-light">Card number*</p>
          <CardNumberElement
            data-testid="stripe-card-number"
            className="w-full border-gray-300 rounded-md shadow-sm bg-gray-50 focus:border disabled:opacity-50 focus:border-prime focus:outline-none"
            options={options}
          />
        </div>

        <div className="mb-4">
          <p className="text-prime-light">Expiration date*</p>
          <CardExpiryElement options={options} />
        </div>

        <div className="">
          <p className="text-prime-light">CVC*</p>
          <CardCvcElement options={options} />
        </div>
      </div>

      <img src={CyberPopupStripe} alt="logo" className="block h-10 mx-auto my-0" />
    </div>
  );
});

const StripeElementsCompany = forwardRef(({ className }, ref) => (
  <div className={`mb-8 ${className}`}>
    <Elements stripe={stripePromise} options={{}}>
      <StripeForm ref={ref} />
    </Elements>
  </div>
));

export default StripeElementsCompany;
