import React, { useEffect, useCallback } from "react";

// context
import { useToast } from "../context";

// services
import { ResourcesService, errorMsgForUser } from "../services";

const Resources = ({
  countries,
  alpha3Code,
  setCountries,
  setStates,
  setTimezones,
  setLanguages,
}) => {
  // context
  const { showToast } = useToast();

  const getCountries = () =>
    ResourcesService.getCountries()
      .then((res) => {
        setCountries(res?.countries);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)));

  // get states if country selected
  const getStates = useCallback(() => {
    const countryObj = countries?.find((n) => n.alpha3Code === alpha3Code);

    if (countryObj) {
      setStates(countryObj.states);
    } else {
      setStates([]);
    }
  }, [alpha3Code, countries, setStates]);

  const getTimezones = () =>
    ResourcesService.getTimezones()
      .then((res) => {
        setTimezones(res?.timezones);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)));

  const getLanguages = () =>
    ResourcesService.getLanguages()
      .then((res) => {
        setLanguages(res?.languages);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)));

  useEffect(() => {
    if (setStates) {
      getStates();
    }
  }, [alpha3Code, countries]);

  useEffect(() => {
    // get countries
    if (setCountries) {
      getCountries();
    }

    // get timezones
    if (setTimezones) {
      getTimezones();
    }

    // get languages
    if (setLanguages) {
      getLanguages();
    }
  }, []);

  return <div />;
};

export default Resources;
