import { forwardRef } from "react";

const Checkbox = forwardRef(
  (
    {
      dataTestId,
      name,
      label,
      className = "mb-4",
      inputClassName = "",
      labelClassName = "text-prime-light-dark",
      value,
      checked = false,
      onChange,
      disabled,
      ...rest
    },
    ref
  ) => (
    <div className={`flex items-center ${className}`}>
      <input
        id={dataTestId}
        data-testid={dataTestId}
        ref={ref}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        className={`mr-4 rounded border-grey shadow-sm focus:border-prime ${inputClassName} ${
          disabled ? "text-prime-light" : "text-prime cursor-pointer"
        }`}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />

      <span className={`block ${labelClassName}`}>{label}</span>
    </div>
  )
);

export default Checkbox;
