import { forwardRef } from "react";

const Switch = forwardRef(
  (
    {
      dataTestId,
      name,
      label,
      className = "mb-4",
      inputClassName = "",
      checked = false,
      onChange,
      disabled = false,
      ...rest
    },
    ref
  ) => (
    <div className={`flex items-center ${className}`}>
      <label
        htmlFor={dataTestId}
        className={`relative inline-flex items-center ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <input
          data-testid={dataTestId}
          id={dataTestId}
          ref={ref}
          name={name}
          type="checkbox"
          role="switch"
          value={dataTestId}
          className="sr-only peer"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          {...rest}
        />

        <div
          className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-prime ${inputClassName}`}
        />

        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</span>
      </label>
    </div>
  )
);

export default Switch;
