import React, { useState, useEffect } from "react";
import { isEmpty, includes } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

// components
import { Paper, Typography, Button, ProgressBar, Loader } from "../../components";
import { useAuth, useToast } from "../../context";
import Resources from "../../resources/Resources";
import { QuestionnaireService, ProfileService, errorMsgForUser } from "../../services";
import CustomerQuestionnaireAdditionalQuestions from "./CustomerQuestionnaireAdditionalQuestions";
import SetInputElement from "./SetInputElement";

// context

// services
import Stepper from "./Stepper";

// constants
import { customer_questions } from "./customer_questions";

const initialProfile = {
  company_name: "",
  industry: "",
  employees_num: "",
  annual_revenue: "",
  main_customers: "",
  customers_generally_location: "",
  vendors_num: "",
  sensitive_info_collection: "",
  formal_cyber_security_strategy: "",
  cybersecurity_strategy: "",
  // cybersecurity_areas: [], // array of strings
  cyber_maturity: "",
  cyber_security_controls: [], // array of strings
  // employee_logins: '',
  // centralized_device_management: '',
  current_technology: "",
  vendor_collaboration_tools: [], // array of strings
  cloud_email_tool: "",
  cloud_collaboration_tool: "",
  cloud_file_storage_tool: "",
  vendor_hosted_website_tool: "",
  work_type: "",
  full_time_cyber_security_workers: "",
  cybersecurity_insurance_policy: "",
  cyber_security_rate: "",
  // responsible_person_email: '',
  responsible_person_first_name: "",
  responsible_person_last_name: "",
  web_site: "",
  // linkedin: '',
  country: "USA",
  state: "",
  city: "",
  zip_code: "",
  time_zone: "",
  prefered_language: "English",
  // team_help: false,
  how_did_you_hear_about_us: "",
  how_did_you_hear_about_us_other: "",
};

const CustomerQuestionnaire = () => {
  // router
  let navigate = useNavigate();
  let { blok } = useParams();

  // context
  const { authContext, setAuthContext } = useAuth();
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(0);
  const [profile, setProfile] = useState(initialProfile);
  const [currentQuestionsBlok, setCurrentQuestionsBlok] = useState([]);
  // resources
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [timezones, setTimezones] = useState([]);

  const setInputElement = (question) => (
    <SetInputElement
      question={question}
      questions={customer_questions}
      profile={profile}
      setProfile={setProfile}
      countries={countries}
      states={states}
      languages={languages}
      timezones={timezones}
    />
  );

  const isNextDisabled = () => {
    let disableButton = false;

    // if introduction
    if (blok === "0") {
      return disableButton;
    }

    // dependency - formal_cyber_security_strategy
    if (
      blok === "6" &&
      (profile.formal_cyber_security_strategy === "We have one, but it needs work" ||
        profile.formal_cyber_security_strategy === "We have a world-class Cybersecurity program") &&
      !profile.cybersecurity_strategy
    ) {
      disableButton = true;
    }

    // dependency - cyber_maturity
    if (
      blok === "7" &&
      profile.cyber_maturity ===
        "We have a 5 star program and the cyber attackers can bring it on!" &&
      isEmpty(profile.cyber_security_controls)
    ) {
      disableButton = true;
    }

    // dependency - vendor_collaboration_tools
    if (blok === "9") {
      if (
        includes(
          profile.vendor_collaboration_tools,
          "Cloud email (i.e. Google Workspace, Microsoft O365, ZohoMail, etc.)"
        ) &&
        !profile.cloud_email_tool
      ) {
        disableButton = true;
      }

      if (
        includes(
          profile.vendor_collaboration_tools,
          "Cloud collaboration tools (i.e. Slack, Teams, Google chat)"
        ) &&
        !profile.cloud_collaboration_tool
      ) {
        disableButton = true;
      }

      if (
        includes(
          profile.vendor_collaboration_tools,
          "Cloud file storage solutions (i.e. Google Drive, Box, OneDrive)"
        ) &&
        !profile.cloud_file_storage_tool
      ) {
        disableButton = true;
      }

      if (
        includes(
          profile.vendor_collaboration_tools,
          "Vendor hosted website (i.e. Wix, Wordpress, Squarespace, Shopify)"
        ) &&
        !profile.vendor_hosted_website_tool
      ) {
        disableButton = true;
      }
    }

    // dependency - state
    if (blok === "12") {
      if (!isEmpty(states) && !profile.state) {
        disableButton = true;
      }
    }

    // dependency - how_did_you_hear_about_us
    if (blok === "13") {
      if (
        profile.how_did_you_hear_about_us === "Other" &&
        !profile.how_did_you_hear_about_us_other
      ) {
        disableButton = true;
      }
    }

    const requiredQuestionsInBlock = currentQuestionsBlok.filter(
      (n) => customer_questions[n]?.required === true
    );

    requiredQuestionsInBlock &&
      requiredQuestionsInBlock.forEach((q) => {
        if (!profile[q] || isEmpty(profile[q])) {
          disableButton = true;
        }
      });

    return disableButton;
  };

  const nextClicked = () => {
    // is last group of questions
    if (blok === "13") {
      return createQuestionnaire();
    }

    const nextBlok = Number(blok) + 1;
    navigate(`/questionnaire/${nextBlok}`);
  };

  const createQuestionnaire = () => {
    // questionnaire create Api
    const questionnaireObj = {
      industry: profile.industry,
      employees_num: profile.employees_num,
      annual_revenue: profile.annual_revenue,
      main_customers: profile.main_customers,
      customers_generally_location: profile.customers_generally_location,
      vendors_num: profile.vendors_num,
      sensitive_info_collection: profile.sensitive_info_collection,
      formal_cyber_security_strategy: profile.formal_cyber_security_strategy,
      // cybersecurity_areas: profile.cybersecurity_areas,
      cybersecurity_strategy: profile.cybersecurity_strategy,
      cyber_maturity: profile.cyber_maturity,
      cyber_security_controls: profile.cyber_security_controls,
      current_technology: profile.current_technology,
      vendor_collaboration_tools: profile.vendor_collaboration_tools,
      cloud_email_tool: profile.cloud_email_tool,
      cloud_collaboration_tool: profile.cloud_collaboration_tool,
      cloud_file_storage_tool: profile.cloud_file_storage_tool,
      vendor_hosted_website_tool: profile.vendor_hosted_website_tool,
      work_type: profile.work_type,
      full_time_cyber_security_workers: profile.full_time_cyber_security_workers,
      cybersecurity_insurance_policy: profile.cybersecurity_insurance_policy,
      cyber_security_rate: profile.cyber_security_rate,
      how_did_you_hear_about_us: profile.how_did_you_hear_about_us,
      how_did_you_hear_about_us_other: profile.how_did_you_hear_about_us_other,
    };

    // create questionnaire Api
    setLoader((l) => l + 1);
    return QuestionnaireService.create({
      questions: questionnaireObj,
      user_uid: authContext?.user?.uid,
    })
      .then((res) => {
        // update 'authContext.user'
        const newUserObj = { ...authContext.user, questionnaire_uid: res?.uid };
        const newAuthObj = { ...authContext, user: newUserObj };
        setAuthContext(newAuthObj);

        // create profile
        const riskAssessment = res?.risk_assessment;
        createProfile(riskAssessment, newUserObj);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const createProfile = (riskAssessment, newUserObj) => {
    // questionnaire create Api
    const profileObj = {
      user_uid: authContext?.user?.uid,
      role: authContext?.role,
      company_name: profile.company_name,
      // responsible_person_email: profile.responsible_person_email,
      responsible_person_first_name: profile.responsible_person_first_name,
      responsible_person_last_name: profile.responsible_person_last_name,
      web_site: profile.web_site,
      // linkedin: profile.linkedin,
      country: profile.country,
      state: profile.state,
      city: profile.city,
      zip_code: profile.zip_code,
      time_zone: profile.time_zone,
      prefered_language: profile.prefered_language,
      risk_assessment: riskAssessment,
      road_map: {},
      race: "",
      race_other: "",
    };

    setLoader((l) => l + 1);
    return ProfileService.create(profileObj)
      .then((res) => {
        // update 'authContext.user'
        const newUserObject = { ...newUserObj, profile_uid: res?.profile?.uid };
        const newAuthObj = { ...authContext, user: newUserObject };
        setAuthContext(newAuthObj);

        // update 'user' in 'localStorage'
        localStorage.setItem("user", JSON.stringify(newUserObject));

        // NOTE: redirection is dont in useEffect
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  // set new question block
  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);

    // set current block
    if (blok !== "0") {
      const activeQuestionBlok = customer_questions?.question_bloks[blok];
      setCurrentQuestionsBlok(activeQuestionBlok);
    }
  }, [blok]);

  // set profile.state
  useEffect(() => {
    setProfile((n) => ({ ...n, state: "" }));
  }, [profile.country]);

  useEffect(() => {
    if (authContext?.user?.profile_uid) {
      navigate("/dashboard");
    }
  }, [authContext, navigate]);

  useEffect(() => {
    if (blok !== "0") {
      setProfile(initialProfile);
      navigate("/questionnaire/0");
    }
  }, []);

  // useEffect(() => {
  //   console.log('currentQuestionsBlok:', currentQuestionsBlok);
  // }, [currentQuestionsBlok]);

  // useEffect(() => {
  //   console.log('profile:', profile);
  // }, [profile]);

  return (
    <div className="flex items-center flex-col p-page">
      {/* get resources */}
      <Resources
        countries={countries}
        setCountries={setCountries}
        alpha3Code={profile?.country}
        setStates={setStates}
        setLanguages={setLanguages}
        setTimezones={setTimezones}
      />

      {/* steper */}
      <Stepper step="questionnaire" className="mb-8" itemClassName="px-2" />

      <Paper className="w-[700px]">
        {/* loader */}
        {!!loader && <Loader themeColor="fill-prime" />}

        <ProgressBar
          text=""
          className="mb-8"
          bgcolor="bg-sec"
          height={17}
          maxStep={13}
          step={Number(blok)}
        />

        {Number(blok) === 0 && (
          <Typography type="subtitle" className="text-left mb-2">
            Please take this short questionnaire to help us understand your current security
            strategy and approach. We will use this information to determine your risk maturity
            rating and help you build a robust and secure organization.
          </Typography>
        )}

        {/* title and subtitle */}
        {Number(blok) > 0 && (
          <>
            <Typography type="subtitle" className="text-left mb-2">
              {Number(blok) < 5
                ? customer_questions?.titles?.about_company
                : Number(blok) < 11
                ? customer_questions?.titles?.cyber_security
                : customer_questions?.titles?.about_you}
            </Typography>

            <p className="mb-6 text-prime-light">
              {Number(blok) === 3 || Number(blok) === 8 || Number(blok) === 9
                ? "Please note that a minimum of one field is required."
                : "Please note that all fields are required."}
            </p>

            {/* set input element (block 9 is rendered in "CustomerQuestionnaireAdditionalQuestions") */}
            {blok !== "9" &&
              currentQuestionsBlok?.map((question) => (
                <div key={question}>{setInputElement(question)}</div>
              ))}

            {/* set additional input element or vendor_collaboration_tools */}
            <CustomerQuestionnaireAdditionalQuestions
              blok={blok}
              profile={profile}
              setProfile={setProfile}
              setInputElement={setInputElement}
            />
          </>
        )}

        {/* active buttons */}
        <div className="flex justify-between mt-8">
          <Button
            dataTestId="back_button"
            variant="flat"
            themecolor="prime"
            label="Back"
            className={Number(blok) === 0 ? "hidden" : ""}
            onClick={() => navigate(-1)}
          />

          <div className={Number(blok) === 0 ? "flex-1" : ""} />

          <Button
            dataTestId="test id"
            variant="full"
            themecolor="prime"
            label="Next"
            disabled={isNextDisabled()}
            onClick={() => nextClicked()}
          />
        </div>
      </Paper>
    </div>
  );
};

export default CustomerQuestionnaire;
