export const COLUMN_NAMES = {
  PROGRESS_INITIATION: {
    value: "Initiation",
    label: "Progress Initiation",
  },
  IN_PROGRESS: {
    label: "Project In Progress",
    value: "In Progress",
  },
  CLOSE: {
    label: "Project Close",
    value: "Close",
  },
};
