import React from "react";
import { map } from "lodash";

// components
import { Textarea } from "../../components";

const FreelancerRadioList = ({ question, questions, profile, setProfile }) => (
  <div>
    <table className="w-full mb-8">
      <thead className="text-prime-light text-sm font-normal border-b border-grey-light">
        <tr>
          <td className="w-full" />
          {questions[question]?.radio_options?.map((n) => (
            <td key={n} className="min-w-[84px] text-center">
              {n}
            </td>
          ))}
        </tr>
        <tr>
          <td className="w-full" />
          {questions[question]?.radio_options_sec_row?.map((n) => (
            <td key={n} className="text-center">
              {n}
            </td>
          ))}
        </tr>
      </thead>

      <tbody>
        {/* k = profile.question.subquestion */}
        {map(
          profile[question],
          (v, k) =>
            k !== "Other" && (
              <tr key={k} className="border-b border-grey-light">
                <td className="px-1">{k}</td>

                {questions[question]?.radio_options?.map((radioOption) => (
                  <td key={`${k}_${radioOption}`} className="px-1">
                    <div className="flex justify-center p-2">
                      <input
                        data-testid={`${k}_${radioOption}`}
                        id={`${k}_${radioOption}`}
                        type="radio"
                        className="text-prime focus:border-prime"
                        // name={profile[question][k]}
                        value={radioOption}
                        checked={radioOption === profile[question][k] ? true : false}
                        onChange={(e) => {
                          setProfile((n) => {
                            const newValues = {
                              ...n[question],
                              [k]: e.target.value,
                            };
                            return {
                              ...n,
                              [question]: newValues,
                            };
                          });
                        }}
                      />
                    </div>
                  </td>
                ))}
              </tr>
            )
        )}
      </tbody>
    </table>

    <Textarea
      dataTestId={`${question}_other`}
      type="text"
      label="Other"
      placeholder={questions[question]?.other_placeholder || ""}
      rows="2"
      maxCharacters={140}
      value={(profile && profile[question] && profile[question]["Other"]) || ""}
      onChange={(e) => {
        setProfile((n) => {
          const newValues = {
            ...n[question],
            Other: e.target.value,
          };
          return {
            ...n,
            [question]: newValues,
          };
        });
      }}
    />
  </div>
);

export default FreelancerRadioList;
