import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import { Typography, Button, Tabs, EnhancedTable, ModalConfirm } from "../../components";
import { useToast } from "../../context";
import { ProjectTemplateService, errorMsgForUser } from "../../services";
import { PROJECTS_TABLE_COLUMNS } from "./utils";

// services

// context

const CsmProjectsTemplates = () => {
  // router
  let navigate = useNavigate();

  // context
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(false);
  const [projects, setProjects] = useState([]);
  const [deleteModalConfirm, setDeleteModalConfirm] = useState([false, null]);

  const getProjectTemplates = useCallback(() => {
    setLoader(true);
    return ProjectTemplateService.getAll({})
      .then((r) => {
        setProjects([...r.project_templates_published_draft]);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  }, [showToast]);

  const updateProject = (project) => {
    setLoader(true);
    return ProjectTemplateService.update({
      uid: project.uid,
      number: project.number,
      version: project.version,
      status: "Archived",
    })
      .then(() => {
        getProjectTemplates();
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  const actionButtonsWithLatestPublished = (n) => {
    const actBtns = [
      {
        dataTestId: "archive_projects",
        variant: "icon-flat",
        themecolor: "prime",
        icon: "fa-solid fa-archive",
        action: (e) => {
          e.stopPropagation();
          updateProject(n);
        },
      },
      {
        dataTestId: "delete_projects",
        variant: "icon-flat",
        themecolor: "warning",
        icon: "fa-solid fa-trash",
        action: (e) => {
          e.stopPropagation();
          setDeleteModalConfirm([true, n.uid]);
        },
      },
    ];

    if (n.latest_published && n.number === 1) {
      return [
        {
          dataTestId: "latest_published",
          variant: "icon-flat",
          themecolor: "success",
          icon: "fa-solid fa-flag",
        },
        ...actBtns,
      ];
    }
    return [...actBtns];
  };

  const tableRows = projects.map((n) => ({
    "data-testid": n.uid,
    id: n.uid,
    short_text: n.short_text,
    number_and_version: n.number_and_version,
    level: n.level,
    nist_level: n.nist_level,
    number: n.number,
    started_instances: n.started_instances,
    work_hours_estimation: n.work_hours_estimation,
    cost: n.cost,
    status: n.status,
    action_buttons: actionButtonsWithLatestPublished(n),
  }));

  const deleteProject = (project_template_uid) => {
    setLoader(true);
    return ProjectTemplateService.delete({ project_template_uid })
      .then(() => {
        getProjectTemplates();
        setDeleteModalConfirm([false, null]);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    getProjectTemplates();
  }, [getProjectTemplates]);

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container flex-1 mx-auto p-page max-w-10xl">
      <div className="flex items-center h-10 mb-6">
        <Typography type="subtitle">Project Templates</Typography>

        <Button
          className="ml-auto md:px-2 md:py-2"
          themecolor="prime"
          label="Create Project"
          onClick={() => navigate(`/project-template/create`)}
        />
      </div>

      <Tabs
        dataTestId="project_archives_tab"
        className="mb-4"
        tabs={["Active / Drafts", "Archived"]}
        value="Active / Drafts"
        onChange={() => navigate("/project-templates/archived")}
      />

      <EnhancedTable
        tableColumns={PROJECTS_TABLE_COLUMNS}
        tableRows={tableRows}
        loader={loader}
        noInfiniteScroll={true}
        tableHeight="auto"
        // selectOptions={csmOptions}
        // option={csmAssigned}
        // setOptions={setCsmAssigned}
        // freelanceOptions={freelanceOptions}
        // setFreelancer={setFreelancer}
        // freelancer={freelancer}
        handleRowClick={(item) => navigate(`/project-template/${item.id}`)}
        handleTableDataModalClick={(item) => navigate(`/project-template/${item.id}`)}
      />

      {/* modal delete project */}
      {deleteModalConfirm[0] && (
        <ModalConfirm
          dataTestId="modal-email-security-project"
          title="Are you sure you want to delete project?"
          confirmBtnText="Delete"
          closeModal={() => setDeleteModalConfirm([false, null])}
          onConfirm={() => deleteProject(deleteModalConfirm[1])}
        />
      )}
    </div>
  );
};

export default CsmProjectsTemplates;
