import { evalQuery } from "./index";

export const TransactionService = {
  getAllTransactions: (request) =>
    evalQuery({
      url: "/transaction/get-all",
      request,
    }),

  getCountOfPopToken: (request) =>
    evalQuery({
      url: "/transaction/get-count-by-poptoken-type",
      request,
    }),
  addPopTokens: (request) =>
    evalQuery({
      url: "/transaction/add-poptokens",
      request,
    }),
};
