import React, { useState, useEffect, useCallback } from "react";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { NavLink, useNavigate } from "react-router-dom";
// components
import { EnhancedTable, Tabs, Typography } from "../../components";
import { useToast } from "../../context";
import {
  ProjectService,
  ProfileService,
  ResourcesService,
  errorMsgForUser,
  CompanyService,
} from "../../services";
import ActiveFiltersBanner from "./ActiveFiltersBanner";
import CsmTableFilterButton from "./CsmTableFIlterButton";
import CustomerView from "./CustomerView";
import DeleteCompanyModal from "./DeleteCompanyModal";
// import ProjectView from './ProjectView';

// services

// context

const tableColumns = [
  {
    id: "customer",
    additional: "custemer_responsible_person_email",
    type: "td-modal-additional",
    label: "Customer",
    style: "text-terc hover:underline",
  },
  {
    id: "project",
    type: "td-modal",
    label: "Project",
    style: "text-terc hover:underline",
  },
  {
    id: "waiting_on",
    type: "string",
    label: "Waiting on",
  },
  {
    id: "freelance_assigned_uid",
    type: "freelancer",
    label: "Cyber-expert Assigned",
  },
  {
    id: "csm_assigned",
    type: "string",
    label: "CSM Assigned",
  },
  {
    id: "project_status",
    type: "string",
    label: "Status",
    index: null,
  },
  {
    id: "final_deliverable",
    type: "final_deliverable",
    label: "Final Deliverable",
    index: null,
  },
];

const CsmUserManagementTable = () => {
  // context
  const { showToast } = useToast();

  // local
  const [loader, setLoader] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [customers, setCustomers] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [freelancers, setFreelancers] = useState([]);
  const [csm, setCsm] = useState([]);
  const [showProjectQuestions, setShowProjectQuestions] = useState(false);
  const [showFinalDeliverable, setFinalDeliverable] = useState(false);
  const [deleteCompanyModal, setDeleteCompanyModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  // * Filters
  const [activeFilters, setActiveFilters] = useState([]);
  // const [filterableFreelancers, setFilterableFreelancers] = useState([]);

  let navigate = useNavigate();

  const initialCustomers = [...customers];

  const getCustomers = useCallback(() => {
    setLoader((l) => l + 1);
    return ProjectService.getCustomersProjects({})
      .then((res) => {
        setCustomers(res.customer_user_management);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  }, [showToast]);

  const getFreelancers = useCallback(() => {
    setLoader((l) => l + 1);
    return ProfileService.getFreelancers({})
      .then((res) => {
        setFreelancers(res.freelancers);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  }, [showToast]);

  const getAllCsm = useCallback(() => {
    setLoader((l) => l + 1);
    return ProfileService.getAllCsm({})
      .then((res) => {
        setCsm(res.csm);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  }, [showToast]);

  const assign = useCallback(
    (uid, freelancer_uid) => {
      setLoader((l) => l + 1);
      return ProjectService.assignFreelancerToProject({
        project_uid: uid,
        freelancer_uid,
      })
        .then(() => {
          getCustomers();
          setActiveFilters([]);
          // setCsm(res.csm);
        })
        .catch((err) => showToast("error", errorMsgForUser(err)))
        .finally(() => setLoader((l) => l - 1));
    },
    [getCustomers, showToast]
  );

  const tableRows = customers.map((customer) =>
    // console.log('customer:', customer);

    ({
      "data-testid": customer.customer_uid,
      id: customer.customer_uid,
      customer: customer.customer,
      custemer_responsible_person: customer.custemer_responsible_person,
      custemer_responsible_person_email: customer.custemer_responsible_person_email,
      project: customer.project || "N/A",
      project_uid: customer.project_uid,
      waiting_on: "CSM",
      freelancer_assigned: customer.freelancer_assigned || "N/A",
      freelance_assigned_uid: customer.freelancer_assigned_uid,
      csm_assigned: customer.csm_assigned,
      csm_assigned_uid: customer.csm_assigned_uid,
      project_questions: "",
      final_deliverable: "",
      project_status: customer.project_status,
    })
  );

  const handleTableDataModalClick = (item, cell) => {
    // open modal
    setCurrentItem(item);
    cell?.id === "customer" ? setModalOpened(true) : navigate(`/project/${item.project_uid}`);
  };

  const handleLinkClick = (e, i, cell) => {
    if (e.type === "click") {
      console.log("left click");
    } else if (e.type === "contextmenu") {
      e.preventDefault();
      cell?.id === "project_questions"
        ? setShowProjectQuestions(!showProjectQuestions)
        : setFinalDeliverable(!showFinalDeliverable);
      // setShowProjectQuestions(!showProjectQuestions);
    }
  };

  const handleDeleteCompanyFirstClick = (value) => {
    setModalOpened(false);
    setDeleteCompanyModal(value);
  };

  const deleteCompanyConfirm = async (company) => {
    try {
      setDeleteLoader(true);
      console.log(company.id);
      await CompanyService.deleteAllCompanyData({
        company_uid: company.id,
      });
      const newState = customers.filter((e) => e.customer_uid !== company.id);
      setCustomers(newState);
      setDeleteCompanyModal(false);
    } catch (error) {
      showToast("error", errorMsgForUser(error));
    } finally {
      setDeleteLoader(false);
    }
  };

  // get Customers, Freelancers and CSM  data
  useEffect(() => {
    getCustomers();
    getFreelancers();
    getAllCsm();
  }, [getAllCsm, getCustomers, getFreelancers]);

  useEffect(() => {
    ResourcesService.getCountries().then((r) => {
      setAllCountries(r.countries);
    });
  }, []);

  const handleAddFilter = (filter) => {
    let newActiveFilters = [...activeFilters];
    if (newActiveFilters.find((f) => f.type === "by_status")) {
      newActiveFilters = newActiveFilters.filter((f) => f.type !== "by_status");
    } else {
      newActiveFilters.push(filter);
    }

    setActiveFilters(newActiveFilters);

    let newCustomers = [...customers];
    console.log("newCustomers:", newCustomers);
    if (filter.type === "by_status") {
      newCustomers = newCustomers.filter((c) => c.project_status != filter.value);
    } else if (filter.type === "by_freelancer") {
      newCustomers = newCustomers.filter((c) => c.freelancer_assigned_uid === filter.value);
    } else if (filter.type === "by_project") {
      newCustomers = newCustomers.filter((c) => c.project === filter.value);
    }

    setCustomers(newCustomers);
  };

  function isUniqueFL(item, index, self) {
    return (
      self.findIndex((obj) => obj.freelancer_assigned === item.freelancer_assigned) === index &&
      item.freelancer_assigned
    );
  }

  function isUniquePR(item, index, self) {
    return self.findIndex((obj) => obj.project === item.project) === index && item.project;
  }
  const filterableFreelancers = customers
    .filter(isUniqueFL)
    .map((f) => ({ name: f.freelancer_assigned, uid: f.freelancer_assigned_uid }));

  const filterableProjects = customers
    .filter(isUniquePR)
    .map((f) => ({ name: f.project, uid: f.project_uid }));

  return (
    <>
      <div className="container flex-1 mx-auto p-page max-w-10xl" data-testid="user-management">
        <div className="flex gap-2 justify-between items-center">
          <Typography className="h-10 mb-6 grow" type="subtitle">
            Customer User Management
          </Typography>
          <ActiveFiltersBanner activeFilters={activeFilters} />
          <CsmTableFilterButton
            handleAddFilter={handleAddFilter}
            activeFilters={activeFilters}
            filterableFreelancers={filterableFreelancers}
            filterableProjects={filterableProjects}
          />

          <button
            type="button"
            className=" rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => {
              setActiveFilters([]);
              getCustomers();
            }}
          >
            <ArrowPathIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
          <a
            href="/app/customer-management"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            View New Customer Table
          </a>
        </div>
        <EnhancedTable
          loader={loader}
          noInfiniteScroll={true}
          tableColumns={tableColumns}
          tableRows={tableRows}
          selectOptions={csm}
          freelanceOptions={freelancers}
          setOptions={(project_uid, freelance_uid, csm_uid) =>
            assign(project_uid, freelance_uid, csm_uid)
          }
          handleTableDataModalClick={(item, cell) => handleTableDataModalClick(item, cell)}
          showProjectQuestions={showProjectQuestions}
          showFinalDeliverable={showFinalDeliverable}
          handleLinkClick={(e, i, cell) => handleLinkClick(e, i, cell)}
        />
      </div>

      {modalOpened && (
        <CustomerView
          closeModal={() => {
            setModalOpened(false);
            setCurrentItem({});
          }}
          item={currentItem}
          csmOptions={csm}
          getCustomers={getCustomers}
          allCountries={allCountries}
          handleDeleteCompanyFirstClick={handleDeleteCompanyFirstClick}
        />
      )}

      {deleteCompanyModal && (
        <DeleteCompanyModal
          company={currentItem}
          open={deleteCompanyModal}
          setOpen={setDeleteCompanyModal}
          deleteLoader={deleteLoader}
          deleteCompanyConfirm={deleteCompanyConfirm}
        />
      )}

      {/* {projectViewModal && (
        <ProjectView
          closeModal={() => {
            setProjectViewModal(false);
            setCurrentItem({});
          }}
          item={currentItem}
        />
      )} */}
    </>
  );
};

export default CsmUserManagementTable;
