import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { useNavigate, useParams } from "react-router-dom";
import "videoask-elements";

// constants

// components
import { Paper, Button, Typography, Loader } from "../../components";
import { STATUS } from "../../constants/statuses";

// context
import { useAuth, useToast } from "../../context";

// services
import { errorMsgForUser, ProfileService, UserService } from "../../services";
import Stepper from "../questionnaire/Stepper";

const FreelancerVideoInterview = () => {
  // context
  const { authContext } = useAuth();
  const { showToast } = useToast();

  // router
  let navigate = useNavigate();
  let { page } = useParams();

  // local
  const [loader, setLoader] = useState(0);
  const [profile, setProfile] = useState({});

  const organization_id = import.meta.env.VITE_APP_ORGANIZATION_ID;
  const dataObject = {
    client_id: import.meta.env.VITE_APP_CLIENT_ID,
    refresh_token: import.meta.env.VITE_APP_REFRESH_TOKEN,
    client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
    grant_type: "refresh_token",
  };

  const getProfile = () => {
    // profile update Api
    setLoader((l) => l + 1);
    return ProfileService.get({ user_uid: authContext?.user?.uid })
      .then((res) => {
        setProfile(res?.profile);
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const redirectFromPage = () => {
    if (page === "onboarding") {
      navigate("/what-to-expect-video");
    } else if (page === "dashboard") {
      navigate("/dashboard");
    } else {
      navigate(-1);
    }
  };

  // Send email to FSM that profile has been changed
  const sendEmail = (toEmails) => {
    // 'to_emails' on local and development ENV is user email
    let to_emails = authContext?.user?.email;
    let message = `Cyber-expert ${profile.responsible_person_first_name} ${profile.responsible_person_last_name}, has updated a video interview!`;

    if (import.meta.env.VITE_APP_ENV === "production") {
      // Hack way to weed out notifications in slack
      to_emails = toEmails;
    } else if (import.meta.env.VITE_APP_ENV === "development") {
      to_emails = "dev-emails@cyberpopup.com";
    } else if (import.meta.env.VITE_APP_ENV === "local") {
      message = `--- on production this mail will be sent to ${toEmails} <br /> ` + message;
    }
    // hack way to weed out slack notifications for testtest accounts on production
    if (!authContext?.user?.email.includes("testtest")) {
      return UserService.sendEmail({
        from_email: to_emails,
        to_emails,
        subjects: authContext?.user?.email,
        message,
      }).catch(() => showToast("error", "Error occurred while sending mail"));
    }
  };

  // function wich request will return new access token
  const getAccessToken = () => {
    setLoader((l) => l + 1);

    return axios({
      method: "post",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      url: "https://auth.videoask.com/oauth/token",
      data: qs.stringify(dataObject),
    })
      .then((r) => r)
      .catch((err) => {
        console.error("getAccessToken err:", err);
        showToast("error", "Oops. Something went wrong");
      })
      .finally(() => setLoader((l) => l - 1));
  };

  // function wich request sends access token and returns id
  const getUserId = (access_token) => {
    setLoader((l) => l + 1);

    return axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "content-type": "application/x-www-form-urlencoded",
        "organization-id": organization_id,
      },
      url: `https://api.videoask.com/search/respondents?limit=100&search=${authContext.user.email}`,
    })
      .then((r) => r)
      .catch((err) => {
        console.error("getUserId err:", err);
        showToast("error", "Oops. Something went wrong");
      })
      .finally(() => setLoader((l) => l - 1));
  };

  // function wich request sends access token and id to get form id and contact id
  const getRespondentId = (access_token, id) => {
    setLoader((l) => l + 1);

    return axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "content-type": "application/x-www-form-urlencoded",
        "organization-id": organization_id,
      },
      url: `https://api.videoask.com/interactions?contact_email&include_read_field=1&limit=15&respondent_id=${id}&without_count=true`,
    })
      .then((r) => r)
      .catch((err) => {
        console.error("getRespondentId err:", err);

        const errMsg = err?.response?.data?.respondent_id[0];

        if (errMsg === "Must be a valid UUID.") {
          showToast("error", "Please record and upload video interview first");
        } else {
          showToast("error", "Oops. Something went wrong");
        }
      })
      .finally(() => setLoader((l) => l - 1));
  };

  // function wich request sends access token form id and contact id to return media url (link to video answer)

  const getAnswers = (access_token, form_id, contact_id) => {
    setLoader((l) => l + 1);

    return axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "content-type": "application/x-www-form-urlencoded",
        "organization-id": organization_id,
      },
      // url: `https://api.videoask.com/forms/${form_id}/contacts/${contact_id}?include_answers=true&include_messages=true&include_tags=true&responses_share_id&tag&tag_share_id`,
      url: `https://api.videoask.com/forms/77788ae8-0213-4477-8f91-da5982914bf1/contacts/${contact_id}?include_answers=true&include_messages=true&include_tags=true&responses_share_id&tag&tag_share_id`,
    })
      .then((r) => r)
      .catch((err) => {
        console.error("getAnswers err:", err);
        showToast("error", "Oops. Something went wrong");
      })
      .finally(() => setLoader((l) => l - 1));
  };

  // function wich updates the user profile with the extracted media url
  const updateProfile = (share_url) => {
    const profileObj = {
      user_uid: authContext?.user?.uid,
      media_url: share_url,
      video_status: STATUS.SUBMITTED.value,
    };

    // profile update Api
    setLoader((l) => l + 1);
    return ProfileService.update(profileObj)
      .then(() => {
        showToast("success", "Video upload successfully");

        // fix for BE problem (parallel sending email can cause problem)
        redirectFromPage();
      })
      .catch((err) => showToast("error", errorMsgForUser(err)))
      .finally(() => setLoader((l) => l - 1));
  };

  const handleVideoAsk = async () => {
    const {
      data: { access_token },
    } = await getAccessToken();
    const { data } = await getUserId(access_token);
    const id = data.results[0]?.id;
    const res = await getRespondentId(access_token, id);

    // sort answer and get lattest
    const sortAnswerByDate = res?.data?.results?.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    const { form_id, contact_id } = sortAnswerByDate[0];

    const answers = await getAnswers(access_token, form_id, contact_id);
    const { share_url } = answers.data;

    if (!share_url) {
      showToast("error", "Something went wrong. Please refresh the page and record again.");
      return;
    }
    updateProfile(share_url);
  };

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);

    // get Profile
    getProfile();
  }, []);

  return (
    <div className="flex justify-center w-full p-page">
      <div className="max-w-page">
        {/* steper after questionnere */}
        {page === "onboarding" && (
          <div className="flex justify-center">
            <Stepper step="video_interview" className="mb-8" itemClassName="px-2" />
          </div>
        )}

        <Paper className="mx-12">
          {/* loader */}
          {!!loader && <Loader themeColor="fill-prime" />}

          <Typography type="title" className="mb-8 text-center">
            Video Interview
          </Typography>

          <div className="flex">
            {/* left side */}
            <div className="flex flex-col px-4">
              <p className="mb-2 text-sec animate-pulse">
                When prompted after video creation, please enter the same email you used to sign up
                for an account.
              </p>

              {/* videoask */}
              {/* <videoask-embed
                url={`https://www.videoask.com/ftdlfzvyk#contact_email=${authContext?.user?.email}&contact_name=${profile?.responsible_person_first_name}_${profile?.responsible_person_last_name}`}
              ></videoask-embed> */}

              <videoask-embed url="https://www.videoask.com/ftdlfzvyk" />

              {/* how it works text */}
              <div className="mt-6">
                <p className="mb-2 text-prime-light">1) Prepare your response</p>
                <p className="mb-2 text-prime-light">2) Record your video</p>
                <p className="mb-2 text-prime-light">3) Send video</p>
                <p className="mb-2 text-prime-light">4) Click save and proceed</p>
              </div>
            </div>

            {/* right side */}
            <div className="flex flex-col p-2">
              <div className="flex-1">
                <p className="mb-6 text-prime-light">
                  This is your chance to tell us your story. Simply PREPARE your responses to the
                  interview questions, RECORD your video, then SAVE and PROCEED.
                </p>

                <p className="mb-4 text-xl font-medium">Interview questions</p>

                <p className="mb-4 text-prime-light">
                  1. Tell me about the most impactful Cybersecurity project you worked on and how
                  you contributed to it.
                </p>
                <p className="mb-4 text-prime-light">
                  2. Can you tell us about a time where a project was assigned to you and you did
                  not possess the skills necessary to complete that project? How did you respond?
                </p>
                <p className="text-prime-light">
                  3. Can you tell us about a time you led a team through a difficult situation?
                </p>
              </div>

              {/* active buttons */}
              <div className="flex justify-evenly">
                <Button
                  dataTestId="upload-recorded-video-btn"
                  label="Save and Proceed"
                  variant="full"
                  themecolor="prime"
                  onClick={handleVideoAsk}
                />
                <Button
                  dataTestId="record-later-btn"
                  label="Record later"
                  variant="outlined"
                  themecolor="prime"
                  onClick={redirectFromPage}
                />
              </div>

              <div className="p-2 mt-8 mb-2">
                <p className="m-2 text-prime-light">
                  Uploading a video may take some time to complete.
                </p>
                <p className="m-2 text-prime-light">
                  In the event that you'd like to record your interview at a later time, please
                  select the Record later link, and you will be automatically reminded to complete
                  your profile.
                </p>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default FreelancerVideoInterview;
