import React, { useEffect, useState, useCallback } from "react";
import { isEmpty } from "lodash";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// components
import { useToast } from "../../context";
import TaskCreateEdit from "../../pages/CSM/TaskTemplateCreateEdit";
import { ProjectTemplateService, TaskTemplateService, errorMsgForUser } from "../../services";
import { Paper, Button } from "../index";
import Column from "./Column";
import MovableItem from "./MovableItem";

// context

// helpers and functions
import { COLUMN_NAMES } from "./columns";

// services

const { PROGRESS_INITIATION, IN_PROGRESS, CLOSE } = COLUMN_NAMES;

const RoadmapList = ({
  className,
  projectUid,
  updateList,
  initialRoadmap = [],
  setChangedRoadmap = [],
  changedRoadmap = [],
  editable = true,
}) => {
  // context
  const { showToast } = useToast();

  const [showCreateTask, setShowCreateTask] = useState(false);
  const [taskUid, setTaskUid] = useState(undefined);

  useEffect(() => {
    console.log("changetRoadmap ---");
    changedRoadmap.map((n) => console.log(n.roadmap_index, n.name, n.phase));
  }, [changedRoadmap]);

  // if there is data in roadmap update it with roadmap_index
  // needed for dnd features
  //
  //
  //
  // TODO: add check if there is roadmap_index not to change it but to chech if
  // it goes by order (0, 1, 2, 3...)
  useEffect(() => {
    if (!isEmpty(initialRoadmap)) {
      setChangedRoadmap(
        // initialRoadmap.map((n, i) => ({ ...n, roadmap_index: i }))
        initialRoadmap
      );
    }
  }, [initialRoadmap, setChangedRoadmap]);

  // get project template so we can update new ordered roadmap
  const getProjectTemplate = useCallback(() => {
    console.log("getProjectTemplate");
    if (!projectUid) return;
    return ProjectTemplateService.get({ project_template_uid: projectUid })
      .then((r) => {
        setChangedRoadmap(
          // r.project_template.roadmap.map((n, i) => ({ ...n, roadmap_index: i }))
          r.project_template.roadmap
        );
      })
      .catch((err) => showToast("error", errorMsgForUser(err)));
  }, [projectUid, setChangedRoadmap, showToast]);

  // saves task list order for each column in db
  const saveTaskOrder = useCallback(
    (projectUid, tasks) =>
      // console.log('updateTaskOrder', projectUid, tasks);
      ProjectTemplateService.updateRoadmap({
        project_template_uid: projectUid,
        roadmap: [...tasks],
        // roadmap: JSON.stringify(tasks),
      })
        .then((r) => {
          setChangedRoadmap(r.roadmap);
        })
        .catch((err) => showToast("error", errorMsgForUser(err))),
    [showToast, setChangedRoadmap]
  );

  const arrayMove = useCallback((arr, fromIndex, toIndex, phase) => {
    let newArr = [...arr];
    let element = arr[fromIndex];
    if (element.phase !== phase) {
      element.phase = phase;
    }
    newArr.splice(fromIndex, 1);
    newArr.splice(toIndex, 0, element);
    return newArr;
  }, []);

  // function that will be triggered when drop event ends
  const moveCardHandler = useCallback(
    (sourceIndex, targetIndex, phase, recalculateIndex = false) => {
      const dragItem = changedRoadmap[sourceIndex];

      if (dragItem) {
        // console.log('moveCardHandler', sourceIndex, targetIndex);
        setChangedRoadmap((prevState) => {
          if (targetIndex === null || targetIndex === undefined) {
            targetIndex = 0;
            if (phase === "Initiation") {
              // do nothing
            } else if (phase === "In Progress") {
              targetIndex = prevState.filter((n) => n.phase === "Initiation").length;
            } else if (phase === "Close") {
              targetIndex = prevState.filter(
                (n) => n.phase === "Initiation" || n.phase === "In Progress"
              ).length;
            }
          }
          let result = arrayMove(prevState, sourceIndex, targetIndex, phase);
          if (recalculateIndex) {
            result = result.map((n, i) => ({ ...n, roadmap_index: i }));
          }
          return result;
        });
      }
    },
    [changedRoadmap, setChangedRoadmap, arrayMove]
  );

  const deleteTask = useCallback(
    (uid) => {
      console.log("deleteTask", uid);
      // task will be removed from roadmap
      // effectively BE will remove it on updateRoadmap.
      // let copyTasks = changedRoadmap.filter(task => uid !== task.uid);
      // setChangedRoadmap(copyTasks);
      TaskTemplateService.delete({ task_template_uid: uid }).then(() => {
        getProjectTemplate();
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getProjectTemplate, projectUid, changedRoadmap, saveTaskOrder]
  );

  // Filter tasks from list with phase to match column
  const returnItemsForColumn = useCallback(
    (columnName) => {
      // let filteredTasks = []
      // console.log('changedRoadmap', changedRoadmap)
      // if (changedRoadmap) {
      //   filteredTasks = changedRoadmap.filter(
      //     item => item.phase === columnName.value
      //   );
      // }

      let filteredTasks = changedRoadmap.filter((item) => item.phase === columnName.value);
      if (!filteredTasks.length) return <div className="text-prime-light">No tasks</div>;

      return filteredTasks.map((item) => (
        <div key={item.uid}>
          <MovableItem
            item={item}
            key={item.uid}
            name={item.name}
            currentColumnName={item.phase}
            changedRoadmap={changedRoadmap}
            setChangedRoadmap={setChangedRoadmap}
            targetedRoadmapIndex={item.roadmap_index}
            moveCardHandler={moveCardHandler}
            // updateTaskPhase={(item, phase) => updateTaskPhase(item, phase)}
            handleTaskEdit={() => {
              setTaskUid(item.uid);
              setShowCreateTask(true);
            }}
            handleDelete={() => deleteTask(item.uid)}
            editable={editable}
          />
        </div>
      ));
    },
    [deleteTask, editable, moveCardHandler, changedRoadmap, setChangedRoadmap]
  );

  return (
    <Paper className={className}>
      {editable && (
        <Button
          themecolor="prime"
          label="Add task"
          className="my-4 ml-auto "
          disabled={projectUid === undefined || projectUid === null || projectUid === "create"}
          // onClick={() => navigate(`/projects/create-task`)}
          onClick={() => {
            setShowCreateTask(true);
          }}
        />
      )}
      <div className="flex items-center justify-start py-4">
        <div className="w-1/2">Task</div>
        <div className="w-1/4">Assigned To</div>
        <div className="w-1/4">Duration</div>
      </div>
      {projectUid && (
        <>
          <DndProvider backend={HTML5Backend}>
            <Column title={PROGRESS_INITIATION.value}>
              {returnItemsForColumn(PROGRESS_INITIATION)}
            </Column>
            <Column title={IN_PROGRESS.value}>{returnItemsForColumn(IN_PROGRESS)}</Column>
            <Column title={CLOSE.value}>{returnItemsForColumn(CLOSE)}</Column>
          </DndProvider>
        </>
      )}

      {showCreateTask && (
        <TaskCreateEdit
          closeModal={() => {
            setTaskUid(undefined);
            setShowCreateTask(false);
          }}
          updateRoadmap={saveTaskOrder}
          roadmap={changedRoadmap}
          projectUid={projectUid}
          updateList={updateList}
          editTaskUid={taskUid}
          isEditable={editable}
        />
      )}
    </Paper>
  );
};

export default RoadmapList;
