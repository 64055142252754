/* NOTE: stripe elements documentation
  https://stripe.com/docs/stripe-js/react
*/
import React, { useState, useMemo } from "react";

// stripe
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// components
import CyberPopupStripe from "../../assets/img/cyber-popup-stripe-icon.png";
import { Button, Loader } from "../../components";
import { useToast } from "../../context";

// context

const APP_ENV = import.meta.env.VITE_APP_ENV;

const stripe_publishable_key_test =
  "pk_test_51KmLu1EVoawK75O7DFJzNlB3KKU3IdOVvX9WMMrxXtFztT8idY3gAa7FUfZIMFncZHneL3Tq3djEdSnoKxOjMfHS00uQ0CM63r";
const stripe_publishable_key_live =
  "pk_live_51KmLu1EVoawK75O7WBys9yRFGYVSgWTupSE2crJm6dRLDDLii7tewGlSn9L5Mt7bxyN6nlhlzvoxr5onrLxwwgX500HlGO39oS";

// ser product_ids for different envirement
let publishable_key = stripe_publishable_key_test;

if (APP_ENV === "production") {
  publishable_key = stripe_publishable_key_live;
}

// this is Publishable key
const stripePromise = loadStripe(publishable_key);

const useOptions = () => {
  // const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: "16px",
          // color: '#424770',
          letterSpacing: "0.025em",
          // fontFamily: 'Source Code Pro, monospace',
          // '::placeholder': {
          //   color: '#aab7c4',
          // },
          border: "1px solid red",
          backgroundColor: "#fff",
          padding: 4,
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const StripeForm = ({ createCard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [loader, setLoader] = useState(false);

  // context
  const { showToast } = useToast();

  const handleSubmit = async () => {
    const cardElement = elements.getElement(CardNumberElement);

    // stripe's create payment method Api
    setLoader(true);
    await stripe
      .createToken(cardElement)
      .then((res) => {
        console.log("stripe.createToken res:", res);
        createCard(res);
      })
      .catch((err) => {
        console.error("stripe.createToken err:", err);
        showToast("error", err);
      })
      .finally(() => setLoader(false));
  };

  return (
    <div className="p-6 border-2 shadow-lg rounded-3xl">
      {/* loader */}
      {loader && <Loader themeColor="fill-prime-light" />}

      {/* stripe forma */}
      <div className="mb-10">
        <div className="mb-4">
          <p className="text-prime-light">Card Number</p>
          <CardNumberElement options={options} />
        </div>

        <div className="mb-4">
          <p className="text-prime-light">Expiration Date</p>
          <CardExpiryElement options={options} />
        </div>

        <div className="">
          <p className="text-prime-light">CVC</p>
          <CardCvcElement options={options} />
        </div>
      </div>

      <Button
        dataTestId="pay-btn"
        variant="full"
        width="w-full"
        themecolor="prime"
        label="Add card"
        className="mb-6"
        disabled={!stripe || !elements}
        onClick={handleSubmit}
      />

      <img src={CyberPopupStripe} alt="logo" className="block h-10 mx-auto my-0" />
    </div>
  );
};

const StripeCard = ({ className, createCard }) => (
  <div className={`mb-8 ${className}`}>
    <Elements stripe={stripePromise} options={{}}>
      <StripeForm createCard={createCard} />
    </Elements>
  </div>
);

export default StripeCard;
