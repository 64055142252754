import { STATUS } from "../../constants/statuses";

export const FREELANCER_STATUSES = {
  NOT_REVIEWED: "Not_reviewed",
  EXPERT: "Expert",
  IN_REVIEW: "In_review",
  RESUBMITTED: "Resubmitted",
  ENTHUSIAST: "Enthusiast",
  DENIED: "Denied",
  APPROVED: "Approved",
};

/**
 * Freelancer table Tabs
 * @param counters State of counters defined in
 * FSMTable component
 * @description
 *  - key - filtering key from profile entity
 *  - value - value of the key
 *  - label - tab label
 *  - counter - tab counter
 */
export const FREELANCER_TABLE_TABS = (counters) => ({
  INCOMPLETE: {
    key: "application_status",
    value: STATUS.INCOMPLETE.value,
    label: "Incomplete",
    counter: counters.Incomplete,
  },
  RESUBMITTED: {
    key: "application_status",
    value: STATUS.RESUBMITTED.value,
    label: "Resubmitted",
    counter: counters.Resubmitted,
  },
  EXPERT_PENDING: {
    key: "background_check_status",
    value: STATUS.SENT.value,
    label: "Expert pending",
    counter: counters.Sent,
  },
  EXPERT: {
    key: "freelancer_status",
    value: STATUS.EXPERT.value,
    label: "Expert",
    counter: counters.Expert,
  },
  PENDING_REVIEW: {
    key: "application_status",
    value: STATUS.PENDING_REVIEW.value,
    label: "Pending Review",
    counter: counters.Pending_review,
  },
  ENTHUSIAST: {
    key: "application_status",
    value: STATUS.ENTHUSIAST.value,
    label: "Enthusiast",
    counter: counters.Enthusiast,
  },
  DENIED: {
    key: "application_status",
    value: STATUS.DENIED.value,
    label: "Denied",
    counter: counters.Denied,
  },
});

/**
 * FSM Table columns
 */
export const FSM_TABLE_COLUMNS = [
  {
    id: "name",
    // type: 'string with image',
    type: "string",

    label: "Name",
    style: "min-w-[200px] text-blue-500 underline",
  },
  {
    id: "email",
    type: "string",
    label: "Email",
  },
  {
    id: "date_submitted",
    type: "date",
    label: "Date Submitted",
  },
  {
    id: "application_status",
    type: "string",
    label: "Application Status",
  },
  // {
  //   id: 'resume',
  //   type: 'string',
  //   label: 'Resume',
  // },
  {
    id: "video",
    type: "string",
    label: "Video",
  },
  // {
  //   id: 'initiate_background',
  //   type: 'string',
  //   label: 'Initiate Background Check',
  // },
  {
    id: "background_status",
    type: "string",
    label: "Background Check Status",
  },
  {
    id: "freelancer_status",
    type: "string",
    label: "Cyber-expert Status",
  },
  {
    id: "days_inactive",
    type: "string",
    label: "Days Inactive",
  },
  {
    id: "profile_completion",
    type: "string",
    label: "Profile Completion",
  },
];

/**
 * Function that handles statuses updates
 * @param applicationStatus profile.application_status
 * @param key background_check_status | application_status
 * @param value value of the status
 */
export const handleFreelanceStatusUpdate = (appStatus, frStatus, bcStatus) => {
  let freelanceStatus;
  let videoStatus;
  let backgroundStatus;
  let appStatusUpdated;

  // if appStatus is denied
  if (appStatus === STATUS.DENIED.value) {
    backgroundStatus = "null";
    freelanceStatus = STATUS.DENIED.value;
  }

  // if appStatus is not expert
  if (appStatus !== STATUS.EXPERT.value) {
    backgroundStatus = "null";
  }

  if (appStatus !== STATUS.EXPERT.value && appStatus !== STATUS.DENIED.value) {
    freelanceStatus = STATUS.ENTHUSIAST.value;
  }

  // if appStatus is expert
  if (appStatus === STATUS.EXPERT.value && bcStatus === STATUS.APPROVED.value) {
    freelanceStatus = STATUS.EXPERT.value;
  }

  if (appStatus === STATUS.EXPERT.value && bcStatus === STATUS.DENIED.value) {
    appStatusUpdated = STATUS.DENIED.value;
    freelanceStatus = STATUS.DENIED.value;
  }

  if (appStatus === STATUS.EXPERT.value && bcStatus === STATUS.SENT.value) {
    freelanceStatus = STATUS.ENTHUSIAST.value;
  }

  // if appStatus is enthusiast
  if (appStatus === STATUS.ENTHUSIAST.value) {
    freelanceStatus = STATUS.ENTHUSIAST.value;
  }

  return { freelanceStatus, videoStatus, backgroundStatus, appStatusUpdated };
};
