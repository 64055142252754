import React from "react";

// Components
import Button from "../Button";

const CardProject = ({
  project_template = {},
  totalPopTokens,
  disabled = false,
  minWidth = "",
  purchaseMoreTokens,
  onClick = () => {},
  onClickLearnMore = () => {},
}) => (
  <div
    className={`flex flex-col p-0 mx-2 mb-8 border border-grey-800 rounded-2xl w-[26rem] ${minWidth}`}
  >
    {/* project name */}
    <div className="flex items-center h-16 px-6 bg-grey-pale rounded-t-2xl">
      <a
        href={project_template.href}
        target="_blank"
        rel="noreferrer"
        className="flex-1 text-xl font-medium text-center text-prime"
      >
        {project_template.short_text}
      </a>
    </div>

    <div className="flex flex-col h-full p-6">
      {/* project goal */}
      <div className="mb-6">
        {project_template?.project_goal && (
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: project_template.project_goal,
            }}
          />
        )}
      </div>

      <div className="flex flex-col flex-1">
        <div
          className="mb-3 text-lg text-center text-terc hover:underline hover:cursor-pointer"
          onClick={onClickLearnMore}
        >
          Learn more
        </div>

        <div className="mb-3 text-lg text-center text-gold">
          <i className="mr-2 fa-solid fa-coins" />
          {project_template.cost / 50} Pop tokens
        </div>

        {/* purchase btn */}
        {totalPopTokens < project_template.cost / 50 ? (
          <Button
            dataTestId={`purchase_project_${project_template?.short_tex}`}
            label={`Purchase ${project_template.cost / 50 - totalPopTokens} more Pop Tokens`}
            className="justify-center mx-auto"
            variant="full"
            themecolor="prime"
            disabled={disabled}
            onClick={() => purchaseMoreTokens(project_template.cost / 50 - totalPopTokens)}
          />
        ) : (
          <Button
            dataTestId={`purchase_project_${project_template?.short_tex}`}
            label="Purchase this Project"
            className="justify-center mx-auto"
            variant="full"
            themecolor="prime"
            disabled={disabled}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  </div>
);

export default CardProject;
